import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { z } from "zod";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { ProductApplicationSelect } from "../ProductApplicationSelect/ProductApplicationSelect";
import { SearchSelectInfiniteScroll } from "../SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import { endpoints } from "../../endpoints";
import type {
  CurrencyCode,
  OptionType,
  Product,
  UUID,
} from "../../types/types";
import type { PIMProductBase } from "../../types/types.PIM";
import { positiveIntegerRegex } from "../../util/regexes";
import {
  convertProductSKUToOption,
  useFormWrapper,
  useStoreState,
} from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import axios from "axios";
import { useAuthContext } from "../Auth";

type BaseQuoteRequestPayload = {
  product_id: UUID;
  sku_id: UUID;
  buyer_id: UUID;
  no_of_units: string;
  price_per_unit?: string;
  currency: CurrencyCode;
  total_quantity: string;
  packaging_unit_id: UUID;
  custom_packaging_quantity: string;
  unlisted_product_name: null;
};

type AddProductToQuoteRequestPayload = BaseQuoteRequestPayload &
  (
    | { applications: [string]; custom_application?: never }
    | { applications: []; custom_application: string }
    | { applications: []; custom_application?: never }
  );

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin: 0;
  padding: 24px 24px 0;
`;

const QuantityContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const createFormSchema = (roleIsSomeKindOfBuyer: boolean) =>
  z.object({
    product_id: z.string().min(1, "Product is required"),
    packaging: z.object({
      value: z.object({
        id: z.string(),
        package_volume: z.string(),
        packaging_unit: z.object({
          id: z.string(),
          name: z.string(),
        }),
      }),
      label: z.string(),
    }),
    no_of_units: z
      .string()
      .regex(positiveIntegerRegex, "Must be a positive integer"),
    total_quantity: z.string(),
    price_per_unit: roleIsSomeKindOfBuyer
      ? z.string().optional()
      : z.string().min(1, "Price is required"),
    disabled_uom: z.string().optional(),
    application: z
      .object({
        value: z.union([z.string(), z.number()]),
        label: z.string(),
      })
      .optional()
      .nullable(),
    custom_application: z.string().optional().nullable(),
  });

type FormValues = z.infer<ReturnType<typeof createFormSchema>>;

interface AddProductToQuoteRequestProps {
  quoteId: UUID;
  buyerId: UUID;
  onComplete: () => void;
  currency: CurrencyCode;
  existingProducts: Array<{ product_id: UUID; name: string }>;
}

export function AddProductToQuoteRequest({
  quoteId,
  buyerId,
  onComplete,
  currency,
  existingProducts,
}: AddProductToQuoteRequestProps) {
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { roleIsSomeKindOfBuyer } = useAuthContext();
  const { notifyError } = useContext(Notifications);
  const [product, setProduct] = useState<Product | null>(null);

  const methodsOfUseForm = useFormWrapper<FormValues>({
    resolver: zodResolver(createFormSchema(roleIsSomeKindOfBuyer)),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    trigger,
    formState,
  } = methodsOfUseForm;
  const { errors } = formState;

  const { packaging: packagingOption } = watch(["packaging"]);

  useEffect(() => {
    register({ name: "product_id", required: true });
    register("disabled_uom");
  }, [register]);

  useEffect(() => {
    if (packagingOption?.value?.packaging_unit?.name) {
      setValue("disabled_uom", packagingOption.value.packaging_unit.name);
    }
  }, [packagingOption, setValue]);

  const handleProductChange = (option: OptionType<Product>) => {
    setValue("product_id", option.value.id);
    if (product) {
      setValue("packaging", { value: undefined, label: undefined });
      setValue("no_of_units", "");
      setValue("total_quantity", "");
      setValue("disabled_uom", "");
      setValue("price_per_unit", "");
      setValue("application", null);
    }
    setProduct(option.value);
  };

  const handleNumberOfUnitsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNumberOfUnits = parseFloat(event.target.value);
    const packagingQuantity = packagingOption?.value?.package_volume
      ? parseFloat(packagingOption.value.package_volume)
      : null;

    if (packagingQuantity && newNumberOfUnits) {
      setValue(
        "total_quantity",
        (packagingQuantity * newNumberOfUnits).toString()
      );
      trigger("total_quantity");
    }
    trigger("no_of_units");
  };

  const handleTotalQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTotalQuantity = parseFloat(event.target.value);
    const packagingQuantity = packagingOption?.value?.package_volume
      ? parseFloat(packagingOption.value.package_volume)
      : null;

    if (packagingQuantity && newTotalQuantity) {
      setValue(
        "no_of_units",
        (newTotalQuantity / packagingQuantity).toString()
      );
      trigger("no_of_units");
    }
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const basePayload: BaseQuoteRequestPayload = {
        product_id: data.product_id,
        sku_id: data.packaging.value.id,
        buyer_id: buyerId,
        no_of_units: data.no_of_units,
        price_per_unit: data.price_per_unit,
        currency: currency,
        total_quantity: data.total_quantity,
        packaging_unit_id: data.packaging.value.packaging_unit.id,
        custom_packaging_quantity: data.packaging.value.package_volume,
        unlisted_product_name: null,
      };

      const payload: AddProductToQuoteRequestPayload =
        // If application exists and its value is not -0, it's a regular application
        data.application?.value && data.application.value !== -0
          ? {
              ...basePayload,
              applications: [data.application.value.toString()],
            }
          : // If application exists with value -0 and custom_application exists, it's a custom application
          data.application?.value === -0 && data.custom_application
          ? {
              ...basePayload,
              applications: [],
              custom_application: data.custom_application,
            }
          : // Otherwise, no application
            { ...basePayload, applications: [] };

      await axios.post(
        endpoints.v1_storefronts_quotes_items(storefront_id, quoteId),
        payload
      );
      onComplete();
    } catch (error) {
      notifyError(t("Failed to add product to quote"));
      console.error("Failed to add product to quote:", error);
    }
  };

  const productSkuOptions =
    product &&
    product.product_skus
      .filter((sku) => !sku.is_sample)
      .map(convertProductSKUToOption);

  return (
    <>
      <Title>{t("Add Product")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <SearchSelectInfiniteScroll
          name="product_id"
          errors={errors}
          formState={formState}
          placeholder={t("Product")}
          value={{
            value: product,
            label: product ? product.name : null,
          }}
          baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("status", "published");
            params.append("include_skus", "true");
            params.append("include_applications", "true");
            return params;
          })()}
          getOptions={(products: PIMProductBase[]) =>
            products
              .filter(
                (product) =>
                  !existingProducts.some((ep) => ep.product_id === product.id)
              )
              .map((product) => ({
                value: product,
                label: product.name,
              }))
          }
          onChange={(data: OptionType<Product>) => handleProductChange(data)}
          testid="product-search"
        />

        <Controller
          as={SelectBoxV2}
          control={control}
          name="packaging"
          placeholder={t("Preferred SKU")}
          options={productSkuOptions}
          isDisabled={!product}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />

        <TextField
          name="no_of_units"
          label={t("Number of Units")}
          type="number"
          onChange={handleNumberOfUnitsChange}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
        />

        <QuantityContainer>
          <TextField
            name="total_quantity"
            label={t("Total Quantity")}
            type="number"
            onChange={handleTotalQuantityChange}
            theref={register({ required: true })}
            errors={errors}
            formState={formState}
          />

          <TextField
            name="disabled_uom"
            label={t("UoM")}
            type="text"
            value={packagingOption?.value?.packaging_unit?.name || "--"}
            disabled
            theref={register({ required: false })}
            errors={errors}
            formState={formState}
          />
        </QuantityContainer>

        {!roleIsSomeKindOfBuyer && (
          <TextField
            name="price_per_unit"
            label={t("Price ($/UoM)")}
            type="number"
            theref={register}
            errors={errors}
            formState={formState}
          />
        )}

        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={product?.product_applications ?? []}
        />

        <PrimaryButtonFitContainer type="submit">
          {t("Add Product")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
