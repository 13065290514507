import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useParams, Link, useLocation } from "react-router-dom";
import useSWR from "swr";
import type { AxiosError } from "axios";
import axios from "axios";
import { useStoreState } from "../../../../../util/util";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { useAuthContext } from "../../../../../components/Auth";
import type { AttributeTemplateSchema } from "../../../../../types/types.PIM";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import { TemplatesNav } from "./TemplatesNav";
import { GoBackButtonSmall } from "../../../../../components/Buttons/Buttons";
import { useRoutePath } from "../../../../../util/Routing";

const SettingsSection = styled.div`
  margin: 24px 0;
`;

const SectionTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.primaryTextColor};
  margin-bottom: 16px;
`;

const SettingRow = styled.div`
  margin: 16px 0;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.secondaryTextColor};
  margin: 8px 0;
`;

export function TemplateSettings() {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { template_id } = useParams<{ template_id: string }>();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { hasPermission } = useAuthContext();
  const location = useLocation();
  const { adminPath } = useRoutePath();

  const { data: template, mutate } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/templates/${template_id}`);

  const handleSettingChange = async (
    field: keyof AttributeTemplateSchema,
    value: boolean
  ) => {
    try {
      await axios.patch(
        `/v2/tenants/${tenant_id}/pim/templates/${template_id}`,
        { [field]: value }
      );
      await mutate();
      notifySuccess(t("Settings updated successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("Failed to update settings"),
        { error }
      );
    }
  };

  if (!template) {
    return null;
  }

  return (
    <PageWrapper>
      <div style={{ position: "relative" }}>
        <Link
          to={
            location.search.includes("from=dashboard")
              ? `${adminPath}/dashboard`
              : `${adminPath}/pim/templates`
          }
        >
          <GoBackButtonSmall text="Back" />
        </Link>
      </div>
      <TemplatesNav
        pageTitle={template?.template_name ?? ""}
        tabIndex={5}
        templateID={template_id}
      />
      <ContentWrapper>
        <SettingsSection>
          <SectionTitle>{t("Completion Scores")}</SectionTitle>
          <Description>
            {t(
              "Configure which completion scores are enabled for this template."
            )}
          </Description>

          <SettingRow>
            <ToggleSwitchV2
              label={t("Enable Total Completion Score")}
              name="show_total_completion_score"
              defaultChecked={template.show_total_completion_score}
              onChange={(e) =>
                handleSettingChange(
                  "show_total_completion_score",
                  e.target.checked
                )
              }
              disabled={!hasPermission("modify_templates")}
            />
          </SettingRow>

          <SettingRow>
            <ToggleSwitchV2
              label={t("Enable Mandatory Completion Score")}
              name="show_mandatory_completion_score"
              defaultChecked={template.show_mandatory_completion_score}
              onChange={(e) =>
                handleSettingChange(
                  "show_mandatory_completion_score",
                  e.target.checked
                )
              }
              disabled={!hasPermission("modify_templates")}
            />
          </SettingRow>
        </SettingsSection>
      </ContentWrapper>
    </PageWrapper>
  );
}
