import { useState } from "react";
import type { UseFieldArrayMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import type { MethodsOfUseForm } from "../../types/types";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import { CheckBoxContainer, CheckBoxFinePrintLabel } from "../Form/Form";
import { Row } from "../Layout/Layout";

const RowWithMargin = styled(Row)`
  margin: 13px 0;
  div {
    margin-right: 10px;
  }
`;

export const TaxExemptFee = ({
  isTaxExempt,
  methodsOfUseForm,
  methodsOfUseFieldArray,
}: {
  isTaxExempt: boolean;
  methodsOfUseForm: MethodsOfUseForm;
  methodsOfUseFieldArray: UseFieldArrayMethods<Record<string, any>, "key">;
}) => {
  const [taxExemptValue, setTaxExemptValue] = useState(isTaxExempt);
  const { t } = useTranslation();
  const { fields, prepend, remove } = methodsOfUseFieldArray;
  const { register, setValue } = methodsOfUseForm;

  const hasTaxFee = () =>
    fields.some(
      (fee) => fee.id === "tax_fee" || fee.name.trim().toLowerCase() === "tax"
    );

  const updateTaxExempt = () => {
    const newTaxExemptVal = !taxExemptValue;
    if (!newTaxExemptVal && !hasTaxFee()) {
      prepend({ name: "Tax", amount: 0, id: "tax_fee" });
    } else {
      remove(
        fields.findIndex(
          (fee) =>
            fee.id === "tax_fee" || fee.name.trim().toLowerCase() === "tax"
        )
      );
    }
    console.info("*** newTaxExemptVal:", newTaxExemptVal);
    setTaxExemptValue(newTaxExemptVal);
    setValue("is_tax_exempt", newTaxExemptVal);
  };

  return (
    <RowWithMargin>
      <CheckBoxContainer>
        <CheckBoxNoLabel
          name="is_tax_exempt"
          ref={register}
          defaultChecked={taxExemptValue}
          onChange={updateTaxExempt}
          id="is_tax_exempt"
        />
        <CheckBoxFinePrintLabel htmlFor="is_tax_exempt">
          {t("Tax exempt")}
        </CheckBoxFinePrintLabel>
      </CheckBoxContainer>
    </RowWithMargin>
  );
};
