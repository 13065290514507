import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { z } from "zod";
import {
  DeleteButton,
  SecondaryButtonWithPlusIcon,
  PrimaryCancelButton,
  PrimaryButtonMedium,
  PrimaryButtonFitContainer,
  SecondaryButtonFitContainer,
} from "../../../../../components/Buttons/Buttons";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../../../components/Form/Form";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { Form } from "../../../../../layout/FormLayout";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import {
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../util/zod.util";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { SelectWithDeleteButton } from "../../../../../layout/shared";
import type { OptionType } from "../../../../../types/types";
import type {
  RoleSummary,
  TemplateViewConfigurstions,
  TemplateViewSchema,
} from "../../../../../types/types.PIM";
import {
  H3,
  RegularTextSmall,
} from "../../../../../components/Typography/Typography";
import theme from "../../../../../theme";
import { CheckBoxNoLabel } from "../../../../../components/CheckBoxes/CheckBoxes";
import styled from "styled-components";
import { t } from "i18next";
import { Modal } from "../../../../../components/Modal/Modal";
import { WarningIcon } from "../../../../../components/Icons/Icons";
import ReactTooltip from "react-tooltip";

const RolesContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left-width: 4px;
`;
const RoleFieldContainer = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;
const ModalWrapper = styled.div`
  padding: 50px 30px 20px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 30px;
`;

const AddRoleToTemplateViewSchema = z.object({
  name: zodRequiredString(t),
  role_ids: z
    .object({
      role: zodSelectBoxType,
    })
    .array()
    .optional(),
  is_assigned_to_customer: z.boolean().optional(),
  is_assigned_to_distributor: z.boolean().optional(),
  is_assigned_to_outside_login: z.boolean().optional(),
});

const AddRoleToTemplateViewSchemaFn = (t: (s: string) => string) =>
  z.object({
    name: zodRequiredString(t),
    role_ids: z
      .object({
        role: zodSelectBoxDefault(t),
      })
      .array()
      .optional(),

    is_assigned_to_customer: z.boolean().optional(),
    is_assigned_to_distributor: z.boolean().optional(),
    is_assigned_to_outside_login: z.boolean().optional(),
  });

type formOutput = z.infer<typeof AddRoleToTemplateViewSchema>;

export function TemplateViewConfigurationForm({
  handleCancel,
  configurations,
  handleSubmittedView,
  numberOfProducts,
  numberOfViews,
}: {
  handleCancel: () => void;
  showConfirmationModal: boolean;
  numberOfProducts: number;
  numberOfViews: number;
  configurations: {
    templateId?: string;
    viewId?: string;
    viewName?: string;
    config?: TemplateViewConfigurstions;
  };
  handleSubmittedView: (view: TemplateViewSchema) => void;
}) {
  const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();

  const { viewName, viewId, config, templateId } = configurations;
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddRoleToTemplateViewSchemaFn(t)),
    defaultValues: {
      name: viewName,
      role_ids:
        config?.assigned_roles.length === 0
          ? undefined
          : config?.assigned_roles.map((role) => {
              return {
                role: {
                  label: role.name,
                  value: role.id,
                },
              };
            }),
      is_assigned_to_customer: config?.is_assigned_to_customer,
      is_assigned_to_distributor: config?.is_assigned_to_distributor,
      is_assigned_to_outside_login: config?.is_assigned_to_outside_login,
    },
  });
  const { handleSubmit, control, formState, errors, register } =
    methodsOfUseForm;

  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "role_ids",
  });

  const { data: roles, error: rolesError } = useSWR<
    { data: RoleSummary },
    AxiosError
  >(`/v1/storefronts/${storefront_id}/roles`);
  useEffect(() => {
    if (roles) {
      // const usedRoles = fields.map((field) => field.role?.value);
      setRoleOptions(
        roles.data.map((role) => {
          return { value: role.id, label: role.name };
        })
      );
    }
  }, [roles]);

  useEffect(() => {
    if (rolesError) {
      notifyError("There was an error fetching user roles, please try again.");
    }
  }, [rolesError, notifyError]);

  const defaultViewtooltipMessage = t(
    "Create a new view before removing the current assignment to ensure that views are reassigned and data access is maintained."
  );
  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <RoleFieldContainer>
        <SelectWithDeleteButton key={field.id}>
          <Controller
            key={field.id}
            as={SelectBoxV2}
            control={control}
            name={`role_ids[${index}].role`}
            placeholder={t("Select User Role")}
            options={roleOptions}
            disabled={config && numberOfViews === 1}
            rules={{ required: true }}
            errors={{
              [`role_ids[${index}].role`]:
                errors?.role_ids?.[index]?.role?.value ??
                errors?.role_ids?.[index]?.role ??
                undefined,
            }}
            formState={formState}
          />
          <DeleteButton
            testid={`delete-button-${index}`}
            id={`delete-button-${index}`}
            onClick={() => remove(index)}
            type="button"
            height={20}
            width={20}
            disabled={config && numberOfViews === 1}
            datafor={`delete-button-${index}`}
            datatip={defaultViewtooltipMessage}
          />
        </SelectWithDeleteButton>
        <ReactTooltip id={`delete-button-${index}`} />
      </RoleFieldContainer>
    ));
  };

  const onSubmit = (values: formOutput) => {
    if (numberOfProducts > 0) {
      setShowConfirmationModal(true);
    } else confirmSubmit(values);
  };

  const confirmSubmit = async (values: formOutput) => {
    setShowConfirmationModal(false);
    setIsSubmitting(true);
    const formValues = {
      ...values,
      role_ids: values.role_ids?.map((role) => role.role.value),
    };

    try {
      const response = viewId
        ? await axios.patch<TemplateViewSchema>(
            `/v2/storefronts/${storefront_id}/pim/templates/${templateId}/views/${viewId}`,
            formValues
          )
        : await axios.post<TemplateViewSchema>(
            `/v2/storefronts/${storefront_id}/pim/templates/${templateId}/views`,
            formValues
          );
      notifySuccess(
        configurations.templateId
          ? t("Template view updated successfully")
          : "Template view created successfully"
      );
      handleSubmittedView(response.data);
    } catch (error) {
      notifyError(
        configurations.templateId
          ? t("There was an error editing template view, please try again.")
          : t("There was an error creating template view, please try again."),
        { error }
      );
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <H3>{t("View Configuration")}</H3>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="name"
          label={t("View Name")}
          theref={register({
            required: true,
          })}
          readOnly={configurations.viewName?.toLocaleLowerCase() === "default"}
          formState={formState}
          errors={errors}
          type="text"
        />
        <div
          style={{
            margin: `25px 0 10px`,
          }}
        >
          <H3 style={{ margin: 0 }}>{t("Internal Assignment")}</H3>
          <RegularTextSmall>
            {t(
              "Each internal role can only be assigned to one view at a time."
            )}
          </RegularTextSmall>
        </div>
        <RolesContainer>
          <div
            style={{
              background: theme.secondaryBG,
              padding: "10px",
              borderBottom: `1px solid ${theme.secondaryBorder}`,
            }}
          >
            {t("Roles")}
          </div>
          {fields.length === 0 && (
            <RegularTextSmall
              style={{ padding: `15px 10px`, display: "block" }}
            >
              {t("No roles assigned")}
            </RegularTextSmall>
          )}
          {createFields()}
        </RolesContainer>
        {fields.length < roleOptions.length && (
          <SecondaryButtonWithPlusIcon
            type="button"
            style={{ marginBottom: "10px" }}
            onClick={() => append({ name: "" })}
          >
            {t("Add")}
          </SecondaryButtonWithPlusIcon>
        )}

        <H3 style={{ margin: `25px 0 10px` }}>{t("Customer Assignment")}</H3>
        <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
          <div style={{ width: "22px", marginRight: "15px" }}>
            <CheckBoxNoLabel
              name="is_assigned_to_customer"
              id="is_assigned_to_customer"
              ref={register({ required: true })}
              data-for="is_assigned_to_customer"
              data-tip={
                config && numberOfViews === 1
                  ? defaultViewtooltipMessage
                  : undefined
              }
              disabled={config && numberOfViews === 1}
            />
            <ReactTooltip id={`is_assigned_to_customer`} />
          </div>
          <CheckBoxFinePrintLabel htmlFor="is_assigned_to_customer">
            {t("Enable for customers")}
          </CheckBoxFinePrintLabel>
        </CheckBoxContainer>

        <H3 style={{ margin: `25px 0 10px` }}>{t("Distributor Assignment")}</H3>
        <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
          <div style={{ width: "22px", marginRight: "15px" }}>
            <CheckBoxNoLabel
              name="is_assigned_to_distributor"
              id="is_assigned_to_distributor"
              ref={register({ required: true })}
              data-for="is_assigned_to_distributor"
              data-tip={
                config && numberOfViews === 1
                  ? defaultViewtooltipMessage
                  : undefined
              }
              disabled={config && numberOfViews === 1}
            />
            <ReactTooltip id={`is_assigned_to_distributor`} />
          </div>
          <CheckBoxFinePrintLabel htmlFor="is_assigned_to_distributor">
            {t("Enable for distributors")}
          </CheckBoxFinePrintLabel>
        </CheckBoxContainer>

        <H3 style={{ margin: `25px 0 10px` }}>{t("Public Assignment")}</H3>
        <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
          <div style={{ width: "22px", marginRight: "15px" }}>
            <CheckBoxNoLabel
              name="is_assigned_to_outside_login"
              id="is_assigned_to_outside_login"
              ref={register({ required: true })}
              data-for="is_assigned_to_outside_login"
              data-tip={
                config && numberOfViews === 1
                  ? defaultViewtooltipMessage
                  : undefined
              }
              disabled={config && numberOfViews === 1}
            />
            <ReactTooltip id={`is_assigned_to_outside_login`} />
          </div>
          <CheckBoxFinePrintLabel htmlFor="is_assigned_to_outside_login">
            {t("Enable for outside login")}
          </CheckBoxFinePrintLabel>
        </CheckBoxContainer>
        <div style={{ display: "flex" }}>
          <PrimaryCancelButton
            style={{ marginRight: "10px", width: "100%" }}
            type="button"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t("Cancel")}
          </PrimaryCancelButton>
          <PrimaryButtonMedium
            loading={isSubmitting}
            style={{ marginLeft: "10px", width: "100%" }}
            disabled={config && numberOfViews === 1}
          >
            {t("Save")}
          </PrimaryButtonMedium>
        </div>
        <Modal
          overlay
          show={showConfirmationModal}
          closeModal={() => {
            setShowConfirmationModal(false);
          }}
          modalWidth={"600px"}
        >
          <ModalWrapper>
            <WarningTitle>
              <div style={{ marginBottom: "15px" }}>
                <WarningIcon width={50} height={50} />
              </div>
              {t(
                `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                {
                  numberOfProducts: numberOfProducts,
                  product: numberOfProducts === 1 ? "product" : "products",
                }
              )}
            </WarningTitle>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <SecondaryButtonFitContainer
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </SecondaryButtonFitContainer>
              <PrimaryButtonFitContainer
                onClick={handleSubmit((formValues) =>
                  confirmSubmit(formValues)
                )}
                style={{ marginLeft: "10px" }}
              >
                {t("Confirm")}
              </PrimaryButtonFitContainer>
            </div>
          </ModalWrapper>
        </Modal>
      </Form>
    </>
  );
}
