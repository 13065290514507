import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components";
import {
  DeleteButton,
  SecondaryButtonWithPlusIcon,
} from "../../../components/Buttons/Buttons";
import { GenericDialogBody } from "../../../components/ConfirmDialog/ConfirmDialog";
import { CurrencyInput } from "../../../components/CurrencyInput/CurrencyInput";
import { InfoIcon } from "../../../components/Icons/Icons";
import { SearchSelect } from "../../../components/SearchSelect/SearchSelect";
import { H3 } from "../../../components/Typography/Typography";
import { Form } from "../../../layout/FormLayout";
import type {
  CreatedOption,
  IFeesAndChargesSchema,
  OptionType,
} from "../../../types/types";
import { positiveNumberRegex } from "../../../util/regexes";
import { DialogWrapper } from "../../SharedPages/OrganizationPage/ProductsList/BulkActionOnProducts/utils";
import { SaveButtonPrimaryMedium } from "../../admin/SellerAdmin/SellerAdminSettings/shared";

type UpdateFeesDialogProps = {
  show: boolean;
  onComplete: (values: Array<UpdatedFees>) => void;
  onCancel: () => void;
  fees: IFeesAndChargesSchema[];
  fieldName?: string;
  keyName?: string;
};

export type UpdatedFees = {
  name: string;
  amount: string;
  description?: string;
  is_disabled: boolean;
};

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const FlexForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const SearchSelectWrapper = styled.div`
  width: 230px;
`;

const AmountWrapper = styled.div`
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
`;

export const UpdateFeesDialog = ({
  show,
  onCancel,
  onComplete,
  fees,
}: UpdateFeesDialogProps) => {
  const [search_select_options, set_search_select_options] = useState<
    OptionType<IFeesAndChargesSchema>[]
  >(() => fees.map((fee) => ({ label: fee.name, value: fee })));

  const [selected_items, set_selected_items] = useState<
    Record<string, UpdatedFees>
  >({});

  const { control, errors, formState, handleSubmit, register, setValue } =
    useForm({
      defaultValues: {
        fees_dialog: [{ name: "", amount: "" }],
      },
      reValidateMode: "onChange",
      mode: "onSubmit",
    });
  const { fields, append, remove } = useFieldArray<
    IFeesAndChargesSchema,
    "key"
  >({
    control,
    name: "fees_dialog",
    keyName: "key",
  });

  const { t } = useTranslation();
  const theme = useTheme();

  const handle_search_select_change = (
    data: CreatedOption<IFeesAndChargesSchema | string> | null,
    index: number,
    key: string
  ) => {
    if (data && typeof data.value === "object") {
      setValue(`fees_dialog[${index}].name`, data.value.name);
      setValue(`fees_dialog[${index}].amount`, data.value.amount);
      setValue(`fees_dialog[${index}].description`, data.value.description);
      setValue(`fees_dialog[${index}].id`, data.value.id);
      const fee = data.value as IFeesAndChargesSchema;
      set_selected_items((prev) => {
        prev[`fees_dialog[${key}]`] = {
          name: fee.name,
          amount: fee.amount,
          description: fee.description,
          is_disabled: true,
        };
        return { ...prev };
      });
    } else if (data && typeof data.value === "string") {
      setValue(`fees_dialog[${index}].name`, data.value);
      const fee_name = data.value as string;
      set_selected_items((prev) => {
        prev[`fees_dialog[${key}]`] = {
          name: fee_name,
          amount: "",
          is_disabled: false,
        };
        return { ...prev };
      });
    }
  };

  const handle_delete = (index: number, key: string) => {
    remove(index);
    set_selected_items((prev) => {
      delete prev[`fees_dialog[${key}]`];
      return { ...prev };
    });
  };

  const on_save = (formValues: {
    fees_dialog: Array<{ name: string; amount: string; is_disabled: boolean }>;
  }) => {
    onComplete(formValues.fees_dialog);
  };

  const should_disable = (key: string) => {
    const selected_item = selected_items[`fees_dialog[${key}]`];
    return selected_item?.is_disabled;
  };

  const get_amount = (key: string) => {
    const selected_item = selected_items[`fees_dialog[${key}]`];
    return selected_item?.amount;
  };

  useEffect(() => {
    const normalize_name = (name: string) =>
      name.replace(" ", "_").toLowerCase();

    const selected_item_values = Object.values(selected_items).reduce<
      Record<string, UpdatedFees>
    >((acc, item) => {
      acc[normalize_name(item.name)] = item;
      return acc;
    }, {});

    const updated_search_select_options = fees.reduce<
      Array<OptionType<IFeesAndChargesSchema>>
    >((acc, fee) => {
      const { name, amount } = fee;
      const selected_item = selected_item_values[normalize_name(name)];
      if (!selected_item || Number(selected_item.amount) !== Number(amount)) {
        acc.push({ label: name, value: fee });
      }
      return acc;
    }, []);

    set_search_select_options(updated_search_select_options);
  }, [fees, selected_items]);

  return (
    <GenericDialogBody show={show} closeDialog={onCancel}>
      <DialogWrapper>
        <H3 style={{ margin: 0 }}>{t("Add Fees & Charges")}</H3>
        <FlexForm onSubmit={handleSubmit(on_save)}>
          {fields.map((field, index) => {
            return (
              <FormRow key={field.key}>
                <Controller
                  control={control}
                  name={`fees_dialog[${index}].name`}
                  defaultValue={field.name}
                  rules={{ required: true }}
                  render={({ onChange }) => (
                    <SearchSelectWrapper>
                      <SearchSelect
                        name={`fees_dialog[${index}].name`}
                        options={search_select_options}
                        errors={{
                          [`fees_dialog[${index}].name`]:
                            errors?.fees_dialog?.[index]?.name ?? undefined,
                        }}
                        formState={formState}
                        placeholder={t("Fees & Charges")}
                        isClearable={false}
                        defaultInputValue={field.name}
                        onChange={(
                          data: CreatedOption<
                            IFeesAndChargesSchema | string
                          > | null
                        ) => {
                          handle_search_select_change(
                            data,
                            index,
                            field.key ?? ""
                          );
                          onChange(
                            data?.__isNew__
                              ? data?.value
                              : (data?.value as IFeesAndChargesSchema)?.name
                          );
                        }}
                        isCreatable={true}
                        isValidNewOption={(val: string) => {
                          return (
                            val.length < 50 &&
                            val !== "" &&
                            val.trim().toLowerCase() !== "tax" &&
                            Object.values(selected_items).every(
                              ({ name }) =>
                                name?.toLowerCase() !== val.toLowerCase()
                            )
                          );
                        }}
                      />
                    </SearchSelectWrapper>
                  )}
                />
                <Controller
                  control={control}
                  name={`fees_dialog[${index}].amount`}
                  defaultValue={get_amount(field.key ?? "")}
                  render={({ onChange }) => (
                    <AmountWrapper>
                      <CurrencyInput
                        name={`fees_dialog[${index}].amount`}
                        label={`Amount ($)`}
                        theref={register({
                          required: true,
                          pattern: {
                            value: positiveNumberRegex,
                            message: t("Must be a valid numeric value"),
                          },
                          validate: (val) => {
                            const selected_item =
                              selected_items[`fees_dialog[${field.key ?? ""}]`];
                            const selected_fee = fees.find(
                              (fee) =>
                                fee.name.toLowerCase() ===
                                selected_item?.name.toLowerCase()
                            );
                            if (
                              selected_item?.is_disabled &&
                              selected_fee &&
                              Number(val) !== Number(selected_fee.amount)
                            ) {
                              return t(
                                "Accepted fee price is {{accepted_value}}",
                                { accepted_value: selected_fee.amount }
                              );
                            }
                            return true;
                          },
                        })}
                        onChange={(event) => {
                          const value = event.target.value;
                          onChange(value);
                          setValue(`fees_dialog[${index}].amount`, value);
                          set_selected_items((prev) => {
                            const prev_item = prev[`fees_dialog[${field.key}]`];
                            if (prev_item) {
                              prev_item.amount = value;
                              prev[`fees_dialog[${field.key}]`] = prev_item;
                              return { ...prev };
                            }
                            return prev;
                          });
                        }}
                        formState={formState}
                        errors={{
                          [`fees_dialog[${index}].amount`]:
                            errors?.fees_dialog?.[index]?.amount ?? undefined,
                        }}
                        type="number"
                        defaultValue={get_amount(field.key ?? "")}
                        readOnly={should_disable(field.key ?? "")}
                        testid={`fees_dialog-${index}-amount`}
                      />
                    </AmountWrapper>
                  )}
                />
                {selected_items[`fees_dialog[${field.key ?? ""}]`]
                  ?.description && (
                  <div
                    data-for={`fees_dialog[${index}]-description`}
                    data-tip={
                      selected_items[`fees_dialog[${field.key ?? ""}]`]
                        .description ?? ""
                    }
                  >
                    <InfoIcon fill={theme.secondaryIconColor} />
                    <ReactTooltip id={`fees_dialog[${index}]-description`} />
                  </div>
                )}
                <ButtonContainer style={{ marginTop: "-8px" }}>
                  <DeleteButton
                    type="button"
                    onClick={() => handle_delete(index, field.key ?? "")}
                  />
                </ButtonContainer>
                <input
                  name={`fees_dialog[${index}].is_disabled`}
                  defaultValue={String(
                    should_disable(field.key ?? "") ?? false
                  )}
                  ref={register({ required: false })}
                  type={"hidden"}
                />
              </FormRow>
            );
          })}
          <SecondaryButtonWithPlusIcon
            style={{ width: "fit-content", marginTop: "16px" }}
            type="button"
            onClick={() => {
              append({
                name: "",
                description: "",
                amount: "",
              });
            }}
          >
            {t("Add")}
          </SecondaryButtonWithPlusIcon>
          <SaveButtonPrimaryMedium
            type="button"
            onClick={() => {
              handleSubmit(on_save)();
            }}
          >
            {t("Save")}
          </SaveButtonPrimaryMedium>
        </FlexForm>
      </DialogWrapper>
    </GenericDialogBody>
  );
};
