import type { ColumnDef, Getter, Row } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import axios from "axios";
import { stringify } from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import type { SortingRule } from "react-table";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import useSWR from "swr";
import type { DecodedValueMap, QueryParamConfigMap } from "use-query-params";
import {
  ArrayParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useAuthContext } from "../../../../../../components/Auth";
import {
  DeleteButton,
  EditButton,
  ListGridToggleButton,
  PrimaryButtonWithPlusIcon,
  TertiaryTextButtonSmall,
} from "../../../../../../components/Buttons/Buttons";
import type { ChipType } from "../../../../../../components/Chips/Chips";
import type {
  DateRange,
  DateRangeStr,
} from "../../../../../../components/DateFilter/date_filter.utils";
import { get_preselected_date_range } from "../../../../../../components/DateFilter/date_filter.utils";
import { DateFilter } from "../../../../../../components/DateFilter/DateFilter";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { DropDownButton } from "../../../../../../components/DropDownButton/DropDownButton";
import { FilterBy } from "../../../../../../components/FilterBy/FilterBy";
import { chipsToStringArray } from "../../../../../../components/Filters/Filters";
import { InfoIcon } from "../../../../../../components/Icons/Icons";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";
import { TableWithBulkAction } from "../../../../../../components/Table/TableWithBulkAction";
import type { TableRefProps } from "../../../../../../components/Table/utils";
import { WithPermission } from "../../../../../../components/WithPermission/WithPermission";
import { endpoints } from "../../../../../../endpoints";
import { Flex, Flex1, Flex2 } from "../../../../../../layout/FormLayout";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../../../layout/portalPageLayout";
import type { Language, OptionType } from "../../../../../../types/types";
import {
  isChipTypeArray,
  isEmpty,
  isStringArray,
} from "../../../../../../types/types";
import type {
  Assets,
  GridItemProps,
  PIMAssetsResponse,
  SupportedAssetCategoryType,
} from "../../../../../../types/types.PIM";
import { useDebounce } from "../../../../../../util/hooks";
import { getCountryOption } from "../../../../../../util/location";
import { useCountriesList } from "../../../../../../util/Locations";
import { useRoutePath } from "../../../../../../util/Routing";
import {
  defaultHandleSort,
  formatDate,
  rowHover,
  toTitleCase,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../../util/util";
import { appendFiltersToUrl } from "../../../../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { BulkActionOnAssets } from "../../../../../SharedPages/ProductDetailPage/Assets/BulkActionOnAssets/BulkActionOnAssets";
import { EditOrGenerateProductDocument } from "../../../../../SharedPages/ProductDetailPage/Assets/EditOrGenerateProductDocuments/EditOrGenerateProductDocument";
import { NewAssetForm } from "../../../../../SharedPages/ProductDetailPage/Assets/NewAssetForm";
import { AssetNameIcon } from "../../../../../SharedPages/ProductDetailPage/Assets/util";
import { GridAssetViewWithBulkAction } from "../GridAssetView/GridAssetViewWithBulkAction";
import { DeleteAssetModal } from "./DeleteAsset";
import { EditAsset } from "./EditAsset";

const NewAssetFormContainer = styled.div`
  #new_asset_form_id {
    margin-top: 60px;
  }
`;

const ScrollGridWrapper = styled.div`
  max-height: 1368px;
  position: relative;
  overflow: auto;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};

  & div[class*="TableWrapper"] {
    overflow: visible;
  }

  @media screen and (max-width: 1200px) {
    max-height: 1692px;
  }

  @media screen and (max-width: 710px) {
    max-height: 3312px;
  }
`;

type TableProduct = {
  id: string | boolean;
  name: string;
  expiration_date: string;
  issue_date: string;
  country: string;
  region: string;
  document_type: string;
  signed_url: string;
};

function getObjArrayFromStringArray(strArr: string[]) {
  return strArr?.map((name) => ({ name }));
}

type ApplyFiltersToURL = {
  categoryType: string[];
  assetType: string[];
  docType: string[];
  languages: string[];
  countries: string[];
  regions: string[];
  issue_date: DateRangeStr | null;
  expiration_date: DateRangeStr | null;
  renewal_date: DateRangeStr | null;
  params: URLSearchParams;
};

const applyFiltersToURL = ({
  categoryType,
  assetType,
  docType,
  countries,
  regions,
  languages,
  issue_date,
  expiration_date,
  renewal_date,
  params,
}: ApplyFiltersToURL): URLSearchParams => {
  if (assetType) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: assetType,
      key: "asset_type",
    });
  }
  if (categoryType) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: categoryType,
      key: "asset_category",
    });
  }
  if (docType) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: docType,
      key: "document_type",
    });
  }
  if (countries) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: countries,
      key: "country",
    });
  }
  if (languages) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: languages,
      key: "language",
    });
  }
  if (regions) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: regions,
      key: "region",
    });
  }
  if (issue_date) {
    const { startDate, endDate } = issue_date;
    if (!!startDate && startDate !== "undefined" && startDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [startDate],
        key: "issue_date_start",
      });
    }
    if (!!endDate && endDate !== "undefined" && endDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [endDate],
        key: "issue_date_end",
      });
    }
  }
  if (expiration_date) {
    const { startDate, endDate } = expiration_date;
    if (!!startDate && startDate !== "undefined" && startDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [startDate],
        key: "expiration_date_start",
      });
    }
    if (!!endDate && endDate !== "undefined" && endDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [endDate],
        key: "expiration_date_end",
      });
    }
  }
  if (renewal_date) {
    const { startDate, endDate } = renewal_date;
    if (!!startDate && startDate !== "undefined" && startDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [startDate],
        key: "renewal_date_start",
      });
    }
    if (!!endDate && endDate !== "undefined" && endDate !== "null") {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: [endDate],
        key: "renewal_date_end",
      });
    }
  }
  return params;
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

const DropDownContainerWrapper = styled(DropdownContainer)`
  .per_Page {
    > div {
      z-index: 3;
    }
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;

const AddAssetButtonWrapper = styled.div`
  flex-shrink: 0;
`;

const BulkActionWrapper = styled.div`
  flex-shrink: 0;
  .dropdown_button {
    button {
      padding: 8px 12px;
    }
  }
`;

type ConstructQuery = {
  baseUrl: string;
  query: string;
  assetType: string[];
  categoryType: string[];
  docType: string[];
  countries: string[];
  regions: string[];
  languages: string[];
  limit: number;
  issue_date: DateRangeStr | null;
  expiration_date: DateRangeStr | null;
  renewal_date: DateRangeStr | null;
};

/**
 * Assets list page.  Used for seller admin to manage all his assets.
 */
export function AssetList() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    asset_type: ArrayParam,
    country: ArrayParam,
    region: ArrayParam,
    document_type: ArrayParam,
    language: ArrayParam,
    asset_category: ArrayParam,
    offset: NumberParam,
    issue_date: ObjectParam,
    expiration_date: ObjectParam,
    renewal_date: ObjectParam,
  });
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [assetTypesFromURL, setAssetTypesFromURL] = useState(
    getObjArrayFromStringArray(
      ((query?.asset_type ?? []) as string[]).map((assetType) =>
        toTitleCase(assetType)
      )
    )
  );
  const [categoryTypesFromURL, setcategoryTypesFromURL] = useState(
    getObjArrayFromStringArray((query?.asset_category ?? []) as string[])
  );
  const [docTypesFromURL, setDocTypesFromURL] = useState(
    getObjArrayFromStringArray((query?.document_type ?? []) as string[])
  );

  const countries = useCountriesList();
  const [countriesFromURL, setCountriesFromURLL] = useState(
    getObjArrayFromStringArray(
      (query?.country?.map(
        (country) =>
          countries.find((c) => c.value === country)?.label ?? country
      ) ?? []) as string[]
    )
  );

  const [regionsFromURL, setRegionsFromURLL] = useState(
    getObjArrayFromStringArray((query?.region ?? []) as string[])
  );
  const [languagesFromURL, setLanguagesFromURLL] = useState(
    getObjArrayFromStringArray((query?.language ?? []) as string[])
  );

  const [issue_date_from_url, set_issue_date_from_url] = useState(
    query?.issue_date ?? null
  );

  const [renewal_date_from_url, set_renewal_date_from_url] = useState(
    query?.renewal_date ?? null
  );

  const [expiration_date_from_url, set_expiration_date_from_url] = useState(
    query?.expiration_date ?? null
  );

  const [assetLanguages, setAssetLanguages] = useState<OptionType[]>([]);
  const [editRowData, setEditRowData] = useState<Assets>();
  const [deleteRowData, setDeleteRowData] = useState<any>();
  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const perPageItems = [10, 20, 50, 100];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const totalLimit = 10000;
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const [selectedAssetType, setSelectedAssetType] = useState(
    isStringArray(query.asset_type) ? query.asset_type : []
  );
  const [selectedCategoryType, setSelectedCategoryType] = useState(
    isStringArray(query.asset_category) ? query.asset_category : []
  );
  const [selectedDocTypes, setSelectedDocTypes] = useState(
    isStringArray(query.document_type) ? query.document_type : []
  );
  const [selectedCountries, setSelectedCountries] = useState(
    isStringArray(query.country) ? query.country : []
  );
  const [selectedRegions, setSelectedRegions] = useState(
    isStringArray(query.region) ? query.region : []
  );
  const [selectedLanguages, setSelectedLanguages] = useState(
    isStringArray(query.language) ? query.language : []
  );
  const [selectedIssueDate, setSelectedIssueDate] = useState(
    query.issue_date ?? null
  );

  const [selectedExpirationDate, setSelectedExpirationDate] = useState(
    query.expiration_date ?? null
  );

  const [selectedRenewalDate, setSelectedRenewalDate] = useState(
    query.renewal_date ?? null
  );

  const [showSlideOut, setShowSlideOut] =
    useState<"add" | "edit" | "delete" | "generate" | false>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [assetTypesFilters, setAssetTypesFilters] = useState<string[]>([]);

  const [documentTypesFilters, setDocumentTypesFilters] = useState<string[]>(
    []
  );

  const [assetCategoryTypesFilters, setAssetCategoryTypesFilters] = useState<
    SupportedAssetCategoryType[]
  >([]);

  const [languagesFilters, setLanguagesFilters] = useState<string[]>([]);
  const [countryFilters, setCountryFilters] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);

  const [tableData, setTableData] = useState<TableProduct[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[] | "all">();
  const [selectedItems, setSelectedItems] =
    useState<Record<number, Array<Assets>>>();

  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");

  const { t } = useTranslation();
  const { hasPermission } = useAuthContext();
  const { storefront_id, tenant_id } = useStoreState();
  const { supported_languages } = useSupportedLanguages();
  const { adminPath } = useRoutePath();
  const { notifyError } = useContext(Notifications);
  const product_document_ref = useRef<{ clear_poll_timeout: () => void }>(null);
  const resetSelectedRowsRef = useRef<TableRefProps>(null);
  const history = useHistory();

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const closeCreateSlideOut = () => {
    setShowSlideOut(false);
    mutateAssetFilters();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    mutateAssetFilters();
  };

  const handleClearFilters = () => {
    setQuery({
      asset_category: undefined,
      asset_type: undefined,
      country: undefined,
      region: undefined,
      language: undefined,
      document_type: undefined,
      issue_date: undefined,
      expiration_date: undefined,
      renewal_date: undefined,
    });
    setSelectedAssetType([]);
    setSelectedCategoryType([]);
    setSelectedCountries([]);
    setSelectedRegions([]);
    setSelectedLanguages([]);
    setSelectedDocTypes([]);
    setSelectedIssueDate(null);
    setSelectedExpirationDate(null);
    setSelectedRenewalDate(null);
    setActiveFilter("");
    mutate();
  };

  const handleDelete = (row: Row<Assets>) => {
    setShowDeleteModal(true);
    setDeleteRowData(row?.original);
  };

  const handleEdit = (asset: Assets) => {
    if (asset.is_generated) {
      setShowSlideOut("generate");
    } else {
      setShowSlideOut("edit");
    }
    setEditRowData(assetsResponse?.data.find((item) => item.id === asset.id));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const handleAssetType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    const assetTypes = chipsToStringArray(values).map((asset) =>
      asset.toLowerCase()
    );
    setSelectedAssetType(assetTypes);
    setActiveFilter("asset_type");
    setQuery({ asset_type: assetTypes }, "replaceIn");

    setOffset(0);
  };

  const handleCategoryType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    let categoryTypes = chipsToStringArray(values);
    setSelectedCategoryType(categoryTypes);
    setActiveFilter("asset_category");
    setQuery({ asset_category: categoryTypes }, "replaceIn");
    setOffset(0);
  };

  const handleDocTypeFilter = ({ values }: { values: ChipType[] }) => {
    let docTypes = chipsToStringArray(values);
    setSelectedDocTypes(docTypes);
    setActiveFilter("document_type");
    setQuery({ document_type: docTypes }, "replaceIn");
    setOffset(0);
  };

  const handleCountryFilter = ({ values }: { values: ChipType[] }) => {
    let countryList = chipsToStringArray(values).map(
      (country) => countries.find((c) => c.label === country)?.value ?? country
    );
    setSelectedCountries(countryList);
    setActiveFilter("country");
    setQuery(
      {
        country: countryList,
      },
      "replaceIn"
    );
    setOffset(0);
  };

  const handleRegionFilter = ({ values }: { values: ChipType[] }) => {
    let regions = chipsToStringArray(values);
    setSelectedRegions(regions);
    setActiveFilter("region");
    setQuery({ region: regions }, "replaceIn");
    setOffset(0);
  };

  const handleLanguageFilter = ({ values }: { values: ChipType[] }) => {
    let languages = chipsToStringArray(
      values.map((val) => ({
        name: assetLanguages.find(
          (languages) => languages.label.indexOf(val.name) === 0
        )?.value,
      }))
    );
    setSelectedLanguages(languages);
    setActiveFilter("language");
    setQuery({ language: languages }, "replaceIn");
    setOffset(0);
  };

  const handleIssueDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setSelectedIssueDate(updated_values);
    setActiveFilter("issue_date");
    setQuery({ issue_date: updated_values }, "replaceIn");
    setOffset(0);
  };

  const handleExpirationDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setSelectedExpirationDate(updated_values);
    setActiveFilter("expiration_date");
    setQuery({ expiration_date: updated_values }, "replaceIn");
    setOffset(0);
  };

  const handleRenewalDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setSelectedRenewalDate(updated_values);
    setActiveFilter("renewal_date");
    setQuery({ renewal_date: updated_values }, "replaceIn");
    setOffset(0);
  };

  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({
    baseUrl,
    query,
    assetType,
    categoryType,
    docType,
    countries,
    regions,
    languages,
    limit,
    issue_date,
    renewal_date,
    expiration_date,
  }: ConstructQuery) {
    const paramsWithOffset = new URLSearchParams();

    if (limit !== totalLimit) {
      paramsWithOffset.append("offset", `${offset}`);
    }
    paramsWithOffset.append("limit", `${limit}`);
    paramsWithOffset.append("show_generated_assets", "true");
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        assetType,
        categoryType,
        docType,
        countries,
        regions,
        languages,
        renewal_date,
        expiration_date,
        issue_date,
        params: paramsWithOffset,
      })
    );
  }

  const {
    data: assetsResponse,
    error: assetsError,
    mutate,
  } = useSWR<PIMAssetsResponse>(
    constructQuery({
      baseUrl: endpoints.v2_storefronts_id_pim_assets(tenant_id),
      query: debouncedSearchQuery,
      assetType: selectedAssetType,
      categoryType: selectedCategoryType,
      docType: selectedDocTypes,
      countries: selectedCountries,
      regions: selectedRegions,
      languages: selectedLanguages,
      limit: perPage,
      issue_date: selectedIssueDate,
      renewal_date: selectedRenewalDate,
      expiration_date: selectedExpirationDate,
    })
  );

  const fetchAssetsForDownload = async () => {
    try {
      const query = constructQuery({
        baseUrl: endpoints.v2_storefronts_id_pim_assets(tenant_id),
        query: debouncedSearchQuery,
        assetType: selectedAssetType,
        categoryType: selectedCategoryType,
        docType: selectedDocTypes,
        countries: selectedCountries,
        regions: selectedRegions,
        languages: selectedLanguages,
        limit: totalLimit,
        issue_date: selectedIssueDate,
        renewal_date: selectedRenewalDate,
        expiration_date: selectedExpirationDate,
      });

      const response = await axios.get(query);
      return response.data?.data ?? [];
    } catch (error) {
      notifyError(
        t("An error occurred while fetching assets. Please try again")
      );
    }
  };

  const assetQueryAsParam = (params: DecodedValueMap<QueryParamConfigMap>) => {
    const result: DecodedValueMap<QueryParamConfigMap> = {};

    const assign_query_value = (key: string, val: object | string) => {
      if (
        key === "expiration_date" ||
        key === "issue_date" ||
        key === "renewal_date"
      ) {
        const { startDate, endDate } = (val ?? {
          startDate: null,
          endDate: null,
        }) as DateRangeStr;
        if (!!startDate && startDate !== "undefined" && startDate !== "null") {
          result[`${key}_start`] = startDate;
        }
        if (!!endDate && endDate !== "undefined" && endDate !== "null") {
          result[`${key}_end`] = endDate;
        }
      } else {
        result[key] = val;
      }
    };

    Object.keys(params).forEach((key) => {
      const val = params[key];

      if (
        key !== "show_generated_assets" &&
        key !== "order_by" &&
        key !== "limit" &&
        key !== "offset" &&
        val !== undefined &&
        val !== null &&
        val !== ""
      ) {
        assign_query_value(key, val);
      }
    });

    return stringify(result)
      ? `?${stringify(result)}${
          activeFilter?.length > 0 ? `&active_filter=${activeFilter}` : ``
        }`
      : ``;
  };

  const { data: assetFilters, mutate: mutateAssetFilters } = useSWR<
    {
      asset_category: string[];
      asset_type: string[];
      country: string[];
      document_type: string[];
      language: string[];
      region: string[];
    },
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/assets/filters${assetQueryAsParam(query)}`, {
    onError: () => {
      notifyError(t("could not load asset filters, something went wrong."));
    },
  });

  useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name}`,
          value: item.alpha_2,
        }))
      );
    },
    onError: () => {
      notifyError(t("could not load asset languages, something went wrong."));
    },
  });

  useEffect(() => {
    if (assetFilters) {
      setAssetCategoryTypesFilters(assetFilters.asset_category);
      setAssetTypesFilters(assetFilters.asset_type);
      setCountryFilters(
        assetFilters.country
          .map((country) =>
            country ? getCountryOption(countries, country)?.label || "" : ""
          )
          .filter((val) => val)
      );
      setRegions(
        assetFilters.region
          .map((region) => region)
          .filter((val) => val !== "--")
      );
      setDocumentTypesFilters(
        assetFilters.document_type
          .map((document_type) => document_type)
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, countries]);

  useEffect(() => {
    if (assetLanguages && assetFilters) {
      setLanguagesFilters(
        assetFilters?.language
          .map(
            (language) =>
              assetLanguages?.find(
                (lang) => lang.value === language?.toLowerCase()
              )?.label ?? "--"
          )
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, assetLanguages]);

  const isLoading = !assetsResponse && !assetsError;

  const has_date_filter = () => {
    const has_filter = (date_filter: DateRangeStr | undefined) => {
      const { startDate, endDate } = date_filter ?? {
        startDate: null,
        endDate: null,
      };
      return (
        (!!startDate && startDate !== "undefined" && startDate !== "null") ||
        (!!endDate && endDate !== "undefined" && endDate !== "null")
      );
    };
    return (
      has_filter(issue_date_from_url as DateRangeStr) ||
      has_filter(expiration_date_from_url as DateRangeStr) ||
      has_filter(renewal_date_from_url as DateRangeStr)
    );
  };

  const hasAssetFilters = !isEmpty(assetTypesFilters) || has_date_filter();
  const hasCategoryFilters = !isEmpty(assetCategoryTypesFilters ?? []);
  const allFiltersAreEmpty = !hasAssetFilters && !hasCategoryFilters;

  //table
  const tableColumns = React.useMemo<ColumnDef<Assets>[]>(() => {
    const handleRowEdit = (row: Row<Assets>) => {
      if (row.original.is_generated) {
        setShowSlideOut("generate");
      } else {
        setShowSlideOut("edit");
      }
      setEditRowData(assetsResponse?.data[row.index]);
    };
    return [
      {
        header: t("Name"),
        accessorKey: "name",
        cell: ({
          getValue,
          row: {
            original: { asset_type, signed_url, is_generated, content_type },
          },
        }: {
          getValue: Getter<string>;
          row: { original: Assets };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={signed_url}
            name={getValue() as string}
            is_downloadable={true}
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        header: t("Asset Type"),
        accessorKey: "asset_type",
        cell: (cell) => <span>{toTitleCase(cell.getValue() as string)}</span>,
      },
      {
        header: t("Category"),
        accessorKey: "asset_category",
      },
      {
        header: t("Language"),
        accessorKey: "language",
        width: "100px",
      },
      ...(tableData.find((asset) => asset.country !== "--") ||
      tableData.find((asset) => asset.region !== "--")
        ? [
            {
              header: t("Country / Region"),
              accessorKey: "country_region",
              enableSorting: false,
            },
          ]
        : []),
      ...(tableData.find((asset) => asset.issue_date !== "--")
        ? [
            {
              header: t("Issue Date"),
              accessorKey: "issue_date",
            },
          ]
        : []),
      ...(tableData.find((asset) => asset.expiration_date !== "--")
        ? [
            {
              header: t("Expiration Date"),
              accessorKey: "expiration_date",
            },
          ]
        : []),
      ...(tableData.find((asset) => asset.document_type !== "--")
        ? [
            {
              header: t("Document Type"),
              accessorKey: "document_type",
            },
          ]
        : []),

      {
        header: "",
        id: "info",
        accessorKey: "info",
        enableSorting: false,
        width: "50px",
        align: "right",
        cell: ({
          row,
          getValue,
        }: {
          getValue: Getter<{
            modified_at: string;
            modified_by: string;
            number_of_products: string;
            renewal_date: string;
          }>;
          row: Row<Assets>;
        }) => {
          return (
            <div
              data-for={`row-tooltip-${row.id}`}
              data-tip={<></>}
              style={{ display: "inline" }}
            >
              <InfoIcon width={20} height={20} />
              <ReactTooltip
                id={`row-tooltip-${row.id}`}
                place="top"
                data-html={true}
                effect="solid"
                multiline={true}
              >
                {
                  <div style={{ textAlign: "left" }}>
                    <span style={{ marginBottom: "3px", display: "block" }}>
                      {t("No. of Products")}: {getValue().number_of_products}
                    </span>
                    {getValue().renewal_date !== "--" && (
                      <span style={{ marginBottom: "3px", display: "block" }}>
                        {t("Renewal Date")}: {getValue().renewal_date}
                      </span>
                    )}
                    {getValue().modified_at !== "--" && (
                      <span style={{ marginBottom: "3px", display: "block" }}>
                        {t("Last Modified On")}: {getValue().modified_at}
                      </span>
                    )}
                    {getValue().modified_by !== "--" && (
                      <span style={{ marginBottom: "3px", display: "block" }}>
                        {t("Last Modified By")}: {getValue().modified_by}
                      </span>
                    )}
                  </div>
                }
              </ReactTooltip>
            </div>
          );
        },
      },
      {
        header: t("Actions"),
        accessorKey: "actions",
        enableSorting: false,
        width: "120px",
        cell: ({ row }: { row: Row<Assets> }) => (
          <IconWrapper>
            {hasPermission("modify_assets") && (
              <>
                <div
                  data-for={`${row.original.id}_tooltip`}
                  data-tip={
                    row.original.is_generated &&
                    row.original.number_of_products < 1
                      ? t(
                          "This asset does not belong to any products and cannot be edited."
                        )
                      : ""
                  }
                >
                  <EditButton
                    width={20}
                    height={20}
                    disabled={
                      row.original.is_generated &&
                      row.original.number_of_products < 1
                    }
                    onClick={() => handleRowEdit(row)}
                  />
                </div>
                <ReactTooltip id={`${row.original.id}_tooltip`} />
              </>
            )}
            {hasPermission("delete_assets") && (
              <DeleteButton
                width={20}
                height={20}
                onClick={() => handleDelete(row)}
              />
            )}
          </IconWrapper>
        ),
      },
    ];
  }, [t, hasPermission, tableData, assetsResponse]);

  useEffect(() => {
    const handleProductsData = ({ data, pagination }: PIMAssetsResponse) => {
      setTableData(
        data.map((asset) => {
          const {
            id,
            name,
            asset_category,
            category,
            language,
            asset_type,
            modified_at,
            modified_by,
            is_generated,
            number_of_products,
            issue_date,
            expiration_date,
            renewal_date,
            country,
            region,
            signed_url,
            document_type,
            ...restProps
          } = asset;
          return {
            id,
            asset_category,
            category,
            language: language?.toLocaleUpperCase() ?? "--",
            name: name ?? "--",
            is_generated,
            asset_type,
            document_type: document_type ?? "--",
            signed_url,
            issue_date: issue_date ? formatDate(issue_date, true) : "--",
            expiration_date: expiration_date
              ? formatDate(expiration_date, true)
              : "--",
            country: country ?? "--",
            region: region ?? "--",
            country_region: `${
              getCountryOption(countries, country)?.label ?? (country || "--")
            } / ${region ?? "--"}`,
            info: {
              modified_at: formatDate(modified_at),
              modified_by: modified_by ?? "--",
              number_of_products,
              renewal_date: renewal_date
                ? formatDate(renewal_date, true)
                : "--",
            },
            ...restProps,
          };
        })
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (assetsResponse) {
      const { data: assets, pagination } = assetsResponse;
      handleProductsData({ data: assets, pagination });
    }
  }, [assetsResponse, perPage, supported_languages, countries]);

  const handleBulkUpload = () => {
    history.push(`${adminPath}/pim/assets/uploads`);
  };

  const handleAssociateProducts = () => {
    //  The endpoint for associating products is to be used here..
    history.push(`${adminPath}/pim/assets/associate-products`);
  };

  useEffect(() => {
    setQuery({ offset });
  }, [offset, setQuery]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  useEffect(() => {
    setAssetTypesFromURL(
      getObjArrayFromStringArray(
        ((query.asset_type ?? []) as string[]).map((assetType) =>
          toTitleCase(assetType)
        )
      )
    );
    setcategoryTypesFromURL(
      getObjArrayFromStringArray((query.asset_category ?? []) as string[])
    );
    setLanguagesFromURLL(
      getObjArrayFromStringArray((query.language ?? []) as string[])
    );
    setCountriesFromURLL(
      getObjArrayFromStringArray(
        (
          query?.country?.map(
            (country) => countries.find((c) => c.value === country)?.label
          ) ?? []
        )?.filter((t) => t !== undefined) as string[]
      )
    );
    setDocTypesFromURL(
      getObjArrayFromStringArray((query.document_type ?? []) as string[])
    );
    setRegionsFromURLL(
      getObjArrayFromStringArray((query.region ?? []) as string[])
    );
    set_issue_date_from_url(query.issue_date ?? null);
    set_expiration_date_from_url(query.expiration_date ?? null);
    set_renewal_date_from_url(query.renewal_date ?? null);
  }, [query, countries]);

  useEffect(() => {
    if (selectedRows && selectedRows !== "all" && tableData.length) {
      const selected_rows_obj = selectedRows.reduce<Record<string, boolean>>(
        (acc, id) => ({ ...acc, [id]: true }),
        {}
      );
      setSelectedItems((prev) => {
        const updated_selected_items = prev ?? {};
        if (!selectedRows.length) {
          Object.keys(updated_selected_items).forEach((key) => {
            updated_selected_items[Number(key)] = [];
          });
        } else {
          updated_selected_items[tablePagination.pageIndex] = (
            tableData as Assets[]
          ).filter(({ id }) => selected_rows_obj[id]);
        }
        return updated_selected_items;
      });
    }
  }, [selectedRows, tableData, tablePagination.pageIndex]);

  return (
    <PageWrapper>
      <Flex style={{ margin: 0 }}>
        <Flex2>
          <PageTitle style={{ margin: "0px" }}>{t("Assets")}</PageTitle>
        </Flex2>
        <Flex1 style={{ textAlign: "right" }}>
          <ListGridToggleButton
            style={{ marginTop: "0px", marginBottom: "20px" }}
            name="assets_list_grid_toggle"
            set_toggle_state={set_list_grid_toggle}
          />
        </Flex1>
      </Flex>
      <FullWidthHorizontalSeparator />
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Asset Name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        {/* All sellers can import or create new products. */}
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            <WithPermission permission="modify_assets">
              <ActionButtonsContainer>
                <AddAssetButtonWrapper>
                  <PrimaryButtonWithPlusIcon
                    onClick={() => setShowSlideOut("add")}
                    style={{ padding: "8px 12px" }}
                  >
                    {t("Add asset")}
                  </PrimaryButtonWithPlusIcon>
                </AddAssetButtonWrapper>
                <BulkActionWrapper>
                  <DropDownButton
                    className="dropdown_button"
                    items={[t("Bulk Upload"), t("Bulk Associate")]}
                    textLeft={t("Bulk Action")}
                    clickHandler={(item: string) => {
                      if (item === t("Bulk Upload")) {
                        handleBulkUpload();
                      } else {
                        handleAssociateProducts();
                      }
                    }}
                  />
                </BulkActionWrapper>
              </ActionButtonsContainer>
            </WithPermission>
          </div>
        </Flex1>
      </Flex>
      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeCreateSlideOut} show={!!showSlideOut}>
          <NewAssetFormContainer>
            <NewAssetForm
              onComplete={closeCreateSlideOut}
              refreshParentData={mutate}
            />
          </NewAssetFormContainer>
        </SlideOut>
      )}
      {showSlideOut === "edit" && (
        <SlideOut closeFlyout={closeCreateSlideOut} show={!!showSlideOut}>
          <EditAsset
            onComplete={closeCreateSlideOut}
            refreshAssetsList={mutate}
            assetData={editRowData!}
          />
        </SlideOut>
      )}
      {showSlideOut === "generate" && (
        <SlideOut
          closeFlyout={async () => {
            closeCreateSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await mutate();
            await mutateAssetFilters();
          }}
          show={!!showSlideOut}
        >
          <EditOrGenerateProductDocument
            asset={
              {
                ...editRowData,
                is_generated: true,
              } as Assets
            }
            ref={product_document_ref}
            onSuccess={async () => {
              await mutate();
              await mutateAssetFilters();
              closeCreateSlideOut();
            }}
          />
        </SlideOut>
      )}
      {showDeleteModal && (
        <DeleteAssetModal
          onComplete={closeDeleteModal}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={mutate}
          assetData={deleteRowData}
        />
      )}
      <Flex style={{ margin: "20px 0px", justifyContent: "space-between" }}>
        <Flex1>
          {assetTypesFilters.length > 0 && (
            <FilterBy
              setter={handleAssetType}
              filter="asset_type"
              options={getObjArrayFromStringArray(
                assetTypesFilters.map((type) => toTitleCase(type))
              )}
              header={t("Asset Type")}
              label={t("Asset Type")}
              preselectedFilters={
                isChipTypeArray(assetTypesFromURL) ? assetTypesFromURL : []
              }
            />
          )}
          {assetCategoryTypesFilters.length > 0 && (
            <FilterBy
              setter={handleCategoryType}
              filter="asset_category"
              options={getObjArrayFromStringArray(assetCategoryTypesFilters)}
              header={t("Category Type")}
              label={t("Category Type")}
              preselectedFilters={
                isChipTypeArray(categoryTypesFromURL)
                  ? categoryTypesFromURL
                  : []
              }
            />
          )}

          {languagesFilters.length > 0 && (
            <FilterBy
              setter={handleLanguageFilter}
              filter="language"
              options={getObjArrayFromStringArray(languagesFilters)}
              header={t("Language")}
              label={t("Language")}
              preselectedFilters={
                isChipTypeArray(languagesFromURL)
                  ? languagesFromURL.map((lang) => ({
                      name:
                        assetLanguages.find(
                          (assetLang) => assetLang.value === lang.name
                        )?.label ?? lang.name,
                    }))
                  : []
              }
            />
          )}
          {countryFilters?.length > 0 && (
            <FilterBy
              setter={handleCountryFilter}
              filter="country"
              options={getObjArrayFromStringArray(countryFilters)}
              header={t("Country")}
              label={t("Country")}
              preselectedFilters={
                isChipTypeArray(countriesFromURL) ? countriesFromURL : []
              }
            />
          )}
          {regions?.length > 0 && (
            <FilterBy
              setter={handleRegionFilter}
              filter="region"
              options={regions.map((region) => ({ name: region }))}
              header={t("Region")}
              label={t("Region")}
              preselectedFilters={
                isChipTypeArray(regionsFromURL) ? regionsFromURL : []
              }
            />
          )}
          {documentTypesFilters?.length > 0 && (
            <FilterBy
              setter={handleDocTypeFilter}
              filter="document_type"
              options={getObjArrayFromStringArray(documentTypesFilters)}
              header={t("Document Type")}
              label={t("Document Type")}
              preselectedFilters={
                isChipTypeArray(docTypesFromURL) ? docTypesFromURL : []
              }
            />
          )}
          <DateFilter
            setter={handleIssueDateFilter}
            filter="issue_date"
            label={t("Issue Date")}
            preselectedDateRange={get_preselected_date_range(
              issue_date_from_url as DateRangeStr
            )}
          />
          <DateFilter
            setter={handleExpirationDateFilter}
            filter="expiration_date"
            label={t("Expiration Date")}
            preselectedDateRange={get_preselected_date_range(
              expiration_date_from_url as DateRangeStr
            )}
          />
          <DateFilter
            setter={handleRenewalDateFilter}
            filter="renewal_date"
            label={t("Renewal Date")}
            preselectedDateRange={get_preselected_date_range(
              renewal_date_from_url as DateRangeStr
            )}
          />
          {!allFiltersAreEmpty && (
            <TertiaryTextButtonSmall
              onClick={() => {
                handleClearFilters();
              }}
            >
              {t("Clear Filters")}
            </TertiaryTextButtonSmall>
          )}
        </Flex1>
        <DropDownContainerWrapper>
          <DropDown
            items={perPageItems}
            activeItem={perPage}
            textLeft={t("items") + ":"}
            textRight={t("Per Page")}
            direction={"right"}
            className={"per_Page"}
            clickHandler={changePerPage}
          ></DropDown>
        </DropDownContainerWrapper>
      </Flex>
      <ContentWrapper>
        {list_grid_toggle === "list" ? (
          <TableWithBulkAction
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={assetsError}
            rowHover={rowHover}
            handleSort={handleSort}
            handleSelectRows={(rows) => setSelectedRows(rows)}
            defaultSelectedRows={selectedRows}
            lastChildleftAlign
            bulkActionElement={({ rows, resetSelectedRows }) => {
              return (
                <BulkActionOnAssets
                  rows={rows}
                  resetSelectedRows={resetSelectedRows}
                  tenant_id={tenant_id}
                  fetchAssetsForDownload={fetchAssetsForDownload}
                />
              );
            }}
            ref={resetSelectedRowsRef}
            maxHeight="706px"
            bulkSelectLimit={500}
          />
        ) : (
          <ScrollGridWrapper>
            <GridAssetViewWithBulkAction
              assets={
                [
                  ...tableData.map((asset) => ({
                    ...asset,
                    can_edit: (asset as Assets).is_generated
                      ? hasPermission("modify_assets") &&
                        (asset as Assets).number_of_products > 0
                      : hasPermission("modify_assets"),
                    can_delete: hasPermission("delete_assets"),
                    can_change_visibility: false,
                    can_external_download: true,
                    can_view_details: true,
                  })),
                ] as unknown as (Assets & GridItemProps)[]
              }
              on_edit_asset={handleEdit}
              on_remove_asset={(asset) =>
                handleDelete({ original: asset } as unknown as Row<Assets>)
              }
              on_download_asset={(asset) => {
                window.open(asset.signed_url, "_blank");
              }}
              bulkActionElement={({ rows, resetSelectedRows }) => {
                return (
                  <BulkActionOnAssets
                    rows={rows}
                    resetSelectedRows={resetSelectedRows}
                    tenant_id={tenant_id}
                    fetchAssetsForDownload={fetchAssetsForDownload}
                  />
                );
              }}
              selectedItems={Object.values(selectedItems ?? {})
                .flat()
                .map(
                  (asset) =>
                    ({ original: asset, id: `${asset.id}` } as Row<Assets>)
                )}
              handleSelectedItems={(items) =>
                setSelectedRows(items.map((item) => item.original.id))
              }
              bulkSelectLimit={500}
            />
          </ScrollGridWrapper>
        )}
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
