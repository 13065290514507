import type { AxiosError } from "axios";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { endpoints } from "../../../../../endpoints";
import type { Assets, PIMProduct } from "../../../../../types/types.PIM";
import {
  isAxiosError,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../../util/util";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type { DataMutate, WithPagination } from "../../../../../types/types";
import { EditDeleteModal } from "../../../../../components/EditDeleteModal/EditDeleteModal";

export const RemoveAssetFromProduct = ({
  show,
  onClose,
  onComplete,
  product,
  refreshAssetsList,
  assetData,
}: {
  onComplete: (productId?: string) => void;
  product: PIMProduct;
  refreshAssetsList: DataMutate<WithPagination<{ data: Assets[] }>>;
  show: boolean;
  onClose: () => void;
  assetData: Assets | null;
}) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { tenant_id } = useStoreState();

  const { id } = assetData as Assets;

  const updateProductStatus = useUpdateProductStatus({ product });

  const onDelete = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      const { data: updatedProduct } = await updateProductStatus();
      const data = {
        product_ids_to_delete: [updatedProduct.id],
      };
      await Axios.patch(
        endpoints.v2_storefronts_id_pim_assets_id(tenant_id, id),
        data
      );
      await refreshAssetsList();
      notifySuccess(t("Asset successfully Removed"));
      setIsSubmitting(false);
      onComplete(
        updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
      );
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not remove asset, Something went wrong."));
      }
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <EditDeleteModal
        title={t("Remove Asset")}
        show={show}
        onClose={onClose}
        onConfirm={onDelete}
        isLoading={isSubmitting}
        body={t("Are you sure you want to remove this Asset from the product?")}
        confirmBtnLabel={t("Remove")}
      />
    </>
  );
};
