import React, { useContext, useEffect, useState } from "react";
import type {
  OptionType,
  PatchSampleRequest,
  SampleRequestIndividualItem,
  SampleRequestStageOne,
} from "../../../types/types";
import styled from "styled-components/macro";
import type { mutateCallback } from "swr/dist/types";
import * as Yup from "yup";
import { strings } from "../../../util/strings";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { CartContext } from "../../../components/quoteCart/CartContext";
import {
  useStoreState,
  useFormWrapper,
  isAxiosError,
  usePolicyDocuments,
  isValidPhoneNumber,
} from "../../../util/util";
import { Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { DesktopFormContainerCart } from "../../../components/Layout/Layout";
import {
  GoBackButton,
  PrimaryButtonLarge,
  TextButton,
} from "../../../components/Buttons/Buttons";
import { H3, Title } from "../../../components/Typography/Typography";
import {
  CartContainer,
  SampleRequestCartItems,
} from "../../../components/Cart/Cart";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  FormGrid2x2,
} from "../../../layout/FormLayout";
import { TextField } from "../../../components/TextFields/TextFields";
import { emailAddressRegex } from "../../../util/regexes";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { getPhoneCodesOptions } from "../../../util/phone";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  WarningMessageBox,
} from "../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { ColoredTextOnError } from "../../../util/util-components";
import type { ChipType } from "../../../components/Chips/Chips";
import { Chips } from "../../../components/Chips/Chips";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../../util/Routing";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import {
  RequestAddress,
  getRequestAddressSchema,
} from "../RequestAddress/RequestAddress";
import { WarningIcon } from "../../../components/Icons/Icons";
import {
  configured_checkboxes_schema_yup,
  create_configured_checkboxes_fields,
} from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import {
  captcha_yup_validation,
  captcha_yup_validation_not_required,
  ControlledRecaptcha,
} from "../../../components/Recaptcha/ControlledRecaptcha";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.secondaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 6px;
  padding: 24px 24px 36px;
  margin: 15px 0px;
`;

export const FormWrapper = styled.div`
  max-width: 452px;
`;

export const ConfirmationSpacer = styled.div`
  margin: 15px 0 10px;
`;

export const CartSubHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.large};
`;

type SampleRequestMutate = (
  data?:
    | SampleRequestStageOne
    | Promise<SampleRequestStageOne>
    | mutateCallback<SampleRequestStageOne>
    | undefined,
  shouldRevalidate?: boolean | undefined
) => Promise<SampleRequestStageOne | undefined>;

type SubmitSampleRequestCartForm = {
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_email: string;
  buyer_phone_number: string;
  buyer_company_name: string;
  address1: string;
  address2?: string;
  city: string;
  postal_code: string;
  county?: string;
  country: OptionType<string>;
  country_code: OptionType<string>;
  is_decision_maker: OptionType<boolean>;
  state: OptionType<string>;
  documents?: { id: string }[];
  decision_timeline: { name: string }[];
  billing_country?: OptionType<string>;
  billing_address1: string;
  billing_address2?: string;
  billing_state: OptionType<string>;
  billing_county?: string; // only shows if selected country is India
  billing_city: string;
  billing_postal_code: string;
  configured_checkboxes?: { value: boolean; label: string }[];
  captcha_token: string;
};

const schema = (
  t: TFunction,
  hideBillingAddress: boolean,
  require_captcha: boolean
) =>
  Yup.lazy((formValues: any) => {
    return Yup.object()
      .shape({
        buyer_first_name: Yup.string().required(
          strings(t).thisIsARequiredField
        ),
        buyer_last_name: Yup.string().required(strings(t).thisIsARequiredField),

        buyer_email: Yup.string()
          .email(strings(t).emailAddressMustBeValid)
          .required(strings(t).thisIsARequiredField),

        buyer_phone_number: Yup.string()
          .min(1, strings(t).phoneNumberMustBeValid)
          .required(strings(t).thisIsARequiredField),
        country_code: Yup.object()
          .shape({
            value: Yup.string().required(strings(t).thisIsARequiredField),
            label: Yup.string().required(strings(t).thisIsARequiredField),
          })
          .required(strings(t).thisIsARequiredField),
        buyer_company_name: Yup.string().required(
          strings(t).thisIsARequiredField
        ),
        confirmation_tos: Yup.boolean().oneOf([true]),
        configured_checkboxes: configured_checkboxes_schema_yup({
          formValues,
          t,
        }),
        ...getRequestAddressSchema(t, hideBillingAddress),
        documents: Yup.array()
          .of(Yup.object({ id: Yup.string().defined() }).defined())
          .notRequired(),
        is_decision_maker: Yup.object()
          .shape({
            value: Yup.boolean().defined(),
            label: Yup.string().defined(),
          })
          .required(strings(t).thisIsARequiredField),
        decision_timeline: Yup.array(
          Yup.object().shape({ name: Yup.string().defined() }).defined()
        ).defined(),
        ...(require_captcha
          ? captcha_yup_validation(t)
          : captcha_yup_validation_not_required()),
        ...captcha_yup_validation(t),
      })
      .required();
  });

export const DOCUMENT_CHIPS = [
  { name: "TDS", id: "TDS" },
  { name: "SDS", id: "SDS" },
  { name: "COA", id: "COA" },
];

export const DECISION_CHIPS = [
  { name: "< 3 months" },
  { name: "3 - 6 months" },
  { name: "6 - 9 months" },
  { name: "1+ years" },
  { name: "Not sure" },
];

export const SampleRequestCart = ({
  sampleRequest,
  mutate,
  setRedirectURI,
  goToParentPage,
}: {
  sampleRequest: SampleRequestStageOne;
  mutate: SampleRequestMutate;
  setRedirectURI: React.Dispatch<React.SetStateAction<string>>;
  goToParentPage: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { clearCartStorage } = useContext(CartContext);
  const { t } = useTranslation();
  const {
    storefront_id,
    storefront_metadata: {
      enable_transaction_lead_sample,
      configured_checkboxes,
      require_captcha,
    },
    api_metadata: { captcha_site_key },
  } = useStoreState();
  const history = useHistory();
  const { storePath } = useRoutePath();
  const { privacyPolicy } = usePolicyDocuments();
  const cartItems = sampleRequest.items;

  const [submitting, setSubmitting] = useState(false);
  const [hideBillingAddress, setHideBillingAddress] = useState(true);
  const [phoneNumberWarning, setPhoneNumberWarning] =
    useState<{ message: string } | null>(null);

  const sampleCheckboxes = configured_checkboxes.filter((item) => item.sample);

  const methodsOfUseForm = useFormWrapper<
    SubmitSampleRequestCartForm & {
      confirmation_tos: boolean;
    }
  >({
    resolver: yupResolver(schema(t, hideBillingAddress, require_captcha)),
    defaultValues: {
      country_code: {},
    },
  });
  const { register, handleSubmit, formState, errors, control, setValue } =
    methodsOfUseForm;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  useEffect(() => {
    if (sampleCheckboxes.length > 0 && fields?.length < 1) {
      sampleCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [sampleCheckboxes, append, fields]);
  useEffect(() => {
    register({ name: "documents", required: false });
    register({ name: "decision_timeline", required: true });
  }, [register]);

  const handleDecisionTimelineClick = (chips: ChipType[]) => {
    setValue("decision_timeline", chips);
  };

  const handleDocumentsClick = (chips: ChipType[]) => {
    setValue("documents", chips);
  };

  const onFormSubmit = async (values: SubmitSampleRequestCartForm) => {
    setSubmitting(true);
    if (
      !isValidPhoneNumber({
        phone_number: values.buyer_phone_number,
        country_code: values.country_code,
      }) &&
      !phoneNumberWarning
    ) {
      // only display this once, if the user clicks submit a second time let
      // them go
      setPhoneNumberWarning({
        message: t("Phone number might be invalid, press submit to continue"),
      });
      setSubmitting(false);

      return;
    }

    try {
      const formValues: PatchSampleRequest = {
        buyer_first_name: values.buyer_first_name,
        buyer_last_name: values.buyer_last_name,
        buyer_email: values.buyer_email,
        buyer_phone: values.country_code.value + values.buyer_phone_number,
        buyer_company_name: values.buyer_company_name,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        zip_code: values.postal_code,
        county: values.county,
        country: values.country.value,
        is_decision_maker: values.is_decision_maker.value,
        state: values.state.value,
        requested_documents: values.documents
          ? values.documents.map((document) => document.id)
          : [],
        captcha_token: require_captcha ? values.captcha_token : undefined,
        recaptcha_key: require_captcha ? captcha_site_key : undefined,
        decision_timeline: values.decision_timeline[0].name,
        ...(values.billing_country
          ? {
              billing_address: {
                country: values.billing_country.value,
                state: values.billing_state.value,
                address1: values.billing_address1,
                address2: values.billing_address2,
                county: values.billing_county,
                city: values.billing_city,
                postal_code: values.billing_postal_code,
              },
            }
          : {}),
      };

      const response = await Axios.patch(
        `/v1/storefronts/${storefront_id}/sample-requests/${sampleRequest.id}`,
        formValues
      );

      if (response.status === 208) {
        notifyError(
          t(
            "You are already registered on the storefront. Please log in to raise the request"
          )
        );
        history.push(`${storePath}/login`);
      } else {
        notifySuccess(t("Cart submitted successfully"));
      }

      if (response.status === 208 && response.data.redirect_uri) {
        // If the email used to submit the leads cart is registered we
        // redirect them to their storefront login page instead of going
        // back.
        setRedirectURI(response.data.redirect_uri);
      } else {
        history.push(`${storePath}/cart/thank-you-sample`);
      }

      // The side effects from the following call causes the cart items
      // in this component to become empty and that causes a useEffect
      // to call goBack() which takes the user back where they came from.
      clearCartStorage();

      await mutate();
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Sample request is already in progress`));
        return;
      }
      const message =
        isAxiosError(error) && error.response?.data.message
          ? error.response?.data.message
          : t("There was an error submitting the cart");

      notifyError(message, { error });
    } finally {
      setSubmitting(false);
    }
  };

  const removeCartItem = async (
    itemToRemove: SampleRequestIndividualItem
  ): Promise<void> => {
    const { id } = itemToRemove;
    const { id: sampleRequestID } = sampleRequest;

    try {
      await Axios.delete(
        `/v1/storefronts/${storefront_id}/sample-requests/${sampleRequestID}/items/${id}`
      );
      notifySuccess("The item has been deleted successfully");
      await mutate();
      if (cartItems.length - 1 === 0) {
        clearCartStorage();
      }
    } catch (error) {
      notifyError("There was an error deleting the item");
    }
  };

  if (!cartItems || cartItems.length === 0) {
    // The cart will become empty on successful submit and also if the user
    // removes all of the cart items.

    goToParentPage();
  }

  return (
    <Wrapper>
      <DesktopFormContainerCart>
        <TextButton onClick={goToParentPage}>
          <GoBackButton text={t("Portfolio")} />
        </TextButton>
        <Title style={{ fontWeight: 600 }}>{t("Sample Request")}</Title>
        <CartContainer>
          <CartSubHeader>{t("Request a sample for")}</CartSubHeader>
          <SampleRequestCartItems
            cartItems={cartItems}
            remove={removeCartItem}
          />
        </CartContainer>
        <Container>
          <H3>{t("Send your request")}</H3>
          {phoneNumberWarning && (
            <WarningMessageBox>
              <div style={{ marginRight: "4px" }}>
                <WarningIcon width={18} />
              </div>
              {t(
                `The phone number provided cannot be validated, if you're confident it is correct please press "submit" again to continue`
              )}
            </WarningMessageBox>
          )}
          <FormWrapper>
            <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Chips
                selectionMode={"many"}
                header={t("Select all you wish to receive")}
                chips={DOCUMENT_CHIPS}
                handleClick={handleDocumentsClick}
                errors={errors}
                name="documents"
              />
              <H3>{t("Your Information")}</H3>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="is_decision_maker"
                placeholder={t("Are you the decision maker?")}
                id="is_decision_maker"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
              <Chips
                selectionMode={"single"}
                header={t("Decision Timeline")}
                chips={DECISION_CHIPS}
                handleClick={handleDecisionTimelineClick}
                errors={errors}
                name={"decision_timeline"}
              />
              <FormGrid2x2>
                <TextField
                  name={"buyer_first_name"}
                  label={t("First Name")}
                  type={"text"}
                  theref={register({
                    maxLength: 50,
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                />
                <TextField
                  name={"buyer_last_name"}
                  label={t("Last Name")}
                  theref={register({
                    required: true,
                  })}
                  type={"text"}
                  formState={formState}
                  errors={errors}
                />
              </FormGrid2x2>
              <TextField
                name={"buyer_email"}
                label={t("Business Email Address")}
                theref={register({
                  required: true,
                  pattern: {
                    value: emailAddressRegex,
                    message: t("invalid email address"),
                  },
                })}
                type={"email"}
                formState={formState}
                errors={errors}
              />
              <Flex>
                <Flex1>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country_code"
                    autoComplete="tel-country-code"
                    placeholder={t("Country Code")}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name={"buyer_phone_number"}
                    theref={register({
                      required: true,
                    })}
                    autoComplete={"tel-national"}
                    warningText={phoneNumberWarning}
                    label={t("Phone Number")}
                    formState={formState}
                    errors={errors}
                    type={"tel"}
                  />
                </Flex2>
              </Flex>
              <TextField
                name={"buyer_company_name"}
                label={t("Company Name")}
                theref={register({
                  required: true,
                })}
                type={"text"}
                formState={formState}
                errors={errors}
              />
              <RequestAddress
                hideBillingAddress={hideBillingAddress}
                showBillingAddressOption={enable_transaction_lead_sample}
                setHideBillingAddress={setHideBillingAddress}
                showLocationId={false}
                formTitle={
                  enable_transaction_lead_sample
                    ? t("Shipping Address")
                    : t("Address")
                }
                methodsOfUseForm={methodsOfUseForm}
              />
              <ConfirmationSpacer>
                {!!privacyPolicy && (
                  <CheckBoxContainer>
                    <div style={{ width: "22px", marginRight: "15px" }}>
                      <CheckBoxNoLabel
                        name="confirmation_tos"
                        id="confirmation_tos"
                        ref={register({ required: true })}
                        error={!!errors.confirmation_tos?.message}
                      />
                    </div>
                    <CheckBoxFinePrintLabel
                      htmlFor="confirmation_tos"
                      style={{ marginLeft: "0px" }}
                    >
                      <ColoredTextOnError
                        isError={!!errors.confirmation_tos?.message}
                      >
                        <>
                          <span>{t("I agree to the ")}</span>
                          <PrivacyPolicyLink customPolicy />
                          <span>.</span>
                        </>
                      </ColoredTextOnError>
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                )}
              </ConfirmationSpacer>
              {fields.length > 0 && (
                <>
                  {create_configured_checkboxes_fields({
                    fields,
                    methodsOfUseForm,
                    t,
                  })}
                </>
              )}
              {require_captcha && (
                <ControlledRecaptcha methodsOfUseForm={methodsOfUseForm} />
              )}
              <PrimaryButtonLarge
                style={{ minWidth: "242px" }}
                loading={submitting}
              >
                {t("Request Sample")}
              </PrimaryButtonLarge>
            </Form>
          </FormWrapper>
        </Container>
      </DesktopFormContainerCart>
    </Wrapper>
  );
};
