import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import { ThreeDotContextMenuIcon } from "../Icons/Icons";

export type MenuItemProps = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type ContextMenuProps = {
  items: MenuItemProps[];
  onSelect: (value: string) => void;
  triggerRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  onClose: () => void;
};

const MenuContainer = styled.div<{ top: number; left: number }>`
  position: fixed;
  z-index: 900;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  box-shadow: 0px 6px 16px -6px ${({ theme }) => theme.shadowColor};
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.primaryBG};
  min-width: 150px;
  overflow: hidden;
`;

const MenuItem = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme, disabled }) =>
    disabled ? theme.secondaryTextColor : theme.primaryTextColor};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "none" : theme.secondaryButtonBG};
  }

  &:focus {
    outline: none;
    background-color: ${({ theme, disabled }) =>
      disabled ? "none" : theme.secondaryButtonBG};
  }
`;

const TriggerButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const ContextMenu: React.FC<ContextMenuProps> = ({
  items,
  onSelect,
  triggerRef,
  isOpen,
  onClose,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });

      const handleClickOutside = (e: MouseEvent) => {
        if (
          triggerRef.current &&
          !triggerRef.current.contains(e.target as Node)
        ) {
          onClose();
        }
      };

      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [isOpen, triggerRef, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <MenuContainer top={position.top} left={position.left}>
      {items.map((item) => (
        <MenuItem
          key={item.value}
          disabled={item.disabled}
          onClick={() => {
            if (!item.disabled) {
              onSelect(item.value);
              onClose();
            }
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </MenuContainer>,
    document.body
  );
};

// Export a convenience wrapper that includes the trigger button
export type ContextMenuTriggerProps = {
  items: MenuItemProps[];
  onSelect: (value: string) => void;
};

export const ContextMenuTrigger: React.FC<ContextMenuTriggerProps> = ({
  items,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <TriggerButton
        ref={triggerRef}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <ThreeDotContextMenuIcon width={16} height={16} />
      </TriggerButton>
      <ContextMenu
        items={items}
        onSelect={onSelect}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
