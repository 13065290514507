import { useCallback, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { InvisibleButton, TertiaryButtonSmall } from "../Buttons/Buttons";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";
import { useOnClickOutside } from "../../util/hooks";
import type { IIsOutOfView } from "../../util/util";
import { isOutOfViewport, useMediaQueries } from "../../util/util";
import { RangeCalendar } from "../Calendar/Calendar";
import {
  DropDownModalWrapper,
  DropDownWrapper,
} from "../FilterBy/filter_by.utils";
import { CheckIcon, XIcon } from "../Icons/Icons";
import { Modal } from "../Modal/Modal";
import { RegularText } from "../Typography/Typography";
import type { DateRange } from "./date_filter.utils";
import { is_date_filters_equal } from "./date_filter.utils";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

interface IDateFilter {
  setter: ({ values }: { values: DateRange }) => void;
  filter: string;
  label: string;
  start_date_placeholder?: string;
  end_date_placeholder?: string;
  preselectedDateRange?: DateRange;
}

interface IRangeCalendarFilter {
  handleClear: () => void;
  handleApply: () => void;
  selectedFilter?: DateRange;
  handleDatesChange: (dateRange: DateRange) => void;
  label: string;
  filter: string;
  start_date_placeholder?: string;
  end_date_placeholder?: string;
}

const RangeCalendarFilter = ({
  handleClear,
  handleApply,
  selectedFilter,
  handleDatesChange,
  label,
  filter,
  start_date_placeholder,
  end_date_placeholder,
}: IRangeCalendarFilter) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <TopWrapper>
        <RegularText>{t("Filter by {{label}}", { label })}</RegularText>
        <ButtonWrapper>
          <InvisibleButton onClick={handleClear}>
            <XIcon
              width={24}
              height={24}
              fill={theme.destructiveButtonTextColor}
            />
          </InvisibleButton>
          <InvisibleButton onClick={handleApply}>
            <CheckIcon width={24} height={24} fill={theme.activeToggleBG} />
          </InvisibleButton>
        </ButtonWrapper>
      </TopWrapper>
      <RangeCalendar
        startDate={selectedFilter?.startDate ?? null}
        endDate={selectedFilter?.endDate ?? null}
        startDateId={"start_date_range_id"}
        endDateId={"end_date_range_id"}
        startDatePlaceholderText={start_date_placeholder ?? t("Start")}
        endDatePlaceholderText={end_date_placeholder ?? t("End")}
        label={label}
        handleDatesChange={handleDatesChange}
        name={filter}
      />
    </>
  );
};

const defaultDateFilter: DateRange = { startDate: null, endDate: null };

export const DateFilter = ({
  setter,
  filter,
  label,
  preselectedDateRange,
}: IDateFilter) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [appliedFilter, setAppliedFilter] =
    useState<DateRange>(defaultDateFilter);
  const [selectedFilter, setSelectedFilter] =
    useState<DateRange>(defaultDateFilter);
  const [showModal, setShowModal] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useQueryParams({
    active_tag_view: StringParam,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [ButtonViewport, setButtonViewport] = useState<
    IIsOutOfView | undefined
  >(isOutOfViewport(buttonRef));
  const { isMediumScreen } = useMediaQueries();

  const apply = () => {
    setAppliedFilter(selectedFilter);
    setter({
      values: selectedFilter
        ? selectedFilter
        : { startDate: null, endDate: null },
    });
    setShowDropDown(false);
  };

  const clear = useCallback(() => {
    setSelectedFilter(defaultDateFilter);
    setAppliedFilter(defaultDateFilter);
    setQuery({
      [filter]: query.active_tag_view,
    });
    setter({ values: { startDate: null, endDate: null } });
    setShowDropDown(false);
  }, [filter, query.active_tag_view, setQuery, setter]);

  const handleDatesChange = (dateRange: DateRange) =>
    setSelectedFilter(dateRange);

  const handleClickOutside = () => {
    if (!is_date_filters_equal(appliedFilter, selectedFilter)) {
      setSelectedFilter(appliedFilter);
    }
    setShowDropDown(false);
  };

  useOnClickOutside(wrapperRef, () => handleClickOutside());

  useEffect(() => {
    const { startDate, endDate } = preselectedDateRange ?? defaultDateFilter;
    setSelectedFilter({ startDate, endDate });
    setAppliedFilter({ startDate, endDate });
  }, [preselectedDateRange]);

  useEffect(() => {
    const handleResize = () => setButtonViewport(isOutOfViewport(buttonRef));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isValidDateRange = (dateRange: DateRange | undefined) => {
    if (!dateRange) {
      return false;
    }
    const { startDate, endDate } = dateRange;
    return !!startDate || !!endDate;
  };

  if (!isMediumScreen) {
    return (
      <DropDownWrapper>
        <TertiaryButtonSmall
          active={isValidDateRange(appliedFilter) || showDropDown}
          ref={buttonRef}
          onClick={() => {
            setShowDropDown(!showDropDown);
            setButtonViewport(isOutOfViewport(buttonRef));
          }}
        >
          {label}
        </TertiaryButtonSmall>
        {showDropDown && (
          <DropDownModalWrapper
            ref={wrapperRef}
            className="dropdownWrapperDiv"
            isOutOfViewport={ButtonViewport}
          >
            <RangeCalendarFilter
              handleApply={apply}
              handleClear={clear}
              selectedFilter={selectedFilter}
              label={label}
              filter={filter}
              handleDatesChange={handleDatesChange}
            />
          </DropDownModalWrapper>
        )}
      </DropDownWrapper>
    );
  } else {
    return (
      <DropDownWrapper>
        <TertiaryButtonSmall
          active={isValidDateRange(appliedFilter)}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {label}
        </TertiaryButtonSmall>
        <Modal show={showModal} closeModal={() => setShowModal(false)}>
          <RangeCalendarFilter
            handleApply={apply}
            handleClear={clear}
            selectedFilter={selectedFilter}
            label={label}
            filter={filter}
            handleDatesChange={handleDatesChange}
          />
        </Modal>
      </DropDownWrapper>
    );
  }
};
