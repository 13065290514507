import type { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { ErrorPlaceholder } from "../../../components/Error";
import type { QuoteRequest, User } from "../../../types/types";
import {
  useFees,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { SellerQuoteDetailPageContent } from "./SellerQuoteDetailPageContent";

/**
 * Shared page component showing the details of a single quote request that
 * came from a buyer user with an account (not a guest user without an account).
 * Accessible from the "Quotes" list page.
 *
 * Used by sellers and seller admins.
 */
export function SellerQuoteDetailPage({ user }: { user: User }) {
  const { storefront_id } = useStoreState();
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);
  const { quote_id } = useParams<{ quote_id: string }>();
  const {
    data: quote,
    error: quoteError,
    mutate: mutateQuote,
  } = useSWR<QuoteRequest, AxiosError>(
    `/v1/storefronts/${storefront_id}/quotes/${quote_id}`
  );
  const { data_map: fees_and_charges_map, loading: is_loading_fees_charges } =
    useFees(storefront_id);
  const isLoadingQuote = !quote && !quoteError;

  if (quote) {
    mutateNotifications();
  }

  if (quoteError) {
    return (
      <ErrorPlaceholder message={"There was an error loading the quote."} />
    );
  }

  if (isLoadingQuote || is_loading_fees_charges) {
    return null;
  }

  if (quote) {
    return (
      <SellerQuoteDetailPageContent
        quote={quote}
        user={user}
        mutateQuote={mutateQuote}
        fees_and_charges_map={fees_and_charges_map}
      />
    );
  } else return null;
}
