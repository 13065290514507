import type { Identifier, XYCoord } from "dnd-core";
import type { FC, HTMLProps } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  CaretDownIcon,
  CheckIcon,
  InfoIcon,
  LightCaretRightIcon,
  NonVisibleIcon,
  ReorderIcon,
  XIcon,
} from "../../../../../components/Icons/Icons";
import { Flex } from "../../../../../layout/FormLayout";
import React from "react";
import { t } from "i18next";
import { Table } from "../../../../../components/Table/Table";
import type { TabItemFormValue } from "./SellerAdminTemplateViewTab";
import { TabItemNameSchemaFn } from "./SellerAdminTemplateViewTab";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  InvisibleButton,
} from "../../../../../components/Buttons/Buttons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EditModeTitle } from "../../../../../components/EditableTitle/EditModeTitle";
import { ButtonGroup } from "../../../../../components/EditableTitle/EditableTitle";
import axios from "axios";
import type { AxiosError } from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import {
  TablePlaceholder,
  toTitleCase,
  useStoreState,
} from "../../../../../util/util";
import type { DataMutate } from "../../../../../types/types";
import type {
  TemplateTabData,
  TemplateTabItem,
  TemplateViewSchema,
} from "../../../../../types/types.PIM";
import ReactTooltip from "react-tooltip";
import { useAuthContext } from "../../../../../components/Auth";
import { ThemeContext } from "styled-components";
import type { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";

export interface CardProps {
  card: TemplateTabData;
  index: number;
  templateId: string;
  viewId: string;
  tabNames: string[];
  isSKUs: boolean;
  isSEO: boolean;
  disabled: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  mutateView: DataMutate<TemplateViewSchema>;
  handleDrop?: () => void;
  handleToggleAllRowsExpanded: (expanded: boolean) => void;
  moveRow: ({
    item,
    source,
  }: {
    item: TemplateTabItem;
    source: string;
    data: TemplateTabItem[];
  }) => void;
  handleExpandedState: (cardId: string, expanded: ExpandedState) => void;
  expandedRows: ExpandedState;
  updateRow: ({
    data,
    source,
  }: {
    source: string;
    data: TemplateTabItem[];
  }) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
const ItemTypes = {
  CARD: "card",
};

export const TemplateViewCard: FC<CardProps> = ({
  card,
  index,
  moveCard,
  moveRow,
  updateRow,
  expandedRows,
  handleExpandedState,
  templateId,
  viewId,
  mutateView,
  handleToggleAllRowsExpanded,
  isSKUs,
  isSEO,
  handleDrop,
  tabNames,
  disabled,
}) => {
  const [tableData, setTableData] = useState(card.items);
  const [showTooltip, setShowTooltip] = useState(false);
  const [editTabDisplayName, setEditTabDisplayName] = useState<string>();
  const theme = useContext(ThemeContext);
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { tenant_id } = useStoreState();
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { hasPermission } = useAuthContext();

  const handleDeleteTab = async (id: string) => {
    try {
      await axios.delete(
        `v2/tenants/${tenant_id}/templates/${templateId}/views/${viewId}/tabs/${id}`
      );
      notifySuccess(t("Tab has been deleted successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error deleting tab"),
        {
          error,
        }
      );
    }
    await mutateView();
  };

  const handleEditTabNameTab = async ({ name }: TabItemFormValue) => {
    try {
      await axios.patch(
        `v2/tenants/${tenant_id}/templates/${templateId}/views/${viewId}/tabs/${card.id}`,
        { name }
      );
      notifySuccess(t(`Tab (${name}) has been updated successfully`));
      setEditTabDisplayName(undefined);
      await mutateView();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error editing tab name"),
        {
          error,
        }
      );
    }
  };

  const editNameUseForm = useForm({
    resolver: zodResolver(TabItemNameSchemaFn(t, tabNames)),
  });

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(_item, _monitor) {
      if (handleDrop) {
        handleDrop();
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: card.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  preview(drop(ref));
  drag(dragRef);

  const IndeterminateCheckbox = ({
    indeterminate,
    className = "",
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
    const ref = React.useRef<HTMLInputElement>(null!);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate, rest.checked]);

    return (
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        style={{ width: "20px", height: "20px", cursor: "pointer" }}
      />
    );
  };

  const tableColumns = React.useMemo<ColumnDef<TemplateTabItem>[]>(() => {
    const handleToggleVisibilty = (
      row: Row<TemplateTabItem>,
      event: React.FormEvent<HTMLInputElement>
    ) => {
      let newData = tableData;
      if (row.depth === 0) {
        newData[row.index].attributes?.forEach(
          (attr) => (attr.is_visible = event.currentTarget.checked)
        );
        updateRow({ source: card.name, data: newData });
      } else {
        const parentRowIndex = row.getParentRow()?.index;
        if (parentRowIndex !== undefined && parentRowIndex > -1) {
          const rowAttributes = newData[parentRowIndex].attributes;
          rowAttributes[row.index].is_visible = event.currentTarget.checked;
          newData[parentRowIndex].attributes = rowAttributes;
          updateRow({ source: card.name, data: newData });
        }
      }
    };
    return [
      {
        header: t("Section"),
        accessorKey: "name",
        cell: ({ row, renderValue, getValue }) => {
          return row.getCanExpand() ? (
            <span
              onClick={row.getToggleExpandedHandler()}
              style={{ cursor: "pointer", display: "flex" }}
            >
              {row.getIsExpanded() ? (
                <CaretDownIcon width={16} height={16} />
              ) : (
                <LightCaretRightIcon width={16} height={16} />
              )}
              {renderValue()}
            </span>
          ) : (
            <>
              <span style={{ display: "inline-block", width: "16px" }} />
              {renderValue()}
              {}
              {row
                .getParentRow()
                ?.original.attributes?.find((attr) => attr.name === getValue())
                ?.is_restricted && (
                <>
                  <div
                    data-for={`restricted-${row.id}`}
                    data-tip={t("Restricted Attribute")}
                    style={{
                      display: "inline-block",
                      padding: "2px 0 0px 5px",
                    }}
                  >
                    <NonVisibleIcon
                      width={16}
                      height={16}
                      fill={theme.primaryBorder}
                    />
                  </div>

                  <ReactTooltip id={`restricted-${row.id}`} />
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Display Name"),
        accessorKey: "display_name",
      },
      {
        header: t("Type"),
        accessorKey: "type",
        cell: (cell) => {
          return toTitleCase((cell.getValue() as string)?.replace(/_/g, " "));
        },
      },
      {
        header: (cell) => {
          return (
            <span style={{ display: "flex" }}>
              {t("Visbility")}
              <span
                style={{ marginLeft: "3px" }}
                data-for={`restricted-${cell.column.id}`}
                data-tip={t(
                  "Visibility determines which attributes are accessible to internal or external assignments."
                )}
              >
                <InfoIcon width={16} height={16} />
              </span>
              <ReactTooltip id={`restricted-${cell.column.id}`} />
            </span>
          );
        },
        accessorKey: "is_visible",
        cell: ({ row, getValue }) => (
          <div>
            <IndeterminateCheckbox
              disabled={!hasPermission("modify_templates")}
              {...{
                checked:
                  row.depth === 0
                    ? row.original.attributes?.every((attr) => attr.is_visible)
                    : getValue<boolean>(),
                indeterminate:
                  row.depth === 0
                    ? !row.original.attributes?.every(
                        (attr) => attr.is_visible
                      ) &&
                      !row.original.attributes?.every(
                        (attr) => !attr.is_visible
                      )
                    : false,
                onChange: (e) => handleToggleVisibilty(row, e),
              }}
            />
          </div>
        ),
      },
    ];
  }, [tableData, card.name, updateRow, theme, hasPermission]);

  const CardContent = () => {
    switch (card.tab_type) {
      case "documents":
        return <></>;
      default:
        return (
          <Table
            columns={tableColumns}
            isLoading={false}
            error={undefined}
            subRowsKey={"attributes"}
            expandedRows={!isDragging ? expandedRows : {}}
            handleExpandedState={(e) => handleExpandedState(card.id, e)}
            handleToggleAllRowsExpanded={handleToggleAllRowsExpanded}
            data={tableData}
            Placeholder={
              <TablePlaceholder message={t("No groups or collections added")} />
            }
            enableReorder={hasPermission("modify_templates")}
            lastChildleftAlign={true}
            enableDefaultSort={false}
            name={card.name}
            handleMoveRow={
              hasPermission("modify_templates") ? moveRow : undefined
            }
            handleDrop={
              hasPermission("modify_templates") ? handleDrop : undefined
            }
          />
        );
    }
  };

  useEffect(() => {
    setTableData(card?.items);
  }, [card]);
  return (
    <div ref={ref} style={{ marginBottom: "30px", opacity: opacity }}>
      <Flex
        style={{
          padding: "5px 0 15px",
          opacity,
          display: "flex",
          alignItems: "center",
        }}
        data-handler-id={handlerId}
      >
        {hasPermission("modify_templates") && (
          <div ref={dragRef} style={{ cursor: "move", marginRight: "20px" }}>
            <ReorderIcon />
          </div>
        )}
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          {editTabDisplayName !== card.id && <div>{card.display_name}</div>}
          {editTabDisplayName === card.id && (
            <Flex>
              <form
                id="edit_tab"
                noValidate
                onSubmit={editNameUseForm.handleSubmit(handleEditTabNameTab)}
                style={{ position: "relative" }}
              >
                <EditModeTitle
                  name={"name"}
                  theref={inputRef}
                  register={editNameUseForm.register}
                  formState={editNameUseForm.formState}
                  errors={editNameUseForm.errors}
                  tabIndex={0}
                  defaultValue={card.display_name}
                  fontSize={"regular"}
                  fontWeight={"medium"}
                />
              </form>

              <ButtonGroup>
                <InvisibleButton form={"edit_tab"} type="submit">
                  <CheckIcon fill={theme.activeToggleBG} />
                </InvisibleButton>
                <InvisibleButton
                  type="button"
                  onClick={() => setEditTabDisplayName(undefined)}
                >
                  <XIcon fill={theme.destructiveTextColor} />
                </InvisibleButton>
              </ButtonGroup>
            </Flex>
          )}
          {editTabDisplayName !== card.id && !isDragging && (
            <>
              {card.tab_type !== "seo" &&
                card.tab_type !== "sku" &&
                hasPermission("modify_templates") && (
                  <div
                  // onMouseEnter={() => setShowTooltip(true)}
                  // onMouseLeave={() => setShowTooltip(false)}
                  >
                    <EditButton
                      height={18}
                      id={`edit-tab${card.id}`}
                      disabled={disabled}
                      style={{ marginLeft: "10px" }}
                      onClick={() => setEditTabDisplayName(card.id)}
                      datafor={`edit-tab${card.id}`}
                      datatip={
                        showTooltip && disabled
                          ? t(
                              "Save your changes first to be able to edit this tab"
                            )
                          : undefined
                      }
                    />
                    {showTooltip && disabled && (
                      <ReactTooltip id={`edit-tab${card.id}`} />
                    )}
                  </div>
                )}
              {card.tab_type === "custom" && hasPermission("modify_templates") && (
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <ButtonWithConfirmDialog
                    Button={(props) => (
                      <>
                        <DeleteButton
                          {...props}
                          id={`remove-tab${card.id}`}
                          datafor={`remove-tab${card.id}`}
                          datatip={
                            showTooltip && card.items.length > 0 && !disabled
                              ? t(
                                  "Move all groups and collections to a different tab to delete"
                                )
                              : showTooltip && disabled
                              ? t(
                                  "Save your changes first to be able to delete this tab"
                                )
                              : undefined
                          }
                        />
                        {showTooltip && (
                          <ReactTooltip id={`remove-tab${card.id}`} />
                        )}
                      </>
                    )}
                    disabled={disabled || card.items.length > 0}
                    testid={`remove-tab${card.id}`}
                    handleConfirm={() => handleDeleteTab(card.id)}
                    confirmMessage={t(
                      "Are you sure you want to delete this tab?"
                    )}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Flex>
      {card && <CardContent />}
    </div>
  );
};
