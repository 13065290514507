import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import type {
  DropdownSearchItem,
  ListPatchArgSchema,
  PaginatedDropdownSearchResults,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { ARCHIVED_UUID, ListHome } from "./ListHome";
import { ListDetails } from "./ListDetails";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { useStoreState } from "../../../../../util/util";
import type { AxiosError } from "axios";
import axios from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { ArchivedListDetails } from "./ArchivedListDetails";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { StringParam, useQueryParams } from "use-query-params";
import { useDebounce } from "../../../../../util/hooks";
import { SecondaryButtonWithImportIcon } from "../../../../../components/Buttons/Buttons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { BulkListUploadFromCSV } from "./BulkListUploadFromCSV";
import type {
  DataMutate,
  UUID,
  WithPagination,
} from "../../../../../types/types";
import { WithPermission } from "../../../../../components/WithPermission/WithPermission";
import { DropDownModal } from "../../../../../components/DropDownModal/DropDownModal";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";

const TopAreaWrapper = styled.div``;

const ListWrapper = styled.div<{ height: string }>`
  display: grid;
  grid-template-columns: 40% 60%;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  width: 100%;
  height: ${({ height }) => height};
`;

const DropDownModalWrapper = styled.div`
  position: absolute;
  top: 125px;
  min-width: 520px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    min-width: 450px;
  }
  @media screen and (max-width: 479px) {
    min-width: 94vw;
  }
`;

const SearchResultsLabel = styled.p`
  position: absolute;
  top: 50px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0px;

  span {
    font-weight: bold;
  }
`;

export const SellerAdminPIMListHomePage = () => {
  const [selectedList, setSelectedList] = useState<ShortListSchema>();
  const [archivedLists, setArchivedLists] =
    useState<WithPagination<{ data: ShortListSchema[] }>>();
  const [activeLists, setActiveLists] =
    useState<WithPagination<{ data: ShortListSchema[] }>>();
  const [showArchived, setShowArchived] = useState(false);
  const [showListImportForm, setShowListImportForm] = useState(false);
  const [offset, setOffset] = useState(0);
  const [dropdownSearchLists, setDropdownSearchLists] =
    useState<WithPagination<{ data: DropdownSearchItem[] }>>();
  const [selectedListID, setSelectedListID] = useState<UUID>();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchInputText, setSearchInputText] = useState("");
  const [selectSearchInputOption, setSelectSearchInputOption] = useState("");
  const [isSearchError, setIsSearchError] = useState(false);
  const [shouldFetchQuery, setShouldFetchQuery] = useState(false);
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const perPage = 300;
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [pageHeight, setPageHeight] = useState<string>("70vh");
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const { t } = useTranslation();
  const { error: listError, mutate: mutateLists } = useSWR<PaginatedListSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debouncedSearchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "active",
          },
        }),
        [debouncedSearchQuery, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: ({ data, pagination }) =>
        setActiveLists({
          data: data.filter(({ parent }) => !Boolean(parent)),
          pagination,
        }),
    }
  );

  const { mutate: mutateArchivedLists } = useSWR<PaginatedListSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debouncedSearchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "archived",
          },
        }),
        [debouncedSearchQuery, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: (result) => setArchivedLists(result),
    }
  );

  useSWR<PaginatedDropdownSearchResults>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists_items(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debouncedSearchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "active",
          },
        }),
        [debouncedSearchQuery, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: (response) => setDropdownSearchLists(response),
    }
  );

  const mutateAllLists = async () => {
    await mutateLists();
    await mutateArchivedLists();
  };

  const handleImportListClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setShowListImportForm(true);

  const onSelectList = (
    list: ShortListSchema | undefined,
    showArchived: boolean = false
  ) => {
    if (showArchived) {
      setShowArchived(true);
    } else {
      const foundList = activeLists!.data.find((item) => item.id === list?.id);
      setSelectedList(foundList ?? list);
      setShowArchived(false);
    }
  };

  const onRestore = async ({ id }: ShortListSchema) => {
    try {
      await axios.patch<ListPatchArgSchema, ShortListSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, id),
        {
          is_deleted: false,
        }
      );
      notifySuccess(t("List restored successfully"));
      if (archivedLists!.pagination.total === 1) {
        setShowArchived(false);
        setSelectedList(undefined);
      }
      await mutateAllLists();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not restore list. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    const value = e.target.value.trim();
    setShowSearchModal(value.length > 0);
    setShouldFetchQuery(false);
    if (value.length === 0) {
      setSearchInputText("");
      setIsSearchError(false);
    }
  };

  const handleClearSearch = () => {
    setSearchInputText("");
    setShowSearchModal(false);
    setSearchQuery("");
    setQuery({ q: undefined });
    setSelectSearchInputOption("");
    setIsSearchError(false);
    setShouldFetchQuery(false);
  };

  const archivedLabel = t("Archived").toLowerCase();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!activeLists || !activeLists.data) return;

    const buttonText = (
      event.currentTarget as HTMLElement
    )?.textContent?.trim();
    if (!buttonText) return;

    const [listName, listValue] = buttonText.split(" > ");

    const foundList = activeLists.data.find(
      (item) => String(item.name) === listName
    );

    setShowSearchModal(false);
    setSearchInputText(searchQuery);

    if (!listValue) {
      setShowArchived(false);
      setSelectedList(foundList);
      setSelectedListID(foundList?.id);
      setShouldFetchQuery(true);
      setSelectSearchInputOption("");
      return;
    }

    if (listName.toLowerCase() === archivedLabel) {
      setSelectedListID(ARCHIVED_UUID);
      onSelectList(undefined, true);
      return;
    }

    if (listValue.toLowerCase() === archivedLabel) {
      setShowArchived(false);
      setSelectedList(foundList);
      setSelectedListID(foundList?.id);
      setSelectSearchInputOption(ARCHIVED_UUID);
      return;
    }

    setShowArchived(false);
    setSelectedList(foundList);
    setSelectedListID(foundList?.id);
    setSelectSearchInputOption(listValue);
  };

  const dropdownOptions = useMemo(() => {
    if (!dropdownSearchLists?.data?.length) return ["No results found"];

    const result = dropdownSearchLists?.data
      .map((item) => {
        const searchTerm = debouncedSearchQuery.toLowerCase().trimEnd();

        if (item.is_list_archived) {
          return `${t("Archived")} > ${item.list_name}`;
        }

        if (item.is_item_archived) {
          return `${item.list_name} > ${t("Archived")} > ${item.name}`;
        }

        const matchesListName = item.list_name
          .toLowerCase()
          .includes(searchTerm);
        const matchesName = item.name.toLowerCase().includes(searchTerm);

        if (matchesListName && matchesName) {
          return `${item.list_name} > ${item.name}`;
        }

        if (matchesListName) {
          return item.list_name;
        } else if (matchesName) {
          return `${item.list_name} > ${item.name}`;
        }

        return null;
      })
      .filter((item) => item !== null);

    const nonArchivedItems = result.filter(
      (item) => item && !item.toLowerCase().includes(archivedLabel)
    );
    const archivedItems = result.filter(
      (item) => item && item.toLowerCase().includes(archivedLabel)
    );

    const uniqueNonArchived = [
      ...new Map(nonArchivedItems.map((item) => [item, item])).values(),
    ];
    const uniqueArchived = [
      ...new Map(archivedItems.map((item) => [item, item])).values(),
    ];

    const sortedNonArchived = uniqueNonArchived.sort((a, b) => {
      if (a && b) {
        return a.localeCompare(b);
      }
      return 0;
    });

    const finalResult = [...sortedNonArchived, ...uniqueArchived];

    return finalResult.length > 0 ? finalResult : ["No results found"];
  }, [debouncedSearchQuery, dropdownSearchLists?.data, archivedLabel, t]);

  const isLoading = !activeLists && !listError;
  const hasNoData = dropdownOptions.toString() === "No results found";

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSearchError(hasNoData);
    setShowSearchModal(false);
    setSearchInputText(searchQuery);
  };

  useEffect(() => {
    if (activeLists) {
      setSelectedList(
        (prev) => activeLists.data.find((val) => val.id === prev?.id) ?? prev
      );
    }
  }, [activeLists]);

  useEffect(() => {
    if (debouncedSearchQuery === "") {
      setQuery({ q: undefined });
    }
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  const topAreaHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPageHeight(
      topAreaHeightRef.current
        ? `calc(100% - ${topAreaHeightRef.current.clientHeight + 65}px)`
        : "70vh"
    );
  }, [activeLists, archivedLists]);

  return (
    <PageWrapper style={{ height: "100%" }}>
      <TopAreaWrapper ref={topAreaHeightRef}>
        <PageHeader>
          <PageTitle>{t("Lists")}</PageTitle>
        </PageHeader>
        <div
          style={{
            marginBottom: "40px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <form
            onSubmit={handleSearchSubmit}
            style={{ width: "100%", marginBottom: "0" }}
          >
            <SearchBar
              query={searchQuery}
              placeHolder={t("Search by list name or value")}
              handleChange={handleSearch}
              handleClearInput={handleClearSearch}
              maxWidth="450px"
            />
          </form>
          <WithPermission permission="modify_lists">
            <SecondaryButtonWithImportIcon onClick={handleImportListClick}>
              {t("Import")}
            </SecondaryButtonWithImportIcon>
          </WithPermission>

          <SlideOut
            show={showListImportForm}
            closeFlyout={() => setShowListImportForm(false)}
          >
            <BulkListUploadFromCSV
              onSuccess={() => {
                mutateAllLists();
                setShowListImportForm(false);
              }}
            />
          </SlideOut>
          <SearchResultsLabel>
            {searchInputText?.length > 0 && (
              <>
                {isSearchError ? (
                  <>
                    {t('No list name or value matches "')}
                    <span>{searchInputText}</span>
                    {t('"')}
                  </>
                ) : (
                  <>
                    {t('Showing results for "')}
                    <span>{searchInputText}</span>
                    {t('"')}
                  </>
                )}
              </>
            )}
          </SearchResultsLabel>
        </div>

        {showSearchModal && (
          <DropDownModalWrapper>
            <DropDownModal
              items={dropdownOptions}
              activeItem=""
              clickHandler={handleClick}
              className="list-dropdown"
              showHighlightTextInDropdown={true}
              searchQuery={searchQuery}
            />
          </DropDownModalWrapper>
        )}
      </TopAreaWrapper>
      {!isLoading ? (
        <>
          {(activeLists?.pagination?.total ?? 0) > 0 ||
          (archivedLists?.pagination?.total ?? 0) > 0 ? (
            <ListWrapper height={pageHeight}>
              <ListHome
                lists={activeLists}
                archivedLists={archivedLists}
                mutateList={mutateAllLists as DataMutate<PaginatedListSchema>}
                listError={listError}
                onSelected={onSelectList}
                offset={offset}
                setOffset={setOffset}
                searchQuery={searchQuery}
                selectedListID={selectedListID}
                setSelectedListID={setSelectedListID}
              />
              {selectedList && !showArchived && (
                <ListDetails
                  mutateList={mutateAllLists as DataMutate<PaginatedListSchema>}
                  item={selectedList}
                  pageHeight={pageHeight}
                  selectSearchInputOption={selectSearchInputOption}
                  shouldFetchQuery={shouldFetchQuery}
                />
              )}
              {showArchived && (
                <ArchivedListDetails
                  searchQuery={debouncedSearchQuery}
                  onRestore={onRestore}
                  pageHeight={pageHeight}
                />
              )}
            </ListWrapper>
          ) : (
            !hasNoData && (
              <span>
                {t("No list name or value matches your search query")}
              </span>
            )
          )}
        </>
      ) : (
        <DelayedSpinner />
      )}
    </PageWrapper>
  );
};
