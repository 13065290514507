import type { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Table } from "../../../../components/Table/Table";
import {
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
  ContentWrapper,
} from "../../../../layout/portalPageLayout";
import type { RoleSummary } from "../../../../types/types.PIM";
import { useRoutePath } from "../../../../util/Routing";
import { useStoreState } from "../../../../util/util";

export function SellerAdminRolesListingPage() {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const [tableData, setTableData] = useState<any[]>([]);

  const { data: roles, error: rolesError } = useSWR<
    { data: RoleSummary },
    AxiosError
  >(`/v1/storefronts/${storefront_id}/roles`);

  const isLoadingRoles = !roles && !rolesError;

  // columns and table data can be made fully dynamic once all permissions are supported.
  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Role"),
        accessorKey: "role",
      },
      {
        header: t("Attributes"),
        accessorKey: "attributes",
        enableSorting: false,
      },
      {
        header: t("Lists"),
        accessorKey: "lists",
      },
      {
        header: t("Templates"),
        accessorKey: "templates",
      },
      {
        header: t("Products"),
        accessorKey: "products",
      },
      { header: t("Assets"), accessorKey: "assets" },
    ],
    [t]
  );

  useEffect(() => {
    const handleRolesData = (data: RoleSummary) => {
      setTableData(
        data.map((role) => {
          return {
            role: role.name,
            id: role.id,
            attributes: role.permission_object_type.attributes,
            lists: role.permission_object_type.lists,
            templates: role.permission_object_type.templates,
            products: role.permission_object_type.products,
            assets: role.permission_object_type.assets,
          };
        })
      );
    };

    if (roles) {
      handleRolesData(roles.data);
    }
  }, [roles]);

  // This is very intentionally not using history.push so that we can reload the
  // page. This is related to issues with showing stale data from the
  // defaultChecked state of the checkboxes on the role detail page.
  // see AD-1908 issue #1
  const handleRowClick = (e: React.MouseEvent): void => {
    window.location.assign(
      `${adminPath}/organization/roles/${e.currentTarget.id}`
    );
  };

  return (
    <PageWrapper>
      <PageTitle>{t("Roles & Permissions")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <ContentWrapper style={{ marginTop: "24px" }}>
        <Table
          columns={tableColumns}
          data={tableData}
          error={rolesError}
          isLoading={isLoadingRoles}
          rowClick={handleRowClick}
          lastChildleftAlign
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
