import { useCallback, useContext, useMemo, useState } from "react";
import type { AxiosError } from "axios";
import Axios from "axios";
import {
  Notifications,
  useNotifications,
} from "../../../../../components/Notifications/NotificationsContext";
import debounce from "lodash/debounce";
import type {
  AttributeGroupSummary,
  CollectionSchema,
  PaginatedAttributeSchema,
} from "../../../../../types/types.PIM";
import {
  TEMPORARY_HIGH_LIMIT,
  useFormWrapper,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../util/util";
import type {
  DataMutate,
  OptionType,
  SupportedLanguage,
  TranslationsSchema,
} from "../../../../../types/types";
import { attributeToOption } from "../SellerAdminPIMAttributeGroups/CreateNewAttributeGroup";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { z } from "zod";
import { strings } from "../../../../../util/strings";
import { ContentWrapper } from "../../../../../layout/portalPageLayout";
import { Table } from "../../../../../components/Table/Table";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { H3, H5 } from "../../../../../components/Typography/Typography";
import { Form } from "../../../../../layout/FormLayout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import {
  PrimaryButtonMedium,
  PrimaryCancelButton,
} from "../../../../../components/Buttons/Buttons";
import {
  LanguageTranslationContainer,
  TranslationsButtonContainer,
  TranslationsModifiedRow,
  TranslationsPropertyItem,
} from "../../../../../components/AccordionCard/TranslationsAccordionCard";
import { Break } from "../../../../../layout/shared";
import { WarningIcon } from "../../../../../components/Icons/Icons";

export const zodSelectAttributeType = z.object({
  value: z.string().min(1),
  label: z.string().min(1),
  display_name: z.string(),
});

export const zodSelectAttribute = (t: TFunction) =>
  z.object({
    value: z.string().min(1, strings(t).thisIsARequiredField),
    label: z.string().min(1, strings(t).thisIsARequiredField),
    display_name: z.string(),
  });

export const useHandleAttributesAsyncSearch = (
  type?: "group" | "collection"
) => {
  const { notifyError } = useContext(Notifications);
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleAttributesSearch = useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          const {
            data: { data },
          } = await Axios.get<PaginatedAttributeSchema>(
            `/v2/tenants/${tenant_id}/pim/attributes`,
            {
              params: { limit: TEMPORARY_HIGH_LIMIT, q: query },
            }
          );
          return data
            .filter((attr) =>
              type === "collection" ? attr.input_type !== "image" : true
            )
            .map((attribute) => {
              const attributeOption: OptionType<string> & {
                display_name: string;
              } = {
                ...attributeToOption(attribute),
                display_name: attribute.display_name
                  ? attribute.display_name
                  : attribute.name,
              };
              return attributeOption;
            });
        } catch (error) {
          notifyError(
            t("Could not fetch attributes. Something went wrong", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleAttributesSearchNoMultiline = useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          const {
            data: { data },
          } = await Axios.get<PaginatedAttributeSchema>(
            `/v2/tenants/${tenant_id}/pim/attributes`,
            {
              params: { limit: TEMPORARY_HIGH_LIMIT, q: query },
            }
          );
          return data.reduce((acc: OptionType[], attribute) => {
            if (
              attribute.input_type !== "multiline_entry" &&
              attribute.input_type !== "image"
            ) {
              const attributeOption: OptionType<string> & {
                display_name: string;
              } = {
                ...attributeToOption(attribute),
                display_name: attribute.display_name
                  ? attribute.display_name
                  : attribute.name,
              };
              acc.push(attributeOption);
            }
            return acc;
          }, []);
          // .filter((attribute) => attribute.input_type !== "multiline_entry")
          // .map((attribute) => {
          //   const attributeOption: OptionType<string> & {
          //     display_name: string;
          //   } = {
          //     ...attributeToOption(attribute),
          //     display_name: attribute.display_name
          //       ? attribute.display_name
          //       : attribute.name,
          //   };
          //   return attributeOption;
          // });
        } catch (error) {
          notifyError(
            t("Could not fetch attributes. Something went wrong", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );
  return { handleAttributesSearch, handleAttributesSearchNoMultiline };
};

export const GroupOrCollectionTranslationsTable = ({
  attribute_display_name,
  translations,
}: {
  translations: TranslationsSchema[];
  attribute_display_name: string;
}) => {
  const { t } = useTranslation();
  const { getLanguageLabel } = useSupportedLanguages();

  const defaultLang = "en";

  const tableColumns = useMemo(
    () => [
      {
        header: t("Language"),
        accessorKey: "language",
      },
      {
        header: t("Display Name"),
        accessorKey: "display_name",
      },
    ],
    [t]
  );

  return (
    <ContentWrapper>
      <Table
        columns={tableColumns}
        data={translations.map(({ display_name, language }) => ({
          language: getLanguageLabel(language),
          display_name:
            language === defaultLang ? attribute_display_name : display_name,
        }))}
        isLoading={false}
        lastChildleftAlign
        error={undefined}
      />
    </ContentWrapper>
  );
};

export const EditGroupOrCollectionTranslations = ({
  translations,
  mutate,
  onClose,
  group_or_collection,
}: {
  group_or_collection: {
    name: string;
    display_name: string;
    id: string;
    type: "groups" | "collections";
  };
  translations: TranslationsSchema[];
  mutate:
    | DataMutate<CollectionSchema & { translations: TranslationsSchema[] }>
    | DataMutate<
        AttributeGroupSummary & { translations: TranslationsSchema[] }
      >;
  onClose: () => void;
}) => {
  const { name, display_name, id, type } = group_or_collection;
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [warningText, setWarningText] = useState<{ [prop: string]: string }>(
    {}
  );
  const { supported_languages } = useSupportedLanguages();
  const { t, i18n } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const methodsOfUseForm = useFormWrapper();
  const { errors, formState, handleSubmit, register } = methodsOfUseForm;

  const defaultTranslation = {
    language: "en",
    display_name: group_or_collection.display_name,
    description: "",
  };

  const isDefaultKey = (key: string) =>
    key.split("_")?.[0] === defaultTranslation.language;

  const onSubmit = async (formValues: { [prop: string]: string }) => {
    const warningObj = { ...warningText };
    let shouldReturn = false;
    for (let [key, value] of Object.entries(formValues)) {
      if (!value && !warningText[key] && !isDefaultKey(key)) {
        warningObj[key] = t("Missing translation");
        shouldReturn = true;
      } else {
        warningObj[key] = "";
      }
    }
    setWarningText(warningObj);
    if (shouldReturn) {
      return;
    }
    setOnSubmitting(true);
    try {
      const translationsArr = Object.keys(supported_languages).reduce<
        TranslationsSchema[]
      >(
        (acc, language) =>
          language === defaultTranslation.language
            ? acc
            : [
                ...acc,
                {
                  language,
                  display_name: formValues[`${language}_display_name`],
                  description: "",
                },
              ],
        []
      );
      await axios.put(
        endpoints.v2_tenants_tenant_id_pim_attribute_id_translations(
          tenant_id,
          type,
          id
        ),
        { translations: translationsArr }
      );
      await mutate();
      await i18n.reloadResources();
      notifySuccess(t("Translations updated successfully"));
      setOnSubmitting(false);
      onClose();
      window.location.reload();
    } catch (error) {
      setOnSubmitting(false);
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update translations. Please try again later."),
        {
          error,
        }
      );
    }
  };
  return (
    <>
      <H3 style={{ margin: "0 0 24px" }}>{t("Manage Translations")}</H3>
      <H5 style={{ margin: "0 0 16px" }}>{t("Attribute details")}</H5>
      <TranslationsModifiedRow>
        <div style={{ flex: 1 }}>
          <TranslationsPropertyItem
            property={
              type === "groups" ? t("Group Name") : t("Collection Name")
            }
            value={name}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TranslationsPropertyItem
            property={t("Display Name")}
            value={display_name ? display_name : "--"}
          />
        </div>
      </TranslationsModifiedRow>
      <Break style={{ marginBottom: "24px" }} />
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {Object.entries(supported_languages).map(([langCode, langLabel]) => (
          <LanguageTranslationContainer>
            <H5>{langLabel}</H5>
            <TextField
              name={`${langCode}_display_name`}
              label={t("Display Name")}
              theref={register({ required: false })}
              formState={formState}
              errors={errors}
              type="text"
              readOnly={langCode === defaultTranslation.language}
              defaultValue={
                getTranslation({ langCode, translations, defaultTranslation })
                  .display_name
              }
              warningText={{ message: warningText[`${langCode}_display_name`] }}
              Icon={
                warningText[`${langCode}_display_name`]
                  ? WarningIcon
                  : undefined
              }
            />
          </LanguageTranslationContainer>
        ))}
        <TranslationsButtonContainer>
          <PrimaryCancelButton style={{ flex: 1 }} onClick={onClose}>
            {t("Cancel")}
          </PrimaryCancelButton>
          <PrimaryButtonMedium
            loading={onSubmitting}
            style={{ flex: 1 }}
            type="submit"
          >
            {t("Confirm")}
          </PrimaryButtonMedium>
        </TranslationsButtonContainer>
      </Form>
    </>
  );
};

export const getTranslation = ({
  langCode,
  translations,
  defaultTranslation,
}: {
  langCode: SupportedLanguage;
  translations: TranslationsSchema[];
  defaultTranslation: TranslationsSchema;
}): TranslationsSchema => {
  if (langCode === defaultTranslation.language) {
    return defaultTranslation;
  } else {
    return (
      translations.find(({ language }) => language === langCode) ?? {
        language: langCode,
        description: "",
        display_name: "",
      }
    );
  }
};
