import { yupResolver } from "@hookform/resolvers/yup";
import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError, AxiosResponse } from "axios";
import axios, { default as Axios } from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import useSWR from "swr";
import type { mutateCallback } from "swr/dist/types";
import type { SetRequired } from "type-fest";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import * as yup from "yup";
import {
  ButtonWithConfirmDialog,
  ButtonWithWarningDialog,
  CancelButton,
  GoBackButton,
  InvisibleButton,
  PrimaryButtonFitContainer,
  PrimaryButtonMedium,
  PrimaryButtonWithPlusIcon,
} from "../../../components/Buttons/Buttons";
import {
  CheckBox,
  CheckBoxNoLabel,
} from "../../../components/CheckBoxes/CheckBoxes";
import {
  ConfirmDialog,
  GenericDialogBody,
} from "../../../components/ConfirmDialog/ConfirmDialog";
import { ContactInfoBlockSmall } from "../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import { makeYupCurrencyTestFn } from "../../../components/CurrencyInput/CurrencyInput";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { TransactionsDocumentView } from "../../../components/DocumentView/TransactionsDocumentView";
import { EditSubtotal } from "../../../components/EditSubtotal/EditSubtotal";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  CustomTermContainer,
  WarningMessage,
} from "../../../components/Form/Form";
import { IDDesktop } from "../../../components/IDs/IDs";
import {
  EditIcon,
  LoadingIcon,
  WarningIcon,
} from "../../../components/Icons/Icons";
import type { KeyValuePair } from "../../../components/KeyValueDisplay";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import { HeaderLeft, HeaderRight } from "../../../components/Layout/Layout";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { createPaymentObjectFromPercentages } from "../../../components/PriceDetails/Payments.util";
import {
  PriceDetails,
  StripePayments,
  calculatePriceDetails,
} from "../../../components/PriceDetails/PriceDetails";
import { SellerQuoteStatus } from "../../../components/QuoteStatus";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { TaxExemptDocument } from "../../../components/TaxExempt/TaxExemptDocument";
import { Timeline } from "../../../components/Timeline/Timeline";
import { QuoteItemCardSellerComplete } from "../../../components/TransactionItem/QuoteItemCardSellerComplete";
import type { ItemDetailRecord } from "../../../components/TransactionItem/QuoteItemCardSellerResponding";
import { QuoteItemCardSellerResponding } from "../../../components/TransactionItem/QuoteItemCardSellerResponding";
import { TransactionSplitForm } from "../../../components/TransactionSplitForm/TransactionSplitForm";
import {
  H3,
  SoftHeader,
  SoftHeaderPrimaryTextColor,
} from "../../../components/Typography/Typography";
import type { ViewPriceTiersFormProps } from "../../../components/ViewPriceTiersForm/ViewPriceTiersForm";
import { ViewPriceTiersForm } from "../../../components/ViewPriceTiersForm/ViewPriceTiersForm";
import { endpoints } from "../../../endpoints";
import { Flex, Flex1, Form } from "../../../layout/FormLayout";
import {
  Card,
  PageHeader,
  PageTitle,
  PageWrapper,
  TwoColumnOrderTotalSection,
} from "../../../layout/portalPageLayout";
import {
  DetailPageContentWrapper,
  KeyValueContainer,
  QuoteOrderContent,
  QuoteTermsContainer,
  TermsContainer,
  TimelineWrapper,
  WideDetails,
} from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import type {
  AccountManagerContactInfo,
  DeliveryTermPaginatedOutput,
  Fee,
  IFeesAndChargesSchema,
  IPriceTier,
  IQuoteRequestItemPatchArgs,
  ITenantCustomerSettings,
  OptionType,
  OrderMessageArgs,
  PaginatedTransactionEvents,
  PaymentFeePercentages,
  PaymentModePaginatedOutput,
  PaymentTermPaginatedOutput,
  ProductSKU,
  QuoteRequest,
  QuoteRequestItem,
  QuoteRequestPatchArgs,
  QuoteStatus,
  TenantSkuCreationArgs,
  UUID,
  User,
} from "../../../types/types";
import { isStringArray } from "../../../types/types";
import { calculatePriceForQuantity } from "../../../util/QuotesAndOrders";
import { useRoutePath } from "../../../util/Routing";
import { positiveIntegerRegex } from "../../../util/regexes";
import { strings } from "../../../util/strings";
import {
  MISSING_CURRENCY_SYMBOL,
  convertDeliveryTermToOption,
  convertPaymentModeToOption,
  convertPaymentTermToOption,
  formatDateTime,
  isAxiosError,
  makeDeliveryTermsGetEndpoint,
  makePaymentModesGetEndpoint,
  makePaymentTermsGetEndpoint,
  markDefaultTerm,
  promiseAllSettledLogAndThrow,
  useCurrency,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import { PriceDetailsColumn } from "../../Buyer/BuyerQuoteDetail/BuyerQuoteDetail";
import { normalizeAttributeName } from "../../admin/SellerAdmin/PIM/components/PIM.components.util";
import { AddDocumentToTransaction } from "../AddDocumentToTransaction/AddDocumentToTransaction";
import { get_existing_documents } from "../AddDocumentToTransaction/utils";
import { AddProductToQuoteRequest } from "../../../components/AddProductToTransaction/AddProductToQuoteRequest";
import type { CustomSkuData } from "./AddCustomSkuForm";
import { AddCustomSkuForm } from "./AddCustomSkuForm";
import { EditPriceTiersForm } from "./EditPriceTiersForm";

/**
 * Validates for duplicate items in a yup array schema
 *
 * @example
 * yup.array(
        yup.object().shape({
            name: yup.string(),
            amount: yup
            .number()
            .min(0, t("Must be an integer value"))
            .typeError(strings(t).thisIsARequiredField)
            .required(strings(t).thisIsARequiredField),
        })
        ) as any
    ).unique(t("Duplicate Name")),
 * You can specify a mapper function and a path name
 */
yup.addMethod(
  yup.array,
  "unique",
  function (
    message: string,
    mapper: Function = <T extends { name: string }>(a: string | T) =>
      typeof a === "string"
        ? a.trim().toLowerCase()
        : a.name.trim().toLowerCase(),
    path_name: string = "name"
  ) {
    return (this as yup.MixedSchema).test("unique", message, function (list) {
      if (!list) return true; // If the list is not defined, skip the validation
      const set = new Set();
      let count = 0;
      for (const item of list) {
        const mapped = mapper(item);
        if (set.has(mapped)) {
          return this.createError({
            path: `${this.path}.[${count}].${path_name}`,
            message,
          });
        }
        set.add(mapped);
        ++count;
      }
      return true;
    });
  }
);

type QuoteResponseForm = {
  delivery_term: OptionType;
  feesList: Fee[];
  payment_term: OptionType;
  payment_mode: OptionType;
  valid_to_date: string;
  required_eta: string;
  is_tax_exempt: boolean;
} & {
  /**
   * We dynamically generate the (string) keys for the quote item inputs at
   * runtime, for example:
   *   "sku_92ebf76a-552a-48d4-a0ea-8248fab8eff7": OptionType<string>
   *   "number_of_units_92ebf76a-552a-48d4-a0ea-8248fab8eff7": string
   *   "price_per_unit_92ebf76a-552a-48d4-a0ea-8248fab8eff7": string
   *   "sku_7486c6e2-dc51-4958-bc31-e00399046d04: OptionType<string>
   *   "number_of_units_7486c6e2-dc51-4958-bc31-e00399046d04: string
   *   "price_per_unit_7486c6e2-dc51-4958-bc31-e00399046d04: string
   */
  // `undefined` is in here because price_per_unit can be undefined if there
  // are price tiers and in any case if you use the wrong string as a key you
  // get undefined.
  [id: string]:
    | string
    | OptionType<ProductSKU>
    | OptionType<CustomSkuData>
    | undefined;
};

const makeInputIdSku = (id: string) => `sku_${id}`;
const makeInputIdCustomSku = (id: string) => `custom_sku_${id}`;
const makeInputIdNumberOfUnits = (id: string) => `number_of_units_${id}`;
const makeInputIdPricePerUnit = (id: string) => `price_per_unit_${id}`;

export type CustomSkuState = {
  itemId: string;
  customSkuData: CustomSkuData;
};

export type EditPriceTiersData = {
  quoteItem: QuoteRequestItem;
  priceTiers: IPriceTier[];
  mutatePriceTiers: () => void;
  sku: ProductSKU;
};

const CustomFlex1 = styled(Flex1)`
  .basic-single {
    min-width: 283px;
  }
  .SingleDatePicker {
    min-width: 283px;
  }
`;

const SingleItemFlex = styled(CustomFlex1)`
  @media (min-width: 648px) {
    flex: 0.5;
  }
  flex: 1;
  margin-right: 10px;
`;

const CustomTermsContainer = styled(TermsContainer)`
  gap: 10px;
  margin-bottom: 10px;
`;

export const HorizontalSeparator = styled.ol`
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding-left: 0;
  list-style: none;
  display: flex;
`;

const ConfirmQuoteDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmQuoteWarningText = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 16px;
  text-align: center;
`;

export const NotificationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  margin: 30px 0px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.productStatusOrange};
  background-color: rgba(219, 91, 22, 0.03);
`;

export const ActiveWarningMessage = styled.span`
  min-height: 19px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkText};
`;

/**
 * Used to calculate the subtotal for seller responding or seller editing cases.
 * Add up the total price values for each item, omitting any that are null.
 */
function calculateSubtotal(itemDetailRecord: ItemDetailRecord) {
  const subtotal = Object.values(itemDetailRecord).reduce<number>(
    (result, itemDetail) => {
      return itemDetail.totalPrice ? result + itemDetail.totalPrice : result;
    },
    0
  );
  return subtotal;
}

/**
 * Used to calculate the total and subtotal for the seller complete case.
 */
const calculateTotalAndSubtotal = (quote: QuoteRequest) => {
  const { subtotalString: subtotal, totalString: total } =
    calculatePriceDetails({
      items: quote.items,
      fees: quote.fees,
      currencyCode: quote.currency,
    });
  return { total, subtotal };
};

/**
 * This primarily exists to set the mode for the QuoteItemCard which is used for
 * both
 * buyers & sellers and has a different enum than the API. The mode is also
 * used to switch between different the form vs view modes because that is
 * the primary difference between the states for seller.
 * @param status
 */
function getModeFromStatus(
  status: Omit<QuoteStatus, "new">
): "seller_complete" | "seller_responding" {
  switch (status) {
    case "requested":
      return "seller_responding";
    case "pending":
    case "pending_activation":
      return "seller_responding";
    default:
      return "seller_complete";
  }
}

function DetailsOrSummary({
  sellerResponding,
  editing,
  onEditClick,
  onCancelClick,
  status,
  onSplitQuoteClick,
  showSplitQuoteButton,
}: {
  sellerResponding: boolean;
  editing: boolean;
  onEditClick: () => void;
  onCancelClick: () => void;
  status: QuoteStatus;
  onSplitQuoteClick: () => void;
  showSplitQuoteButton: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {!(sellerResponding || editing) && (
        <>
          <PageHeader>
            <HeaderLeft>
              <H3>{t("Summary")}</H3>
            </HeaderLeft>
            {status !== "declined" &&
              status !== "cancelled" &&
              !status.includes("pending") && (
                <HeaderRight>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    {showSplitQuoteButton && (
                      <PrimaryButtonMedium onClick={onSplitQuoteClick}>
                        {t("Split Quote Request")}
                      </PrimaryButtonMedium>
                    )}

                    <InvisibleButton
                      style={{
                        color: theme.tertiaryTextColor,
                        display: "inline-block",
                      }}
                      onClick={onEditClick}
                    >
                      <div>{t("Edit and resend quote")}</div>
                      <span style={{ verticalAlign: "middle" }}>
                        <EditIcon fill={theme.primaryButtonBG} />
                      </span>
                    </InvisibleButton>
                  </div>
                </HeaderRight>
              )}
          </PageHeader>
          <HorizontalSeparator />
        </>
      )}
      {(sellerResponding || editing) && (
        <>
          <PageHeader>
            <HeaderLeft>
              <H3>{editing ? t("Summary") : t("Details")}</H3>
            </HeaderLeft>
            {status !== "declined" &&
              status !== "cancelled" &&
              !status.includes("pending") && (
                <HeaderRight>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {showSplitQuoteButton && (
                      <PrimaryButtonMedium onClick={onSplitQuoteClick}>
                        {t("Split Quote Request")}
                      </PrimaryButtonMedium>
                    )}
                    {editing && (
                      <CancelButton
                        onClick={onCancelClick}
                        style={{
                          padding: "0",
                        }}
                      >
                        {t("Cancel Edit")}
                      </CancelButton>
                    )}
                  </div>
                </HeaderRight>
              )}
          </PageHeader>
          <HorizontalSeparator />
        </>
      )}
    </>
  );
}

/**
 * The page content for the page showing the details of a single quote.
 */
export function SellerQuoteDetailPageContent({
  quote,
  user,
  mutateQuote,
  fees_and_charges_map,
}: {
  quote: QuoteRequest;
  user: User;
  mutateQuote: (
    data?: QuoteRequest | Promise<QuoteRequest> | mutateCallback<QuoteRequest>,
    shouldRevalidate?: boolean
  ) => Promise<QuoteRequest | undefined>;
  fees_and_charges_map?: Record<string, IFeesAndChargesSchema>;
}) {
  const {
    storefront_id,
    storefront_metadata: { accepts_payments, enable_order_auto_accept },
  } = useStoreState();
  const quoteCurrencyTemp = useCurrency(quote.currency);
  const quoteCurrency = useMemo(
    () =>
      quoteCurrencyTemp ?? {
        code: quote.currency,
        symbol: MISSING_CURRENCY_SYMBOL,
        name: "--",
      },
    [quote.currency, quoteCurrencyTemp]
  );

  const theme = useTheme();
  const { t } = useTranslation();

  const { data: paymentFees, error: paymentFeesError } =
    useSWR<PaymentFeePercentages>(
      accepts_payments ? `/v1/storefronts/${storefront_id}/payments/fees` : null
    );

  const { data: termsData } = useSWR<ITenantCustomerSettings>(
    endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
      storefront_id,
      quote.seller_id,
      quote.buyer_id
    )
  );

  let accountManagerContactInfo: AccountManagerContactInfo | undefined =
    undefined;
  if (quote && quote.sold_by) {
    accountManagerContactInfo = quote.sold_by.tenant_user_contact_info;
  }
  const [isDefaultPaymentTerm, setIsDefaultPaymentTerm] = useState(true);
  const [isDefaultDeliveryTerm, setIsDefaultDeliveryTerm] = useState(true);
  const [isDefaultPaymentMode, setIsDefaultPaymentMode] = useState(true);
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const [showSplitQuoteForm, setShowSplitQuoteForm] = useState(false);
  const [showAddProductForm, setShowAddProductForm] = useState(false);

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (termsData && quote) {
      setIsDefaultDeliveryTerm(
        termsData.default_delivery_term?.id === quote?.delivery_term?.id
      );
      setIsDefaultPaymentTerm(
        termsData.default_payment_term?.id === quote?.payment_term?.id
      );
      setIsDefaultPaymentMode(
        termsData.default_payment_mode?.id === quote?.payment_mode?.id
      );
    }
  }, [termsData, quote]);

  // The mode is based on the status of the quote, and there are two modes that
  // are relevant. Once the seller has responded to the quote the quote status
  // changes and the mode changes from "seller_responding" to "seller_complete".
  // Then the seller can edit the quote, which is a local UI state tracked by
  // the "editing" boolean. Much but not all of the page is the same for the
  // "seller_responding" mode and when "editing" is true. In sum there are
  // three possibilities:
  //
  // - "seller_responding", editing is false (always)
  // - "seller_complete", editing is false
  // - "seller_complete", editing is true
  //
  const mode: "seller_responding" | "seller_complete" = getModeFromStatus(
    quote.status
  );
  const sellerResponding = mode === "seller_responding";
  const sellerComplete = mode === "seller_complete";
  const [editing, setEditing] = useState(false);

  const [showQuoteDeclineConfirmDialog, setShowQuoteDeclineConfirmDialog] =
    useState(false);

  const [submitting, setSubmitting] = useState(false);
  const { notifyError, notifySuccess } = useNotifications();
  const [autoAcceptQuote, setAutoAcceptQuote] = useState(quote.auto_accept);
  const [autoAcceptLoading, setAutoAcceptLoading] = useState(false);
  const [is_splitting_quote, set_is_splitting_quote] = useState(false);
  const { accountPath } = useRoutePath();
  const history = useHistory();
  const { quote_id } =
    useParams<{
      quote_id: string;
    }>();

  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });

  // A record mapping quote item IDs to details about that item that come from
  // child components, like the item's total price and whether price tiers
  // were used to calculate it.
  const [itemDetailRecord, setItemDetailRecord] = useState<ItemDetailRecord>(
    {}
  );

  // Disable the submit button if we don't have a total price for one of the
  // items. This can happen if the quote request item's number of units is
  // lower than the minimum units in the existing price tiers for that item.
  // The seller needs to edit or delete the price tiers to fix this.
  const disableFormSubmission = !Object.values(itemDetailRecord).every(
    (itemDetail) => itemDetail.totalPrice
  );

  // Set this to edit the price tiers for a given item.
  const [editPriceTiersData, setEditPriceTiersData] =
    useState<EditPriceTiersData | null>(null);

  // Set this to respond with a custom SKU for a given item.
  const [customSkuState, setCustomSkuState] =
    useState<CustomSkuState | null>(null);

  // A record of the custom SKUs the seller has created for each item. Used in
  // the form submit handler to create new custom SKUs.
  // The keys are the item IDs.
  // (I tried to use react-hook-form's setValue for this but I couldn't get it
  // to work. -Paul)
  const [customSkuRecord, setCustomSkuRecord] = useState<
    Record<string, CustomSkuData>
  >({});

  // Set this to view (read-only) the price tiers for a given item.
  const [viewTiersFormProps, setViewTiersFormProps] =
    useState<ViewPriceTiersFormProps | null>(null);

  const { data: paymentTerms } = useSWR<PaymentTermPaginatedOutput, AxiosError>(
    makePaymentTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(
          t(
            "There was an error fetching the payment terms, please reload the page."
          ),
          { error }
        );
      },
    }
  );

  const { data: deliveryTerms } = useSWR<
    DeliveryTermPaginatedOutput,
    AxiosError
  >(makeDeliveryTermsGetEndpoint(storefront_id), {
    onError: (error) => {
      notifyError(
        t(
          "There was an error fetching the shipping terms, please reload the page."
        ),
        { error }
      );
    },
  });

  const { data: paymentModes } = useSWR<PaymentModePaginatedOutput, AxiosError>(
    makePaymentModesGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(
          t(
            "There was an error fetching the payment modes, please reload the page."
          ),
          { error }
        );
      },
    }
  );

  const { data, mutate: mutateEvents } = useSWR<
    PaginatedTransactionEvents,
    AxiosError
  >(
    quote_id
      ? `/v1/storefronts/${storefront_id}/quotes/${quote_id}/events?limit=100`
      : null,
    {
      onSuccess: (events) => events.data.reverse(),
    }
  );

  const mutateQuoteAndEvents = () => {
    return Promise.allSettled([mutateQuote(), mutateEvents()]);
  };

  const quoteEvents = data?.data;

  const handleSendMessage = async (message: string) => {
    try {
      await Axios.post<OrderMessageArgs>(
        `/v1/storefronts/${storefront_id}/quotes/${quote_id}/messages`,
        {
          message: message,
          message_type: "Other",
        }
      );
      await mutateEvents();
    } catch (error) {
      console.error(error);
    }
  };

  const declineQuoteRequest = async (id: string) => {
    try {
      await Axios.patch(
        `/v1/storefronts/${storefront_id}/quotes/${id}/decline`
      );
      setShowQuoteDeclineConfirmDialog(false);
      await mutateQuoteAndEvents();
    } catch (error) {
      notifyError(`There was an error declining quote ${quote.number}`, {
        error,
      });
      setShowQuoteDeclineConfirmDialog(false);
    }
  };

  // We have to re-create the form schema when price tiers are introduced or
  // removed, because that adds and removes an input field.
  // Particularly needed for currency input validations.
  const formSchema = useMemo(() => {
    return yup.object().shape({
      feesList: (
        yup.array(
          yup.object().shape({
            name: yup.string(),
            amount: yup
              .number()
              .min(0, t("Must be an integer value"))
              .typeError(strings(t).thisIsARequiredField)
              .required(strings(t).thisIsARequiredField),
          })
        ) as any
      ).unique(t("Duplicate Name")),
      delivery_term: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
      payment_term: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
      payment_mode: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
      valid_to_date: yup
        .string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
      required_eta: yup
        .string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
      ...quote.items.reduce<Record<string, unknown>>((result, item) => {
        const itemDetail = itemDetailRecord[item.id];
        // If the item has price tiers, then there is no price_per_unit input
        // to validate.
        if (!itemDetail?.hasPriceTiers) {
          const pricePerUnitId = makeInputIdPricePerUnit(item.id);
          result[pricePerUnitId] = yup
            .string()
            .test(
              pricePerUnitId,
              strings(t).currencyMustBeValid,
              makeYupCurrencyTestFn(quoteCurrency.code)
            )
            .required(strings(t).thisIsARequiredField);
        }

        // If there is a custom SKU then we don't have a SKU input that needs
        // validation.
        if (!customSkuRecord[item.id]) {
          result[makeInputIdSku(item.id)] = yup
            .object()
            .required(strings(t).thisIsARequiredField);
        }

        result[makeInputIdNumberOfUnits(item.id)] = yup
          .string()
          .required(strings(t).thisIsARequiredField)
          // Use a regex to validate because it's a string.
          .matches(positiveIntegerRegex, t("Must be an integer value"));

        return result;
      }, {}),
    });
  }, [t, quote.items, itemDetailRecord, customSkuRecord, quoteCurrency.code]);

  const get_fees_list = (
    charges_map: Record<string, IFeesAndChargesSchema> | undefined,
    fees: Fee[]
  ) => {
    return charges_map
      ? fees.map((fee) => {
          const fee_charge =
            charges_map[normalizeAttributeName(fee.name).toLowerCase()];
          if (fee_charge && Number(fee_charge.amount) === Number(fee.amount)) {
            return {
              ...fee,
              is_disabled: true,
            };
          }
          return { ...fee, is_disabled: false };
        })
      : fees.map((fee) => ({ ...fee, is_disabled: false }));
  };

  const defaultValues = useMemo(
    () => ({
      // This should only run if the quote is put back into requested state
      // by the seller in order to edit it. This swr call is outside of this
      // component because this will only run once, and having `quote` be
      // initialized to undefined means the values are not correctly reset.
      // For reasons that verge on a bug/feature gap in react-hook-form we
      // can't use `reset` for this purpose.
      feesList: get_fees_list(fees_and_charges_map, quote.fees),
      delivery_term: convertDeliveryTermToOption(quote.delivery_term),
      payment_term: convertPaymentTermToOption(quote.payment_term),
      payment_mode: convertPaymentModeToOption(quote.payment_mode),
      required_eta: quote.required_eta,
      valid_to_date: quote.valid_to_date,
    }),
    [
      fees_and_charges_map,
      quote.delivery_term,
      quote.fees,
      quote.payment_mode,
      quote.payment_term,
      quote.required_eta,
      quote.valid_to_date,
    ]
  );

  const methodsOfUseForm = useFormWrapper({
    resolver: yupResolver(formSchema),
    // We have to set the default values here for pre-population of
    // the existing `feesList` values to work.
    defaultValues,
  });

  const { errors, formState, watch, control, handleSubmit, setValue } =
    methodsOfUseForm;

  const splitQuoteRequestMethodsOfUseForm = useFormWrapper();
  const { register } = splitQuoteRequestMethodsOfUseForm;

  const selectedDeliveryTerms = watch("delivery_term");
  const selectedPaymentTerms = watch("payment_term");
  const selectedPaymentModes = watch("payment_mode");

  useEffect(() => {
    if (sellerResponding || editing) {
      const taxExemptValue = editing
        ? quote.is_tax_exempt
        : termsData?.is_tax_exempt ?? false;
      const feesList =
        !taxExemptValue && !quote.fees.length
          ? [{ name: "Tax", amount: 0, id: "tax_fee" }]
          : get_fees_list(fees_and_charges_map, quote.fees);
      setValue("feesList", feesList);
      setValue(
        "delivery_term",
        convertDeliveryTermToOption(quote.delivery_term)
      );
      setValue("payment_term", convertPaymentTermToOption(quote.payment_term));
      setValue("payment_mode", convertPaymentModeToOption(quote.payment_mode));
    }
  }, [
    editing,
    fees_and_charges_map,
    quote.delivery_term,
    quote.fees,
    quote.is_tax_exempt,
    quote.payment_mode,
    quote.payment_term,
    sellerResponding,
    setValue,
    termsData?.is_tax_exempt,
  ]);

  useEffect(() => {
    // When editing a quote that has a custom SKU, we want to pre-fill the
    // custom sku data into the form. That's what this useEffect is for.
    if (editing) {
      const newCustomSkuRecord = quote.items.reduce<
        Record<string, CustomSkuData>
      >((record, item) => {
        if (item.sku.kind === "Buyer SKU") {
          record[item.id] = {
            erp_system_id: item.sku.erp_system_id,
            packaging_type: item.sku.packaging_type,
            packaging_unit: item.sku.packaging_unit,
            package_volume: parseFloat(item.sku.package_volume),
            package_description: item.sku.package_description,
          };
        }
        return record;
      }, {});

      setCustomSkuRecord(newCustomSkuRecord);
    }
  }, [editing, quote]);

  const requestedEta = moment(quote.required_eta).format("MMMM DD, YYYY");
  const validToDate = moment(quote.valid_to_date).format("MMMM DD, YYYY");

  const terms: KeyValuePair[] = [
    { key: "Shipping Terms", value: quote.delivery_term?.name || "--" },
    { key: "Payment Terms", value: quote.payment_term?.name || "--" },
    { key: "Shipment ETA", value: requestedEta || "--" },
    { key: "Payment Method", value: quote.payment_mode?.name || "--" },
  ];
  const respondedTerms: KeyValuePair[] = [
    ...terms,
    { key: "Price Valid Until", value: validToDate || "--" },
  ];

  const onSubmit = async (values: QuoteResponseForm) => {
    setSubmitting(true);
    let QuoteResponse: SetRequired<
      QuoteRequestPatchArgs,
      | "delivery_term_id"
      | "payment_term_id"
      | "payment_mode_id"
      | "valid_to_date"
      | "required_eta"
      | "is_tax_exempt"
    > = {
      delivery_term_id: values.delivery_term.value,
      payment_term_id: values.payment_term.value,
      payment_mode_id: values.payment_mode.value,
      valid_to_date: values.valid_to_date,
      required_eta: values.required_eta,
      is_tax_exempt: values.is_tax_exempt,
    };

    if (values.feesList) {
      QuoteResponse = {
        ...QuoteResponse,
        fees_to_overwrite: [...values.feesList],
      };
    }

    if (!values.feesList && quote.fees.length !== 0) {
      const feeIDs = quote.fees.map((fee) => fee.id);
      if (feeIDs.length > 0 && isStringArray(feeIDs)) {
        QuoteResponse = {
          ...QuoteResponse,
          fees_to_remove: feeIDs,
        };
      }
    }

    try {
      // Assemble the data needed for creating custom SKUs. Put the data in an
      // object with keys that are the item IDs for easy access.
      const customSkuCreationArgsRecord = quote.items.reduce<
        Record<
          string,
          { productId: string; creationArgs: TenantSkuCreationArgs }
        >
      >((record, item) => {
        const customSkuData = customSkuRecord[item.id];
        if (customSkuData) {
          record[item.id] = {
            productId: item.product_id,
            creationArgs: {
              erp_system_id: customSkuData.erp_system_id,
              custom_packaging_name: customSkuData.packaging_type?.name,
              packaging_type_id: customSkuData.packaging_type.id,
              packaging_unit_id: customSkuData.packaging_unit.id,
              package_volume: String(customSkuData.package_volume),
              package_description: customSkuData.package_description,
            },
          };
        }
        return record;
      }, {});

      // We patch each item to set the price_per_unit, sku_id, and no_of_units.
      // If price tiers exist for an item then there will be no user input for
      // that item's price_per_unit, and the price_per_unit will be calculated
      // by the backend, based on the price tiers.
      const patchRecipes: {
        itemId: string;
        requestBody: IQuoteRequestItemPatchArgs;
      }[] = await Promise.all(
        quote.items.map(async (item) => {
          const skuOption = values[makeInputIdSku(item.id)];
          const price_per_unit = values[makeInputIdPricePerUnit(item.id)];
          const no_of_units = values[makeInputIdNumberOfUnits(item.id)];
          const customSkuArgs = customSkuCreationArgsRecord[item.id];

          const customSku = customSkuArgs
            ? await Axios.post<ProductSKU>(
                `/v1/tenants/${quote.buyer_id}/products/${item.product_id}/custom-skus`,
                customSkuArgs.creationArgs
              )
            : null;

          // Should never be null, for type checker.
          const no_of_units_string =
            typeof no_of_units === "string" ? no_of_units : null;

          // Should never be null, for type checker.
          const skuOptionOption =
            typeof skuOption === "object" && "id" in skuOption.value
              ? (skuOption as OptionType<ProductSKU>)
              : null;

          const sku_id = customSku
            ? customSku.data.id
            : skuOptionOption?.value.id;

          const packageVolume =
            customSku?.data.package_volume ||
            skuOptionOption?.value.package_volume;

          const totalQuantity =
            packageVolume && no_of_units_string
              ? parseFloat(packageVolume) * parseFloat(no_of_units_string)
              : null;

          const total_quantity = totalQuantity ? String(totalQuantity) : null;

          // Here's some ceremony to make the type checker happy (because
          // we are dynamically creating the keys for the form values we have
          // to check their types so the checker knows which type they are).
          // If an item has price tiers then price_per_unit will be undefined.
          // The backend will calculate the price per unit in this case.
          if (
            (price_per_unit === undefined ||
              typeof price_per_unit === "string") &&
            typeof no_of_units === "string" &&
            sku_id &&
            total_quantity
          ) {
            return {
              itemId: item.id,
              requestBody: price_per_unit
                ? { sku_id, no_of_units, total_quantity, price_per_unit }
                : { sku_id, no_of_units, total_quantity },
            };
          }
          // Something went really wrong if this ever runs.
          // TODO: maybe we should just throw here?
          return {
            itemId: item.id,
            requestBody: {},
          };
        })
      );

      const patchItemPromises = patchRecipes.map(({ itemId, requestBody }) =>
        Axios.patch(
          `/v1/storefronts/${storefront_id}/quotes/${quote_id}/items/${itemId}`,
          requestBody
        )
      );
      await promiseAllSettledLogAndThrow(patchItemPromises);

      // When editing a quote request that the seller has already responded to:
      // For any items that already have a price_per_unit, that was entered by
      // the seller (and not calculated via price tiers), and those items now
      // have price tiers that apply (and thus there is no user input for that
      // item), set the price_per_unit to null so it will be re-calculated
      // using the tiers.
      const morePatchItemPromises = quote.items
        .filter(
          (item) =>
            item.price_per_unit && !values[makeInputIdPricePerUnit(item.id)]
        )
        .map((item) => {
          return Axios.patch(
            `/v1/storefronts/${storefront_id}/quotes/${quote_id}/items/${item.id}`,
            {
              price_per_unit: null,
            }
          );
        });
      await promiseAllSettledLogAndThrow(morePatchItemPromises);

      await Axios.patch(
        `/v1/storefronts/${storefront_id}/quotes/${quote_id}/respond`,
        QuoteResponse
      );
      // Revalidate the quote and timeline.
      await mutateQuoteAndEvents();
      setSubmitting(false);
      setEditing(false);
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.status_code === "403") {
        notifyError(error.response.data.message);
      } else {
        const errorMessage = `There was an error ${
          editing ? "editing" : "responding to"
        } quote ${quote.number}`;

        notifyError(errorMessage, { error });
      }
      await mutateQuoteAndEvents();
      setSubmitting(false);
    }
  };

  const selectedDeliverTerm = watch("delivery_term");
  const selectedPaymentTerm = watch("payment_term");
  const selectedPaymentMode = watch("payment_mode");

  useEffect(() => {
    if (termsData && selectedDeliverTerm) {
      setIsDefaultDeliveryTerm(
        selectedDeliverTerm.value === termsData.default_delivery_term?.id
      );
    }

    if (termsData && selectedPaymentTerm) {
      setIsDefaultPaymentTerm(
        selectedPaymentTerm.value === termsData.default_payment_term?.id
      );
    }
    if (termsData && selectedPaymentMode) {
      setIsDefaultPaymentMode(
        selectedPaymentMode.value === termsData.default_payment_mode?.id
      );
    }
  }, [
    selectedDeliverTerm,
    selectedPaymentTerm,
    selectedPaymentMode,
    termsData,
  ]);

  const termsWarningMessage =
    isDefaultDeliveryTerm && !isDefaultPaymentTerm
      ? t(
          "The requested payment terms are not the default terms.\n Would you like to proceed?"
        )
      : !isDefaultDeliveryTerm && isDefaultPaymentTerm
      ? t(
          "The requested shipping terms are not the default terms.\n Would you like to proceed?"
        )
      : !isDefaultDeliveryTerm && !isDefaultPaymentTerm
      ? t(
          "The requested shipping and payment terms are not the default terms.\n Would you like to proceed?"
        )
      : null;

  const quote_pending_warning_message = t(
    `Accepting this Quote Request will override the account activation process for ${quote.buyer_name}. ${quote.buyer_name} will not be notified of any updates until they verify their account details.`
  );

  // TODO: centralize this permission logic, when we have time to do it right!
  const quoteThreshold =
    quote.currency === "USD"
      ? user.quote_threshold_usd
      : user.quote_threshold_local;

  const totalIsAboveThreshold = quoteThreshold && total > quoteThreshold;
  const totalString = formatPrice(total, quote.currency);
  const quoteThresholdString = quoteThreshold
    ? formatPrice(quoteThreshold, quote.currency)
    : "--";

  const params = new URLSearchParams();
  if (query.q) {
    params.append("q", query.q);
  }
  params.append("offset", String(query?.offset ?? 0));
  params.append("perPage", String(query?.perPage ?? 10));
  ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
    (status) => params.append("status", status)
  );

  const estimatedPayments =
    paymentFees && total !== 0
      ? createPaymentObjectFromPercentages(paymentFees, total)
      : {};

  const handleAutoAcceptQuote = async (autoAcceptQuote: boolean) => {
    setAutoAcceptLoading(true);
    try {
      const response: AxiosResponse<QuoteRequest> = await Axios.patch(
        `/v1/storefronts/${storefront_id}/quotes/${quote.id}`,
        {
          auto_accept: autoAcceptQuote,
        }
      );
      setAutoAcceptQuote(response.data.auto_accept);
      setAutoAcceptLoading(false);
      notifySuccess(t(`Your changes have been saved successfully`));
    } catch (error) {
      await mutateQuoteAndEvents();
      setAutoAcceptLoading(false);
      notifyError(t("An error occurred"), { error });
    }
  };

  const onSplitQuoteRequest = async (values: Record<UUID, boolean>) => {
    set_is_splitting_quote(true);
    try {
      const itemsToSplit = Object.entries(values).reduce<UUID[]>(
        (accumulator, [key, value]) => {
          if (value) {
            accumulator.push(key);
          }
          return accumulator;
        },
        []
      );

      const res: { data: { id: UUID } } = await axios.post(
        `/v1/storefronts/${storefront_id}/quotes/${quote.id}/split`,
        { item_ids: itemsToSplit }
      );
      await mutateQuoteAndEvents();
      notifySuccess(t("Quote request split successfully"));
      set_is_splitting_quote(false);
      history.push(`${accountPath}/quotes/${res.data.id}`);
    } catch (error) {
      notifyError(t("There was an error splitting the Quote Request"));
      set_is_splitting_quote(false);
    }
  };

  const quoteRequestCanBeSplit =
    quote.items.length > 1 &&
    (quote.status === "requested" || quote.status === "responded");

  const tableColumns = React.useMemo<
    ColumnDef<{
      name: string;
      quantity: string;
      checkbox: string;
      price: string;
    }>[]
  >(
    () => [
      {
        header: "",
        width: "20px",
        accessorKey: "checkbox",
        cell: (cell) => {
          return (
            <>
              <CheckBoxNoLabel
                name={cell.getValue() as UUID}
                ref={register({
                  required: false,
                  setValueAs: () => Boolean((cell.getValue() as UUID).length),
                })}
                data-for="checkbox"
              />
            </>
          );
        },
      },
      {
        header: t("Name"),
        accessorKey: "name",
      },
      {
        header: t("Quantity"),
        accessorKey: "quantity",
      },
      { header: t("Price"), accessorKey: "price" },
    ],
    [t, register]
  );

  const tableData = quote.items.map((item) => {
    return {
      name: item.product.name,
      quantity: `${Intl.NumberFormat("en-US").format(
        Number(item.total_quantity)
      )} ${item.sku.packaging_unit?.name ?? ""}`,
      checkbox: item.id,
      price: (() => {
        if (item.price_per_unit) {
          const price = calculatePriceForQuantity(
            item.price_per_unit,
            item.total_quantity
          );
          if (price) {
            return formatPrice(price, item.currency);
          } else return "--";
        } else return "--";
      })(),
    };
  });

  const quote_disabled_msg = quote.status.includes("pending")
    ? t("Awaiting buyer activation")
    : disableFormSubmission
    ? t("Enter price details for all items.")
    : "";

  return (
    <PageWrapper>
      <Link to={`${accountPath}/quotes?${params}`}>
        <GoBackButton text={t("Quotes")} />
      </Link>
      <PageHeader>
        <HeaderLeft>
          <PageTitle>{t("Quote")}</PageTitle>
          <IDDesktop>{`${quote.number}`}</IDDesktop>
        </HeaderLeft>
        <HeaderRight data-testid={"quote-detail-status-container"}>
          <SellerQuoteStatus status={quote.status} />
        </HeaderRight>
      </PageHeader>
      <Flex>
        <SoftHeader style={{ margin: "0 3px 5px 0" }}>
          {t("Created By")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {`${quote.created_by || `--`} (${quote.created_by_company_name})`}
        </SoftHeaderPrimaryTextColor>
      </Flex>
      <Flex>
        <SoftHeader style={{ margin: "0 3px 0 0" }}>
          {t("Created On")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {formatDateTime(quote.created_at) || `--`}
        </SoftHeaderPrimaryTextColor>
        {(formatDateTime(quote.modified_at) !==
          formatDateTime(quote.created_at) ||
          quote.created_by !== quote.modified_by) && (
          <>
            <SoftHeader style={{ margin: "0 2px 0 10px" }}>
              {t("Last Modified")}:
            </SoftHeader>
            <SoftHeaderPrimaryTextColor>
              {quote.modified_at
                ? `${formatDateTime(quote.modified_at)} By ${quote.modified_by}`
                : `--`}
            </SoftHeaderPrimaryTextColor>
          </>
        )}
      </Flex>
      <HorizontalSeparator />
      <DetailPageContentWrapper>
        <QuoteOrderContent>
          <Card>
            <DetailsOrSummary
              sellerResponding={sellerResponding}
              editing={editing}
              onEditClick={async () => {
                await mutateQuoteAndEvents();
                setEditing(true);
                // The mutate is needed here to refresh the quote data so we
                // have up-to-date fees to display the fee editing inputs in
                // the subtotal/total area.
                // When we get to this point the top right status will
                // still say "responded" because the state transition can't
                // happen until the edit is finished. If QA complains about
                // this we can manually set the status to "New Request" on fe
                // only but that may be misleading.
              }}
              onCancelClick={() => setEditing(false)}
              status={quote.status}
              onSplitQuoteClick={() => setShowSplitQuoteForm(true)}
              showSplitQuoteButton={quoteRequestCanBeSplit}
            />
            <WideDetails>
              <ContactInfoBlockSmall
                address={quote.shipping_address}
                header={t("Ship to")}
                testid={"shipping-address"}
              />
              <ContactInfoBlockSmall
                address={quote.billing_address}
                header={t("Bill to")}
                testid={"billing-address"}
              />
              <ContactInfoBlockSmall
                address={quote.seller_address}
                accountManagerContactInfo={accountManagerContactInfo}
                header={t("Sold by")}
                testid={"shipping-address"}
              />
            </WideDetails>
            {!quote.is_buyer_activated && (
              <NotificationContainer>
                <WarningIcon width={18} height={18} />
                <ActiveWarningMessage>
                  {t(
                    `${quote.buyer_name} has not activated their account yet, and will not receive any updates until their account is verified`
                  )}
                </ActiveWarningMessage>
              </NotificationContainer>
            )}
            <>
              <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                {(sellerResponding || editing) && (
                  <>
                    <H3>{t("Your terms")}</H3>
                    <div style={{ maxWidth: "576px" }}>
                      <CustomTermsContainer>
                        <CustomFlex1>
                          <CustomTermContainer
                            highlight={
                              !isDefaultPaymentTerm &&
                              !!selectedPaymentTerm.value
                            }
                            has_error={!!errors.payment_term}
                          >
                            <Controller
                              as={SelectBoxV2}
                              control={control}
                              name="payment_term"
                              placeholder={t("Payment Terms")}
                              options={markDefaultTerm(
                                paymentTerms?.data.map(
                                  convertPaymentTermToOption
                                ) ?? [],
                                termsData?.default_payment_term
                                  ? convertPaymentTermToOption(
                                      termsData.default_payment_term
                                    )
                                  : undefined
                              )}
                              rules={{
                                required: true,
                              }}
                              errors={errors}
                              formState={formState}
                              setDefaultTerm={!!termsData?.default_payment_term}
                            />
                            {!isDefaultPaymentTerm &&
                              !!selectedPaymentTerm.value && (
                                <WarningMessage>
                                  {t(
                                    "Requested payment terms are not the default terms"
                                  )}
                                </WarningMessage>
                              )}
                          </CustomTermContainer>
                        </CustomFlex1>
                        <CustomFlex1>
                          <CustomTermContainer
                            highlight={
                              !isDefaultPaymentMode &&
                              !!selectedPaymentMode.value
                            }
                            has_error={!!errors.payment_mode}
                          >
                            <Controller
                              as={SelectBoxV2}
                              control={control}
                              name="payment_mode"
                              placeholder={t("Payment Method")}
                              options={markDefaultTerm(
                                paymentModes?.data.map(
                                  convertPaymentModeToOption
                                ) ?? [],
                                termsData?.default_payment_mode
                                  ? convertPaymentTermToOption(
                                      termsData.default_payment_mode
                                    )
                                  : undefined
                              )}
                              rules={{
                                required: true,
                              }}
                              errors={errors}
                              formState={formState}
                              setDefaultTerm={!!termsData?.default_payment_mode}
                            />
                            {!isDefaultPaymentMode &&
                              !!selectedPaymentMode.value && (
                                <WarningMessage>
                                  {t(
                                    "Requested shipping terms are not the default terms"
                                  )}
                                </WarningMessage>
                              )}
                          </CustomTermContainer>
                        </CustomFlex1>
                      </CustomTermsContainer>
                      <CustomTermsContainer>
                        <CustomFlex1>
                          <CustomTermContainer
                            highlight={
                              !isDefaultDeliveryTerm &&
                              !!selectedDeliverTerm.value
                            }
                            has_error={!!errors.delivery_term}
                          >
                            <Controller
                              as={SelectBoxV2}
                              control={control}
                              name="delivery_term"
                              placeholder={t("Shipping Terms")}
                              options={markDefaultTerm(
                                deliveryTerms?.data.map(
                                  convertDeliveryTermToOption
                                ) ?? [],
                                termsData?.default_delivery_term
                                  ? convertDeliveryTermToOption(
                                      termsData.default_delivery_term
                                    )
                                  : undefined
                              )}
                              rules={{
                                required: true,
                              }}
                              errors={errors}
                              formState={formState}
                              setDefaultTerm={
                                !!termsData?.default_delivery_term
                              }
                            />
                            {!isDefaultDeliveryTerm &&
                              !!selectedDeliverTerm.value && (
                                <WarningMessage>
                                  {t(
                                    "Requested shipping terms are not the default terms"
                                  )}
                                </WarningMessage>
                              )}
                          </CustomTermContainer>
                        </CustomFlex1>
                        <CustomFlex1>
                          <DatePicker
                            label={t("Quote valid until")}
                            name={"valid_to_date"}
                            methodsOfUseForm={methodsOfUseForm}
                            required={true}
                            defaultValue={quote.valid_to_date}
                          />
                        </CustomFlex1>
                      </CustomTermsContainer>
                      <CustomTermsContainer>
                        <SingleItemFlex>
                          <DatePicker
                            label={t("Shipment Schedule")}
                            name={"required_eta"}
                            methodsOfUseForm={methodsOfUseForm}
                            required={true}
                            defaultValue={quote.required_eta}
                          />
                        </SingleItemFlex>
                      </CustomTermsContainer>
                    </div>
                  </>
                )}
                <H3>{t("Items")}</H3>

                {sellerComplete &&
                  !editing &&
                  quote.items.map((item, index) => {
                    return (
                      <QuoteItemCardSellerComplete
                        key={item.id + index}
                        quote={quote}
                        item={item}
                        index={index}
                        viewPriceTiers={(tiersData) => {
                          setViewTiersFormProps({
                            tiersData: tiersData,
                            quoteId: quote.id,
                            quoteItem: item,
                            closeForm: () => setViewTiersFormProps(null),
                            mutateQuoteAndEvents,
                            isReadOnly: true,
                            titleText: t("View Price Tiers") as string,
                          });
                        }}
                      />
                    );
                  })}

                {(sellerResponding || editing) &&
                  quote.items.map((item, index) => {
                    return (
                      <QuoteItemCardSellerResponding
                        key={item.id + index}
                        item={item}
                        index={index}
                        methodsOfUseForm={methodsOfUseForm}
                        editPriceTiers={setEditPriceTiersData}
                        quote={quote}
                        itemDetail={itemDetailRecord[item.id]}
                        propagateItemDetails={(itemDetailRecord) => {
                          setItemDetailRecord((previous) => ({
                            ...previous,
                            ...itemDetailRecord,
                          }));
                        }}
                        deliveryTermId={selectedDeliveryTerms.value}
                        paymentTermId={selectedPaymentTerms.value}
                        inputIdSku={makeInputIdSku(item.id)}
                        inputIdCustomSku={makeInputIdCustomSku(item.id)}
                        inputIdNumberOfUnits={makeInputIdNumberOfUnits(item.id)}
                        inputIdPricePerUnit={makeInputIdPricePerUnit(item.id)}
                        setCustomSkuState={setCustomSkuState}
                        deleteCustomSku={() => {
                          setCustomSkuRecord((previous) => {
                            delete previous[item.id];
                            return { ...previous };
                          });
                        }}
                        customSkuFromSeller={customSkuRecord[item.id]}
                      />
                    );
                  })}
                {(quote.status === "requested" ||
                  quote.status === "responded") && (
                  <PrimaryButtonWithPlusIcon
                    onClick={() => setShowAddProductForm(true)}
                    style={{ marginBottom: "12px" }}
                    type="button"
                  >
                    {t("Add Product")}
                  </PrimaryButtonWithPlusIcon>
                )}

                <TwoColumnOrderTotalSection>
                  <QuoteTermsContainer>
                    <KeyValueContainer>
                      <KeyValueDisplay
                        data={
                          quote.status === "responded" ? respondedTerms : terms
                        }
                      />
                      {termsData?.is_tax_exempt &&
                        termsData?.tax_exempt_document && (
                          <TaxExemptDocument
                            document={termsData.tax_exempt_document}
                          />
                        )}
                    </KeyValueContainer>
                    {/* Quotes can only be declined before they have been
                          responded to. A seller can't edit a responded quote
                          and decline it.  (At least the backend does not
                          allow this.) */}
                    {sellerResponding && (
                      <CancelButton
                        onClick={() => setShowQuoteDeclineConfirmDialog(true)}
                        type={"button"}
                      >
                        {t("Decline Request")}
                      </CancelButton>
                    )}
                  </QuoteTermsContainer>
                  <PriceDetailsColumn>
                    {(sellerResponding || editing) && (
                      <>
                        <EditSubtotal
                          subtotal={calculateSubtotal(itemDetailRecord)}
                          total={total}
                          setTotal={setTotal}
                          isTaxExempt={
                            editing
                              ? quote.is_tax_exempt
                              : termsData?.is_tax_exempt ?? false
                          }
                          methodsOfUseForm={methodsOfUseForm}
                          currency={quoteCurrency}
                        />
                        {paymentFeesError ? (
                          <div
                            style={{
                              color: theme.errorColor,
                              fontWeight: theme.fontWeights.large,
                            }}
                          >
                            {t("Error calculating estimated fees")}
                          </div>
                        ) : (
                          <div style={{ maxWidth: "340px" }}>
                            <StripePayments
                              payment={estimatedPayments}
                              currencyCode={quoteCurrency.code}
                            />
                          </div>
                        )}
                        {enable_order_auto_accept && (
                          <AutoAcceptQuoteComponent
                            autoAcceptLoading={autoAcceptLoading}
                            autoAcceptQuote={autoAcceptQuote}
                            handleAutoAcceptQuote={handleAutoAcceptQuote}
                          />
                        )}
                        {totalIsAboveThreshold ? (
                          <ButtonWithConfirmDialog
                            Button={PrimaryButtonFitContainer}
                            testid={"quote-response-submit"}
                            buttonText={t("Send your quote")}
                            datafor="quote_button_disabled"
                            datatip={quote_disabled_msg}
                            confirmMessage={t(
                              `The total price {{totalPrice}} is larger than your quote threshold {{quoteThreshold}}. The quote cannot be sent.`,
                              {
                                totalPrice: totalString,
                                quoteThreshold: quoteThresholdString,
                              }
                            )}
                            type="button"
                            disabled={
                              disableFormSubmission ||
                              quote.status.includes("pending")
                            }
                          />
                        ) : termsWarningMessage &&
                          !quote.status.includes("pending") ? (
                          <ButtonWithConfirmDialog
                            Button={PrimaryButtonFitContainer}
                            testid={"quote-response-submit"}
                            handleConfirm={handleSubmit(onSubmit)}
                            buttonText={t("Send your quote")}
                            confirmMessage={termsWarningMessage}
                            type="button"
                            disabled={disableFormSubmission}
                            datafor="quote_button_disabled"
                            datatip={quote_disabled_msg}
                          />
                        ) : quote.status.includes("pending") ? (
                          <ButtonWithWarningDialog
                            Button={(props) => (
                              <>
                                <PrimaryButtonFitContainer
                                  type="button"
                                  style={{ maxWidth: "350px" }}
                                  disabled={disableFormSubmission}
                                  datafor="account_activation_button"
                                  datatip="Awaiting buyer account activation"
                                  onClick={props.onClick}
                                >
                                  {t("Send your quote")}
                                </PrimaryButtonFitContainer>
                                <ReactTooltip
                                  id="account_activation_button"
                                  effect="solid"
                                />
                              </>
                            )}
                            testid={`close_upload`}
                            confirmMessage={quote_pending_warning_message}
                            handleConfirm={handleSubmit(onSubmit)}
                            heading={t("Override Quote Request")}
                            showSecondButton={true}
                            saveBtnTitle={t("Override & Accept")}
                            showAlignCloseButton={true}
                            isSmallErrorMsgStyle={true}
                          />
                        ) : (
                          <>
                            <PrimaryButtonFitContainer
                              type="submit"
                              style={{ maxWidth: "350px" }}
                              loading={submitting}
                              disabled={
                                disableFormSubmission ||
                                quote.status.includes("pending")
                              }
                              datafor="quote_button_disabled"
                              datatip={quote_disabled_msg}
                            >
                              {t("Send your quote")}
                            </PrimaryButtonFitContainer>
                            <ReactTooltip
                              id="quote_button_disabled"
                              effect="solid"
                            />
                          </>
                        )}
                      </>
                    )}
                    {sellerComplete && !editing && (
                      <div style={{ marginTop: "19px" }}>
                        <PriceDetails
                          {...calculateTotalAndSubtotal(quote)}
                          fees={quote.fees}
                          currencyCode={quoteCurrency.code}
                          payment={quote.payments}
                        />
                      </div>
                    )}
                  </PriceDetailsColumn>
                </TwoColumnOrderTotalSection>
              </Form>
            </>
          </Card>
          <SlideOut
            show={showAddDocumentForm}
            closeFlyout={() => setShowAddDocumentForm(false)}
          >
            <AddDocumentToTransaction
              existing_documents={get_existing_documents({
                items: quote.items,
                seller_or_buyer_documents: quote.seller_documents,
              })}
              products={quote.items
                .filter((item) => item.product_id)
                .map(({ product: { name }, product_id }) => ({
                  name,
                  id: product_id,
                }))}
              transaction_type="quotes"
              transaction_type_id={quote_id}
              fetchData={mutateQuoteAndEvents}
              onComplete={() => setShowAddDocumentForm(false)}
            />
          </SlideOut>
          <SlideOut
            show={showSplitQuoteForm}
            closeFlyout={() => setShowSplitQuoteForm(false)}
          >
            <TransactionSplitForm
              formTitle={t("Split Quote")}
              subtitle={t("Select Products to move to a new Quote")}
              buttonText={t("Split Quote Request")}
              isLoading={is_splitting_quote}
              columns={tableColumns}
              tableData={tableData}
              methodsOfUseForm={splitQuoteRequestMethodsOfUseForm}
              onSubmit={onSplitQuoteRequest}
            />
          </SlideOut>
          <TransactionsDocumentView
            items={quote.items}
            seller_documents={quote.seller_documents}
            buyer_documents={quote.buyer_documents}
            onAddDocumentClick={() => setShowAddDocumentForm(true)}
            status={quote.status}
            buyer_role={quote.buyer_role}
          />
        </QuoteOrderContent>
        <TimelineWrapper>
          {user && quoteEvents && quote && (
            <Timeline
              messages={quoteEvents}
              loggedInUser={user}
              fetchingData={false}
              sendMessage={handleSendMessage}
              quote={quote}
            />
          )}
        </TimelineWrapper>
      </DetailPageContentWrapper>
      <ConfirmDialog
        show={showQuoteDeclineConfirmDialog}
        closeDialog={() => setShowQuoteDeclineConfirmDialog(false)}
        confirmMessage={t("Are you sure you want to decline this quote?")}
        handleConfirm={() => declineQuoteRequest(quote_id)}
      />
      <SlideOut
        show={!!editPriceTiersData || !!viewTiersFormProps}
        closeFlyout={() => {
          setEditPriceTiersData(null);
          setViewTiersFormProps(null);
        }}
      >
        {editPriceTiersData && (
          <EditPriceTiersForm
            editPriceTiersData={editPriceTiersData}
            destinationId={quote.shipping_address.id}
            deliveryTermId={selectedDeliveryTerms.value}
            paymentTermId={selectedPaymentTerms.value}
            paymentModeId={selectedPaymentModes.value}
            sellerId={quote.seller_id}
            buyerId={quote.buyer_id}
            closeModal={() => setEditPriceTiersData(null)}
          />
        )}
        {viewTiersFormProps && <ViewPriceTiersForm {...viewTiersFormProps} />}
      </SlideOut>
      <SlideOut
        show={!!customSkuState}
        closeFlyout={() => setCustomSkuState(null)}
      >
        {customSkuState && (
          <AddCustomSkuForm
            customSkuData={customSkuState.customSkuData}
            setCustomSkuData={(customSkuData: CustomSkuData) => {
              setCustomSkuRecord((previous) => ({
                ...previous,
                [customSkuState.itemId]: customSkuData,
              }));
            }}
            closeForm={() => setCustomSkuState(null)}
          />
        )}
      </SlideOut>

      <SlideOut
        show={showAddProductForm}
        closeFlyout={() => setShowAddProductForm(false)}
      >
        <AddProductToQuoteRequest
          buyerId={quote.buyer_id}
          quoteId={quote_id}
          currency={quote.currency}
          onComplete={() => {
            setShowAddProductForm(false);
            mutateQuote();
          }}
          existingProducts={quote.items.map((item) => ({
            product_id: item.product_id,
            name: item.product.name,
          }))}
        />
      </SlideOut>
    </PageWrapper>
  );
}

/**
 * Auto Accept Quote Component
 */
function AutoAcceptQuoteComponent({
  autoAcceptLoading,
  autoAcceptQuote,
  handleAutoAcceptQuote,
}: {
  autoAcceptLoading: boolean;
  autoAcceptQuote: boolean;
  handleAutoAcceptQuote: (autoAcceptQuote: boolean) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleAutoAccept = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const autoAccept = e.target.checked;
    if (!autoAccept) {
      await handleAutoAcceptQuote(false);
    } else {
      setShow(true);
    }
  };

  const handleConfirm = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isConfirmed = e.target.checked;
    await handleAutoAcceptQuote(isConfirmed);
    setShow(false);
  };
  return (
    <>
      <CheckBoxContainer style={{ marginTop: "10px" }}>
        {autoAcceptLoading ? (
          <LoadingIcon width={21} height={21} />
        ) : (
          <CheckBox
            name="autoAcceptQuote"
            id="autoAcceptQuote"
            checked={autoAcceptQuote}
            onChange={handleAutoAccept}
          />
        )}
        <CheckBoxFinePrintLabel
          htmlFor="autoAcceptQuote"
          style={{ marginLeft: "10px" }}
        >
          {t("Auto-Accept Order")}
        </CheckBoxFinePrintLabel>
      </CheckBoxContainer>
      <GenericDialogBody show={show} closeDialog={() => setShow(false)}>
        <ConfirmQuoteDialogWrapper>
          <ConfirmQuoteWarningText>
            {t(
              "Enabling of auto acceptance of this order will result in the order being confirmed to Buyer"
            ) + " "}
            <strong>
              {t("without you and your team having the chance to review it.")}
            </strong>
          </ConfirmQuoteWarningText>
          <ConfirmQuoteWarningText>
            {t("If you are providing a tiered price in response") + ", "}
            <strong>
              {t(
                "the order quantity might be different from the quantity you see on the screen."
              )}
            </strong>
          </ConfirmQuoteWarningText>
          <ConfirmQuoteWarningText>
            {t("Are you sure you want to proceed?")}
          </ConfirmQuoteWarningText>
          <CheckBoxContainer>
            {autoAcceptLoading ? (
              <LoadingIcon width={21} height={21} />
            ) : (
              <CheckBox
                name="user_confirmation"
                id="user_confirmation"
                onChange={handleConfirm}
              />
            )}
            <CheckBoxFinePrintLabel
              htmlFor="user_confirmation"
              style={{ marginLeft: "10px", flex: 1 }}
            >
              {t(
                "I understand the implication of enabling this option, and confirm that Agilis Commerce will not be liable for any adverse business impact arising out of enabling auto acceptance of the order."
              )}
            </CheckBoxFinePrintLabel>
          </CheckBoxContainer>
        </ConfirmQuoteDialogWrapper>
      </GenericDialogBody>
    </>
  );
}
