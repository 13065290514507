import styled from "styled-components/macro";
import type { IIsOutOfView } from "../../util/util";

export const DropDownWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  margin: 3px 10px 2px 0;
  button {
    cursor: pointer;
  }
`;
export interface IDropDownModalWrapperProps {
  isOutOfViewport?: IIsOutOfView;
}
export const DropDownModalWrapper = styled.div<IDropDownModalWrapperProps>`
  position: absolute;
  top: 100%;
  left: ${({ isOutOfViewport }) =>
    isOutOfViewport && isOutOfViewport?.right > 480
      ? 0
      : isOutOfViewport &&
        isOutOfViewport?.right < 480 &&
        isOutOfViewport?.left > 480
      ? "auto"
      : `-${(isOutOfViewport && isOutOfViewport?.left - 25) || 0}px`};

  right: ${({ isOutOfViewport }) =>
    isOutOfViewport && isOutOfViewport?.right > 480
      ? "auto"
      : isOutOfViewport &&
        isOutOfViewport?.right < 480 &&
        isOutOfViewport?.left > 480
      ? 0
      : "auto"};
  width: 500px;
  max-width: 500px;
  background: ${({ theme }) => theme.primaryBG};
  padding: 20px;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadowColor};
  border-radius: 2px;
  z-index: 900 !important;
`;
