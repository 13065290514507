import axios from "axios";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import { SearchSelectInfiniteScroll } from "../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { H5, SoftHeader } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { Form } from "../../../layout/FormLayout";
import type {
  LeadAssignee,
  OptionType,
  UUID,
  User,
} from "../../../types/types";
import { getCountryOption } from "../../../util/location";
import { useCountriesList } from "../../../util/Locations";
import {
  convertUserToOption,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";

type AssignLeadToUsersProps = {
  onSuccess: (state: "assigned" | "unassigned") => void;
  onError: (error: unknown) => void;
  /**
   * The URL to send the assign the lead to the user. Different for different
   * types of leads.
   */
  url: string;
  prefilledUser?: LeadAssignee | null;
};

/**
 * onSuccess
 * onError
 * url
 * list of users?
 */
export function AssignLeadToUser({
  onSuccess,
  onError,
  url,
  prefilledUser,
}: AssignLeadToUsersProps) {
  const {
    handleSubmit,
    setValue,
    errors,
    formState,
    register,
    control,
    watch,
  } = useFormWrapper({
    defaultValues: {
      user: prefilledUser ? convertUserToOption(prefilledUser) : null,
    },
  });
  const { tenant_id, storefront_id } = useStoreState();
  const countries = useCountriesList();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selected_user = watch("user");

  const { data: assigned_user } = useSWR<User>(
    selected_user?.value
      ? endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          tenant_id,
          selected_user.value
        )
      : null
  );

  const { t } = useTranslation();

  const onSubmit = async ({ user }: { user: OptionType<UUID> | null }) => {
    try {
      setIsSubmitting(true);
      await axios.patch(url, { id: user?.value ?? null });
      onSuccess(user?.value ? "assigned" : "unassigned");
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1>{t("Assign Lead To User")}</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          as={SearchSelectInfiniteScroll}
          name={"user"}
          params={(() => {
            const params = new URLSearchParams();
            params.append("is_active", "true");
            return params;
          })()}
          theref={register({ required: false })}
          baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            tenant_id
          )}
          getOptions={(response: User[]) => response.map(convertUserToOption)}
          errors={errors}
          formState={formState}
          isClearable
          placeholder={t("Select User")}
          onChange={(data: any) => {
            setValue("user", data.value);
          }}
        />
        {assigned_user && (
          <>
            <div>
              <SoftHeader>{t("Email Id")}</SoftHeader>
              <H5>{assigned_user?.email_address}</H5>
            </div>
            {assigned_user?.business_unit && (
              <div>
                <SoftHeader>{t("Business Unit")}</SoftHeader>
                <H5>{assigned_user.business_unit}</H5>
              </div>
            )}
            {assigned_user?.country && (
              <div>
                <SoftHeader>{t("Country")}</SoftHeader>
                <H5>
                  {getCountryOption(countries, assigned_user?.country)?.label}
                </H5>
              </div>
            )}
          </>
        )}
        <PrimaryButtonFitContainer type="submit" loading={isSubmitting}>
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
