import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ScrollTableWrapper } from "../../pages/SharedPages/ProductDetailPage/Assets/DocumentsOrDigitalMediaTable/DocumentsOrDigitalMediaTable";
import type { Assets } from "../../types/types.PIM";
import { useAuthContext } from "../Auth";
import { TextButton } from "../Buttons/Buttons";
import { SoftHeader2 } from "../Typography/Typography";
import { Table } from "./Table";
import type { ITableProps, TableRefProps } from "./utils";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
`;

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
`;

const ResetBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TableWithBulkAction = forwardRef<
  TableRefProps,
  ITableProps<any> & {
    bulkActionElement: <
      ElementProps extends {
        rows: string[] | "all";
        resetSelectedRows: () => void;
      }
    >(
      props: ElementProps
    ) => JSX.Element;
  }
>(
  <TableData extends object>(
    props: ITableProps<TableData> & {
      bulkActionElement: <
        ElementProps extends {
          rows: string[] | "all";
          resetSelectedRows: () => void;
        }
      >(
        props: ElementProps
      ) => JSX.Element;
    },
    ref: React.ForwardedRef<TableRefProps>
  ) => {
    const { roleIsGuest } = useAuthContext();
    const {
      data,
      handleSelectRows,
      bulkActionElement,
      bulkSelectLimit,
      maxHeight,
      scrollRef,
      onScroll,
      defaultSelectedRows,
    } = props;
    const [rowsSelected, setRowsSelected] = useState<string[] | "all">(
      defaultSelectedRows ?? []
    );
    const resetSelectedRowsRef = useRef<TableRefProps>(null);

    const { t } = useTranslation();

    const hasNonDownloadableAssets = data.some(
      (el) => (el as Assets).is_downloadable === false
    );
    const hasGuestAccessRestriction = roleIsGuest && hasNonDownloadableAssets;

    const onSelectRows = (rows: string[] | "all") => {
      setRowsSelected(rows);
      handleSelectRows?.(rows);
    };

    const handleResetRows = useCallback(() => {
      resetSelectedRowsRef?.current?.resetSelectedRows();
      handleSelectRows?.([]);
    }, [handleSelectRows]);

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: handleResetRows,
        selectAllItems: () => resetSelectedRowsRef?.current?.selectAllItems(),
        resetIsDefaultSelected: () =>
          resetSelectedRowsRef?.current?.resetIsDefaultSelected(),
      }),
      [handleResetRows]
    );

    return (
      <TableContainer>
        {rowsSelected.length > 0 && !!resetSelectedRowsRef.current ? (
          <BulkActionWrapper>
            <ResetBtnWrapper>
              <SoftHeader2>
                {rowsSelected === "all"
                  ? t("All items selected")
                  : `${rowsSelected.length} ${t("selected")}`}
              </SoftHeader2>
              <TextButton onClick={handleResetRows}>
                <SoftHeader2>{t("Unselect All")}</SoftHeader2>
              </TextButton>
              {rowsSelected !== "all" &&
              !hasGuestAccessRestriction &&
              !bulkSelectLimit ? (
                <TextButton
                  onClick={resetSelectedRowsRef.current.selectAllItems}
                >
                  <SoftHeader2>{t("Select All")}</SoftHeader2>
                </TextButton>
              ) : (
                <></>
              )}
            </ResetBtnWrapper>
            {bulkActionElement({
              rows: rowsSelected,
              resetSelectedRows: handleResetRows,
            })}
          </BulkActionWrapper>
        ) : (
          <></>
        )}
        <ScrollTableWrapper
          maxHeight={maxHeight}
          ref={scrollRef as React.RefObject<HTMLDivElement>}
          onScroll={onScroll}
        >
          <TableWrapper>
            <Table
              {...props}
              handleSelectRows={onSelectRows}
              ref={resetSelectedRowsRef}
            />
          </TableWrapper>
        </ScrollTableWrapper>
      </TableContainer>
    );
  }
);
