import styled from "styled-components/macro";

// Nav Menu
export const NavWrapper = styled.nav<{ isCollapsed: boolean }>`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: ${({ isCollapsed }) => (isCollapsed ? "visible" : "auto")};
  overflow-x: ${({ isCollapsed }) => (isCollapsed ? "visible" : "hidden")}; ;
`;

export interface INavStyles {
  name: string;
  bold: boolean;
  italic: boolean;
  uppercase: boolean;
  font_size: "small" | "medium" | "large";
}

interface INavProps {
  collapsedSideNav?: boolean;
  navStyles?: INavStyles;
}

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9.5px;
  background: ${(props) => props.theme.badgeColor};
  color: ${(props) => props.theme.badgeTextColor};
  font-size: 12px;
  padding: 3px 5px 4px;
  text-align: center;
  margin-left: auto;
  font-weight: 500;
  max-height: 19px;
  min-width: 19px;
`;

export const NavItemWrapper = styled.div<INavProps>`
  display: flex;
  justify-content: flex-start;
  position: relative;
  a {
    color: ${(props) => props.theme.navLinkColor};
    width: 100%;
    display: block;
    text-decoration: none;
    padding: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "6px 10px" : "6px 20px"};
    font-size: ${({ theme, navStyles }) =>
      navStyles?.font_size
        ? theme.fontSizes[navStyles.font_size]
        : theme.fontSizes.medium};
    font-weight: ${({ navStyles }) =>
      navStyles?.bold ? "bold !important" : "500"};
    text-transform: ${({ navStyles }) =>
      navStyles?.uppercase ? "uppercase" : "capitalize"};
    font-style: ${({ navStyles }) => (navStyles?.italic ? "italic" : "normal")};
    transition: all 0.2 ease-out;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    span {
      text-indent: ${({ collapsedSideNav }) =>
        collapsedSideNav ? "-999999px" : "none"};
      white-space: nowrap;
      transition: all 0.2 ease-out;
    }
    svg {
      vertical-align: bottom;
      margin-right: ${({ collapsedSideNav }) =>
        collapsedSideNav ? 0 : "10px"};
      transition: all 0.2 ease-out;
      width: 24px;
      path {
        fill: ${(props) => props.theme.navLinkColor};
      }
    }
    &:hover {
      color: ${(props) => props.theme.navIconActive};
      background: ${({ theme }) => `${theme.primaryButtonHover}`};
      svg path {
        fill: ${(props) => props.theme.navIconActive};
      }
    }
    &.active {
      border-right: ${({ theme }) => `2px solid ${theme.navIconActive}`};
      background-color: ${({ theme }) => `${theme.primaryButtonHover}`};
      margin-right: -1px;
      box-sizing: content-box;
      svg path {
        fill: ${(props) => props.theme.navIconActive};
      }
    }
  }
  ${Badge} {
    height: ${({ collapsedSideNav }) => (collapsedSideNav ? "16px" : "20px")};
    position: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "absolute" : "initial"};
    top: ${({ collapsedSideNav }) => (collapsedSideNav ? "4px" : "20px")};
    left: ${({ collapsedSideNav }) => (collapsedSideNav ? "27px" : "auto")};
    min-width: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "16px" : "20px"};
    font-size: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "11px" : "12px"};
    padding: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "1px 5px" : "2px 5px"};
    font-weight: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "normal" : "400"};
  }
`;

// Footer
export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.footerBg};
  padding: 10px;
  padding-bottom: 0px;
  a {
    text-decoration: none;
    display: inline-block;
    margin: 0px 15px;
    font-size: 12px;
    color: ${(props) => props.theme.secondaryLinkColor};
    font-family: Inter;
    cursor: pointer;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 0px 5px 0px;
  color: ${(props) => props.theme.secondaryTextColor};
  font-family: Inter;
  font-size: 12px;
`;

export const Break = styled.div`
  background-color: ${({ theme }) => theme.primaryBorder};
  width: 100%;
  height: 1px;
`;

export const SelectWithDeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  .basic-single {
    width: 420px;
    margin-right: 6px;
  }
`;
