import type { AxiosResponse } from "axios";
import Axios from "axios";
import type { MutableRefObject } from "react";
import type { DeepMap, FieldError, UseFormMethods } from "react-hook-form";
import { Controller } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { z } from "zod";
import {
  GreyLine,
  GreyLineWrapper,
} from "../../../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import type { ChipType } from "../../../../../../components/Chips/Chips";
import { DatePicker } from "../../../../../../components/DatePicker/DatePicker";
import { CheckIcon, XIcon } from "../../../../../../components/Icons/Icons";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { RichEditor } from "../../../../../../components/RichEditor/RichEditor";
import { SearchSelectInfiniteScroll } from "../../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { ToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import { endpoints } from "../../../../../../endpoints";
import type {
  MethodsOfUseForm,
  OptionType,
  UUID,
} from "../../../../../../types/types";
import type {
  AttributeSchema,
  AttributeValue,
  ImageAttributeValue,
  IproductReferenceCollectionItem,
  PIMProduct,
} from "../../../../../../types/types.PIM";
import { strings } from "../../../../../../util/strings";
import { toTitleCase } from "../../../../../../util/util";
import {
  LinkAttributeValueSchema,
  LinkAttributeValueSchemaRefined,
  zodMultiSelectDefault,
  zodOptionalSelectBoxDefault,
  zodOptionalString,
  zodRequiredString,
  zodSelectBoxDefault,
} from "../../../../../../util/zod.util";
import { MultiSelectCell } from "./MultiSelectCell";
import type { FormValue, IRow, OnSubmitValue } from "./type";

const DateWrapper = styled.div`
  input {
    padding-right: 30px !important;
    line-height: 18px;
  }
`;

export const VerticalLinkTextFieldsContainer = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-rows: 48% auto 48%;
  flex-wrap: nowrap;
`;

export const GENERATED_UUID = "-generated";

export const getAttributeValue = (
  attr: AttributeValue | undefined,
  row: IRow
):
  | string
  | boolean
  | null
  | undefined
  | { name: string }[]
  | { display_text: string; url: string }
  | ImageAttributeValue
  | IproductReferenceCollectionItem => {
  if (attr?.data_type === "enum") {
    const prev = row[attr?.attribute_id] ?? [];
    prev.push({ name: attr.enum_variant?.variant ?? "" });
    return prev;
  } else if (attr?.product_ref?.id) {
    const prev = row[attr?.attribute_id] ?? [];
    prev.push({
      name: attr?.product_ref?.name,
      value: attr?.product_ref?.id,
      slug: attr?.product_ref?.slug,
      is_visible_on_storefronts: attr?.product_ref?.is_visible_on_storefronts,
      visibility_tenants: attr?.product_ref?.visibility_tenants,
      is_accessible: attr?.product_ref?.is_accessible,
      status: attr?.product_ref?.status,
      id: attr?.product_ref.id,
    });
    return prev;
  }
  return attr?.value ?? "";
};

export const getValidationForProductCollection = ({
  input_type,
  optional,
  t,
}: {
  input_type: AttributeSchema["input_type"];
  optional: boolean;
  t: TFunction;
}) => {
  switch (input_type) {
    case "single_select":
      return optional
        ? zodOptionalSelectBoxDefault.nullable()
        : zodSelectBoxDefault(t);
    case "numeric":
      return z
        .preprocess((val) => Number(val), z.number())
        .refine((val) => val > -1, {
          message: t("must be a positive number"),
        });
    case "product_reference":
      return z
        .object({
          value: zodOptionalString.nullable(),
          label: zodOptionalString.nullable(),
        })
        .nullable();
    case "multi_select":
      return optional
        ? z.object({ name: zodOptionalString }).array().optional()
        : zodMultiSelectDefault(t)
            .array()
            .min(1, strings(t).thisIsARequiredField);
    case "multiline_entry":
      return optional ? zodOptionalString : zodRequiredString(t);
    case "link":
      return LinkAttributeValueSchemaRefined(t);
    case "toggle":
    case "checkbox":
      // for some weird reasons, sometimes react-form library turns boolean to
      // ['on'] for true and [] for false
      // this fixes that.
      const zBool = z.preprocess((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value;
      }, z.any());
      return optional ? zBool.optional() : zBool;
    default:
      return optional ? zodOptionalString : zodRequiredString(t);
  }
};

export const createFormFieldForCollection = ({
  col,
  value,
  row,
  methodsOfUseForm,
  errors,
  tenant_id,
  t,
}: {
  col: AttributeSchema;
  value:
    | string
    | null
    | boolean
    | number
    | { name: string }[]
    | { display_text: string; url: string };
  row: IRow;
  methodsOfUseForm: MethodsOfUseForm;
  errors: DeepMap<FormValue, FieldError>;
  tenant_id?: string;
  t: TFunction;
}) => {
  const { formState, register, setValue, control, watch } = methodsOfUseForm;
  const hasValue = Array.isArray(value) ? value.length > 0 : Boolean(value);
  if (col.input_type === "product_reference") {
    setValue(col.name, {
      label: (value as { name: string; id: string }[])?.[0]?.name ?? "",
      value: (value as { name: string; id: string }[])?.[0]?.id ?? "",
    });
  }
  switch (col.input_type) {
    case "date":
      return (
        <DateWrapper>
          <DatePicker
            label={toTitleCase(
              col.display_name ? t([col.display_name]) : col.name
            )}
            name={col.id}
            isOutsideRange={() => false}
            defaultValue={(value as string) ?? ""}
            required={col.is_required}
            methodsOfUseForm={{ ...methodsOfUseForm, errors }}
            normalPlaceholder={true}
            disabled={!col.is_editable || col.is_not_applicable}
            appendToBody={true}
          />
        </DateWrapper>
      );
    case "single_select":
      return (
        <Controller
          as={SelectBoxV2}
          control={control}
          name={col.id}
          placeholder={col.display_name ? t([col.display_name]) : col.name}
          normalPlaceholder={true}
          menuPortalTarget={document.querySelector("body")}
          options={
            col.choices?.map((choice) => ({
              label: choice,
              value: choice,
            })) ?? []
          }
          rules={{
            required: col.is_required,
          }}
          defaultValue={{
            label: (value as { name: string }[])?.[0]?.name ?? "",
            value: (value as { name: string }[])?.[0]?.name ?? "",
          }}
          errors={errors}
          formState={{ submitCount: 1 }}
          disabled={!col.is_editable || col.is_not_applicable}
        />
      );
    case "product_reference": {
      return (
        <Controller
          as={SearchSelectInfiniteScroll}
          isClearable={true}
          control={control}
          normalPlaceholder={true}
          menuPortalTarget={document.querySelector("body")}
          name={col.id}
          autoComplete="on"
          rules={{
            required: col.is_required,
          }}
          disabled={!col.is_editable || col.is_not_applicable}
          baseUrl={
            tenant_id
              ? endpoints.v2_tenants_id_pim_products_summary(tenant_id)
              : null
          }
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("status", "published");
            return params;
          })()}
          getOptions={(response: PIMProduct[]) =>
            response.reduce(
              (
                prev: { label: string; value: string }[],
                { name, id }: PIMProduct
              ) => [...prev, { label: name, value: id }],
              []
            )
          }
          placeholder={col.display_name ? t([col.display_name]) : col.name}
          testid={col.name}
          defaultOptions
          defaultValue={{
            label: (value as { name: string; id: string }[])?.[0]?.name ?? "",
            value: (value as { name: string; id: string }[])?.[0]?.id ?? "",
          }}
          theref={register()}
          errors={errors}
          formState={formState}
        />
      );
    }
    case "toggle":
      return (
        <ToggleSwitch
          label={toTitleCase(
            col.display_name ? t([col.display_name]) : col.name
          )}
          name={col.id}
          theref={register()}
          isChecked={hasValue}
          disabled={!col.is_editable || col.is_not_applicable}
        />
      );
    case "checkbox":
      return (
        <ToggleSwitch
          label={""}
          name={col.id}
          theref={register()}
          isChecked={hasValue}
          disabled={!col.is_editable || col.is_not_applicable}
        />
      );
    case "numeric":
      return (
        <TextField
          name={col.id}
          label={`${toTitleCase(
            col.display_name ? t([col.display_name]) : col.name
          )} (number)`}
          normalPlaceHolder={true}
          theref={register()}
          defaultValue={(value as number) ?? ""}
          formState={formState}
          errors={errors}
          type="number"
          readOnly={!col.is_editable || col.is_not_applicable}
        />
      );
    case "multiline_entry":
      return (
        <RichEditor
          value={(value as string) ?? ""}
          id={`${col.id}${row.uuid}`}
          name={`${col.id}`}
          label={toTitleCase(
            col.display_name ? t([col.display_name]) : col.name
          )}
          useFormMethods={{ register, formState, setValue, errors, watch }}
          readOnly={!col.is_editable || col.is_not_applicable}
        />
      );
    case "multi_select":
      return (
        <MultiSelectCell
          attribute={col}
          methodsOfUseForm={{ ...methodsOfUseForm, errors }}
          values={value as { name: string }[] | undefined}
          isDisabled={!col.is_editable || col.is_not_applicable}
        />
      );
    case "link":
      const maybeDefaultValue = (() => {
        const parsed = LinkAttributeValueSchema.safeParse(value);
        if (parsed.success) {
          return parsed.data;
        }
        return { url: undefined, display_text: undefined };
      })();
      const parsed = LinkAttributeValueSchema.safeParse(value);

      return (
        <Controller
          control={control}
          name={col.id}
          defaultValue={maybeDefaultValue}
          render={({ onChange, value: formValue }) => (
            <VerticalLinkTextFieldsContainer>
              <TextField
                label={`${
                  col.display_name ? t([col.display_name]) : col.name
                } - ${t("Display Text")}`}
                name={"display_text"}
                type={"text"}
                theref={register({ required: col.is_required })}
                required={col.is_required}
                normalPlaceHolder={true}
                formState={formState}
                errors={errors}
                // Need to manually set the error here because the form field
                // has the id of the column set above in the Controller
                error={
                  errors[col.id]
                    ? {
                        // Message an empty string because the normal string
                        // doesn't fit when the fields are stacked + smaller than
                        // normal for the table
                        message: "",
                        type: "invalid_type",
                      }
                    : null
                }
                defaultValue={maybeDefaultValue.display_text}
                value={formValue?.display_text ?? ""}
                onChange={(event) =>
                  onChange({
                    ...formValue,
                    display_text: event.target.value,
                  })
                }
                readOnly={!col.is_editable || col.is_not_applicable}
              />
              <GreyLineWrapper style={{ justifyContent: "center" }}>
                <GreyLine style={{ height: "8px", width: "1px" }} />
              </GreyLineWrapper>
              <TextField
                name={"url"}
                type={"url"}
                label={`${
                  col.display_name ? t([col.display_name]) : col.name
                } - URL`}
                formState={formState}
                normalPlaceHolder={true}
                errors={errors}
                defaultValue={maybeDefaultValue.url}
                value={
                  formValue?.url
                    ? formValue.url
                    : parsed.success
                    ? parsed.data.url
                    : ""
                }
                onChange={(event) =>
                  onChange({ ...formValue, url: event.target.value })
                }
                // URL is never required for some reason
                theref={register({ required: false })}
                readOnly={!col.is_editable || col.is_not_applicable}
              />
            </VerticalLinkTextFieldsContainer>
          )}
        />
      );
    default:
      return (
        <TextField
          name={col.id}
          theref={register()}
          formState={formState}
          errors={errors}
          normalPlaceHolder={true}
          label={toTitleCase(
            col.display_name ? t([col.display_name]) : col.name
          )}
          defaultValue={(value as string) ?? ""}
          type="text"
          form={row.uuid}
          readOnly={
            col.object_type === "sku_id" ||
            !col.is_editable ||
            col.is_not_applicable
          }
        />
      );
  }
};

export const ActiveCheckIcon = () => {
  const theme = useTheme();
  return <CheckIcon fill={theme.activeToggleBG} />;
};

export const DestructiveXIcon = () => {
  const theme = useTheme();
  return <XIcon fill={theme.destructiveTextColor} />;
};

export const generateUUID = <T extends { uuid: string }>(arr: T[]) => {
  const lastUUID = arr[arr.length - 1]?.uuid;
  return !lastUUID
    ? `${Math.floor(Math.random() * 1234)}${GENERATED_UUID}`
    : lastUUID.includes(GENERATED_UUID)
    ? `${lastUUID}-${Math.floor(Math.random() * 1234)}`
    : `${lastUUID}${GENERATED_UUID}-${Math.floor(Math.random() * 1234)}`;
};

export const GenerateForm = ({
  rowInEdit,
  methodForm,
  onSubmitForm,
  errorRef,
}: {
  rowInEdit: UUID;
  methodForm: UseFormMethods<FormValue>;
  onSubmitForm: (formValue: OnSubmitValue) => Promise<void>;
  errorRef: MutableRefObject<DeepMap<FormValue, FieldError> | null>;
}) => {
  const { notifyError } = useNotifications();
  const { t } = useTranslation();
  return (
    <form
      noValidate
      onSubmit={methodForm.handleSubmit(
        (values: FormValue) => {
          const modifiedValues: FormValue = {};
          Object.entries(values).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              modifiedValues[key] =
                value.length === 0
                  ? ""
                  : (value as ChipType[]).map((val) => val.name);
            } else {
              modifiedValues[key] =
                value instanceof Date ? value.toISOString() : value;
            }
          });
          onSubmitForm({
            ...modifiedValues,
            row_id: rowInEdit,
          });
        },
        (errors: DeepMap<FormValue, FieldError>) => {
          errorRef.current = errors;
          if (errors["select_a_field"]) {
            notifyError(t("Enter at least one field."));
          }
        }
      )}
      id={rowInEdit}
      style={{ display: "none" }}
    />
  );
};

export const editCollectionRow = async ({
  values,
  tenant_id,
  product_id,
  collection_id,
}: {
  values: OnSubmitValue;
  tenant_id: string;
  product_id: string;
  collection_id: string;
}) => {
  const { row_id, ...attributeValues } = values;
  const response = await Axios.put<
    {
      values: {
        attribute_id: string;
        value: string | boolean | number | undefined;
      }[];
    },
    AxiosResponse<PIMProduct>
  >(
    endpoints.v2_tenants_id_or_slug_pim_products_id_collections_id_rows_id(
      tenant_id,
      product_id,
      collection_id,
      row_id!
    ),
    {
      values: getPatchValuesForCollectionRow(attributeValues),
    }
  );
  return response;
};

export const deleteRow = async ({
  tenant_id,
  product_id,
  collection_id,
  row_id,
}: {
  tenant_id: string;
  product_id: string;
  collection_id: string;
  row_id: string;
}) => {
  await Axios.delete(
    endpoints.v2_tenants_id_or_slug_pim_products_id_collections_id_rows_id(
      tenant_id,
      product_id,
      collection_id,
      row_id
    )
  );
};

const getValue = (
  value:
    | string
    | boolean
    | OptionType<string | boolean>
    | { display_text: string; url: string }
    | undefined
) => {
  const parsedLink = LinkAttributeValueSchema.safeParse(value);
  const parsedOptionType = z
    .object({
      value: z.union([z.string(), z.boolean()]),
      label: z.string(),
    })
    .safeParse(value);
  if (parsedLink.success) {
    const linkData = parsedLink.data;
    if (linkData.display_text === "" && linkData.url === "") {
      return null;
    }
    return linkData;
  }

  if (parsedOptionType.success) {
    const value = parsedOptionType.data.value;
    return value === "" ? null : value;
  }
  if (value === undefined) {
    return null;
  }
  return value as any;
};

const getPatchValuesForCollectionRow = (attributeValues: {
  [props: string]:
    | string
    | boolean
    | string[]
    | ChipType[]
    | OptionType<string | boolean>
    | undefined;
}) => {
  let valuesList: {
    attribute_id: string;
    value:
      | string
      | boolean
      | number
      | undefined
      | { display_text: string; url?: string }
      | null;
  }[] = [];
  Object.entries(attributeValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as string[]).forEach((val) => {
        valuesList.push({
          attribute_id: key,
          value: val,
        });
      });
    } else {
      valuesList.push({
        attribute_id: key,
        value: getValue(value),
      });
    }
  });
  return valuesList;
};

export const addRow = async ({
  values,
  tenant_id,
  product_id,
  collection_id,
}: {
  values: OnSubmitValue;
  tenant_id: string;
  product_id: string;
  collection_id: string;
}) => {
  const { row_id, ...attributeValues } = values;

  const response = await Axios.patch<
    {
      rows: {
        values: {
          attribute_id: string;
          value: string | boolean | number | undefined;
        }[];
      }[];
    },
    AxiosResponse<PIMProduct>
  >(
    endpoints.v2_tenants_id_or_slug_pim_products_id_collections_id(
      tenant_id,
      product_id,
      collection_id
    ),
    {
      rows: [
        {
          values: getPatchValuesForCollectionRow(attributeValues),
        },
      ],
    }
  );
  return response;
};
