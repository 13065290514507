import { Pagination } from "../../../components/Pagination/Pagination";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { Table } from "../../../components/Table/Table";
import { useDebounce } from "../../../util/hooks";
import type {
  MyProductsPaginatedOutput,
  MyProductsProduct,
  Product,
  IPriceTier,
  StorefrontUnifiedCart,
} from "../../../types/types";
import { formatPrice } from "../../../util/util-components";
import { PageHeader, PageTitle } from "../../../layout/portalPageLayout";
import {
  PageWrapper,
  HeaderBar,
  ContentWrapper,
} from "../../../layout/publicPageLayout";
import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  NumberParam,
} from "use-query-params";
import { BuyerQuoteItemFormForCart } from "../../../components/quoteCart/BuyerQuoteItemFormForCart";
import type { ISubmitQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import {
  formatDateTime,
  makeUrlWithParams,
  useStoreState,
} from "../../../util/util";
import { useTranslation } from "react-i18next";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import ReactTooltip from "react-tooltip";
import type { Getter, Row } from "@tanstack/react-table";

type TableProduct = {
  product_name: string;
  last_purchased_date: string;
  last_purchase_price: string;
  id: string;
  product_price_tiers: IPriceTier[];
};

type ConstructQuery = {
  baseUrl: string;
  query: string;
};

/**
 * Show a list of products that the buyer has previously ordered.
 */
export const BuyerMyProducts = providePrivatePageProps(({ user }) => {
  const { storefront_id, tenant_id } = useStoreState();

  const [query, setQuery] = useQueryParams({
    product: StringParam,
    q: StringParam,
    industry: ArrayParam,
    market_segment: ArrayParam,
    application: ArrayParam,
    function: ArrayParam,
    proposition: ArrayParam,
    produced_by: ArrayParam,
    offset: NumberParam,
  });

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const { accountPath } = useRoutePath();
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedMyProduct, setSelectedMyProduct] =
    useState<MyProductsProduct>();
  const [showAddToCartForm, setShowAddToCartForm] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const history = useHistory();
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);

  const { data: unifiedCart } = useSWR<StorefrontUnifiedCart, AxiosError>(
    `/v1/storefronts/${storefront_id}/unified-cart`
  );

  const cart = unifiedCart?.quote;

  /**
   * add search term to url. This function feels a little superfluous without
   * filters, but I'm leaving it here based on the idea that they will one day
   * be added.
   */
  const constructQuery = ({ baseUrl, query }: ConstructQuery) => {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", "asc");

    return baseUrl + "?" + paramsWithOffset;
  };

  const { data: productsResponse, error: productsError } =
    useSWR<MyProductsPaginatedOutput>(
      constructQuery({
        baseUrl: `/v1/storefronts/${storefront_id}/my-products`,
        query: debouncedSearchQuery,
      })
    );

  const isLoading = !productsResponse && !productsError;

  const handleCloseModal = () => setShowAddToCartForm(false);

  const fetchProduct = async (id: string) => {
    return Axios.get<Product>(`/v2/tenants/${tenant_id}/pim/products/${id}`);
  };

  const [tableData, setTableData] = useState<TableProduct[]>([]);
  const tableColumns = React.useMemo(
    () => [
      {
        header: `${t("Product(s)")}`,
        accessorKey: "product_name",
        cell: ({
          row,
          getValue,
        }: {
          row: Row<MyProductsProduct["product"]>;
          getValue: Getter<string>;
        }) => {
          const productIsUnavailable =
            row.original.status === "unpublished" ||
            row.original.status === "archived";

          return (
            <>
              <div
                {...(productIsUnavailable
                  ? {
                      "data-for": "product-unavailable",
                      "data-tip": t("This product is currently unavailable"),
                    }
                  : {})}
                style={{
                  textDecoration: productIsUnavailable
                    ? "line-through"
                    : "unset",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {getValue()}
              </div>
              {productIsUnavailable && (
                <ReactTooltip
                  delayHide={500}
                  id="product-unavailable"
                  effect="solid"
                />
              )}
            </>
          );
        },
      },
      {
        header: t("Last Purchased Date"),
        accessorKey: "last_purchased_date",
      },
      {
        header: t("Last Purchased Price"),
        accessorKey: "last_purchase_price",
        align: "right",
      },
    ],
    [t]
  );

  const submitQuoteItemForm: ISubmitQuoteItemForm = async (unifiedCartArg) => {
    try {
      const endpoint = `/v1/storefronts/${storefront_id}/unified-cart`;
      await Axios.post(endpoint, unifiedCartArg);
      mutate(endpoint);
      setShowAddToCartForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: MyProductsPaginatedOutput) => {
      setTableData(
        data.map((product) => ({
          // this should always be defined for PIM products,
          // but I'm leaving defensive code in places to cover migrated products
          product_name: product.product.name ?? "--",
          last_purchased_date:
            product.modified_at !== null
              ? formatDateTime(product.modified_at)
              : "--",
          last_purchase_price: product.order
            ? `${
                product.price_per_unit !== null
                  ? formatPrice(
                      product.price_per_unit,
                      product.currency
                      // This should probably always get the right one unless we allow
                      // multiple of the same product in the order at some point in the future?
                    )
                  : "--"
              }/${
                product.order?.items.find(
                  (item) => item.product_id === product.transacted_product_id
                )?.sku?.packaging_unit?.name ?? ""
              }`
            : "--",

          id: product.product.id,
          product_price_tiers: product.product_price_tiers,
          status: product.product.status,
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, setTableData, perPage]);

  useEffect(() => {
    setQuery({ offset });
  }, [offset, setQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  // Go to price tiers page if a product has tiers, otherwise open the add to
  // cart form.
  const handleRowClick = async (e: React.MouseEvent) => {
    if (productsResponse) {
      const clickedProduct = productsResponse.data.find(
        (product) => product.product.id === e.currentTarget.id
      ) as MyProductsProduct;
      if (
        clickedProduct.product.status === "unpublished" ||
        clickedProduct.product.status === "archived"
      ) {
        return;
      }
      setSelectedMyProduct(clickedProduct);

      // Go to the price tiers page if the cart is empty.
      if (clickedProduct?.product_price_tiers?.length > 0 && !cart) {
        history.push(
          makeUrlWithParams(
            `${accountPath}/my-products/tiers/${e.currentTarget.id}`,
            {
              offset,
              q: query.q,
            }
          )
        );
      } else {
        try {
          const productID = clickedProduct.product.id;
          const { data: product } = await fetchProduct(productID);

          if (product && product.id !== clickedProduct.transacted_product_id) {
            setWarningMessage(
              t(
                "The following product has changed since it was last ordered, some information may be different"
              )
            );
          } else {
            setWarningMessage(null);
          }
          if (product) {
            setSelectedProduct(product);
            setShowAddToCartForm(true);
          }
        } catch (error) {
          const { status_code } = (error as any)?.response?.data;
          if (status_code && status_code === "404") {
            notifyError(t("This product is no longer available"));
          }
        }
      }
    }
  };

  const orderItem = selectedMyProduct?.order?.items.find(
    (item) => item.product_id === selectedMyProduct.transacted_product_id
  );

  const calculateCustomPackagingQuantity = (
    selectedMyProduct: MyProductsProduct | undefined
  ) => {
    if (!selectedMyProduct) {
      return "";
    }
    const { total_quantity, number_of_units } = selectedMyProduct;
    return (number_of_units ? total_quantity / number_of_units : 0).toFixed(0);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("My Products")}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by product name")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </PageHeader>
      <HeaderBar></HeaderBar>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          error={productsError}
          isLoading={isLoading}
          rowClick={handleRowClick}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
      <SlideOut show={showAddToCartForm} closeFlyout={handleCloseModal}>
        {selectedProduct && selectedMyProduct && (
          <BuyerQuoteItemFormForCart
            warning={warningMessage}
            product={selectedProduct}
            submitQuoteItemForm={submitQuoteItemForm}
            buyerUser={user}
            showProceedToCheckoutButton={true}
            productSku={orderItem?.sku ?? undefined}
            no_of_units={String(selectedMyProduct.number_of_units)}
            custom_packaging_quantity={calculateCustomPackagingQuantity(
              selectedMyProduct
            )}
            shippingAddress={selectedMyProduct.order?.shipping_address}
            paymentTerm={undefined} // Business requirement is that the default values should ALWAYS be shown on the add to cart flybar
            deliveryTerm={undefined} // Business requirement is that the default values should ALWAYS be shown on the add to cart flybar
            total_quantity={String(selectedMyProduct.total_quantity)}
            allowEditingTransactionValues={true}
            currencyCode={
              user.settings?.preferred_currency || orderItem?.currency || "USD"
            }
          />
        )}
      </SlideOut>
    </PageWrapper>
  );
});
