import { H3, Title, XL4Title } from "../components/Typography/Typography";
import styled, { css } from "styled-components/macro";
import { screenSize } from "../theme";
import { Link } from "react-router-dom";

export const PageWrapper = styled.div`
  width: 100%;
  max-width: ${(props: any) => (props.narrow ? "900px" : "1400px")};
  min-width: 300px;
  margin: 0 auto;
  .ql-editor {
    height: auto !important;
  }
`;

export const PortfolioHeader = styled.div`
  padding-left: 70px;

  @media ${screenSize.medium} {
    padding-left: 10px;
  }
`;

export const PortfolioTitle = styled(XL4Title)`
  margin: 0;

  @media ${screenSize.medium} {
    font-size: ${({ theme }) => theme.fontSizes["xl-2"]};
    margin-top: 10px;
  }
`;

export const PortfolioCaption = styled(H3)`
  font-weight: normal;
  color: #4a4a4a;
  margin-top: 10px;
  margin-left: 2px;
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  & > *[data-alignright] {
    margin-left: auto;
  }
  .searchBar {
    flex: 1 1 100%;
    order: 10;
    max-width: 100%;
  }

  @media ${screenSize.medium} {
    flex-wrap: wrap;
    & > *[data-alignright] {
      margin-left: 0 !important;
    }
  }
`;

export const PortfolioSearch = styled(HeaderBar)`
  margin-top: 40px;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
  .searchBar {
    max-width: 515px;
  }
  @media ${screenSize.medium} {
    margin-top: 0px;
  }
`;

export const FiltersWrapper = styled.div`
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='18' viewBox='0 0 23 18'%3E%3Cdefs%3E%3Cfilter id='lo99llmo3a'%3E%3CfeColorMatrix in='SourceGraphic' /%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg filter='url(%23lo99llmo3a)' transform='translate(-862 -1020)'%3E%3Cg%3E%3Cpath fill='%23B7B7B7' d='M.85 5.395h3.497C4.737 7.455 6.462 9 8.51 9c2.047 0 3.795-1.545 4.186-3.605h9.453c.46 0 .851-.392.851-.907s-.39-.907-.85-.907h-9.454C12.305 1.545 10.58 0 8.51 0S4.738 1.545 4.347 3.605H.85c-.46 0-.851.392-.851.907s.39.883.85.883zm7.66-3.604c1.403 0 2.553 1.2 2.553 2.697 0 1.496-1.15 2.698-2.553 2.698S5.957 5.984 5.957 4.488s1.15-2.697 2.553-2.697zM22.15 12.605h-3.497C18.263 10.545 16.538 9 14.49 9c-2.047 0-3.795 1.545-4.186 3.605H.851c-.46 0-.851.392-.851.907 0 .49.39.907.85.907h9.454C10.695 16.455 12.42 18 14.49 18s3.772-1.545 4.163-3.605h3.496c.46 0 .851-.392.851-.907 0-.49-.39-.883-.85-.883zm-7.66 3.604c-1.403 0-2.553-1.2-2.553-2.697 0-1.496 1.15-2.698 2.553-2.698s2.553 1.202 2.553 2.698c0 1.471-1.15 2.697-2.553 2.697z' transform='translate(862 1020)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left 10px;
  padding-left: 38px;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 25px 0 35px;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  & > *[data-alignright] {
    margin-left: auto;
  }

  @media ${screenSize.mLarge} {
    width: 100%;
  }

  @media ${screenSize.medium} {
    margin: 10px 0 5px;
    flex-wrap: wrap;
    width: 100%;
    button {
      white-space: nowrap;
      text-align: center;
    }

    & > * {
      margin-right: 5px;
      margin-bottom: 8px;
    }
    & > *[data-alignright] {
      margin-left: 0 !important;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const PortfolioContentLayout = styled.div`
  width: 100%;
  display: Flex;
  flex-direction: row;
  position: relative;
  .gutter {
    background-color: ${(props: any) => props.theme.primaryBorder};
    opacity: 0.5;
    height: 100%;
    transition: all linear 0.2s;
    border-right: 2px solid ${(props: any) => props.theme.colors.white};
    border-left: 2px solid ${(props: any) => props.theme.colors.white};
    width: 7px;
    cursor: col-resize;
    &:hover {
      box-shadow: 0 0 5px ${(props: any) => props.theme.colors.black};
      transform: scale(1.1, 1);
      opacity: 1;
    }
  }
  @media ${screenSize.large} {
    flex-direction: column;
    padding-left: 0;
    .gutter {
      display: none;
    }
  }
`;
interface IProductListProps {
  gridView?: boolean;
}
export const ProductListMainWrapper = styled.div<IProductListProps>`
  width: 100%;
  height: 100%;
  min-width: 200px;
  max-width: ${({ gridView }) => (gridView ? "100%" : "42%")};
  display: flex;
  flex-direction: ${({ gridView }) => (gridView ? "row" : "column")};
  flex-wrap: ${({ gridView }) => (gridView ? "wrap" : "column")};
  align-items: ${({ gridView }) => (gridView ? "flex-start" : "center")};
  padding: 0 15px 0 0;
  align-content: flex-start;
  justify-content: normal;
  @media ${screenSize.large} {
    flex-direction: row;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow-y: auto;
    position: relative;
  }
  overflow: auto;
`;

export const baseProductListStyles = css<IProductListProps>`
  display: block;
  width: ${({ gridView }) => (gridView ? "31%" : "100%")};
  min-height: 120px;
  margin: ${({ gridView }) => (gridView ? "0px 15px 15px 0" : "0 0 15px 0")};
  border: 1px solid ${(props: any) => props.theme.primaryBorder};
  border-radius: 4px;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  h3 {
    margin: 0 0 10px 0;
  }
  &:last-child {
    margin-bottom: ${({ gridView }) => (gridView ? "0" : "15px")};
  }
  @media ${screenSize.large} {
    width: ${({ gridView }) => (gridView ? "45%" : "100%")};
    max-width: 340px;
    margin-right: 15px;
    padding: 10px 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${screenSize.large} {
    min-width: ${({ gridView }) => (gridView ? "200px" : "250px")};
  }
  @media ${screenSize.medium} {
    width: 100%;
    max-width: 100%;
  }
  text-decoration: none;
`;

export const ProductListItemWrapper = styled(Link)<IProductListProps>`
  ${baseProductListStyles}
`;

export const SelectedProductListItemWrapper = styled.div<IProductListProps>`
  ${baseProductListStyles}
  background: ${(props) => props.theme.primaryButtonBG};
  border-color: ${(props) => props.theme.selectedBorder};
`;

export const ProductContentWrapper = styled.div`
  width: 100%;
  min-width: 57%;
  margin-left: 20px;
  border: 1px solid ${(props: any) => props.theme.secondaryBorder};
  border-radius: 8px;
  padding: 35px;
  min-height: 500px;
  height: fit-content;
  @media ${screenSize.large} {
    margin-left: 0;
    margin-top: 25px;
    width: 100% !important;
  }
  @media ${screenSize.small} {
    padding: 14px;
    min-height: 0;
  }
`;

// TODO can this be deleted
export const Card = styled.div`
  border-radius: 4px;
  border: solid 0.6px ${(props) => props.theme.primaryBorder};
  background-color: ${(props) => props.theme.secondaryBG};
  font-size: 13px;
  padding: 15px 15px;
  width: 100%;
`;

export const AuthPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  outline: ${({ theme }) => `2px solid ${theme.publicNavBorder}`};
  @media ${screenSize.medium} {
    outline: none;
  }
  border-radius: 12px;
  margin: 0 auto;
  padding: 35px 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AuthContainer = styled(AuthWrapper)`
  form {
    width: 100%;
    max-width: 320px;
    button {
      margin-top: 15px;
      text-shadow: 0 0 5px #00000040;
    }
    .errorMessage {
      margin-top: 5px;
    }
  }
`;

export const AuthLogo = styled("img")`
  max-width: 160px !important;
  margin: 30px auto;
`;

export const HomePageTitle = styled(Title)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  letter-spacing: -1px;
  margin-bottom: 20px;
  margin-top: 10px;
  @media ${screenSize.small} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: ${({ theme }) => theme.fontWeights.small};
    margin-bottom: 10px;
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
  align-content: center;
  width: 100%;
`;

/**
 * User for forgot-password and forgot-username
 */
export const AuthForgotContainer = styled.div`
  display: flex;
  justify-content: center;
  a {
    color: ${({ theme }) => theme.primaryButtonBG};
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const GetStartedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0;
`;

export const SectionDivider = styled.div`
  height: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.publicNavBorder}`};
  position: relative;
  margin: 0 0 30px;
  > * {
    position: relative;
    top: 10px;
    text-align: center;
    background: ${({ theme }) => theme.colors.white};
    width: 80px;
    height: 40px;
  }
`;
