import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  ButtonWithWarningDialog,
  PrimaryButtonMedium,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import type { BlockLeadFormData } from "../../../components/Buttons/Buttons";
import type { DeepMap, FieldError, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormWrapper } from "../../../util/util";
import { CheckBoxContainer } from "../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import type { Dispatch, SetStateAction } from "react";
import {
  CheckboxContainerWrapper,
  CheckBoxPrintLabel,
  ErrorMessage,
} from "./LeadToBlockModal";

interface BlockLeadFormValues extends FieldValues {
  confirm_convert_to_lead: boolean;
}
interface BlockLeadFormErrors extends DeepMap<BlockLeadFormValues, FieldError> {
  confirm_convert_to_lead?: FieldError;
}

export const BlockLeadToUserModal = ({
  showConvertLeadModal,
  handleCancel,
  handleConvertToLead,
  setShowConvertLeadModal,
}: {
  showConvertLeadModal: boolean;
  handleCancel: () => void;
  handleConvertToLead: (data: BlockLeadFormData) => void;
  setShowConvertLeadModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const convertLeadValidationSchema = yup.object({
    confirm_convert_to_lead: yup
      .boolean()
      .oneOf([true], t("You must select this option to proceed")),
  });

  const convertLeadCheckBox = [
    {
      name: "confirm_convert_to_lead",
      label: t("I confirm all information provided is accurate"),
      required: true,
    },
  ];

  const convertLeadDefaultValue = {
    confirm_convert_to_lead: false,
  };

  const { register, handleSubmit, errors, watch } = useFormWrapper<
    BlockLeadFormValues,
    BlockLeadFormErrors
  >({
    resolver: yupResolver(convertLeadValidationSchema),
    defaultValues: convertLeadDefaultValue || {},
  });

  const checkboxValues: BlockLeadFormData = watch(["confirm_convert_to_lead"]);

  const validationError =
    errors?.confirm_convert_to_lead && !checkboxValues.confirm_convert_to_lead;

  const validationMessage = errors?.confirm_convert_to_lead;

  const renderCheckboxes = () => {
    return (
      <>
        <CheckboxContainerWrapper>
          {convertLeadCheckBox.map((checkbox, index) => (
            <CheckBoxContainer key={index}>
              <div style={{ minWidth: "12px" }}>
                <CheckBoxNoLabel
                  name={checkbox.name}
                  id={checkbox.name}
                  ref={register({ required: checkbox.required })}
                  style={{ marginRight: "10px" }}
                  error={checkbox.required && validationError}
                />
              </div>
              <CheckBoxPrintLabel
                htmlFor={checkbox.name}
                hasError={checkbox.required && validationError}
              >
                {checkbox.label}
              </CheckBoxPrintLabel>
            </CheckBoxContainer>
          ))}
          {validationError && validationMessage?.message && (
            <ErrorMessage>{t(validationMessage.message)}</ErrorMessage>
          )}
        </CheckboxContainerWrapper>

        <div style={{ display: "flex", gap: "16px" }}>
          <SecondaryButtonMedium
            style={{ flex: 1 }}
            onClick={() => {
              setShowConvertLeadModal(false);
            }}
          >
            {t("Cancel")}
          </SecondaryButtonMedium>
          <PrimaryButtonMedium
            style={{ flex: 1 }}
            onClick={handleSubmit((data) => handleConvertToLead(data))}
            disabled={validationError}
          >
            {t("Continue")}
          </PrimaryButtonMedium>
        </div>
      </>
    );
  };

  return (
    <ButtonWithWarningDialog
      openModalDirectly={showConvertLeadModal}
      testid={`close_upload`}
      confirmMessage={t(
        `This lead request may be spam. Please review the details carefully before accepting this lead request, or contact support for additional help`
      )}
      heading={t("Convert lead")}
      showSecondButton
      saveBtnTitle={t("Convert Lead")}
      showAlignCloseButton
      isSmallErrorMsgStyle
      showBlockLeadCheckBox
      renderCheckboxes={renderCheckboxes}
      handleCancel={handleCancel}
    />
  );
};
