// A file for shared form utilities

/**
 * Finds indexes of duplicate non-empty strings in an array.
 *
 * @param arr - Array of strings to check for duplicates
 * @returns Array of indexes where duplicate non-empty strings are found
 *
 * @example
 * const arr = ['a', 'b', 'a', 'c', 'b'];
 * findNonEmptyDuplicateIndexes(arr); // returns [2, 4]
 */
export const findNonEmptyDuplicateIndexes = (arr: string[]) => {
  const idxArr: number[] = [];
  const arrHash: { [prop: string]: number } = {};
  for (let idx = 0; idx < arr.length; idx++) {
    const cur = arr[idx];
    if (cur && cur in arrHash) {
      idxArr.push(idx);
      arrHash[cur] += 1;
    } else {
      arrHash[cur] = 0;
    }
  }
  return idxArr;
};
