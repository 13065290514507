import styled from "styled-components/macro";
import { DetailsDark, DetailsLight, HeadingText } from "./TransactionItem";

const TextAlignRight = styled.div`
  text-align: right;
`;

const headingTextSpacing = "6px";

const InternalHeadingText = styled(DetailsLight)`
  margin-bottom: ${headingTextSpacing};
`;

const MainText = styled(HeadingText)`
  margin-bottom: ${headingTextSpacing};
`;

const LineText = styled(DetailsDark)`
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const WarnLineText = styled(LineText)`
  color: ${({ theme }) => theme.warningTextColor};
`;

/**
 * A column of lines of text for a given type of information. For example,
 * quantity details, price details, or requested details. For example:
 *
 *     heading:        Requested Details
 *     main:           440 KG
 *     lines:          Bags (55 KG)
 *                     8 Units
 *     warningLine:    Custom SKU
 */
export const TransactionItemInfoBlock = ({
  heading,
  main,
  lines,
  warningLine,
  alignRight,
}: {
  heading?: string;
  main: string;
  lines?: string[];
  warningLine?: string;
  alignRight?: boolean;
}) => {
  const content = (
    <>
      {heading && <InternalHeadingText>{heading}</InternalHeadingText>}
      <MainText>{main}</MainText>
      {lines?.map((line, index) => (
        <LineText key={line + index}>{line}</LineText>
      ))}
      {warningLine && <WarnLineText>{warningLine}</WarnLineText>}
    </>
  );

  return alignRight ? (
    <TextAlignRight>{content}</TextAlignRight>
  ) : (
    <div>{content}</div>
  );
};
