import type { Row } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextButton } from "../../../../../../components/Buttons/Buttons";
import { TableWrapper } from "../../../../../../components/Table/Table";
import { SoftHeader2 } from "../../../../../../components/Typography/Typography";
import type { Assets, GridItemProps } from "../../../../../../types/types.PIM";
import type { GridAssetsSelectProps } from "./GridAssetView";
import { GridAssetView } from "./GridAssetView";

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
`;

const ResetBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export function GridAssetViewWithBulkAction({
  assets,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  bulkActionElement,
  bulkSelectLimit,
  selectedItems,
  handleSelectedItems,
}: {
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  bulkActionElement: <
    ElementProps extends {
      rows: string[] | "all";
      resetSelectedRows: () => void;
    }
  >(
    props: ElementProps
  ) => JSX.Element;
  bulkSelectLimit: number;
} & GridAssetsSelectProps) {
  const [rowsSelected, setRowsSelected] = useState<string[] | "all">([]);
  const [isAllCheckBoxUnselected, setIsAllCheckBoxUnselected] = useState(false);
  const [is_page_selected, set_is_page_selected] = useState(false);
  const [unselected_rows_count, set_unselected_rows_count] = useState(0);

  const { t } = useTranslation();

  const handleSelectRows = (items: Row<Assets>[]) => {
    setRowsSelected(items.map((item) => item.original.id));
    handleSelectedItems?.(items);
    setIsAllCheckBoxUnselected(!items.length);
    const items_obj = items.reduce<Record<string, boolean>>((acc, item) => {
      acc[item.original.id] = true;
      return acc;
    }, {});
    set_is_page_selected(
      Object.keys(items_obj).length > 0 && assets.length > 0
        ? assets.every((asset) => items_obj[asset.id])
        : false
    );
  };

  const handleUnselectAll = () => {
    setRowsSelected([]);
    setIsAllCheckBoxUnselected(true);
    handleSelectedItems?.([]);
    set_is_page_selected(false);
  };

  const handleSelectPage = () => {
    set_is_page_selected(true);
    setRowsSelected((prev) => {
      const updated_rows = Array.isArray(prev) ? [...prev] : [];
      return [
        ...new Set([...updated_rows, ...assets.map((asset) => asset.id)]),
      ];
    });
    handleSelectedItems?.([
      ...(selectedItems || []),
      ...assets.map(
        (asset) =>
          ({ original: asset as Assets, id: `${asset.id}` } as Row<Assets>)
      ),
    ]);
    setIsAllCheckBoxUnselected(false);
  };

  useEffect(() => {
    const rows_selected_obj = (
      Array.isArray(rowsSelected) ? rowsSelected : []
    ).reduce<Record<string, boolean>>((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
    const unselected_rows_length =
      Object.keys(rows_selected_obj).length > 0
        ? assets.filter((asset) => !rows_selected_obj[asset.id]).length
        : assets.length;
    set_unselected_rows_count(
      Array.isArray(rowsSelected) ? unselected_rows_length : assets.length
    );
    set_is_page_selected(
      Object.keys(rows_selected_obj).length > 0 && assets.length > 0
        ? assets.every((asset) => rows_selected_obj[asset.id])
        : false
    );
  }, [assets, rowsSelected]);

  return (
    <TableWrapper>
      {rowsSelected?.length > 0 ? (
        <BulkActionWrapper>
          <ResetBtnWrapper>
            <SoftHeader2>
              {rowsSelected === "all"
                ? t("All items selected")
                : `${rowsSelected.length} ${t("selected")}`}
            </SoftHeader2>
            <TextButton onClick={handleUnselectAll}>
              <SoftHeader2>{t("Unselect All")}</SoftHeader2>
            </TextButton>
            {!is_page_selected &&
            rowsSelected.length + unselected_rows_count <= bulkSelectLimit ? (
              <TextButton onClick={handleSelectPage}>
                <SoftHeader2>{t("Select items on this page")}</SoftHeader2>
              </TextButton>
            ) : (
              <></>
            )}
          </ResetBtnWrapper>
          {bulkActionElement({
            rows: rowsSelected,
            resetSelectedRows: handleUnselectAll,
          })}
        </BulkActionWrapper>
      ) : (
        <></>
      )}
      <GridAssetView
        assets={assets}
        handle_asset_visibility_change={handle_asset_visibility_change}
        on_edit_asset={on_edit_asset}
        on_remove_asset={on_remove_asset}
        on_download_asset={on_download_asset}
        handleSelectedItems={handleSelectRows}
        show_download_checkbox={true}
        isAllCheckBoxUnselected={isAllCheckBoxUnselected}
        is_page_selected={is_page_selected}
        bulkSelectLimit={bulkSelectLimit}
        selectedItems={selectedItems}
      />
    </TableWrapper>
  );
}
