import { StatusLeft, StatusRight } from "../Status/Status";
import type { SampleRequestStatusUnion } from "../../types/types";
import React from "react";

type SampleRequestStatusProps = {
  status: SampleRequestStatusUnion;
  position: "right" | "left";
  id: string;
  t: (s: string) => string;
};

export const SampleRequestStatus = ({
  status,
  position,
  id,
  t,
}: SampleRequestStatusProps) => {
  switch (status) {
    case "new":
      // This is never used currently, the `new` status of a sample request is
      // for when it is still in the cart.
      return position === "left" ? (
        <StatusLeft color="blue" text={t("New")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("New")} id={id} />
      );
    case "accepted":
      return position === "left" ? (
        <StatusLeft color="green" text={t("Accepted")} id={id} />
      ) : (
        <StatusRight color="green" text={t("Accepted")} id={id} />
      );
    case "requested":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("New")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("New")} id={id} />
      );
    case "rejected":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Rejected")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Rejected")} id={id} />
      );
    case "forwarded":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Forwarded")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Forwarded")} id={id} />
      );
    case "Added as User":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Added as User")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Added as User")} id={id} />
      );
    case "Added as Address":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Added as Address")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Added as Address")} id={id} />
      );
    case "blocked":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Blocked")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Blocked")} id={id} />
      );
    default:
      return position === "left" ? (
        <StatusLeft color="gray" text={status} id={id} />
      ) : (
        <StatusRight color="gray" text={status} id={id} />
      );
  }
};
