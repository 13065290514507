import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import * as yup from "yup";
import {
  PrimaryButtonFitContainer,
  SecondaryButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { PriceTiersBigTable } from "../../../../../components/PriceTiersBigTable/PriceTiersBigTable";
import {
  tierToRowData,
  usePriceTiersBigTable,
} from "../../../../../components/PriceTiersBigTable/usePriceTiersBigTable";
import { SubmitButtonContainer } from "../../../../../layout/FormLayout";
import type {
  CurrencyCode,
  IGroupedPriceTierSingleTier,
  IPriceTier,
  ProductSKU,
} from "../../../../../types/types";
import { strings } from "../../../../../util/strings";
import {
  promiseAllSettledLogAndThrow,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { submitPriceTierEdits } from "../../shared";

const StepTwoContainer = styled.div`
  margin-top: 50px;
  & > * {
    margin-bottom: 20px;
  }
`;

const DatePickerContainer = styled.div`
  max-width: 254px;
`;

const SaveChangesButtonContainer = styled(SubmitButtonContainer)`
  max-width: 510px;
`;

const priceTierToTableRow = (tier: IPriceTier): IGroupedPriceTierSingleTier => {
  const {
    id,
    product_sku,
    price_per_uom,
    minimum_uom_quantity,
    minimum_sku_quantity,
  } = tier;

  return {
    tier_id: id,
    product_sku,
    price_per_uom,
    minimum_uom_quantity,
    minimum_sku_quantity,
  };
};

const formSchemaType = yup
  .object()
  .shape({
    valid_until: yup.string().required(),
  })
  .defined();

type FormValues = yup.InferType<typeof formSchemaType>;

/**
 * Second step of the create price tiers workflow. Shows the table of tiers and
 * a field to enter "valid until".  Handles submission to the backend. Values
 * entered in step one are passed in as props.
 */
export const CreatePriceTierGroupStepTwo = ({
  tiers,
  destinationId,
  paymentTermId,
  deliveryTermId,
  productSkus,
  sellerTenantId,
  buyerTenantId,
  currencyCode,
  onTierCreated,
}: {
  tiers: IPriceTier[];
  destinationId: string;
  paymentTermId: string;
  deliveryTermId: string;
  productSkus: ProductSKU[];
  sellerTenantId: string;
  buyerTenantId: string;
  currencyCode: CurrencyCode;
  onTierCreated: () => void;
}) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const [submitting, setSubmitting] = useState(false);

  const formSchema = yup
    .object()
    .shape({
      valid_until: yup.string().required(strings(t).thisIsARequiredField),
    })
    .defined();

  const {
    tableRowsData,
    editRowIndex,
    addNewRow,
    startEditingRow,
    cancelEditingRow,
    finishEditingRow,
    deleteRow,
  } = usePriceTiersBigTable(
    // If tiers exist we show them in the table, if they don't we start with
    // a single new row in "edit" state.
    tiers.length > 0
      ? tiers.map(priceTierToTableRow).map(tierToRowData)
      : [
          {
            status: "new",
            data: {
              tier_id: "",
              product_sku: productSkus[0],
              price_per_uom: "",
              minimum_uom_quantity: "",
              minimum_sku_quantity: "",
            },
          },
        ],
    tiers.length > 0 ? undefined : 0
  );

  const rowEditInProgress = editRowIndex !== null;
  const firstProductSku = productSkus.length > 0 ? productSkus[0] : null;

  const methodsOfUseForm = useFormWrapper<FormValues>({
    defaultValues: {
      valid_until: tiers.length > 0 ? tiers[0].valid_to_date : undefined,
    },
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values: FormValues) => {
    setSubmitting(true);
    try {
      const { valid_until } = values;

      const promises = submitPriceTierEdits({
        tableRowsData,
        valid_until: moment.utc(valid_until).endOf("day").toISOString(),
        previousValidUntil:
          tiers.length > 0
            ? moment.utc(tiers[0].valid_to_date).endOf("day").toISOString()
            : null,
        seller_id: sellerTenantId,
        buyer_id: buyerTenantId,
        currencyCode,
        destination_id: destinationId,
        delivery_term_id: deliveryTermId,
        payment_term_id: paymentTermId,
        storefront_id,
      });
      await promiseAllSettledLogAndThrow(promises);

      notifySuccess(t("Price tiers updated"));
      setSubmitting(false);
      onTierCreated();
    } catch (error) {
      notifyError(
        t("There was an error saving the changes, please try again"),
        {
          error,
        }
      );
      setSubmitting(false);
    }
  };

  return (
    <StepTwoContainer>
      {tiers.length > 0 && (
        <div>
          {t(`Some price tiers already exist. You can add new tiers and also edit or
          delete the existing tiers.`)}
        </div>
      )}
      <div>
        <PriceTiersBigTable
          tableRowsData={tableRowsData}
          editRowIndex={editRowIndex}
          startEditingRow={startEditingRow}
          cancelEditingRow={cancelEditingRow}
          finishEditingRow={finishEditingRow}
          deleteRow={deleteRow}
          currencyCode={currencyCode}
          isInEditMode={true}
          productSkus={productSkus}
        />
      </div>
      <div>
        <SecondaryButtonSmall
          onClick={() => {
            if (firstProductSku) {
              // For TypeScript: first product sku will always be defined here.
              addNewRow(firstProductSku);
            }
          }}
          disabled={submitting || rowEditInProgress}
        >
          {t("Add")}
        </SecondaryButtonSmall>
      </div>
      <DatePickerContainer>
        <DatePicker
          label={t("Valid Until")}
          name={"valid_until"}
          required={true}
          methodsOfUseForm={methodsOfUseForm}
          defaultValue={tiers.length > 0 ? tiers[0].valid_to_date : null}
        />
      </DatePickerContainer>
      <SaveChangesButtonContainer>
        <PrimaryButtonFitContainer
          onClick={methodsOfUseForm.handleSubmit(onSubmit)}
          loading={submitting}
          disabled={rowEditInProgress}
        >
          {tiers.length > 0 ? t("Update Price Tiers") : t("Create Price Tiers")}
        </PrimaryButtonFitContainer>
      </SaveChangesButtonContainer>
    </StepTwoContainer>
  );
};
