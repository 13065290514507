import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import { Form, FormFieldAndIcon } from "../../layout/FormLayout";
import { normalizeAttributeName } from "../../pages/admin/SellerAdmin/PIM/components/PIM.components.util";
import { AddOrEditImageAttribute } from "../../pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributes/ImageAttributes/AddOrEditImageAttribute";
import { stringArrayToOptionArray } from "../../pages/admin/SellerAdmin/PIM/SellerAdminTemplates/AddItemToTemplateCollectionForm";
import type {
  CreatedOption,
  MethodsOfUseForm,
  OptionType,
} from "../../types/types";
import { isCreatedOption } from "../../types/types";
import type {
  Assets,
  AttributeSchema,
  ImageAttributeValue,
  PIMProduct,
} from "../../types/types.PIM";
import { useStoreState } from "../../util/util";
import { LinkAttributeValueSchema } from "../../util/zod.util";
import { useAuthContext } from "../Auth";
import {
  ButtonWithConfirmDialog,
  PrimaryButtonSmall,
  PrimaryCancelButton,
  SecondaryButtonWithPlusIcon,
  XSmallButtonRow,
} from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { CreatableSelectBox } from "../CreateableSelectBox/CreatableSelectBox";
import { DatePicker } from "../DatePicker/DatePicker";
import { DeletableChips } from "../DeletableChips/DeletableChips";
import { ErrorPlaceholder } from "../Error";
import {
  InfoIcon,
  LoadingIcon,
  NonVisibleIcon,
  NotApplicableIcon,
} from "../Icons/Icons";
import { RichEditor } from "../RichEditor/RichEditor";
import { SearchSelect } from "../SearchSelect/SearchSelect";
import { SearchSelectInfiniteScroll } from "../SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { SlideOut } from "../SlideOut/SlideOut";
import { TextArea } from "../TextArea/TextArea";
import { TextField } from "../TextFields/TextFields";
import { ToggleSwitchV2 } from "../ToggleSwitch/ToggleSwitch";
import { SoftHeaderRegular } from "../Typography/Typography";
import { ImageAttributePreview } from "./ImageAttributePreview";

const InputWrapper = styled.div`
  width: 100%;
  max-width: 520px;
  margin-bottom: 10px;
`;

const CenteredIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 12px;
`;

const FlexForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const LinkTextFieldsContainer = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-columns: 48% auto 48%;
  flex-wrap: nowrap;
  overflow: hidden;
`;

export const GreyLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GreyLine = styled.div`
  height: 1px;
  width: 100%;
  border: solid 1px ${({ theme }) => theme.primaryBorder};
`;

const LoadingIconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ImageAttribute = ({
  attribute,
  methodsOfUseForm,
  onCreateImageAttribute,
  isDisabled,
}: {
  attribute: AttributeSchema;
  methodsOfUseForm: MethodsOfUseForm;
  onCreateImageAttribute?: (
    image_arr: ImageAttributeValue | null,
    attribute: AttributeSchema
  ) => void;
  isDisabled?: boolean;
}) => {
  const [show_slideout, set_show_slideout] = useState(false);
  const [asset_id, set_asset_id] = useState<string | null>(null);
  const [image_attr, set_image_atrr] = useState<ImageAttributeValue>();
  const [is_deleted, set_is_deleted] = useState(false);
  const [asset, set_asset] = useState<Assets>();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();

  const { error: asset_error } = useSWR<Assets>(
    asset_id
      ? endpoints.v2_storefronts_id_pim_assets_id(tenant_id, asset_id)
      : null,
    {
      onSuccess: (data) => {
        if (data.id === asset_id) {
          set_asset(data);
        }
      },
    }
  );

  const close_slideout = () => {
    set_show_slideout(false);
  };

  const onComplete = (
    image_attr: ImageAttributeValue | null,
    can_close = true
  ) => {
    set_asset_id(image_attr?.asset_id ?? null);
    if (!!image_attr?.asset_id && image_attr.asset_id !== asset_id) {
      set_asset(undefined);
    }
    set_image_atrr(image_attr ?? undefined);
    onCreateImageAttribute && onCreateImageAttribute(image_attr, attribute);
    if (!image_attr) {
      set_is_deleted(true);
    }
    can_close && close_slideout();
  };

  useEffect(() => {
    if (attribute.values?.length && !asset_id && !is_deleted) {
      set_asset_id((attribute.values[0].value as ImageAttributeValue).asset_id);
    }
  }, [asset_id, attribute.values, is_deleted]);

  useEffect(() => {
    methodsOfUseForm.register({
      name: normalizeAttributeName(attribute.name),
      required: attribute.is_required,
    });
    if (!image_attr && !is_deleted) {
      const defaultValue = attribute.values?.length
        ? (attribute.values[0].value as ImageAttributeValue)
        : undefined;
      methodsOfUseForm.setValue(
        normalizeAttributeName(attribute.name),
        defaultValue
      );
    }
  }, [
    attribute.is_required,
    attribute.name,
    attribute.values,
    image_attr,
    is_deleted,
    methodsOfUseForm,
  ]);

  const is_loading = !asset_error && !asset;

  return asset_id ? (
    <>
      {is_loading ? (
        <LoadingIconContainer>
          <LoadingIcon width={24} height={24} />
        </LoadingIconContainer>
      ) : (
        <>
          {asset_error && (
            <ErrorPlaceholder message={t("Could not load image")} />
          )}
          {asset && (
            <ImageAttributePreview
              attribute={attribute}
              asset={asset}
              onCreateImageAttribute={(image_attr) => {
                onComplete(image_attr, false);
              }}
              image_attribute_value={image_attr}
            />
          )}
        </>
      )}
    </>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          gap: "8px",
        }}
      >
        <SoftHeaderRegular>{attribute.name}</SoftHeaderRegular>
        <SecondaryButtonWithPlusIcon
          type="button"
          onClick={() => set_show_slideout(true)}
          disabled={isDisabled}
        >
          {t("Add Image")}
        </SecondaryButtonWithPlusIcon>
      </div>
      <SlideOut show={show_slideout} closeFlyout={close_slideout}>
        <AddOrEditImageAttribute
          type="add"
          attribute={attribute}
          onCancel={close_slideout}
          onComplete={onComplete}
          existing_asset={!is_deleted ? asset : undefined}
        />
      </SlideOut>
    </>
  );
};

export const FormFieldAndIcons = ({
  description,
  restricted,
  children,
  attribute,
  applicable,
  onClickNotApplicable,
}: {
  description?: string | null;
  restricted?: boolean;
  children: ReactNode;
  attribute?: AttributeSchema;
  applicable?: boolean;
  onClickNotApplicable: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { hasPermission } = useAuthContext();

  return (
    <FormFieldAndIcon>
      {children}
      {description && (
        <>
          <CenteredIconContainer data-tip={description} data-for="info-tip">
            <InfoIcon fill={theme.secondaryIconColor} />
          </CenteredIconContainer>
          <ReactTooltip
            delayHide={500}
            id="info-tip"
            clickable
            effect="solid"
          />
        </>
      )}
      {restricted && (
        <>
          <CenteredIconContainer
            data-for="restricted-tip"
            data-tip={t("This attribute is only visible for internal users")}
          >
            <NonVisibleIcon
              width={24}
              height={24}
              fill={theme.secondaryIconColor}
            />
          </CenteredIconContainer>
          <ReactTooltip delayHide={500} id="restricted-tip" effect="solid" />
        </>
      )}
      {!attribute?.is_mandatory && !attribute?.is_required && (
        <>
          <ButtonWithConfirmDialog
            Button={({ onClick }) => (
              <CenteredIconContainer
                as="button"
                type="button"
                disabled={!hasPermission("modify_products")}
                onClick={onClick}
                css={`
                  background: none;
                  border: none;
                  margin-top: 12px;
                  width: 24px;
                  height: 24px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                  cursor: ${({ disabled }: { disabled?: boolean }) =>
                    disabled ? "not-allowed" : "pointer"};
                `}
              >
                <NotApplicableIcon
                  width={24}
                  height={24}
                  fill={
                    !applicable ? theme.errorColor : theme.secondaryIconColor
                  }
                />
              </CenteredIconContainer>
            )}
            testid="not-applicable-button"
            confirmMessage={t(
              applicable
                ? "Are you sure you want to mark this field as not applicable?"
                : "Are you sure you want to mark this field as applicable?"
            )}
            handleConfirm={() => onClickNotApplicable()}
            disabled={!hasPermission("modify_products")}
            datatip={
              hasPermission("modify_products")
                ? t("Mark as not applicable")
                : t(
                    "You don't have permission to mark attributes as not applicable"
                  )
            }
            datafor="not-applicable-tip"
          />
          <ReactTooltip
            delayHide={500}
            id="not-applicable-tip"
            effect="solid"
          />
        </>
      )}
    </FormFieldAndIcon>
  );
};
export interface ProductGroupFormValues {
  [key: string]:
    | string
    | string[]
    | boolean
    | number
    | { display_text: string; url: string }
    | ImageAttributeValue
    | ChipType[];
}

export type AttributeGroupFormGeneratorProps = {
  attributes: AttributeSchema[];
  methodsOfUseForm: MethodsOfUseForm;
  onSubmit: (values: ProductGroupFormValues) => Promise<JSX.Element | void>;
  onCancel: () => void;
  isLoading: boolean;
  onCreateOption: (value: string, attribute: AttributeSchema) => Promise<void>;
  onCreateImageAttribute?: (
    image_arr: ImageAttributeValue | null,
    attribute: AttributeSchema
  ) => void;
  showMandatoryAsterisk?: boolean;
};

// Helper function to format label with asterisk if mandatory
const formatLabel = (
  attr: AttributeSchema,
  t: (key: string | string[]) => string,
  showMandatoryAsterisk?: boolean
) => {
  const displayName = attr.display_name ? t([attr.display_name]) : attr.name;
  return attr.is_mandatory && showMandatoryAsterisk
    ? `${displayName} *`
    : displayName;
};

/**
 * Takes in columns and outputs a form. Supports all values of @see AttributeInput
 */
export function AttributeGroupFormGenerator({
  attributes,
  methodsOfUseForm,
  onSubmit,
  onCancel,
  onCreateOption,
  onCreateImageAttribute,
  isLoading,
  showMandatoryAsterisk,
}: AttributeGroupFormGeneratorProps): JSX.Element {
  const {
    register,
    formState,
    errors,
    control,
    setValue,
    handleSubmit,
    watch,
  } = methodsOfUseForm;
  const { t } = useTranslation();
  const { hasPermission } = useAuthContext();

  // Add state to track not applicable attributes
  const [notApplicableAttributes, setNotApplicableAttributes] = useState<{
    [key: string]: boolean;
  }>({});

  const multiSelectRefs = useRef<{ [key: string]: { clearChips: () => void } }>(
    {}
  );

  const onClickNotApplicable = (attr: AttributeSchema) => {
    const currentlyNotApplicable =
      notApplicableAttributes[attr.id] ?? attr.is_not_applicable;

    const newNotApplicableState = !currentlyNotApplicable;

    setNotApplicableAttributes((prev) => ({
      ...prev,
      [attr.id]: newNotApplicableState,
    }));

    methodsOfUseForm.register(
      `${normalizeAttributeName(attr.name)}__notApplicable`
    );

    methodsOfUseForm.setValue(
      `${normalizeAttributeName(attr.name)}__notApplicable`,
      newNotApplicableState,
      {
        shouldDirty: true,
      }
    );

    if (newNotApplicableState) {
      const normalizedName = normalizeAttributeName(attr.name);

      switch (attr?.input_type) {
        case "date":
          methodsOfUseForm.setValue(normalizedName, "", {
            shouldValidate: true,
            shouldDirty: true,
          });
          break;
        case "form_field":
          methodsOfUseForm.setValue(normalizedName, "", {
            shouldValidate: true,
            shouldDirty: true,
          });
          break;
        case "multiline_entry":
          methodsOfUseForm.setValue(normalizedName, "", {
            shouldValidate: true,
            shouldDirty: true,
          });

          break;
        case "multi_select":
          methodsOfUseForm.setValue(normalizedName, []);
          multiSelectRefs.current[attr.id]?.clearChips();
          break;
        case "link":
          setTimeout(() => {
            setValue(
              normalizeAttributeName(attr.name),
              { display_text: "", url: "" },
              {
                shouldValidate: true,
                shouldDirty: true,
              }
            );
          }, 0);

          break;
        default:
          methodsOfUseForm.setValue(normalizedName, "");
      }
    }
  };

  return (
    <FlexForm onSubmit={handleSubmit(onSubmit)}>
      {attributes.map((attr) => {
        // Pass the local not applicable state to FormFieldAndIcons
        const isNotApplicable =
          notApplicableAttributes[attr.id] ?? attr.is_not_applicable;

        const isDisabled = !attr.is_editable || isNotApplicable;

        switch (attr.input_type) {
          case "date":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <DatePicker
                  isOutsideRange={() => false}
                  label={formatLabel(attr, t, showMandatoryAsterisk)}
                  name={normalizeAttributeName(attr.name)}
                  defaultValue={
                    attr.values?.length
                      ? (attr.values[0].value as string)
                      : undefined
                  }
                  required={attr.is_required}
                  methodsOfUseForm={methodsOfUseForm}
                  disabled={isDisabled}
                />
              </FormFieldAndIcons>
            );
          case "form_field": {
            const fieldKey = `${attr.id}-${isNotApplicable ? "na" : "norm"}`;

            return (
              <FormFieldAndIcons
                key={attr.id}
                applicable={!isNotApplicable}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <TextField
                  key={fieldKey}
                  name={normalizeAttributeName(attr.name)}
                  label={formatLabel(attr, t, showMandatoryAsterisk)}
                  theref={register({
                    required: attr.is_required,
                  })}
                  defaultValue={
                    isNotApplicable
                      ? ""
                      : attr.values?.length
                      ? (attr.values[0].value as string)
                      : undefined
                  }
                  formState={formState}
                  errors={errors}
                  type="text"
                  readOnly={attr.object_type === "product_id" || isDisabled}
                />
              </FormFieldAndIcons>
            );
          }
          case "single_select":
            const choices = attr?.choices?.map(stringArrayToOptionArray) ?? [];
            const formatCreateLabel = (value: string) =>
              t(`Save "{{value}}" to {{list_name}}`, {
                value,
                list_name: attr.list_name,
              });

            const creatableProps = hasPermission("modify_lists")
              ? {
                  isCreatable: true,
                  onCreateOption: (value: string) => {
                    onCreateOption(value, attr);
                  },
                  createOptionPosition: "first",
                  isValidNewOption: (val: string) => {
                    return (
                      val.length < 50 &&
                      !choices
                        .map(({ value }) => value.toLowerCase())
                        .includes(val.toLowerCase()) &&
                      val !== ""
                    );
                  },
                  formatCreateLabel: formatCreateLabel,
                }
              : {};

            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <Controller
                  as={CreatableSelectBox}
                  control={control}
                  name={normalizeAttributeName(attr.name)}
                  placeholder={formatLabel(attr, t, showMandatoryAsterisk)}
                  options={choices}
                  isClearable
                  rules={{
                    required: attr.is_required,
                  }}
                  defaultValue={
                    attr?.values?.length
                      ? {
                          label: attr.values[0].enum_variant?.variant,
                          value: attr.values[0].enum_variant?.variant,
                        }
                      : null
                  }
                  errors={errors}
                  formState={formState}
                  disabled={isDisabled}
                  {...creatableProps}
                />
              </FormFieldAndIcons>
            );
          case "toggle":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <div>
                  <ToggleSwitchV2
                    label={formatLabel(attr, t, showMandatoryAsterisk)}
                    name={normalizeAttributeName(attr.name)}
                    ref={register({ required: attr.is_required })}
                    defaultChecked={
                      attr.values && (attr.values[0]?.value as boolean)
                    }
                    disabled={isDisabled}
                  />
                </div>
              </FormFieldAndIcons>
            );
          case "checkbox":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
              >
                <div>
                  <ToggleSwitchV2
                    label={formatLabel(attr, t, showMandatoryAsterisk)}
                    name={normalizeAttributeName(attr.name)}
                    ref={register({ required: attr.is_required })}
                    defaultChecked={attr.values[0]?.value as boolean}
                    disabled={isDisabled}
                  />
                </div>
              </FormFieldAndIcons>
            );
          case "numeric": {
            const fieldKey = `${attr.id}-${isNotApplicable ? "na" : "norm"}`;

            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <TextField
                  key={fieldKey}
                  name={normalizeAttributeName(attr.name)}
                  label={`${formatLabel(
                    attr,
                    t,
                    showMandatoryAsterisk
                  )} (number)`}
                  theref={register({
                    required: attr.is_required,
                  })}
                  defaultValue={
                    isNotApplicable
                      ? ""
                      : attr.values?.length
                      ? (attr.values[0].value as string)
                      : undefined
                  }
                  formState={formState}
                  errors={errors}
                  type="number"
                  readOnly={isDisabled}
                />
              </FormFieldAndIcons>
            );
          }
          case "multiline_entry":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                {attr.name === "Meta Keywords" ||
                attr.name === "Meta Description" ? (
                  <TextArea
                    name={normalizeAttributeName(attr.name)}
                    label={formatLabel(attr, t, showMandatoryAsterisk)}
                    theref={register({ required: attr.is_required })}
                    formState={formState}
                    required={attr.is_required}
                    defaultValue={
                      attr.values?.length
                        ? (attr.values[0].value as string)
                        : undefined
                    }
                    readOnly={isDisabled}
                  />
                ) : (
                  <RichEditor
                    value={
                      attr.values?.length
                        ? (attr.values[0].value as string)
                        : undefined
                    }
                    name={normalizeAttributeName(attr.name)}
                    label={formatLabel(attr, t, showMandatoryAsterisk)}
                    useFormMethods={{
                      register,
                      formState,
                      setValue,
                      errors,
                      watch,
                    }}
                    readOnly={isDisabled}
                  />
                )}
              </FormFieldAndIcons>
            );
          case "multi_select":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                {hasPermission("modify_lists") ? (
                  <MultiSelectField
                    onCreateOption={onCreateOption}
                    attribute={attr}
                    type={"group"}
                    methodsOfUseForm={methodsOfUseForm}
                    multiSelectRefs={multiSelectRefs}
                    isDisabled={isDisabled}
                  />
                ) : (
                  <MultiSelectField
                    attribute={attr}
                    type={"group"}
                    methodsOfUseForm={methodsOfUseForm}
                    multiSelectRefs={multiSelectRefs}
                    isDisabled={isDisabled}
                  />
                )}
              </FormFieldAndIcons>
            );
          case "link":
            const fieldKey = `${attr.id}-${isNotApplicable ? "na" : "norm"}`;

            const maybeDefaultValue = (() => {
              if (attr?.values?.[0]?.value && !isNotApplicable) {
                const parsed = LinkAttributeValueSchema.safeParse(
                  attr.values[0]?.value
                );
                if (parsed.success) {
                  return parsed.data;
                }
              }
              return { url: "", display_text: "" };
            })();

            const link = watch(normalizeAttributeName(attr.name));

            const displayTextIsRequired = !!(link?.url || attr.is_required);
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <Controller
                  key={fieldKey}
                  control={control}
                  name={normalizeAttributeName(attr.name)}
                  defaultValue={maybeDefaultValue}
                  render={({ onChange, value: formValue }) => {
                    return (
                      <LinkTextFieldsContainer>
                        <TextField
                          label={`${formatLabel(
                            attr,
                            t,
                            showMandatoryAsterisk
                          )} - ${t("Display Text")}`}
                          name={"display_text"}
                          type={"text"}
                          theref={register({ required: displayTextIsRequired })}
                          formState={formState}
                          errors={errors}
                          defaultValue={maybeDefaultValue.display_text}
                          value={
                            watch(normalizeAttributeName(attr.name))
                              ?.display_text ?? ""
                          }
                          onChange={(event) => {
                            const newValue = {
                              ...formValue,
                              display_text: event.target.value,
                            };

                            onChange(newValue);
                          }}
                          readOnly={isDisabled}
                        />
                        <GreyLineWrapper>
                          <GreyLine />
                        </GreyLineWrapper>
                        <TextField
                          name={""}
                          type={"url"}
                          label={`${formatLabel(
                            attr,
                            t,
                            showMandatoryAsterisk
                          )} - URL`}
                          formState={formState}
                          errors={errors}
                          defaultValue={maybeDefaultValue.url}
                          value={formValue?.url ?? ""}
                          onChange={(event) => {
                            const newValue = {
                              ...formValue,
                              url: event.target.value,
                            };

                            onChange(newValue);
                          }}
                          theref={register({ required: false })}
                          readOnly={isDisabled}
                        />
                      </LinkTextFieldsContainer>
                    );
                  }}
                />
              </FormFieldAndIcons>
            );
          case "product_reference":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <ProductReferenceField
                  attribute={attr}
                  methodsOfUseForm={methodsOfUseForm}
                  isDisabled={isDisabled}
                />
              </FormFieldAndIcons>
            );
          case "image":
            return (
              <FormFieldAndIcons
                key={attr.id}
                description={attr.description ? t([attr.description]) : null}
                restricted={attr.is_restricted}
                applicable={!isNotApplicable}
                onClickNotApplicable={() => onClickNotApplicable(attr)}
                attribute={attr}
              >
                <ImageAttribute
                  attribute={attr}
                  methodsOfUseForm={methodsOfUseForm}
                  onCreateImageAttribute={onCreateImageAttribute}
                  isDisabled={isDisabled}
                />
              </FormFieldAndIcons>
            );
          default:
            return <></>; //assertUnreachable(attr);
        }
      })}
      <XSmallButtonRow>
        <PrimaryCancelButton onClick={onCancel} type="reset">
          {t("Cancel")}
        </PrimaryCancelButton>
        <PrimaryButtonSmall loading={isLoading}>{t("Save")}</PrimaryButtonSmall>
      </XSmallButtonRow>
    </FlexForm>
  );
}

export function MultiSelectField({
  type,
  attribute,
  methodsOfUseForm,
  displayByName,
  onCreateOption,
  multiSelectRefs,
  isDisabled,
}: {
  type: "group" | "collection";
  attribute: AttributeSchema;
  methodsOfUseForm: MethodsOfUseForm;
  displayByName?: boolean;
  onCreateOption?: AttributeGroupFormGeneratorProps["onCreateOption"];
  multiSelectRefs: React.MutableRefObject<{
    [key: string]: { clearChips: () => void };
  }>;
  isDisabled?: boolean;
}) {
  const [attributeChips, setAttributeChips] = useState<{ name: string }[]>([]);
  const [attributeOptions, setAttributeOptions] = useState<OptionType[]>(
    attribute?.choices?.map(stringArrayToOptionArray) ?? []
  );
  const { t } = useTranslation();
  const { register, setValue, errors, formState } = methodsOfUseForm;
  const handleMultiSelectChange = (data: CreatedOption | null) => {
    if (isCreatedOption(data)) {
      setAttributeChips((previous) => [
        ...previous,
        { name: data.label, id: data.value },
      ]);
      setAttributeOptions(
        (previous) =>
          previous?.filter((item) => item.label !== data.label) ?? []
      );
    }
  };
  useEffect(() => {
    const getAttributeValuesChips = () => {
      const values =
        type === "group"
          ? attribute.values?.map((value) => {
              return {
                name: value.enum_variant?.variant ?? value.attribute_id,
              };
            })
          : attribute.default_values?.map((value) => {
              return {
                name: value.enum_variant?.variant ?? value.attribute_id,
              };
            });
      return values ?? [];
    };
    setAttributeChips(getAttributeValuesChips() ?? []);
    setAttributeOptions(
      (previous) =>
        previous.filter(
          (item) =>
            (getAttributeValuesChips() as { name: string }[])
              .map((option) => option.name)
              .indexOf(item.value) < 0
        ) ?? []
    );
  }, [attribute, setValue, type]);

  useEffect(() => {
    if (attributeChips) {
      setValue(
        normalizeAttributeName(attribute.name),
        attributeChips.length
          ? attributeChips.map((attribute) => attribute.name)
          : undefined,
        { shouldValidate: true }
      );
    }
  }, [attributeChips, setValue, attribute]);

  const handleAttributeChipClick = (name: string) => {
    if (!attributeOptions.map(({ value }) => value).includes(name)) {
      setAttributeOptions([{ value: name, label: name }, ...attributeOptions]);
    }
    setAttributeChips(
      (previous) => previous?.filter((item) => item.name !== name) ?? []
    );
  };

  useEffect(() => {
    register({
      name: normalizeAttributeName(attribute.name),
      required: attribute.is_required,
    });
  }, [register, attribute]);

  const formatCreateLabel = (value: string) =>
    t(`Save "{{value}}" to {{list_name}}`, {
      value,
      list_name: attribute.list_name,
    });

  useEffect(() => {
    if (multiSelectRefs.current) {
      multiSelectRefs.current[attribute.id] = {
        clearChips: () => setAttributeChips([]),
      };
    }
  }, [attribute.id, multiSelectRefs]);

  return (
    <div>
      <InputWrapper>
        <SearchSelect
          name={normalizeAttributeName(attribute.name)}
          options={attributeOptions}
          errors={errors}
          formState={formState}
          disabled={isDisabled || !attribute.is_editable}
          placeholder={t(`Select {{attribute}}`, {
            attribute: displayByName
              ? attribute.name
              : attribute.display_name
              ? t([attribute.display_name])
              : attribute.name,
          })}
          value={null}
          onChange={handleMultiSelectChange}
          {...(onCreateOption && {
            isCreatable: true,
            onCreateOption: (value: string) => {
              onCreateOption(value, attribute);
              handleMultiSelectChange({ label: value, value: value });
            },
            formatCreateLabel: formatCreateLabel,
            createOptionPosition: "first",
            isValidNewOption: (val: string) => {
              return (
                val.length < 50 &&
                !attributeOptions
                  .map(({ value }) => value.toLowerCase())
                  .includes(val.toLowerCase()) &&
                !attributeChips.find(
                  ({ name }) => name.toLowerCase() === val.toLowerCase()
                ) &&
                val !== ""
              );
            },
          })}
        />
      </InputWrapper>
      <DeletableChips
        chips={attributeChips}
        noneSelectedName={
          displayByName ? attribute.name : t([attribute.display_name])
        }
        handleClick={handleAttributeChipClick}
      />
    </div>
  );
}

export function ProductReferenceField({
  attribute,
  methodsOfUseForm,
  isDisabled,
}: {
  attribute: AttributeSchema;
  methodsOfUseForm: MethodsOfUseForm;
  isDisabled?: boolean;
}) {
  const [attributeChips, setAttributeChips] = useState<ChipType[]>([]);
  const { tenant_id } = useStoreState();
  const [, setAttributeOptions] = useState<OptionType[]>( //attributeOptions,
    attribute?.choices?.map(stringArrayToOptionArray) ?? []
  );
  const { t } = useTranslation();
  const { register, setValue, errors, formState } = methodsOfUseForm;
  const handleMultiSelectChange = (data: CreatedOption | null) => {
    if (isCreatedOption(data)) {
      setAttributeChips((previous) => [
        ...previous,
        { name: data.label, id: data.value },
      ]);
      setAttributeOptions(
        (previous) =>
          previous?.filter((item) => item.label !== data.label) ?? []
      );
    }
  };

  useEffect(() => {
    const getAttributeValuesChips = () => {
      const values = attribute.values?.map((value) => {
        return {
          name: value.product_ref?.name ?? value.attribute_id,
          id: value.product_ref?.id,
        };
      });

      return values ?? [];
    };
    setAttributeChips(
      getAttributeValuesChips() ? getAttributeValuesChips() : []
    );
    setAttributeOptions(
      (previous) =>
        previous.filter(
          (item) =>
            (getAttributeValuesChips() as { name: string; id: string }[])
              .map((option) => option)
              .indexOf(item.value) < 0
        ) ?? []
    );
  }, [attribute, setValue]);

  useEffect(() => {
    if (attributeChips) {
      setValue(
        normalizeAttributeName(attribute.name),
        //attributeChips[0] ? attributeChips[0].name
        attributeChips.length
          ? attributeChips.map((attribute) => attribute)
          : undefined,
        { shouldValidate: true }
      );

      setAttributeOptions(
        attribute?.choices
          ?.map(stringArrayToOptionArray)
          .filter(
            (item) =>
              attributeChips.map((option) => option.name).indexOf(item.value) <
              0
          ) ?? []
      );
    }
  }, [attributeChips, setValue, attribute]);

  const handleAttributeChipClick = (name: string) => {
    setAttributeChips(
      (previous) => previous?.filter((item) => item.name !== name) ?? []
    );
  };

  useEffect(() => {
    register({
      name: normalizeAttributeName(attribute.name),
      required: attribute.is_required,
    });
  }, [register, attribute]);

  return (
    <div>
      <InputWrapper>
        {attribute.is_multiple_choice ? (
          <>
            <InputWrapper>
              <SearchSelectInfiniteScroll
                name={normalizeAttributeName(attribute.name)}
                key={attribute.id}
                isDisabled={isDisabled}
                params={(() => {
                  const params = new URLSearchParams();
                  params.append("order_by", "asc");
                  params.append("status", "published");
                  return params;
                })()}
                baseUrl={endpoints.v2_tenants_id_pim_products_summary(
                  tenant_id
                )}
                getOptions={(response: PIMProduct[]) =>
                  response
                    .reduce(
                      (
                        prev: { label: string; value: string }[],
                        { name, id }: PIMProduct
                      ) => [...prev, { label: name, value: id }],
                      []
                    )
                    .filter(
                      (item) =>
                        attributeChips
                          .map((option) => option.name)
                          .indexOf(item.label) < 0
                    )
                }
                onChange={handleMultiSelectChange}
                disabled={!attribute.is_editable}
                errors={errors}
                formState={formState}
                isClearable
                placeholder={
                  attribute.display_name
                    ? t([attribute.display_name])
                    : attribute.name
                }
                value={null}
                testid={attribute.name}
                autoComplete="on"
              />
            </InputWrapper>
            <DeletableChips
              chips={attributeChips}
              noneSelectedName="choices"
              handleClick={handleAttributeChipClick}
            />
          </>
        ) : (
          <SearchSelectInfiniteScroll
            name={normalizeAttributeName(attribute.name)}
            key={attribute.id}
            errors={errors}
            formState={formState}
            isClearable
            disabled={isDisabled || !attribute.is_editable}
            placeholder={
              attribute.display_name
                ? t([attribute.display_name])
                : attribute.name
            }
            defaultValue={
              attribute?.values?.length
                ? {
                    label: attribute.values[0].product_ref?.name,
                    value: attribute.values[0].product_ref?.id,
                  }
                : undefined
            }
            baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              params.append("status", "published");
              return params;
            })()}
            getOptions={(response: PIMProduct[]) =>
              response.reduce(
                (
                  prev: { label: string; value: string }[],
                  { name, id }: PIMProduct
                ) => [...prev, { label: name, value: id }],
                []
              )
            }
            onChange={(data: any) =>
              setValue(normalizeAttributeName(attribute.name), data)
            }
            testid={attribute.name}
          />
        )}
      </InputWrapper>
    </div>
  );
}
