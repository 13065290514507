import type {
  IPackagingType,
  IPackagingUnit,
  IPriceTier,
  OptionType,
  PackagingTypePaginatedOutput,
  PackagingUnitPaginatedOutput,
  WithPagination,
} from "../types/types";
import type { AxiosError } from "axios";
import useSWR from "swr";
import { endpoints } from "../endpoints";
import { useStoreState } from "./util";
import type { ProductListPriceSchema } from "../types/types.PIM";

/**
 * A react hook that gets the packaging types for the current storefront from
 * the back end. It sorts them to make sure they are in the correct order.
 * The return values are similar to those of useSWR.
 */
export const useStorefrontPackagingTypes = () => {
  const { storefront_id } = useStoreState();

  const {
    data: packagingTypesResponse,
    error: packagingTypesError,
    mutate: mutatePackagingTypes,
    isValidating: isFetchingPackagingTypes,
  } = useSWR<PackagingTypePaginatedOutput>(
    endpoints.v1_storefronts_id_packagingTypes(storefront_id),
    { revalidateOnFocus: false, revalidateOnMount: true }
  );

  const packagingTypes = packagingTypesResponse?.data;

  if (packagingTypes) {
    packagingTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  if (packagingTypesError) {
    console.error("Error fetching packaging types", packagingTypesError);
  }

  return {
    packagingTypes,
    packagingTypesError,
    mutatePackagingTypes,
    isFetchingPackagingTypes,
  };
};

/**
 * A react hook that gets the packaging types to show for a given product. Used
 * for the guest quote item form where logged out site visitors add products
 * to the cart.
 *
 * Depending on the storefront settings (e.g. as set by a seller admin) it will
 * either return the packaging types for a particular product, or a custom list
 * of packaging types that is used for all products on that storefront.
 */
export const useProductPackagingTypes = (productId: string) => {
  const { storefront_id } = useStoreState();

  const {
    data: packagingTypesResponse,
    error: packagingTypesError,
    mutate: mutatePackagingTypes,
  } = useSWR<PackagingTypePaginatedOutput>(
    endpoints.v1_storefronts_id_products_id_packagingTypes(
      storefront_id,
      productId
    )
  );

  const packagingTypes = packagingTypesResponse?.data;

  if (packagingTypes) {
    packagingTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  if (packagingTypesError) {
    console.error("Error fetching packaging types", packagingTypesError);
  }

  return { packagingTypes, packagingTypesError, mutatePackagingTypes };
};

/**
 * A react hook that gets all possible packaging units from the back end.
 * The return values are similar to those of useSWR.
 */
export const usePackagingUnits = () => {
  const { storefront_id } = useStoreState();

  const {
    data: packagingUnitsResponse,
    error: packagingUnitsError,
    isValidating: isFetchingPackagingUnits,
  } = useSWR<
    PackagingUnitPaginatedOutput,
    AxiosError<PackagingUnitPaginatedOutput>
  >(`/v2/storefronts/${storefront_id}/packaging-units`, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const packagingUnits = packagingUnitsResponse?.data;

  if (packagingUnits) {
    packagingUnits.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  if (packagingUnitsError) {
    console.error("Error fetching packaging units", packagingUnitsError);
  }

  return { packagingUnits, packagingUnitsError, isFetchingPackagingUnits };
};

/**
 * Convert a packaging type or unit into an option type, e.g. for use in a drop
 * down menu.
 */
export const packagingTypeOrUnitToOption = (
  packagingTypeOrUnit: IPackagingUnit | IPackagingType
): OptionType<string> => ({
  value: packagingTypeOrUnit.id,
  label: packagingTypeOrUnit.name,
});

/**
 * Fetches the list prices for a product sku.
 * @param product_id
 * @param sku_number
 * @returns {list_prices, list_prices_as_price_tiers, list_prices_exist}
 */
export const useProductSkuListPrice = (
  product_id?: string,
  sku_number?: string
) => {
  const { tenant_id } = useStoreState();
  const { data: list_prices } = useSWR<
    WithPagination<{ data: ProductListPriceSchema[] }>
  >(
    sku_number && product_id
      ? endpoints.v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices(
          tenant_id,
          product_id,
          sku_number
        )
      : null
  );

  const list_prices_exist = !!list_prices?.data && list_prices.data.length > 0;

  const list_prices_as_price_tiers = list_prices_exist
    ? list_prices?.data.map(
        ({ id, sku, minimum_units, quantity, price, currency, valid_until }) =>
          ({
            id,
            product_sku: sku,
            minimum_sku_quantity: String(minimum_units),
            minimum_uom_quantity: String(quantity),
            price_per_uom: price,
            currency,
            valid_to: valid_until,
          } as unknown as IPriceTier)
      )
    : [];

  return { list_prices, list_prices_as_price_tiers, list_prices_exist };
};
