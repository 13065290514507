import type { InputHTMLAttributes } from "react";
import React, { useContext, useState } from "react";
import type {
  DeepMap,
  FieldError,
  FormState,
} from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import type { DefaultTheme } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";
import { showErrors } from "../../util/util-components";
import type { IIconProps } from "../Icons/Icons";
import { NonVisibleIcon, VisibleIcon } from "../Icons/Icons";
import {
  FormInputErrorText,
  FormInputWarnText,
} from "../Typography/Typography";

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  identifier?: string;
  Icon?: ({ fill }: IIconProps) => JSX.Element; // can be string or SVG component
  label: string;
  name: string;
  theref: any; // register
  type: string;
  formState: FormState<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  disabled?: boolean;
  readOnly?: boolean;
  testid?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  small?: boolean;
  extraSmall?: boolean;
  error?: FieldError | null;
  tabIndex?: number;
  warningText?: { message: string } | null;
  normalPlaceHolder?: boolean;
  error_bottom_position?: string;
}

interface IInputProps {
  invalid?: boolean;
  warn?: boolean;
  normalPlaceHolder?: boolean;
  theme: DefaultTheme;
}

const Input = styled.input.attrs({
  //  rows: 3, maxLength: 96
})`
  box-sizing: border-box;
  /* height: 52px; */
  width: 100%;
  resize: none;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.primaryBG};
  padding: ${({ normalPlaceHolder }) =>
    normalPlaceHolder ? "12px 13px 6px 12px" : "21px 40px 6px 12px"};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  border: ${({ invalid, warn, theme }: IInputProps) => {
    if (invalid) {
      return `2px solid ${theme.errorColor}`;
    } else if (warn) {
      return `2px solid ${theme.warningTextColor}`;
    } else {
      return `1px solid ${theme.primaryBorder}`;
    }
  }};
  &:focus {
    border: ${({ invalid, warn, theme }: IInputProps) =>
      (invalid && `2px solid ${theme.errorColor}`) ||
      (warn && `2px solid ${theme.warningTextColor}`) ||
      `2px solid ${theme.tertiaryBorder}`};
    outline: none;
  }
  ::placeholder {
    color: ${({ invalid, warn, theme }) =>
      (invalid && `${theme.errorColor}`) ||
      (warn && `${theme.warningTextColor}`) ||
      `${theme.secondaryTextColor}`};
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Removes the weird blue color on autofill */
  &:-internal-autofill-selected {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  text-overflow: ellipsis;
`;

const DisabledInput = styled.input.attrs({})<IInputProps>`
  box-sizing: border-box;
  height: 52px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  font-family: "Inter";
  color: #808080;
  padding: 21px 13px 6px 12px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background-color: ${({ theme }) => theme.disabledInput};
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  pointer-events: none;
  text-overflow: ellipsis;
`;

const FloatingLabel = styled.span<IInputProps>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${(props: IInputProps) =>
    props.invalid
      ? props.theme.errorColor
      : props.warn
      ? props.theme.warningTextColor
      : props.theme.secondaryTextColor};
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 14px;
  transition: 0.2s ease all;
  & ${Input}:not(:focus):not(:placeholder-shown) {
    left: 13px !important;
    top: 14px !important;
  }
`;

const DisabledFloatingLabel = styled.span<IInputProps>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.secondaryTextColor};
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 7px;
  bottom: 10px;
  transition: 0.2s ease all;
`;

type WrapperProps = {
  extraSmall?: boolean;
  small?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  /* 
    if the input is focused or read only move the label to the top left.
    css does not have a selector for "if input has text" so we must use 
    :placeholder-shown as a proxy. 
    We cannot you use :valid because it will fail for things like 
    input[type="email"] which can have content but be invalid.
  */
  /* prettier-ignore */
  & ${Input}:focus + ${FloatingLabel},
  & ${Input}:not(:placeholder-shown) + ${FloatingLabel},
  & ${Input}:read-only + ${FloatingLabel} {
    outline: none;
    top: 7px;
    bottom: 10px;
    left: 13px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    opacity: 1;
    max-width: ${(props) =>
    props.small ? "176px" : props.extraSmall ? "78px" : "unset"}
  }
`;

const IdentifierWrapper = styled.div<IInputProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  position: absolute;
  top: 15px;
  right: 14px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

const RedIdentifierWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  position: absolute;
  top: 15px;
  right: 14px;
  color: #${({ theme }) => theme.errorColor};
`;

const ToggleIconWrapper = styled.div`
  cursor: pointer;
`;

/**
 * A generic text field component designed to be used with react hook form.
 * This component will automatically display errors in all cases except for when
 * used as part of field array, in which case you have to manually set the error
 * using the `error` prop.
 */
export const TextField = ({
  identifier,
  Icon,
  label,
  name,
  theref,
  type,
  formState,
  errors,
  disabled,
  readOnly,
  autoComplete,
  defaultValue,
  testid,
  onChange,
  small,
  extraSmall,
  error,
  tabIndex,
  warningText,
  form,
  normalPlaceHolder,
  error_bottom_position,
}: ITextFieldProps) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  // Support manually setting the error
  if (error) {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <Input
          autoComplete={autoComplete || "on"}
          ref={theref}
          type={type}
          placeholder={normalPlaceHolder ? label : " "}
          normalPlaceHolder={normalPlaceHolder}
          required
          name={name}
          invalid
          defaultValue={defaultValue}
          data-testid={testid}
          onChange={onChange}
          tabIndex={tabIndex}
          form={form}
        />
        {!normalPlaceHolder && <FloatingLabel invalid>{label}</FloatingLabel>}
        <RedIdentifierWrapper>{identifier || ""}</RedIdentifierWrapper>
        <IdentifierWrapper>
          {Icon ? <Icon fill={theme.errorColor} /> : null}
        </IdentifierWrapper>
        <div
          style={{ position: "absolute", bottom: "-12px", minWidth: "250px" }}
        >
          <FormInputErrorText className="errorMessage">
            {error.message}
          </FormInputErrorText>
        </div>
      </Wrapper>
    );
  } else if (
    (formState.submitCount > 0 && errors[name]?.message) ||
    errors[name]?.type
  ) {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <Input
          autoComplete={autoComplete || "on"}
          ref={theref}
          type={type}
          placeholder={normalPlaceHolder ? label : " "}
          normalPlaceHolder={normalPlaceHolder}
          required
          name={name}
          invalid
          defaultValue={defaultValue}
          data-testid={testid}
          onChange={onChange}
          tabIndex={tabIndex}
          form={form}
        />
        {!normalPlaceHolder && <FloatingLabel invalid>{label}</FloatingLabel>}
        <RedIdentifierWrapper>{identifier || ""}</RedIdentifierWrapper>
        <IdentifierWrapper>
          {Icon ? <Icon fill={theme.errorColor} /> : null}
        </IdentifierWrapper>
        {showErrors({ errors, name, t, bottomPosition: error_bottom_position })}
      </Wrapper>
    );
  } else if (disabled) {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <DisabledInput
          placeholder={normalPlaceHolder ? label : " "}
          type={type}
          required
          ref={theref}
          name={name}
          defaultValue={defaultValue}
          data-testid={testid}
          disabled={true}
          form={form}
          title={String(defaultValue)}
        />
        {!normalPlaceHolder && (
          <DisabledFloatingLabel>{label}</DisabledFloatingLabel>
        )}
        <IdentifierWrapper>{identifier || ""}</IdentifierWrapper>
        <IdentifierWrapper>{Icon ? <Icon /> : null}</IdentifierWrapper>
      </Wrapper>
    );
  } else if (readOnly) {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <DisabledInput
          placeholder={normalPlaceHolder ? label : " "}
          type={type}
          required
          ref={theref}
          name={name}
          defaultValue={defaultValue}
          data-testid={testid}
          readOnly={readOnly}
          form={form}
          title={String(defaultValue)}
        />
        {!normalPlaceHolder && (
          <DisabledFloatingLabel>{label}</DisabledFloatingLabel>
        )}
        <IdentifierWrapper>{identifier || ""}</IdentifierWrapper>
        <IdentifierWrapper>{Icon ? <Icon /> : null}</IdentifierWrapper>
      </Wrapper>
    );
  } else if (warningText && warningText.message) {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <Input
          autoComplete={autoComplete || "on"}
          ref={theref}
          type={type}
          placeholder={normalPlaceHolder ? label : " "}
          normalPlaceHolder={normalPlaceHolder}
          required
          name={name}
          warn
          defaultValue={defaultValue}
          data-testid={testid}
          onChange={onChange}
          form={form}
          title={String(defaultValue)}
        />
        {!normalPlaceHolder && <FloatingLabel warn>{label}</FloatingLabel>}
        <RedIdentifierWrapper>{identifier || ""}</RedIdentifierWrapper>
        <IdentifierWrapper>
          {Icon ? <Icon fill={theme.warningTextColor} /> : null}
        </IdentifierWrapper>
        <div
          style={{ position: "absolute", bottom: "-12px", minWidth: "250px" }}
        >
          <FormInputWarnText>{warningText.message}</FormInputWarnText>
        </div>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper className="textField" small={small} extraSmall={extraSmall}>
        <Input
          placeholder={normalPlaceHolder ? label : " "}
          normalPlaceHolder={normalPlaceHolder}
          type={type}
          required
          ref={theref}
          name={name}
          readOnly={readOnly}
          defaultValue={defaultValue}
          data-testid={testid}
          onChange={onChange}
          tabIndex={tabIndex}
          form={form}
          title={String(defaultValue)}
        />
        {!normalPlaceHolder && <FloatingLabel>{label}</FloatingLabel>}
        <IdentifierWrapper>{identifier || ""}</IdentifierWrapper>
        <IdentifierWrapper>{Icon ? <Icon /> : null}</IdentifierWrapper>
      </Wrapper>
    );
  }
};

/**
 * A text field component with type, password designed to be used with react hook form.
 * This component works exactly like TextField component, with the difference that it already
 * has a preset type="password" and an Icon attached to it. The Icon toggles between VisibleIcon
 * and NonVisibleIcon
 */
export const PasswordToggleTextField = (
  props: Omit<ITextFieldProps, "type" | "Icon">
) => {
  const [type, setType] = useState<"password" | "text">("password");
  const toggleIcon = (props: IIconProps): JSX.Element => (
    <ToggleIconWrapper
      onClick={() => setType(type === "password" ? "text" : "password")}
    >
      <>
        {type === "password" && <VisibleIcon {...props} />}
        {type === "text" && <NonVisibleIcon {...props} />}
      </>
    </ToggleIconWrapper>
  );
  return (
    <TextField
      {...props}
      type={type}
      Icon={toggleIcon}
      normalPlaceHolder={props.normalPlaceHolder}
    />
  );
};
