import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { mutate } from "swr";
import * as Yup from "yup";
import {
  PrimaryButtonFitContainer,
  SecondaryButtonSmall,
} from "../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { EditablePriceList } from "../../../../components/EditablePriceList/EditablePriceList";
import { FileCardUploading } from "../../../../components/FileCard/FileCard";
import { FilePicker } from "../../../../components/FilePicker/FilePicker";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../../components/Form/Form";
import { Modal } from "../../../../components/Modal/Modal";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { TextArea } from "../../../../components/TextArea/TextArea";
import { TextField } from "../../../../components/TextFields/TextFields";
import {
  H3,
  Title,
  XLTitle,
} from "../../../../components/Typography/Typography";
import { Form } from "../../../../layout/FormLayout";
import type {
  CurrencyCode,
  IGeneratedInvoiceArgs,
} from "../../../../types/types";
import { strings } from "../../../../util/strings";
import {
  getDateTime,
  getFileUploadErrorText,
  isAxiosError,
  useCurrencySymbol,
  useFormWrapper,
  useStoreState,
} from "../../../../util/util";
import { ColoredTextOnError } from "../../../../util/util-components";

const ConfirmationSpacer = styled.div`
  margin: 15px 0 10px;
`;

const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    min-width: 48%;
  }
`;

const SectionHeader = styled(H3)`
  margin-top: 30px;
`;

const AddChargeButtonWrapper = styled.div`
  margin: 15px 0 0;
`;

const OrContainer = styled.div`
  text-align: center;
`;

const UploadTitleContainer = styled.div`
  text-align: center;
`;

interface AddInvoiceFormData {
  invoice_number: string;
  invoice_date: string;
  due_date: string;
  ship_via?: string;
  tracking_number?: string;
  notes?: string;
  feesList: { name: string; amount: string }[] | undefined;
  confirmation: boolean;
}

const defaultValues: AddInvoiceFormData = {
  invoice_number: "",
  invoice_date: "",
  due_date: "",
  feesList: [],
  confirmation: false,
};

export const AddInvoiceForm = ({
  orderId,
  currencyCode,
  closeForm,
}: {
  orderId: string;
  currencyCode: CurrencyCode;
  closeForm: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const {
    storefront_id,
    storefront_metadata: { accepts_payments },
  } = useStoreState();
  const currencySymbol = useCurrencySymbol(currencyCode);

  const generateInvoiceFormSchema = Yup.object().shape({
    invoice_number: Yup.string()
      .required(strings(t).thisIsARequiredField)
      .nullable(),
    invoice_date: Yup.string()
      .required(strings(t).thisIsARequiredField)
      .nullable(),
    due_date: Yup.string().required(strings(t).thisIsARequiredField).nullable(),
    ship_via: Yup.string().notRequired(),
    tracking_number: Yup.string().notRequired(),
    notes: Yup.string().notRequired(),
    confirmation: Yup.boolean().oneOf([true]),
  });

  // Used to temporarily store the form data when uploading a file.
  // When it is set, we show the file upload UI in a modal.
  const [formDataForUpload, setFormDataForUpload] =
    useState<AddInvoiceFormData | undefined>();

  const [uploadingDocument, setUploadingDocument] =
    useState<File | undefined>();
  const [isGenerating, setIsGenerating] = useState(false);

  const methodsOfUseForm = useFormWrapper({
    resolver: yupResolver(generateInvoiceFormSchema),
    defaultValues,
  });
  const { register, handleSubmit, formState, errors, control, getValues } =
    methodsOfUseForm;

  const methodsOfUseFieldArray = useFieldArray({
    control,
    name: "feesList",
    keyName: "key",
  });
  const { append } = methodsOfUseFieldArray;

  useEffect(() => {
    register({ name: "invoice_date", required: true });
    register({ name: "due_date", required: true });
  }, [register]);

  const handleUploadInvoiceClick = (formData: AddInvoiceFormData) => {
    setFormDataForUpload(formData);
  };

  /**
   * Submits the invoice form data. On success returns `true` otherwise `false`.
   */
  const submitInvoiceForm = async (
    formData: AddInvoiceFormData,
    is_upload: boolean
  ): Promise<boolean> => {
    try {
      // The currency of any additional charges is the same as the order items'
      // currency.
      const additional_charges = formData.feesList?.map((fee) => ({
        ...fee,
        currency: currencyCode,
      }));

      const requestBody: IGeneratedInvoiceArgs = {
        invoice_number: formData.invoice_number,
        invoice_date: getDateTime(formData.invoice_date, true).dateFullYear,
        due_date: getDateTime(formData.due_date, true).dateFullYear,
        ship_via: formData.ship_via,
        tracking_number: formData.tracking_number,
        notes: formData.notes,
        additional_charges,
        is_upload: is_upload,
      };
      await Axios.post(
        `/v1/storefronts/${storefront_id}/orders/${orderId}/invoices`,
        requestBody
      );
      return true;
    } catch (error) {
      notifyError("Submitting the form failed", { error });
      return false;
    }
  };

  /**
   * First submit the form data, and then upload the file.
   */
  const submitFormAndUploadFile = async (file: File) => {
    setUploadingDocument(file);

    // formDataForUpload should always be defined here, so the getValues
    // fallback is for TypeScript and out of an abundance of caution.
    const formSubmitSucceeded = await submitInvoiceForm(
      formDataForUpload ?? getValues(),
      true
    );
    if (!formSubmitSucceeded) {
      setUploadingDocument(undefined);
      return;
    }

    try {
      const fileFormData = new FormData();
      fileFormData.append("file", file);

      await Axios.patch(
        `/v1/storefronts/${storefront_id}/orders/${orderId}/documents/invoice`,
        fileFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
    } catch (error) {
      setUploadingDocument(undefined);
      if (isAxiosError(error)) {
        const { notificationErrorText } = getFileUploadErrorText(error);
        notifyError(notificationErrorText, { error });
      } else {
        notifyError("There was an error uploading the invoice");
      }
      return;
    }

    notifySuccess(t("Invoice document uploaded successfully"));
    mutate(`/v1/storefronts/${storefront_id}/orders/${orderId}`);
    mutate(
      `/v1/storefronts/${storefront_id}/orders/${orderId}/events?limit=100`
    );
    setUploadingDocument(undefined);
    setFormDataForUpload(undefined);
    closeForm();
  };

  /**
   * First submit the form data, and then generate the file.
   */
  const submitFormAndGenerateFile = async (formData: AddInvoiceFormData) => {
    setIsGenerating(true);

    const formSubmitSucceeded = await submitInvoiceForm(formData, false);
    if (!formSubmitSucceeded) {
      setIsGenerating(false);
      return;
    }

    try {
      await Axios.post(
        `/v1/storefronts/${storefront_id}/orders/${orderId}/invoices/generate`
      );
    } catch (error) {
      notifyError(t("There was an error generating the invoice"), { error });
      setIsGenerating(false);
      return;
    }

    notifySuccess(t("Invoice generation succeeded"));
    mutate(`/v1/storefronts/${storefront_id}/orders/${orderId}`);
    mutate(
      `/v1/storefronts/${storefront_id}/orders/${orderId}/events?limit=100`
    );
    setIsGenerating(false);
    closeForm();
  };

  return (
    <div>
      <XLTitle>{t("Add Invoice")}</XLTitle>
      <Form noValidate>
        <TextField
          name={"invoice_number"}
          label={t("Invoice Number")}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <DateRow>
          <DatePicker
            label={t("Invoice Date")}
            name={"invoice_date"}
            methodsOfUseForm={methodsOfUseForm}
            required={true}
          />
          <DatePicker
            label={t("Due Date")}
            name={"due_date"}
            methodsOfUseForm={methodsOfUseForm}
            required={true}
          />
        </DateRow>
        <TextField
          name={"ship_via"}
          label={t("Ship Via")}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          name={"tracking_number"}
          label={t("Tracking Number")}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <SectionHeader>{t("Additional Charges")}</SectionHeader>
        <EditablePriceList
          // We have to spread `methodsOfUseForm` to satisfy TypeScript.
          methodsOfUseForm={{ ...methodsOfUseForm }}
          methodsOfUseFieldArray={methodsOfUseFieldArray}
          testIdPrefix={"generate-invoice-charge"}
          currencySymbol={currencySymbol}
          hasPossibleTaxFee={false}
        />
        <AddChargeButtonWrapper>
          <SecondaryButtonSmall
            type="button"
            onClick={() => append({ name: "", amount: "" })}
          >
            {t("Add Charge")}
          </SecondaryButtonSmall>
        </AddChargeButtonWrapper>
        <SectionHeader>{t("Add Notes")}</SectionHeader>
        <TextArea
          name={"notes"}
          label={t("Notes")}
          theref={register({ required: true })}
          formState={formState}
          required={false}
        />
        <ConfirmationSpacer>
          <CheckBoxContainer
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "22px", marginRight: "15px" }}>
              <CheckBoxNoLabel
                name="confirmation"
                id="confirmation"
                ref={register()}
                error={!!errors.confirmation?.message}
              />
            </div>
            <CheckBoxFinePrintLabel
              htmlFor="confirmation"
              style={{ marginLeft: "4px" }}
            >
              <ColoredTextOnError isError={!!errors.confirmation?.message}>
                {t("I confirm all the information I provided is accurate.")}
              </ColoredTextOnError>
            </CheckBoxFinePrintLabel>
          </CheckBoxContainer>
        </ConfirmationSpacer>
        {/* Invoice must be generated for payments to work. */}
        {!accepts_payments && (
          <>
            <PrimaryButtonFitContainer
              onClick={handleSubmit(handleUploadInvoiceClick)}
              loading={!!formDataForUpload}
              disabled={isGenerating}
            >
              {t("Upload Invoice")}
            </PrimaryButtonFitContainer>
            <OrContainer>{t("OR")}</OrContainer>
          </>
        )}
        <PrimaryButtonFitContainer
          onClick={handleSubmit(submitFormAndGenerateFile)}
          loading={isGenerating}
          disabled={!!formDataForUpload}
        >
          {t("Generate Invoice")}
        </PrimaryButtonFitContainer>
      </Form>

      <Modal
        show={!!formDataForUpload}
        closeModal={() => setFormDataForUpload(undefined)}
      >
        <UploadTitleContainer>
          <Title>{t("Upload Invoice File")}</Title>
        </UploadTitleContainer>
        <FilePicker handleFile={submitFormAndUploadFile} />
        {uploadingDocument && (
          <FileCardUploading fileName={uploadingDocument.name} />
        )}
      </Modal>
    </div>
  );
};
