import type { ChangeEvent, MouseEvent } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useAuthContext } from "../../../../../components/Auth";
import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../../components/Layout/Layout";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { endpoints } from "../../../../../endpoints";
import { Flex, Flex1, Flex2 } from "../../../../../layout/FormLayout";
import {
  ContentWrapper,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import type { PaginatedAttributeSchema } from "../../../../../types/types.PIM";
import { useDebounce } from "../../../../../util/hooks";
import { useRoutePath } from "../../../../../util/Routing";
import {
  defaultHandleSort,
  removeUnderscore,
  toTitleCase,
  useStoreState,
} from "../../../../../util/util";
import { AttributesNav } from "../components/AttributesNav";
import { NameWithSystemDefaultIcon } from "../components/PIM.components.util";
import { CreateAttribute } from "./CreateAttribute";
import { appendFiltersToUrl } from "../../../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { FilterBy } from "../../../../../components/FilterBy/FilterBy";
import { isChipTypeArray } from "../../../../../types/types";
import type { AttributeListLocationState } from "../../../../../types/types";
import type { ChipType } from "../../../../../components/Chips/Chips";
import { chipsToStringArray } from "../../../../../components/Filters/Filters";

type ApplyFiltersToURL = {
  attribute_type: string[];
  params: URLSearchParams;
};

export const AttributesList = () => {
  const [tableData, setTableData] = useState<
    {
      id: string;
      attribute_name: { name: string; is_system: boolean; id: string };
      attribute_display_name: string;
      attribute_type: string;
    }[]
  >([]);
  const perPageItems = [10, 20, 50];
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    attribute_type: ArrayParam,
  });
  const [attributeTypesFromURL, setAttributeTypesFromURL] = useState(
    getObjArrayFromStringArray(
      ((query?.attribute_type ?? []) as string[]).map((attributeType) =>
        toTitleCase(attributeType)
      )
    )
  );
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [showCreateSlideOut, setCreateSlideOut] = useState(false);
  const [attributeTypeFilters, setAttributeTypeFilters] = useState<string[]>(
    []
  );

  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<AttributeListLocationState>();
  const { hasPermission } = useAuthContext();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const tableColumns = useMemo(
    () => [
      {
        header: t("Attribute Name"),
        accessorKey: "attribute_name",
        id: "attribute_name",
        cell: ({
          row,
        }: {
          row: {
            original: {
              attribute_name: { name: string; is_system: boolean; id: string };
            };
          };
        }) => (
          <NameWithSystemDefaultIcon
            name={row.original.attribute_name.name}
            id={row.original.attribute_name.id}
            is_system={row.original.attribute_name.is_system}
          />
        ),
      },
      {
        header: t("Display Name"),
        accessorKey: "attribute_display_name",
      },
      {
        header: t("Attribute Type"),
        accessorKey: "attribute_type",
        cell: ({ row }: { row: { original: { attribute_type: string } } }) => (
          <span style={{ width: "fit-content" }}>
            {toTitleCase(removeUnderscore(row.original.attribute_type))}
          </span>
        ),
      },
    ],
    [t]
  );

  const applyFiltersToURL = ({
    attribute_type,
    params,
  }: ApplyFiltersToURL): URLSearchParams => {
    if (attribute_type) {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: attributeTypeFilters,
        key: "attribute_type",
      });
    }
    return params;
  };

  const constructQuery = ({
    baseUrl,
    query,
  }: {
    baseUrl: string;
    query: string;
    attribute_type: string[];
  }) => {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    //return baseUrl + paramsWithOffset;

    return (
      baseUrl +
      applyFiltersToURL({
        attribute_type: attributeTypeFilters,
        params: paramsWithOffset,
      })
    );
  };

  const { data: attributeTypes } = useSWR<{ data: string[] }>(
    endpoints.v2_pim_types()
  );

  const {
    data: attributesList,
    error: attributesError,
    mutate,
  } = useSWR(
    constructQuery({
      baseUrl: `${endpoints.v2_tenants_id_pim_attributes(tenant_id!!)}?`,
      query: debouncedSearchQuery,
      attribute_type: attributeTypeFilters,
    })
  );

  const isLoading = !attributesList && !attributesError;

  // Restore state when returning to the page
  useEffect(() => {
    if (location.state) {
      if (location.state.searchQuery) {
        setSearchQuery(location.state.searchQuery);
        setQuery({ q: location.state.searchQuery });
      }
      if (location.state.offset) {
        setOffset(location.state.offset);
      }
      if (location.state.perPage) {
        setPerPage(location.state.perPage);
      }
      if (location.state.attributeTypeFilters) {
        setAttributeTypeFilters(location.state.attributeTypeFilters);
        setQuery((prevQuery) => ({
          ...prevQuery,
          attribute_type: location.state.attributeTypeFilters,
        }));
      }
    }
  }, [location.state, setQuery]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const handleRowClick = (e: MouseEvent) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    history.push({
      pathname: `${adminPath}/pim/attributes/${e.currentTarget.id}`,
      search: params.toString(),
      state: {
        searchQuery,
        offset,
        perPage,
        attributeTypeFilters,
        previousPath: location.pathname,
      },
    });
  };

  const closeCreateSlideOut = () => setCreateSlideOut(false);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  function getObjArrayFromStringArray(strArr: string[]) {
    return strArr?.map((name) => ({ name }));
  }

  const handleAttributeType = ({ values }: { values: ChipType[] }) => {
    const attrTypes = chipsToStringArray(values).map((attr) =>
      attr.split(" ").join("_").toLowerCase()
    );
    setAttributeTypeFilters(attrTypes);
    setQuery({ attribute_type: attrTypes });
    setOffset(0);
  };

  useEffect(() => {
    const handleData = ({ data, pagination }: PaginatedAttributeSchema) => {
      setTableData(
        data.map((attribute) => ({
          id: attribute.id,
          attribute_name: {
            name: attribute.name,
            is_system: attribute.is_system,
            id: attribute.id,
          },
          attribute_display_name: attribute.display_name
            ? t([attribute?.display_name])
            : attribute.name,
          attribute_type: attribute.input_type,
        }))
      );

      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (attributesList) {
      const { data: attributes, pagination } = attributesList;
      handleData({ data: attributes, pagination });
    }
  }, [attributesList, perPage, t]);

  useEffect(() => {
    setQuery({ q: debouncedSearchQuery || undefined });
  }, [setQuery, debouncedSearchQuery]);

  useEffect(() => {
    setAttributeTypesFromURL(
      getObjArrayFromStringArray(
        ((query.attribute_type ?? []) as string[]).map((attributeType) =>
          toTitleCase(attributeType.split("_").join(" "))
        )
      )
    );
  }, [query]);

  return (
    <PageWrapper>
      <AttributesNav tabName="Attributes" />
      <Flex style={{ marginBottom: "24px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Attribute name or Display name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
          />
        </Flex2>
        {hasPermission("modify_attributes") && (
          <Flex1 style={{ textAlign: "right" }}>
            <PrimaryButtonWithPlusIcon
              onClick={() => setCreateSlideOut(true)}
              data-alignright
            >
              {t("Create")}
            </PrimaryButtonWithPlusIcon>
          </Flex1>
        )}
      </Flex>
      <SlideOut closeFlyout={closeCreateSlideOut} show={showCreateSlideOut}>
        <CreateAttribute
          onComplete={closeCreateSlideOut}
          refreshAttributesList={mutate}
        />
      </SlideOut>

      <FilterBy
        setter={handleAttributeType}
        filter="attribute_type"
        options={(attributeTypes?.data ?? []).map((attr_type) => ({
          name: toTitleCase(removeUnderscore(attr_type)),
        }))}
        header={t("Attribute Type")}
        label={t("Attribute Type")}
        preselectedFilters={
          isChipTypeArray(attributeTypesFromURL) ? attributeTypesFromURL : []
        }
      />
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={isLoading}
          error={attributesError}
          data={tableData}
          rowClick={handleRowClick}
          handleSort={handleSort}
          lastChildleftAlign
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};
