import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import type { FunctionComponent } from "react";
import React, { useEffect, useMemo, useState } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { PrimaryButtonWithPlusIcon } from "../../../../components/Buttons/Buttons";
import { CreateNewUser } from "../../../../components/CreateNewUser/CreateNewUser";
import { EditUser } from "../../../../components/EditUser/EditUser";
import { Modal } from "../../../../components/Modal/Modal";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { StatusRight } from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import {
  ContentWrapper,
  PageHeader,
} from "../../../../layout/portalPageLayout";
import type { AgilisUsersPaginatedOutput, User } from "../../../../types/types";
import { useDebounce } from "../../../../util/hooks";
import { useCountriesList } from "../../../../util/Locations";
import { makeUrlWithParams, useStoreState } from "../../../../util/util";
import { getUserTypeForUi } from "../../../../util/util-components";

/* Seller Admin > Customers > Customer > Users */

type UsersTable = {
  name: string;
  user_type: string;
  //created_at: string;
  is_active: boolean;
};

const localStrings = (t: TFunction) => ({
  placeholder: t("Search for users by name"),
  createNew: t("Create New"),
  perPage: t("Per Page"),
});

const PER_PAGE_ITEMS = [10, 20, 50];

/**
 * Renders a list of users for a given tenant.
 */
export const UsersList: FunctionComponent<{
  tenantID: string;
  userType?: "Buyer Admin" | "Distributor Admin";
}> = ({ tenantID, userType }) => {
  const { storefront_id } = useStoreState();
  const [query, setQuery] = useQueryParams({
    userQuery: StringParam,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [perPage] = useState(PER_PAGE_ITEMS[0]);
  const [offset, setOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<UsersTable[]>([]);

  const [userToEdit, setUserToEdit] = useState<User | undefined>();

  const { t } = useTranslation();
  const countries = useCountriesList();

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const columns = useMemo<ColumnDef<UsersTable>[]>(
    () => [
      { accessorKey: "name", header: t("Name") },
      { accessorKey: "user_type", header: t("Account Type") },
      //TODO: these are commented out until the API supports it.
      //{ accessorKey: "created_at", header: "Created date" },
      {
        accessorKey: "is_active",
        header: t("Status"),
        align: "right",
        cell: (cell) => (
          <StatusRight
            color={cell.getValue() === true ? "blue" : "red"}
            text={cell.getValue() === true ? t("Active") : t("Inactive")}
          />
        ),
      },
    ],
    [t]
  );

  const usersQuery = tenantID
    ? makeUrlWithParams(
        endpoints.v1_storefronts_id_tenants_id_users(storefront_id, tenantID),
        {
          offset: offset,
          limit: perPage,
          order_by: "asc",
          q: debouncedSearchQuery || null,
        }
      )
    : null;

  const {
    data: usersResponse,
    error: usersError,
    mutate,
  } = useSWR<AgilisUsersPaginatedOutput, AxiosError>(usersQuery, {
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (usersResponse) {
      const { data: users, pagination } = usersResponse;
      setTableData(
        users
          .filter((user) => user.user_type !== "Primary Contact")
          .map((user: User) => {
            return {
              name: `${user.firstname || ""} ${user.lastname || ""}`,
              user_type: getUserTypeForUi(user.user_type),
              //created_at: user.created_at,
              is_active: user.is_active,
              id: user.id,
            };
          })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    } else {
      setTableData([]);
    }
  }, [usersResponse, setTableData, perPage]);

  const isLoading = !usersResponse && !usersError;

  // Unused code.
  // const changePerPage = (perPage: number) => {
  //   setPerPage(perPage);
  //   if (perPage > offset) {
  //     setOffset(0);
  //   }
  // };

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ userQuery: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ userQuery: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ userQuery: undefined });
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const handleRowClick = (e: React.MouseEvent): void => {
    if (usersResponse) {
      const userId = e.currentTarget.id;
      const user = usersResponse.data.find((user) => user.id === userId);
      setUserToEdit(user);
    } else {
      // Should never happen.
      console.error(new Error("No users data"));
    }
  };

  return (
    <>
      <PageHeader style={{ marginBottom: "24px" }}>
        <SearchBar
          query={searchQuery}
          placeHolder={localStrings(t).placeholder}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        <PrimaryButtonWithPlusIcon onClick={openModal} data-alignright>
          {localStrings(t).createNew}
        </PrimaryButtonWithPlusIcon>
        <Modal closeModal={closeModal} show={showModal}>
          <CreateNewUser
            tenantId={tenantID}
            userTypeChips={[
              { name: t("Admin"), id: "Buyer Admin" },
              { name: t("Distributor"), id: "Distributor Admin" },
              // We currently don't support standard buyer accounts on
              // business edition, but we will in the future.
              // { name: "Standard", id: "Buyer Standard" },
            ]}
            defaultUserType={userType ?? "Buyer Admin"}
            tenantTypeToFetch="Buyer"
            userFormType="customer_distributor"
            onSuccess={() => {
              closeModal();
              mutate();
            }}
          />
        </Modal>
      </PageHeader>
      <ContentWrapper>
        <Table
          columns={columns}
          isLoading={isLoading}
          error={usersError}
          data={tableData}
          rowClick={handleRowClick}
        />
        <Pagination
          pagination={pagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
      {userToEdit && (
        <SlideOut
          show={!!userToEdit}
          closeFlyout={() => setUserToEdit(undefined)}
        >
          <EditUser
            user={userToEdit}
            userTypeChips={
              userType === "Buyer Admin"
                ? [{ name: t("Admin"), id: "Buyer Admin" }]
                : [
                    { name: t("Distributor"), id: "Distributor Admin" },

                    // We currently don't support standard buyer accounts on
                    // business edition, but we will in the future.
                    // { name: "Standard", id: "Buyer Standard" },
                  ]
            }
            onSuccess={() => {
              setUserToEdit(undefined);
              mutate();
            }}
            countries={countries}
          />
        </SlideOut>
      )}
    </>
  );
};
