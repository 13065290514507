import type { ProductSKU, OptionType, CurrencyCode } from "../../types/types";
import type { Row } from "react-table";
import { convertProductSKUToOption, useFormWrapper } from "../../util/util";
import React from "react";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import {
  CurrencyInput,
  makeYupCurrencyTestFn,
} from "../CurrencyInput/CurrencyInput";
import styled from "styled-components/macro";
import { CheckButton, XButton } from "../Buttons/Buttons";
import { TableRowButtonBox } from "./shared";
import type { PriceTierRowData } from "./PriceTiersBigTable";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { strings } from "../../util/strings";
import { positiveIntegerRegex } from "../../util/regexes";
import { useTranslation } from "react-i18next";

const InputTd = styled.td`
  overflow: visible !important;
  height: 88px;
  vertical-align: top;
`;

export type EditableRowValues = {
  product_sku: OptionType<ProductSKU>;
  minimum_sku_quantity: string; // decimal
  minimum_uom_quantity: string; // decimal
  price_per_uom: string; // decimal
};

export const EditableRow = ({
  reactTableRow,
  tableRowsData,
  editRowIndex,
  finishEditingRow,
  cancelEditingRow,
  productSkus,
  currencySymbol,
  currencyCode,
}: {
  reactTableRow: Row<{
    sku: string;
    minimum_sku_quantity: string;
    min_order_qty: string;
    price_per_uom: string;
  }>;
  tableRowsData: PriceTierRowData[];
  editRowIndex: number;
  finishEditingRow: (formValues: EditableRowValues) => void;
  cancelEditingRow: () => void;
  productSkus: ProductSKU[];
  currencySymbol: string;
  currencyCode: CurrencyCode;
}) => {
  const { t } = useTranslation();
  const priceTierData = tableRowsData[editRowIndex].data;

  const formSchema = yup.object().shape({
    product_sku: yup.object().required(strings(t).thisIsARequiredField),
    minimum_sku_quantity: yup
      .string()
      .required(strings(t).thisIsARequiredField)
      // Use a regex to validate because it's a string.
      .matches(positiveIntegerRegex, t("Must be an integer value")),
    minimum_uom_quantity: yup
      .string()
      .required(strings(t).thisIsARequiredField),
    price_per_uom: yup
      .string()
      .test(
        "list_price",
        strings(t).currencyMustBeValid,
        makeYupCurrencyTestFn(currencyCode)
      )
      .required(strings(t).thisIsARequiredField),
  });

  const {
    register,
    control,
    errors,
    formState,
    handleSubmit,
    watch,
    setError,
  } = useFormWrapper<EditableRowValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      product_sku: priceTierData.product_sku
        ? convertProductSKUToOption(priceTierData.product_sku)
        : undefined,
      minimum_sku_quantity: priceTierData.minimum_sku_quantity || "",
      minimum_uom_quantity: priceTierData.minimum_uom_quantity || "",
      price_per_uom: priceTierData.price_per_uom || "",
    },
  });

  const watchedSku = watch("product_sku");
  const watchedMinimumSkuQuantity = watch("minimum_sku_quantity");

  // TODO: call function to calculate?
  const minimum_uom_quantity =
    watchedSku?.value?.package_volume && watchedMinimumSkuQuantity
      ? parseFloat(watchedSku.value.package_volume) *
        parseInt(watchedMinimumSkuQuantity)
      : 0;

  const formatted_minimum_uom_quantity = `${minimum_uom_quantity} ${
    watchedSku?.value?.packaging_unit?.name ?? "--"
  }`;

  const onSubmit = (formValues: EditableRowValues) => {
    const isDuplicateTier = tableRowsData.some((row, index) => {
      return (
        index !== editRowIndex &&
        row.data.product_sku.id === formValues.product_sku.value.id &&
        row.data.minimum_sku_quantity === formValues.minimum_sku_quantity
      );
    });

    if (isDuplicateTier) {
      setError("minimum_sku_quantity", {
        type: "manual",
        message: "This tier already exists",
      });
    } else {
      finishEditingRow(formValues);
    }
  };

  return (
    <tr key={editRowIndex}>
      <InputTd>
        <Controller
          placeholder={"SKU"}
          name={"product_sku"}
          as={SelectBoxV2}
          control={control}
          options={productSkus.map(convertProductSKUToOption)}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
      </InputTd>
      <InputTd>
        <TextField
          label={"Units"}
          name={"minimum_sku_quantity"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="number"
        />
      </InputTd>
      <td>
        {formatted_minimum_uom_quantity}
        <input
          type="hidden"
          name="minimum_uom_quantity"
          ref={register({ required: false })}
          value={minimum_uom_quantity}
        />
      </td>
      <InputTd>
        <CurrencyInput
          label={`${currencySymbol}/UoM`}
          name={"price_per_uom"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="number"
        />
      </InputTd>
      <td>
        <TableRowButtonBox>
          <CheckButton
            onClick={handleSubmit(onSubmit)}
            testid={"finish-editing-price-tier-row"}
          />
        </TableRowButtonBox>
      </td>
      <td>
        <TableRowButtonBox>
          <XButton
            onClick={cancelEditingRow}
            testid={"cancel-editing-price-tier-row"}
          />
        </TableRowButtonBox>
      </td>
    </tr>
  );
};
