import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import type { MouseEvent } from "react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { useAuthContext } from "../../../../components/Auth";
import { PrimaryButtonWithPlusIcon } from "../../../../components/Buttons/Buttons";
import { CreateNewUser } from "../../../../components/CreateNewUser/CreateNewUser";
import { EditUser } from "../../../../components/EditUser/EditUser";
import { Modal } from "../../../../components/Modal/Modal";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { StatusRight } from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import { ErrorHandler } from "../../../../ErrorHandler";
import { ContentWrapper, HeaderBar } from "../../../../layout/portalPageLayout";
import type {
  IPagination,
  User,
  UserType,
  UserTypeChip,
} from "../../../../types/types";
import { useDebounce } from "../../../../util/hooks";
import { getCountryOption } from "../../../../util/location";
import { useCountriesList } from "../../../../util/Locations";
import { makeUrlWithParams, useStoreState } from "../../../../util/util";
import { getUserTypeForUi } from "../../../../util/util-components";

type UsersAPIResponse = {
  data: User[];
  pagination: IPagination;
};

type UsersTable = {
  id: string;
  name: string;
  account_type: string;
  //created_date: string;
  is_active: boolean;
};

/**
 * The "Manage > Users" page.  Used for:
 *   - Seller Admin
 *   - Buyer Admin
 *
 * Use the tenant ID from the user object to show and create users for
 * the correct tenant.  The Seller Admin's tenant is the same as the
 * storefront tenant.
 */
export function ManageUsersList({ user }: { user: User }) {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const { storefront_id, storefront_metadata } = useStoreState();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const {
    roleIsSellerAdmin,
    roleIsBuyerAdmin,
    roleIsSomeKindOfSeller,
    roleIsDistributorAdmin,
  } = useAuthContext();

  const countries = useCountriesList();

  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const [showModal, setShowModal] = useState(false);
  const [showEditSlideOut, setEditSlideOut] = useState(false);

  const { notifyError } = useContext(Notifications);

  const {
    data: usersResponse,
    error: usersError,
    mutate,
  } = useSWR<UsersAPIResponse, AxiosError>(
    makeUrlWithParams(
      endpoints.v1_storefronts_id_tenants_id_users(
        storefront_id,
        user.tenant_id
      ),
      {
        offset: offset,
        limit: perPage,
        q: debouncedSearchQuery || null,
      }
    ),
    { revalidateOnMount: true }
  );

  const isLoading = !usersResponse && !usersError;

  const [tableData, setTableData] = useState<UsersTable[]>([]);
  const tableColumns = React.useMemo<ColumnDef<UsersTable>[]>(
    () => [
      {
        header: t("Name"),
        accessorKey: "name",
      },
      {
        header: roleIsSomeKindOfSeller ? t("Role") : t("Account Type"),
        accessorKey: "account_type",
      },
      {
        header: t("Business Unit"),
        accessorKey: "business_unit",
      },
      {
        header: t("Country"),
        accessorKey: "country",
      },
      {
        header: t("Status"),
        accessorKey: "is_active",
        width: 50,
        minWidth: 50,
        align: "right",
        cell: (cell) => (
          <StatusRight
            color={cell.getValue() ? "green" : "red"}
            text={cell.getValue() ? t("Active") : t("Inactive")}
          />
        ),
      },
    ],
    [t, roleIsSomeKindOfSeller]
  );

  useEffect(() => {
    const handleUsersData = ({ data, pagination }: UsersAPIResponse) => {
      setTableData(
        data
          .filter((user) => user.user_type !== "Primary Contact")
          .map((user) => ({
            id: user.id,
            name: `${user.firstname || ""} ${user.lastname || ""}`,
            account_type: user.role
              ? user.role.name
              : getUserTypeForUi(user.user_type),
            business_unit: user.business_unit,
            country: getCountryOption(countries, user.country)?.label || "",
            is_active: user.is_active,
          }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (usersResponse) {
      const { data: users, pagination } = usersResponse;
      handleUsersData({ data: users, pagination });
    }
  }, [usersResponse, setTableData, perPage, countries]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (e: MouseEvent) => {
    if (usersResponse) {
      setSelectedUser(
        usersResponse.data.find((user: User) => user.id === e.currentTarget.id)
      );
      setEditSlideOut(true);
    } else {
      notifyError(t("There was an error fetching the user"));
    }
  };

  const openCreateUserModal = () => setShowModal(true);
  const closeCreateUserModal = () => setShowModal(false);

  const closeEditSlideOut = () => {
    setEditSlideOut(false);
  };

  const userChipDetails = ((): {
    chips: UserTypeChip[];
    default: UserType;
  } | null => {
    if (roleIsBuyerAdmin) {
      return {
        chips: [{ name: "Admin", id: "Buyer Admin" }],
        default: "Buyer Admin",
      };
    }
    if (roleIsDistributorAdmin) {
      return {
        chips: [{ name: "Distributor", id: "Distributor Admin" }],
        default: "Distributor Admin",
      };
    }
    if (roleIsSellerAdmin) {
      // @Paul when you review this why is the below line here?
      // if (isStarterEdition) {
      return {
        chips: [
          { name: "Admin", id: "Seller Admin" },
          { name: "Standard", id: "Seller Standard" },
        ],
        default: "Seller Admin",
      };
    }
    // Should never happen.
    console.error("Unsupported user type:", user.rbac_role);
    ErrorHandler.report(`Unsupported user type: ${user.rbac_role}`);
    return null;
  })();

  return (
    <>
      <HeaderBar>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search for users by name")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        {userChipDetails && !storefront_metadata.sso_only && (
          <>
            <PrimaryButtonWithPlusIcon
              onClick={openCreateUserModal}
              data-alignright
            >
              {t("Create New")}
            </PrimaryButtonWithPlusIcon>
            <Modal closeModal={closeCreateUserModal} show={showModal}>
              <CreateNewUser
                tenantId={user.tenant_id}
                userTypeChips={userChipDetails.chips}
                defaultUserType={userChipDetails.default}
                userFormType="tenant"
                tenantTypeToFetch="Buyer"
                onSuccess={() => {
                  closeCreateUserModal();
                  mutate();
                }}
              />
            </Modal>
          </>
        )}
      </HeaderBar>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={isLoading}
          error={usersError}
          data={tableData}
          rowClick={handleRowClick}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
        <SlideOut closeFlyout={closeEditSlideOut} show={showEditSlideOut}>
          {selectedUser && userChipDetails && (
            <EditUser
              user={selectedUser}
              userTypeChips={userChipDetails.chips}
              onSuccess={() => {
                closeEditSlideOut();
                // force SWR to revalidate
                mutate();
              }}
              countries={countries}
            />
          )}
        </SlideOut>
      </ContentWrapper>
    </>
  );
}
