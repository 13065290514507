import type { AxiosError } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import type { ColumnDef } from "@tanstack/react-table";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components/macro";
import { useAuthContext } from "../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DestructiveButtonLarge,
  GoBackButtonSmall,
  InvisibleButton,
  PrimaryButtonLarge,
} from "../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import {
  ArchiveIcon,
  EditIcon,
  LoadingIcon,
  RestoreIcon,
} from "../../../../components/Icons/Icons";
import { HeaderLeft, HeaderRight } from "../../../../components/Layout/Layout";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import {
  StatusLeft,
  getProductStatusColor,
  getProductStatusText,
} from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { H3 } from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import {
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import type {
  AssignedProductSchema,
  AssignedUserSchema,
  TeamDetailsSchema,
  TeamPermissionsEnum,
  TeamProductPermissionSchema,
} from "../../../../types/types";
import type { ProductStatusType } from "../../../../types/types.PIM";
import { useRoutePath } from "../../../../util/Routing";
import {
  rowHover,
  toTitleCase,
  useHistoryGoBack,
  useStoreState,
} from "../../../../util/util";
import { SellerAdminEditTeam } from "./SellerAdminEditTeam";
import {
  CheckboxNoMargin,
  HeaderCheckboxContainer,
  SpinnerContainer,
} from "./seller_admin.utils";

const LargeDestructiveButtonWithArchiveIcon = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const theme = useTheme();
  return (
    <DestructiveButtonLarge {...props}>
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <ArchiveIcon width={22} height={22} fill={theme.destructiveTextColor} />
        {props.children}
      </span>
    </DestructiveButtonLarge>
  );
};

const LargePrimaryButtonWithRestoreIcon = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <PrimaryButtonLarge {...props}>
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <RestoreIcon width={22} height={22} />
        {props.children}
      </span>
    </PrimaryButtonLarge>
  );
};

type ProductTableData = AssignedProductSchema & {
  id: string;
} & TeamProductPermissionSchema;

export function SellerAdminTeamDetail() {
  const [userTableData, setUserTableData] = useState<
    {
      id: string;
      user_name: string;
      role: string;
      status: boolean;
    }[]
  >([]);

  const [productTableData, setProductTableData] = useState<ProductTableData[]>(
    []
  );
  const [showEditTeamForm, setShowEditTeamForm] = useState(false);
  const [is_all_view_permissions_enabled, set_is_all_view_permissions_enabled] =
    useState(false);
  const [is_all_edit_permissions_enabled, set_is_all_edit_permissions_enabled] =
    useState(false);
  const [
    is_all_publish_permissions_enabled,
    set_is_all_publish_permissions_enabled,
  ] = useState(false);

  const [product_in_edit, set_product_in_edit] =
    useState<ProductTableData | null>(null);
  const [property_in_edit, set_property_in_edit] =
    useState<keyof TeamProductPermissionSchema | null>(null);
  const [is_all_product_edit_loading, set_is_all_product_edit_loading] =
    useState(false);

  const {
    tenant_id,
    storefront_metadata: { enable_team_product_permissions },
  } = useStoreState();
  const { roleIsSellerAdmin } = useAuthContext();

  const { team_id } = useParams<{ team_id: string }>();

  const { t } = useTranslation();

  const { historyGoBack } = useHistoryGoBack();
  const { adminPath } = useRoutePath();

  const { notifyError, notifySuccess } = useNotifications();

  const {
    data: team,
    mutate: mutateTeam,
    error: teamError,
  } = useSWR<TeamDetailsSchema, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/teams/${team_id}`
  );

  const isLoading = !team && !teamError;

  const update_all_permissions = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      property_in_edit: keyof TeamProductPermissionSchema
    ) => {
      const on_update = async (
        e: React.ChangeEvent<HTMLInputElement>,
        property_in_edit: keyof TeamProductPermissionSchema
      ) => {
        try {
          set_is_all_product_edit_loading(true);
          set_property_in_edit(property_in_edit);
          const value = e.target.checked;
          const promises = team?.team_products?.map(
            ({ product: { product_number } }) => {
              let permission: TeamPermissionsEnum = "no_permission";
              if (property_in_edit === "can_view" && value) {
                permission = "can_view";
              }
              if (property_in_edit === "can_edit") {
                permission = value ? "can_edit" : "can_view";
              }
              if (property_in_edit === "can_publish") {
                permission = value ? "can_publish" : "can_edit";
              }
              return axios.patch(
                endpoints.v2_tenants_tenant_id_pim_teams_team_id_products_product_id_permissions(
                  tenant_id,
                  team_id,
                  product_number
                ),
                {
                  permissions: permission,
                }
              );
            }
          );
          if (promises) {
            await Promise.all(promises);
          }
          await mutateTeam();
          if (property_in_edit === "can_view") {
            set_is_all_view_permissions_enabled(value);
          } else if (property_in_edit === "can_edit") {
            set_is_all_edit_permissions_enabled(value);
          } else {
            set_is_all_publish_permissions_enabled(value);
          }
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not update team permission. Please try again."),
            {
              error,
            }
          );
        } finally {
          set_is_all_product_edit_loading(false);
          set_property_in_edit(null);
        }
      };
      on_update(e, property_in_edit);
    },
    [mutateTeam, notifyError, t, team?.team_products, team_id, tenant_id]
  );

  const update_product_permissions = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      product: ProductTableData,
      property_in_edit: keyof TeamProductPermissionSchema
    ) => {
      const on_update = async (
        e: React.ChangeEvent<HTMLInputElement>,
        product: ProductTableData,
        property_in_edit: keyof TeamProductPermissionSchema
      ) => {
        try {
          set_product_in_edit(product);
          set_property_in_edit(property_in_edit);
          let permission: TeamPermissionsEnum = "no_permission";
          if (property_in_edit === "can_view") {
            permission = e.target.checked ? "can_view" : "no_permission";
          }
          if (property_in_edit === "can_edit") {
            permission = e.target.checked ? "can_edit" : "can_view";
          }
          if (property_in_edit === "can_publish") {
            permission = e.target.checked ? "can_publish" : "can_edit";
          }
          await axios.patch(
            endpoints.v2_tenants_tenant_id_pim_teams_team_id_products_product_id_permissions(
              tenant_id,
              team_id,
              product.product_number
            ),
            {
              permissions: permission,
            }
          );

          await mutateTeam();
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not update team permission. Please try again."),
            {
              error,
            }
          );
        } finally {
          set_product_in_edit(null);
          set_property_in_edit(null);
        }
      };
      on_update(e, product, property_in_edit);
    },
    [mutateTeam, notifyError, t, team_id, tenant_id]
  );

  const userTableColumns = useMemo<ColumnDef<AssignedUserSchema>[]>(
    () => [
      {
        header: t("User Name"),
        accessorKey: "user_name",
      },
      {
        header: t("Role"),
        accessorKey: "role",
        cell: (cell) => <div>{toTitleCase(cell.getValue() as string)}</div>,
      },
      {
        header: t("Status"),
        accessorKey: "status",
        width: 50,
        minWidth: 50,
        align: "left",
        cell: (cell) => (
          <StatusLeft
            color={cell.getValue() ? "green" : "red"}
            text={cell.getValue() ? t("Active") : t("Inactive")}
          />
        ),
      },
    ],
    [t]
  );

  const is_product_property_loading = useCallback(
    (product_number: string, prop: keyof TeamProductPermissionSchema) => {
      return (
        product_in_edit?.product_number === product_number &&
        property_in_edit === prop
      );
    },
    [product_in_edit?.product_number, property_in_edit]
  );

  const is_product_property_update_in_progress = useCallback(
    (product_number: string) =>
      product_in_edit?.product_number === product_number,
    [product_in_edit?.product_number]
  );

  const team_permission_headers = useMemo(
    (): Array<ColumnDef<ProductTableData>> => [
      {
        header: () => (
          <>
            {property_in_edit === "can_view" && is_all_product_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="view_all_checkbox"
                  id="view_all_checkbox_id"
                  checked={is_all_view_permissions_enabled}
                  disabled={is_all_product_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_view")}
                />
                {t("View")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_view",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_product_property_loading(
              cell.row.original.product_number,
              "can_view"
            ) ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="view_checkbox"
                id="view_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_product_property_update_in_progress(
                    cell.row.original.product_number
                  ) || is_all_product_edit_loading
                }
                onChange={(e) =>
                  update_product_permissions(e, cell.row.original, "can_view")
                }
              />
            )}
          </>
        ),
      },
      {
        header: () => (
          <>
            {property_in_edit === "can_edit" && is_all_product_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="edit_all_checkbox"
                  id="edit_all_checkbox_id"
                  checked={is_all_edit_permissions_enabled}
                  disabled={is_all_product_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_edit")}
                />
                {t("Edit")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_edit",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_product_property_loading(
              cell.row.original.product_number,
              "can_edit"
            ) ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="edit_checkbox"
                id="edit_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_product_property_update_in_progress(
                    cell.row.original.product_number
                  ) || is_all_product_edit_loading
                }
                onChange={(e) =>
                  update_product_permissions(e, cell.row.original, "can_edit")
                }
              />
            )}
          </>
        ),
      },
      {
        header: () => (
          <>
            {property_in_edit === "can_publish" &&
            is_all_product_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="publish_all_checkbox"
                  id="publish_all_checkbox_id"
                  checked={is_all_publish_permissions_enabled}
                  disabled={is_all_product_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_publish")}
                />
                {t("Publish/Unpublish")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_publish",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_product_property_loading(
              cell.row.original.product_number,
              "can_publish"
            ) ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="publish_checkbox"
                id="publish_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_product_property_update_in_progress(
                    cell.row.original.product_number
                  ) || is_all_product_edit_loading
                }
                onChange={(e) =>
                  update_product_permissions(
                    e,
                    cell.row.original,
                    "can_publish"
                  )
                }
              />
            )}
          </>
        ),
      },
    ],
    [
      is_all_edit_permissions_enabled,
      is_all_product_edit_loading,
      is_all_publish_permissions_enabled,
      is_all_view_permissions_enabled,
      is_product_property_loading,
      is_product_property_update_in_progress,
      property_in_edit,
      t,
      update_all_permissions,
      update_product_permissions,
    ]
  );

  const productTableColumns = useMemo<ColumnDef<ProductTableData>[]>(
    () => [
      {
        header: t("Product ID"),
        accessorKey: "product_number",
      },
      {
        header: t("Product Name"),
        accessorKey: "product_name",
      },
      {
        header: t("Template"),
        accessorKey: "product_schema.template_name",
      },
      ...(enable_team_product_permissions &&
      team?.status !== "archived" &&
      roleIsSellerAdmin
        ? team_permission_headers
        : []),
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: (cell) => (
          <StatusLeft
            color={getProductStatusColor(
              (cell.getValue() as ProductStatusType) ?? "draft"
            )}
            text={getProductStatusText(
              (cell.getValue() as ProductStatusType) ?? "draft",
              t
            )}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [
      t,
      enable_team_product_permissions,
      team?.status,
      roleIsSellerAdmin,
      team_permission_headers,
    ]
  );

  const theme = useTheme();

  const onArchiveTeam = async () => {
    try {
      await axios.patch(`/v2/tenants/${tenant_id}/pim/teams/${team_id}`, {
        status: "archived",
      });
      notifySuccess(t("Team archived successfully"));
      await mutateTeam();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error archiving the team"),
        {
          error,
        }
      );
    }
  };

  const onRestoreTeam = async () => {
    try {
      await axios.patch(`/v2/tenants/${tenant_id}/pim/teams/${team_id}`, {
        status: "active",
      });
      notifySuccess(t("Team restored successfully"));
      await mutateTeam();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error restoring the team"),
        {
          error,
        }
      );
    }
  };

  const onEditSuccess = async () => {
    setShowEditTeamForm(false);
    await mutateTeam();
  };

  useEffect(() => {
    const handleUsersData = (data: AssignedUserSchema[]) => {
      setUserTableData(
        data.map((user) => ({
          id: user.user_id,
          user_name: `${user.firstname} ${user.lastname}`,
          role: user.role ?? "--", // For at least some users locally this is nullable
          status: user.is_active,
        }))
      );
    };

    const handleProductsData = (data: TeamDetailsSchema["team_products"]) => {
      setProductTableData(
        team?.status === "archived"
          ? []
          : data.map((data) => {
              const { product, can_edit, can_view, can_publish } = data;
              return {
                ...product,
                can_edit,
                can_view,
                can_publish,
                id: product.product_number,
              };
            })
      );
    };
    if (team?.users) {
      handleUsersData(team.users);
    }

    if (team?.team_products) {
      handleProductsData(team.team_products);
    }
  }, [team?.users, team?.team_products, team?.status]);

  useEffect(() => {
    if (team?.team_products) {
      const all_view_permissions_enabled = team.team_products.every(
        ({ can_view }) => can_view
      );
      const all_edit_permissions_enabled = team.team_products.every(
        ({ can_edit }) => can_edit
      );
      const all_publish_permissions_enabled = team.team_products.every(
        ({ can_publish }) => can_publish
      );
      set_is_all_view_permissions_enabled(all_view_permissions_enabled);
      set_is_all_edit_permissions_enabled(all_edit_permissions_enabled);
      set_is_all_publish_permissions_enabled(all_publish_permissions_enabled);
    }
  }, [team?.team_products]);

  return (
    <PageWrapper>
      <InvisibleButton
        onClick={() => historyGoBack(`${adminPath}/organization/teams`)}
      >
        <GoBackButtonSmall text={t("Back")} />
      </InvisibleButton>
      <PageHeader>
        <HeaderLeft>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <PageTitle>{team ? team.team_name : ""}</PageTitle>
          </div>
        </HeaderLeft>
        {team?.status && (
          <HeaderRight>
            <StatusLeft
              color={team.status === "active" ? "green" : "red"}
              text={team.status}
              textStyle={{ fontSize: "13px" }}
              productStatusType="product"
            />
          </HeaderRight>
        )}
      </PageHeader>
      <FullWidthHorizontalSeparator />
      <div style={{ marginBottom: "30px" }}></div>
      <H3>{t("Users")}</H3>
      <Table
        columns={userTableColumns}
        isLoading={isLoading}
        error={teamError}
        data={userTableData}
      />

      <H3 style={{ marginTop: "48px" }}>{t("Assigned Products")}</H3>
      <Table
        columns={productTableColumns}
        isLoading={isLoading}
        error={teamError}
        data={productTableData}
        rowHover={rowHover}
      />
      {roleIsSellerAdmin && (
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            gap: "24px",
          }}
        >
          {team && team.status === "active" && (
            <ButtonWithConfirmDialog
              Button={LargeDestructiveButtonWithArchiveIcon}
              testid={"archive-button"}
              buttonText={t("Archive Team")}
              handleConfirm={onArchiveTeam}
              confirmMessage={t("Are you sure you want to archive this team?")}
            />
          )}
          {team && team.status === "archived" && (
            <ButtonWithConfirmDialog
              Button={LargePrimaryButtonWithRestoreIcon}
              testid={`restore-team`}
              handleConfirm={onRestoreTeam}
              buttonText={t("Restore Team")}
              confirmMessage={t("Are you sure you want to restore this team?")}
            />
          )}

          {team && team.status !== "archived" ? (
            <PrimaryButtonLarge onClick={() => setShowEditTeamForm(true)}>
              <span
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <EditIcon
                  width={20}
                  height={20}
                  fill={theme.primaryButtonTextColor}
                />
                {t("Edit Team")}
              </span>
            </PrimaryButtonLarge>
          ) : (
            <></>
          )}
          <SlideOut
            show={showEditTeamForm}
            closeFlyout={() => setShowEditTeamForm(false)}
          >
            {team?.team_products && team?.users && team?.team_name && (
              <SellerAdminEditTeam
                onSuccess={onEditSuccess}
                onCancel={() => setShowEditTeamForm(false)}
                products={team.team_products.map(({ product, product_id }) => ({
                  ...product,
                  product_id,
                }))}
                users={team.users}
                teamName={team.team_name}
              />
            )}
          </SlideOut>
        </div>
      )}
    </PageWrapper>
  );
}
