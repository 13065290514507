import React from "react";
import Select, { components } from "react-select";
import styled, { useTheme } from "styled-components/macro";
import type { OptionType } from "../../types/types";
import { filterOption } from "../../util/util";

interface ISingleSelectProps {
  options?: OptionType<string>[];
  placeholder?: string;
  isLoading?: boolean;
  handleChange: any;
  name: string;
  styles?: any;
  defaultValue?: any;
}

const { ValueContainer, Placeholder } = components;

const InterDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  margin-top: 6px;
`;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <InterDiv>
        <Placeholder
          {...props}
          isFocused={props.isFocused}
          style={{ marginTop: "-5px" }}
        >
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </InterDiv>
    </ValueContainer>
  );
};

const Menu = (props: any) => (
  <InterDiv>
    <components.Menu {...props}>{props.children}</components.Menu>
  </InterDiv>
);

export function SingleSelectNoController({
  placeholder,
  options,
  handleChange,
  isLoading,
  name,
  styles,
  defaultValue,
}: ISingleSelectProps) {
  const theme = useTheme();
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      options={options}
      isLoading={isLoading}
      onChange={handleChange}
      defaultValue={defaultValue || null}
      name={name}
      filterOption={filterOption}
      components={{
        ValueContainer: CustomValueContainer,
        IndicatorSeparator: () => null,
        Menu: Menu,
      }}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: "#0f1d2b",
        },
      })}
      styles={{
        control: (base) => ({
          ...base,
          height: 50,
          minHeight: 50,
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor:
            state.isFocused || state.isSelected
              ? theme.secondaryButtonBG
              : theme.primaryBG,
          color: theme.primaryTextColor,
          ":hover": {
            backgroundColor: theme.secondaryButtonBG,
          },
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          overflow: "visible",
          marginTop: state.isSelected ? "0px" : "9px",
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? 2 : "40%",
          transition: "top 0.1s, font-size 0.1s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 11,
          marginBottom: 2,
        }),
        ...styles,
      }}
    />
  );
}
