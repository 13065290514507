import type { AxiosError, AxiosResponse } from "axios";
import Axios from "axios";
import { useMemo, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  InvisibleButton,
} from "../../../../../../components/Buttons/Buttons";
import { ConfirmDialog } from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import { CurrencyInput } from "../../../../../../components/CurrencyInput/CurrencyInput";
import { PlusIcon } from "../../../../../../components/Icons/Icons";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../../../endpoints";
import { Form, FormItems } from "../../../../../../layout/FormLayout";
import type { IFeesAndChargesSchema } from "../../../../../../types/types";
import { positiveNumberRegex } from "../../../../../../util/regexes";
import {
  promiseAllSettledLogAndThrow,
  useFormWrapper,
  useStoreState,
} from "../../../../../../util/util";
import {
  ButtonContainer,
  FieldContainer,
  InputRow,
  SaveButtonPrimaryMedium,
} from "../../shared";

const FeesAndChargesFormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const FeesAndChargesNameContainer = styled.div`
  max-width: 150px;
`;

const FeesAndChargesAmountContainer = styled.div`
  max-width: 130px;
`;

const FeesAndChargesDescriptionContainer = styled.div`
  flex-grow: 1;
`;

type FormValues = {
  fees_and_charges_fields: IFeesAndChargesSchema[] | undefined;
};

type ConfirmDeleteState = {
  message: string;
  formTerms: IFeesAndChargesSchema[];
  termsToDelete: IFeesAndChargesSchema[];
};

export const EditFeesAndCharges = ({
  feesAndCharges,
  mutateFeesAndCharges,
  closeEditFeesAndCharges,
}: {
  feesAndCharges: IFeesAndChargesSchema[];
  mutateFeesAndCharges: () => void;
  closeEditFeesAndCharges: () => void;
}) => {
  const theme = useTheme();
  const { notifySuccess, notifyError } = useNotifications();
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [items_to_delete, set_items_to_delete] = useState<
    IFeesAndChargesSchema[]
  >([]);
  const [items_edited, set_items_edited] = useState<
    Record<string, Array<boolean | undefined>>
  >({});

  // When confirmDeleteState is not null, a dialog is shown.
  const [confirmDeleteState, setConfirmDeleteState] =
    useState<ConfirmDeleteState | null>(null);

  const existing_fees_hash = useMemo(
    () =>
      feesAndCharges.reduce<Record<string, IFeesAndChargesSchema>>(
        (hash, item) => {
          hash[item.id] = item;
          return hash;
        },
        {}
      ),
    [feesAndCharges]
  );
  const { register, handleSubmit, errors, control, formState, trigger } =
    useFormWrapper({
      defaultValues: {
        fees_and_charges_fields:
          feesAndCharges.length === 0
            ? [{ name: "", description: "", amount: "", id: "" }]
            : feesAndCharges,
      },
      mode: "onChange",
    });

  const { fields, append, remove } = useFieldArray<
    IFeesAndChargesSchema,
    "key"
  >({
    control,
    name: "fees_and_charges_fields",
    keyName: "key",
  });

  const on_delete_item = (item: IFeesAndChargesSchema, index: number) => {
    set_items_to_delete((prev) => [...prev, item]);
    remove(index);
  };

  const on_prop_change = (
    property: "name" | "description" | "amount",
    value: string | number,
    id: string | undefined
  ) => {
    if (id && !!existing_fees_hash[id]) {
      const existing_value = existing_fees_hash[id][property];
      const prop_hash = { name: 0, description: 1, amount: 2 };
      set_items_edited((prev) => {
        if (!prev[id]) {
          prev[id] = [false, false, false];
        }
        prev[id][prop_hash[property]] =
          String(existing_value) !== String(value);
        if (prev[id].every((is_edited) => is_edited === false)) {
          delete prev[id];
        }
        return { ...prev };
      });
    }
  };

  const onSubmit = async (formValues: FormValues) => {
    const is_valid = await trigger();
    if (is_valid) {
      setSubmitting(true);
      const formTerms = formValues.fees_and_charges_fields;
      const termsToDelete = items_to_delete.filter((item) => !!item.id);
      const terms_to_delete_with_transaction = termsToDelete.filter((item) =>
        Boolean(item.has_transactions)
      );
      if (terms_to_delete_with_transaction.length > 0) {
        const confirmationMessage = t(
          "You are about to delete fees and charges that are currently used in active transactions."
        );
        setConfirmDeleteState({
          message: confirmationMessage,
          formTerms: formTerms ?? [],
          termsToDelete,
        });
      } else {
        await continueSubmitting(formTerms ?? [], termsToDelete);
      }
    }
  };

  const continueSubmitting = async (
    form_terms: IFeesAndChargesSchema[],
    terms_to_delete: IFeesAndChargesSchema[]
  ) => {
    try {
      const delete_promises = terms_to_delete.map((term) => {
        // Term was deleted from the form -> delete.
        return Axios.delete(
          endpoints.v2_storefronts_storefront_id_fees_id(storefront_id, term.id)
        );
      });

      const terms_edited = form_terms.filter(
        (term) =>
          !!term.id && !!existing_fees_hash[term.id] && !!items_edited[term.id]
      );
      const patch_promises = terms_edited.reduce(
        (promises: Promise<AxiosResponse<any>>[], form_term) => {
          const { name, description, amount } = form_term;
          return [
            ...promises,
            Axios.patch(
              endpoints.v2_storefronts_storefront_id_fees_id(
                storefront_id,
                form_term.id
              ),
              { name, description, amount }
            ),
          ];
        },
        []
      );

      // DELETE requests have to happen before POST requests, to handle the case
      // where the term was deleted and then re-introduced (new term with same
      // name) in the same editing pass. This is because on the backend if the
      // user creates a new term with the same name as a deleted term,
      // the deleted term is re-used (un-deleted, and description updated) that
      // way there is consistency in the IDs of the terms if they are deleted
      // and re-created.
      await promiseAllSettledLogAndThrow(delete_promises);

      const post_promises = form_terms.reduce(
        (promises: Promise<AxiosResponse<any>>[], form_term) => {
          if (!form_term.id) {
            const { name, description, amount } = form_term;
            return [
              ...promises,
              Axios.post(
                endpoints.v2_storefronts_storefront_id_fees(storefront_id),
                { name, description, amount }
              ),
            ];
          }
          return promises;
        },
        []
      );
      await promiseAllSettledLogAndThrow(patch_promises);
      await promiseAllSettledLogAndThrow(post_promises);

      notifySuccess(t("Changes saved"));
      mutateFeesAndCharges();
      closeEditFeesAndCharges();
      setSubmitting(false);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error saving the changes"),
        {
          error,
        }
      );
      setSubmitting(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItems style={{ maxWidth: "unset" }}>
          {fields.map((field, index) => {
            return (
              <InputRow key={field.key || "formRow" + index}>
                <FieldContainer>
                  <FeesAndChargesFormRow>
                    <FeesAndChargesNameContainer>
                      <Controller
                        control={control}
                        name={`fees_and_charges_fields[${index}].name`}
                        defaultValue={field.name}
                        render={({ onChange }) => (
                          <TextField
                            label={`Name`}
                            name={`fees_and_charges_fields[${index}].name`}
                            defaultValue={field.name}
                            theref={register({
                              required: true,
                            })}
                            errors={{
                              [`fees_and_charges_fields[${index}].name`]:
                                errors?.fees_and_charges_fields?.[index]
                                  ?.name ?? undefined,
                            }}
                            onChange={(event) => {
                              const value = event.target.value;
                              on_prop_change("name", value, field.id);
                              onChange(value);
                            }}
                            formState={formState}
                            type={"text"}
                          />
                        )}
                      />
                    </FeesAndChargesNameContainer>
                    <FeesAndChargesAmountContainer>
                      <Controller
                        control={control}
                        name={`fees_and_charges_fields[${index}].amount`}
                        defaultValue={field.amount}
                        render={({ onChange }) => (
                          <CurrencyInput
                            name={`fees_and_charges_fields[${index}].amount`}
                            label={`Amount ($)`}
                            theref={register({
                              required: true,
                              pattern: {
                                value: positiveNumberRegex,
                                message: t("Must be a valid numeric value"),
                              },
                            })}
                            onChange={(event) => {
                              const value = event.target.value;
                              on_prop_change("amount", value, field.id);
                              onChange(value);
                            }}
                            formState={formState}
                            errors={{
                              [`fees_and_charges_fields[${index}].amount`]:
                                errors?.fees_and_charges_fields?.[index]
                                  ?.amount ?? undefined,
                            }}
                            type="number"
                            defaultValue={field.amount}
                            testid={`fees_and_charges_fields-${index}-amount`}
                            error_bottom_position={
                              errors?.fees_and_charges_fields?.[index]?.amount
                                ?.message === t("Must be a valid numeric value")
                                ? "-22px"
                                : undefined
                            }
                          />
                        )}
                      />
                    </FeesAndChargesAmountContainer>
                    <FeesAndChargesDescriptionContainer>
                      <Controller
                        control={control}
                        name={`fees_and_charges_fields[${index}].description`}
                        defaultValue={field.description}
                        render={({ onChange }) => (
                          <TextField
                            label={`Description`}
                            name={`fees_and_charges_fields[${index}].description`}
                            defaultValue={field.description}
                            theref={register({
                              required: true,
                            })}
                            errors={{
                              [`fees_and_charges_fields[${index}].description`]:
                                errors?.fees_and_charges_fields?.[index]
                                  ?.description ?? undefined,
                            }}
                            onChange={(event) => {
                              const value = event.target.value;
                              on_prop_change("description", value, field.id);
                              onChange(value);
                            }}
                            formState={formState}
                            type={"text"}
                          />
                        )}
                      />
                    </FeesAndChargesDescriptionContainer>
                    <input
                      name={`fees_and_charges_fields[${index}].id`}
                      defaultValue={field.id}
                      ref={register({ required: false })}
                      type={"hidden"}
                    />
                    <input
                      name={`fees_and_charges_fields[${index}].has_transactions`}
                      defaultValue={String(field.has_transactions ?? false)}
                      ref={register({ required: false })}
                      type={"hidden"}
                    />
                  </FeesAndChargesFormRow>
                </FieldContainer>
                <ButtonContainer>
                  <ButtonWithConfirmDialog
                    Button={DeleteButton}
                    confirmMessage={
                      "Are you sure you want to remove this item?"
                    }
                    handleConfirm={() =>
                      on_delete_item(field as IFeesAndChargesSchema, index)
                    }
                    type="button"
                    testid={`delete-fees-and-charges-field-${index}`}
                  />
                </ButtonContainer>
                {index === fields.length - 1 && (
                  <ButtonContainer>
                    <InvisibleButton
                      type="button"
                      onClick={() => {
                        append({
                          name: "",
                          description: "",
                          id: "",
                          amount: "",
                        });
                      }}
                    >
                      <PlusIcon
                        width={16}
                        height={16}
                        fill={theme.primaryIconColor}
                      />
                    </InvisibleButton>
                  </ButtonContainer>
                )}
              </InputRow>
            );
          })}
        </FormItems>
        <SaveButtonPrimaryMedium type="submit" loading={submitting}>
          {t("Save your changes")}
        </SaveButtonPrimaryMedium>
      </Form>
      {confirmDeleteState && (
        <ConfirmDialog
          show={!!confirmDeleteState}
          closeDialog={() => {
            setSubmitting(false);
            setConfirmDeleteState(null);
          }}
          confirmMessage={confirmDeleteState?.message || ""}
          handleConfirm={() => {
            continueSubmitting(
              confirmDeleteState.formTerms,
              confirmDeleteState.termsToDelete
            );
            setConfirmDeleteState(null);
          }}
        />
      )}
    </>
  );
};
