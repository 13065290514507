import React, { useState, useContext } from "react";
import { FormItems, Form } from "../../../../../../layout/FormLayout";
import { useFormWrapper } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import type { StorefrontFormData } from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { SaveButtonPrimaryMedium } from "../../shared";
import { useTranslation } from "react-i18next";
import { ButtonWithConfirmDialog } from "../../../../../../components/Buttons/Buttons";
import { RichEditor } from "../../../../../../components/RichEditor/RichEditor";
interface FormValues {
  registration: string;
  contact_us: string;
}

export const EditPageDescription = ({
  registration,
  contact_us,
  formData,
  closeEditPageDescription,
  mutatePageDescription,
}: {
  registration?: string;
  contact_us?: string;
  formData?: {
    Contact: {
      Description: string;
    };
    Register: {
      Description: string;
    };
  };
  closeEditPageDescription: () => void;
  mutatePageDescription: (
    data: StorefrontFormData,
    shouldRevalidate: boolean
  ) => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storeState } = useContext(Store);
  const { storefront_id } = storeState;
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const registrationDescription = formData?.Register
    ? registration
    : t(
        "Register to access technical product documentation, such as TDS and SDS, or to request samples and quotes. Please provide your professional details, including the company name and address, in the form below. A member of our team will reach out to you shortly to confirm your registration."
      );

  const contactDescription = formData?.Contact
    ? contact_us
    : t(
        "Looking for something specific? We'd be happy to help. Fill in the form below and a member of our team will get back to you."
      );

  const { register, handleSubmit, formState, watch, setValue, errors } =
    useFormWrapper({
      defaultValues: {
        registration: registrationDescription,
        contact_us: contactDescription,
      },
    });

  const watchRegister = watch("registration");
  const watchContact = watch("contact_us");

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const data = {
        data: [
          {
            name: "Register",
            label: t("Description"),
            text: formValues.registration,
          },
          {
            name: "Contact",
            label: t("Description"),
            text: formValues.contact_us,
          },
        ],
      };

      await Axios.patch<StorefrontFormData>(
        endpoints.v1_storefronts_id_form_data(storefront_id, "en"),
        data
      );

      const { data: updatedFormData } = await Axios.get(
        endpoints.v1_storefronts_id_form_data(storefront_id, "en")
      );

      notifySuccess(t("Changes saved"));
      setSubmitting(false);
      mutatePageDescription(updatedFormData, false);
      closeEditPageDescription();
    } catch (error) {
      notifyError(t("There was an error saving the changes"), { error });
      setSubmitting(false);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormItems>
        <RichEditor
          value={registrationDescription}
          name={"registration"}
          label={t("Registration Form Description")}
          placeholder={t("Registration Form Description")}
          useFormMethods={{ register, formState, setValue, errors, watch }}
        />
        <RichEditor
          value={contactDescription}
          name={"contact_us"}
          label={t("Contact Us Form Description")}
          placeholder={t("Contact Us Form Description")}
          useFormMethods={{ register, formState, setValue, errors, watch }}
        />
      </FormItems>
      {(!watchRegister || !watchContact) && (
        <ButtonWithConfirmDialog
          Button={SaveButtonPrimaryMedium}
          buttonText={t("Save your changes")}
          confirmMessage={
            t("There is no description available for ") +
            (!watchRegister ? t("Registration") : "") +
            (!watchContact && !watchRegister ? t(" and ") : "") +
            (!watchContact ? t("Contact Us") : "") +
            t(". Do you want to proceed?")
          }
          handleConfirm={handleSubmit(onSubmit)}
          type="button"
          testid={`submit-form-changes`}
        />
      )}
      {watchContact && watchRegister && (
        <SaveButtonPrimaryMedium loading={submitting}>
          {t("Save your changes")}
        </SaveButtonPrimaryMedium>
      )}
    </Form>
  );
};
