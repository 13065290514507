import { useEffect, useState } from "react";
import type { OptionType, WithPagination } from "../../types/types";
import { CreatableSelectBox } from "../CreateableSelectBox/CreatableSelectBox";
import type { SelectBoxProps } from "../SelectBoxV2/SelectBoxV2";
import { useDebounce } from "../../util/hooks";
import { useSWRInfinite } from "swr";
import type { InputActionMeta } from "react-select";
import type { AxiosError } from "axios";
import { useNotifyErrorOnce } from "../Notifications/useNotifyErrorOnce";
import { useTranslation } from "react-i18next";

function constructQuery<ResponseType>({
  baseUrl,
  existingParams,
  query,
  previousPageData,
  index,
}: {
  baseUrl: string;
  existingParams?: URLSearchParams;
  query: string;
  previousPageData: WithPagination<{ data: ResponseType[] }> | null;
  index: number;
}) {
  const pageSize = 20;
  if (previousPageData && previousPageData.data.length === 0) {
    return null;
  }
  const offset =
    index === 0 ? 0 : (previousPageData?.pagination.offset ?? 0) + pageSize;
  const params = new URLSearchParams();
  params.append("offset", `${offset}`);
  params.append("limit", `${pageSize}`);
  if (existingParams) {
    for (const [key, value] of existingParams.entries()) {
      params.append(key, value);
    }
  }
  if (query) {
    params.append("q", query);
  }
  return `${baseUrl}?${params}`;
}

export const SearchSelectInfiniteScroll = <
  ResponseType extends {},
  OptionValue extends string | {}
>(
  props: {
    baseUrl: string;
    getOptions: (response: ResponseType[]) => OptionType<OptionValue>[];
    params?: URLSearchParams;
    normalPlaceholder?: boolean;
  } & Omit<SelectBoxProps, "options">
) => {
  const {
    baseUrl,
    getOptions,
    params: existingParams,
    ...selectBoxProps
  } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState<OptionType<OptionValue>[]>([]);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);

  const {
    data,
    error: dataError,
    setSize,
  } = useSWRInfinite<WithPagination<{ data: ResponseType[] }>>(
    (index, previousPageData) =>
      constructQuery({
        baseUrl,
        existingParams,
        query: debouncedSearchQuery,
        previousPageData,
        index,
      })
  );

  const handleInputChange = (query: string, actionMeta: InputActionMeta) => {
    switch (actionMeta.action) {
      case "menu-close":
        setSearchQuery("");
        return;
      case "input-change":
        setSearchQuery(query);
        return;
    }
    setSize(1);
  };

  useNotifyErrorOnce(
    (dataError as AxiosError)?.response?.data?.message ??
      t("Could not load search options. Please try again later."),
    dataError
  );

  const isLoading = !data && !dataError;

  useEffect(() => {
    if (data) {
      setOptions(data.flatMap(({ data }) => getOptions(data)));
    }
  }, [data, getOptions]);

  return (
    <CreatableSelectBox
      {...(selectBoxProps as SelectBoxProps)}
      key={props.key}
      isSearchBar
      normalPlaceholder={props.normalPlaceholder}
      isClearable={selectBoxProps.isClearable ?? false}
      options={options}
      onMenuScrollToBottom={() => setSize((size) => size + 1)}
      onInputChange={handleInputChange}
      isLoading={isLoading}
      filterOption={null}
      loadingMessage={() => t("Please wait...")}
    />
  );
};
