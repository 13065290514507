import type { ColumnDef, Getter } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import { DropDown } from "../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../components/Layout/Layout";
import { Pagination } from "../../../components/Pagination/Pagination";
import {
  StatusLeft,
  getProductStatusColor,
  getProductStatusText,
} from "../../../components/Status/Status";
import { Table } from "../../../components/Table/Table";
import { ContentWrapper } from "../../../layout/portalPageLayout";
import type {
  PIMProductBasePaginagedOutput,
  ProductStatusType,
} from "../../../types/types.PIM";
import { useRoutePath } from "../../../util/Routing";
import { defaultHandleSort, formatDate } from "../../../util/util";
import { TotalProducts } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList";
import type { BuyerTableProduct } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";

interface IProductsTableViewList {
  productsResponse?: PIMProductBasePaginagedOutput;
  productsError: AxiosError<any>;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  sortingRules: { sortBy?: string; orderBy: "asc" | "desc" };
  setSortingRules: Dispatch<
    SetStateAction<IProductsTableViewList["sortingRules"]>
  >;
}

export const ProductsTableViewList = ({
  productsResponse,
  productsError,
  offset,
  setOffset,
  perPage,
  setPerPage,
  sortingRules,
  setSortingRules,
}: IProductsTableViewList) => {
  const [tableData, setTableData] = useState<BuyerTableProduct[]>([]);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
    totalCount: 0,
  });

  const { storePath } = useRoutePath();
  const { t } = useTranslation();
  const perPageItems = [10, 20, 50];

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const isLoading = !productsResponse && !productsError;

  const tableColumns = useMemo<ColumnDef<BuyerTableProduct>[]>(
    () => [
      {
        header: t("Product ID"),
        accessorKey: "externalId",
        enableSorting: false,
      },
      {
        header: t("Product Name"),
        accessorKey: "name",
      },
      {
        header: t("Chemical Name"),
        accessorKey: "chemical_name",
      },
      {
        header: t("Last Modified"),
        accessorKey: "modified_at",
        cell: ({
          getValue,
        }: {
          getValue: Getter<{ date: string; id: string }>;
        }) => <span>{getValue().date}</span>,
      },
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: ({ getValue }: { getValue: Getter<ProductStatusType> }) => (
          <StatusLeft
            color={getProductStatusColor(getValue() ?? "draft")}
            text={getProductStatusText(getValue() ?? "draft", t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: PIMProductBasePaginagedOutput) => {
      setTableData(
        data.map(
          ({
            id,
            slug,
            name,
            last_modified_full: { modified_at },
            product_number,
            chemical_name,
            primary_staged_product_id,
            status,
          }) => ({
            id: product_number ?? primary_staged_product_id ?? id,
            externalId: product_number ?? "--",
            name: name ?? "--",
            chemical_name: chemical_name ?? "--",
            modified_at: {
              date: formatDate(modified_at),
              id,
            },
            status: status ?? "draft",
            rowURL: `${storePath}/product/${slug}`,
          })
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
        totalCount: pagination.total,
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, storePath, perPage]);

  return (
    <>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
        <TotalProducts>
          {tablePagination.totalCount} {t("products")}
        </TotalProducts>
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={productsError}
          handleSort={handleSort}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
};
