import type {
  QuoteRequestItem,
  QuoteRequest,
  IPriceTierPaginatedOutput,
  ProductSKU,
  OptionType,
  MethodsOfUseForm,
} from "../../types/types";
import type { PIMProduct } from "../../types/types.PIM";
import { Controller } from "react-hook-form";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import React, { useContext, useEffect, useMemo } from "react";
import { formatPrice } from "../../util/util-components";
import { DeleteButton, EditButton, TextButton } from "../Buttons/Buttons";
import {
  calculatePriceForQuantity,
  calculatePriceTiersPricePerUom,
} from "../../util/QuotesAndOrders";
import { Notifications } from "../Notifications/NotificationsContext";
import type {
  CustomSkuState,
  EditPriceTiersData,
} from "../../pages/SharedPages/SellerQuoteDetailPage/SellerQuoteDetailPageContent";
import {
  convertProductSKUToOption,
  makeUrlWithParams,
  useCurrencySymbol,
  useStoreState,
} from "../../util/util";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import {
  calculateTotalQuantity,
  formatTotalQuantityString,
} from "../quoteCart/BuyerQuoteItemForm";
import type { CustomSkuData } from "../../pages/SharedPages/SellerQuoteDetailPage/AddCustomSkuForm";
import { Row } from "../Layout/Layout";
import { TransactionItem } from "./TransactionItem";
import { TransactionItemTopLeft } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlockRequestedDetails } from "./TransactionItemInfoBlockRequestedDetails";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import {
  makeNumberOfUnitsLabel,
  makePricePerUnitString,
  makeSkuLabel,
} from "./TransactionItemUtils";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useProductSkuListPrice } from "../../util/SkuUtils";

// A record mapping an item ID to details about that item, like its total price
// as a number, or null if there was an issue calculating the total.
export type ItemDetailRecord = Record<string, ItemDetail>;

type ItemDetail = {
  totalPrice: number | null;
  hasPriceTiers: boolean;
};

const BottomRightContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const InputContainer = styled.div`
  flex: 0.25;
`;

const InputContainerPPU = styled.div`
  flex: 0.5;
`;

const InputLinkContainer = styled.div`
  margin-top: 6px;
  min-height: 20px;
`;

/**
 * Quote item card to display items to the seller when they are responding to
 * a quote request.
 */
export const QuoteItemCardSellerResponding = ({
  quote,
  item,
  index,
  editPriceTiers,
  methodsOfUseForm,
  itemDetail,
  propagateItemDetails,
  deliveryTermId,
  paymentTermId,
  inputIdSku,
  inputIdCustomSku,
  inputIdNumberOfUnits,
  inputIdPricePerUnit,
  setCustomSkuState,
  deleteCustomSku,
  customSkuFromSeller,
}: {
  quote: QuoteRequest;
  item: QuoteRequestItem;
  index: number;
  editPriceTiers: (editPriceTiersData: EditPriceTiersData) => void;
  methodsOfUseForm: MethodsOfUseForm;
  itemDetail: ItemDetail;
  propagateItemDetails: (itemDetailRecord: ItemDetailRecord) => void;
  deliveryTermId: string;
  paymentTermId: string;
  inputIdSku: string;
  inputIdCustomSku: string;
  inputIdNumberOfUnits: string;
  inputIdPricePerUnit: string;
  setCustomSkuState: (customSkuState: CustomSkuState) => void;
  deleteCustomSku: () => void;
  customSkuFromSeller?: CustomSkuData;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();

  const currencySymbol = useCurrencySymbol(item.currency);

  const { register, errors, formState, watch, control, setValue } =
    methodsOfUseForm;

  const skuOption: OptionType<ProductSKU> | undefined = watch(inputIdSku);
  const numberOfUnits: string = watch(inputIdNumberOfUnits);
  const pricePerUnit: string = watch(inputIdPricePerUnit);

  const customSkuOptions = useMemo(
    () =>
      customSkuFromSeller
        ? [
            {
              label: `${customSkuFromSeller.packaging_type?.name ?? ""} (${
                customSkuFromSeller.package_volume
              } ${customSkuFromSeller.packaging_unit?.name ?? ""})`,
              value: customSkuFromSeller,
            },
          ]
        : [],
    [customSkuFromSeller]
  );

  useEffect(() => {
    // Keep the disabled custom SKU input up to date if the custom SKU changes.
    setValue(inputIdCustomSku, customSkuOptions[0]);
  }, [customSkuOptions, inputIdCustomSku, setValue]);

  const {
    data: priceTiersData,
    error: priceTiersError,
    mutate: mutatePriceTiers,
  } = useSWR<IPriceTierPaginatedOutput>(
    // You need a SKU to check for price tiers.
    skuOption && deliveryTermId && paymentTermId
      ? makeUrlWithParams(endpoints.v1_priceTiers(), {
          seller_id: quote.seller_id,
          buyer_id: quote.buyer_id,
          destination_id: quote.shipping_address.id,
          product_sku_id: skuOption.value.id,
          delivery_term_id: deliveryTermId,
          payment_term_id: paymentTermId,
          currency: item.currency,
        })
      : null,
    {
      onError: (error) => {
        notifyError(t("There was an error checking for price tiers"), {
          error,
        });
      },
    }
  );

  const { list_prices_as_price_tiers, list_prices_exist } =
    useProductSkuListPrice(item.product_id, skuOption?.value?.number);

  const fetchingPriceTiers =
    !deliveryTermId || !paymentTermId
      ? false
      : skuOption && !priceTiersData && !priceTiersError;

  // Fetch the product to get the product SKUs.
  const { data: product } = useSWR<PIMProduct>(
    item.product_id
      ? endpoints.v2_storefronts_id_pim_products_id(
          storefront_id,
          item.product_id
        )
      : null
  );

  const addCustomSku = () => {
    // `packaging_type` should always be defined for a "custom_sku".
    // But this check is needed for type checker happiness.
    if (item?.requested_sku?.packaging_type) {
      setCustomSkuState({
        itemId: item.id,
        customSkuData: {
          erp_system_id: item?.requested_sku?.erp_system_id ?? "",
          packaging_type: item?.requested_sku?.packaging_type ?? null,
          packaging_unit: item?.requested_sku?.packaging_unit ?? null,
          package_volume: parseFloat(item?.requested_sku?.package_volume ?? ""),
          package_description: item?.requested_sku?.package_description ?? "",
        },
      });
    }
  };

  const editCustomSku = () => {
    // `packaging_type` should always be defined for a "custom_sku".
    // But this check is needed for type checker happiness.
    // customSkuFromSeller should also always be defined here.
    if (item?.requested_sku?.packaging_type && customSkuFromSeller) {
      setCustomSkuState({
        itemId: item.id,
        customSkuData: customSkuFromSeller,
      });
    }
  };

  const priceTiers = priceTiersData?.data;
  const itemHasPriceTiers = priceTiers && priceTiers.length > 0;

  const priceTiersPricePerUom =
    itemHasPriceTiers && priceTiers && numberOfUnits
      ? calculatePriceTiersPricePerUom(priceTiers, parseFloat(numberOfUnits))
      : null;

  const list_prices_price_per_uom =
    list_prices_exist && numberOfUnits
      ? calculatePriceTiersPricePerUom(
          list_prices_as_price_tiers,
          parseFloat(numberOfUnits)
        )
      : null;

  const sku = skuOption?.value || customSkuFromSeller;

  const totalQuantity = calculateTotalQuantity(
    sku?.package_volume,
    numberOfUnits
  );
  const totalQuantityString =
    totalQuantity === null
      ? "--"
      : formatTotalQuantityString(sku?.packaging_unit?.name, totalQuantity);

  const newTotalItemPrice = (() => {
    if (fetchingPriceTiers) {
      return null;
    }
    if (itemHasPriceTiers && priceTiersPricePerUom && totalQuantity) {
      return calculatePriceForQuantity(priceTiersPricePerUom, totalQuantity);
    }
    if (list_prices_exist && list_prices_price_per_uom && totalQuantity) {
      return calculatePriceForQuantity(
        list_prices_price_per_uom,
        totalQuantity
      );
    }
    if (totalQuantity) {
      // Calculate based on user input.
      return calculatePriceForQuantity(pricePerUnit, totalQuantity);
    }
    return null;
  })();

  useEffect(() => {
    // Keep the input in line with the price tiers price per unit. Or clear the
    // input if there are price tiers but no price per unit yet (e.g. due to
    // number of units input).
    if (itemHasPriceTiers) {
      setValue(inputIdPricePerUnit, priceTiersPricePerUom || "");
    } else if (list_prices_exist) {
      setValue(inputIdPricePerUnit, list_prices_price_per_uom || "");
    }
  }, [
    itemHasPriceTiers,
    priceTiersPricePerUom,
    inputIdPricePerUnit,
    setValue,
    list_prices_exist,
    list_prices_price_per_uom,
  ]);

  // Just to keep a dependency array happy.
  const itemDetailTotalPrice = itemDetail?.totalPrice;
  const itemDetailHasPriceTiers = itemDetail?.hasPriceTiers;

  useEffect(() => {
    // This useEffect handles reporting the new total item price to the parent
    // component when the total item price changes, either because of a change
    // in price tiers or a change in user input. This way the calculation
    // happens in one place and is propagated out from there. We also report
    // whether price tiers are involved.
    const newHasPriceTiers = !!(itemHasPriceTiers && priceTiersPricePerUom);
    if (
      itemDetailTotalPrice !== newTotalItemPrice ||
      itemDetailHasPriceTiers !== newHasPriceTiers
    ) {
      propagateItemDetails({
        [item.id]: {
          totalPrice: newTotalItemPrice,
          hasPriceTiers: newHasPriceTiers,
        },
      });
    }
  }, [
    propagateItemDetails,
    item.id,
    itemDetailTotalPrice,
    newTotalItemPrice,
    itemDetailHasPriceTiers,
    itemHasPriceTiers,
    priceTiersPricePerUom,
  ]);

  const topRightCol1 = (
    <TransactionItemInfoBlock
      main={totalQuantityString}
      lines={[makeSkuLabel(sku), makeNumberOfUnitsLabel(numberOfUnits, t)]}
    />
  );

  const topRightCol2 = (
    <TransactionItemInfoBlock
      alignRight={true}
      main={
        newTotalItemPrice ? formatPrice(newTotalItemPrice, item.currency) : "--"
      }
      lines={[
        makePricePerUnitString(
          itemHasPriceTiers ? String(priceTiersPricePerUom) : pricePerUnit,
          item.currency,
          sku?.packaging_unit?.name
        ),
      ]}
    />
  );

  const skuInput = (
    <InputContainer>
      {!customSkuFromSeller && (
        <Controller
          as={SelectBoxV2}
          control={control}
          name={inputIdSku}
          placeholder={"SKU"}
          options={product?.product_skus.reduce<OptionType<ProductSKU>[]>(
            (acc, sku) => {
              if (!sku.is_sample) {
                acc.push(convertProductSKUToOption(sku));
              }
              return acc;
            },
            []
          )}
          defaultValue={
            item.sku && item.sku.kind === "Product SKU"
              ? convertProductSKUToOption(item.sku)
              : undefined
          }
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
      )}
      {customSkuFromSeller && (
        <Controller
          isDisabled={true}
          as={SelectBoxV2}
          control={control}
          name={inputIdCustomSku}
          placeholder={t("Custom SKU")}
          options={customSkuOptions}
          defaultValue={customSkuOptions[0]}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
      )}
      <InputLinkContainer>
        {item?.requested_sku?.kind === "Buyer SKU" &&
          !customSkuFromSeller &&
          item.product_id && (
            <TextButton type="button" onClick={addCustomSku}>
              {t("Add Custom SKU")}
            </TextButton>
          )}
        {item?.requested_sku?.kind === "Buyer SKU" && customSkuFromSeller && (
          <Row style={{ justifyContent: "flex-end" }}>
            <EditButton
              onClick={editCustomSku}
              testid={`edit-custom-sku-button-${item.id}`}
              title={t("Edit Custom SKU")}
              type="button"
            />
            {/* TODO: Do we want to show a confirmation dialog here? */}
            <DeleteButton
              onClick={deleteCustomSku}
              testid={`delete-custom-sku-button-${item.id}`}
              title={t("Remove Custom SKU")}
              type="button"
            />
          </Row>
        )}
      </InputLinkContainer>
    </InputContainer>
  );

  const numberOfUnitsInput = (
    <InputContainer>
      <TextField
        name={inputIdNumberOfUnits}
        label={t("No. of Units")}
        defaultValue={item.no_of_units ?? undefined}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        min={1}
        type="number"
      />
    </InputContainer>
  );

  const pricePerUnitInput = (
    <InputContainerPPU>
      <CurrencyInput
        name={inputIdPricePerUnit}
        label={`Price (${currencySymbol}/UoM)`}
        defaultValue={item.price_per_unit ?? undefined}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        testid={`price-per-unit-input-${index}`}
        disabled={itemHasPriceTiers}
        type="number"
      />
      <InputLinkContainer>
        {!fetchingPriceTiers && skuOption && (
          <TextButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              const sku = skuOption.value;
              if (sku) {
                editPriceTiers({
                  priceTiers: priceTiers ?? [],
                  quoteItem: item,
                  mutatePriceTiers,
                  sku,
                });
              }
            }}
          >
            {itemHasPriceTiers ? t("Edit Price Tiers") : t("Offer Price Tiers")}
          </TextButton>
        )}
      </InputLinkContainer>
    </InputContainerPPU>
  );

  const bottomRight = (
    <BottomRightContainer>
      {skuInput}
      {numberOfUnitsInput}
      {pricePerUnitInput}
    </BottomRightContainer>
  );

  return (
    <TransactionItem
      topLeft={<TransactionItemTopLeft item={item} index={index} />}
      topRightCol1={topRightCol1}
      topRightCol2={topRightCol2}
      bottomLeftCol1={<TransactionItemInfoBlockRequestedDetails item={item} />}
      bottomRightAlignTop={bottomRight}
      requestType="quote"
      requestId={quote.id}
      itemId={item.id}
      productId={item.product_id}
      buyerId={quote.buyer_id}
    />
  );
};
