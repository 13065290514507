import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Button,
  ButtonWithConfirmDialog,
  DestructiveButton,
  DestructiveButtonLarge,
  GoBackButtonSmall,
  InvisibleButton,
  PrimaryButtonFitContainer,
  PrimaryButtonLarge,
  PrimaryButtonMedium,
  PrimaryButtonWithPlusIcon,
  SecondaryButtonFitContainer,
} from "../../../../../components/Buttons/Buttons";
import {
  BorderCard,
  PageWrapper,
  TwoColumGrid,
} from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import { useRoutePath } from "../../../../../util/Routing";
import { useStoreState } from "../../../../../util/util";
import { TemplatesNav } from "./TemplatesNav";
import type {
  AttributeTemplateSchema,
  TemplateTabItem,
  TemplateViewConfigurstions,
  TemplateViewSchema,
} from "../../../../../types/types.PIM";
import useSWR from "swr";
import type { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { TemplateViewCard } from "./TemplateViewCard";
import { ButtonGroup } from "../../../../../components/EditableTitle/EditableTitle";
import { useForm } from "react-hook-form";
import { EditModeTitle } from "../../../../../components/EditableTitle/EditModeTitle";
import {
  CaretDownIcon,
  CaretUpIcon,
  CheckIcon,
  EditIcon,
  ViewIcon,
  WarningIcon,
  XIcon,
} from "../../../../../components/Icons/Icons";
import theme, { screenSize } from "../../../../../theme";
import { Flex } from "../../../../../layout/FormLayout";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { Modal } from "../../../../../components/Modal/Modal";
import styled from "styled-components";
import { zodRequiredString } from "../../../../../util/zod.util";
import { useAuthContext } from "../../../../../components/Auth";
import {
  H3,
  RegularTextSmall,
} from "../../../../../components/Typography/Typography";
import { InfoBlockExtraSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { TemplateViewConfigurationForm } from "./TemplateViewConfigurationForm";
import type { ExpandedState } from "@tanstack/react-table";

const SaveButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 2;
`;

const DeleteViewButtonContainer = styled.div`
  button {
    padding: 10px 15px;
  }
  margin-top: 5px;
  margin-bottom: 20px;
`;

const ModalWrapper = styled.div`
  padding: 50px 30px 20px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 30px;
`;

export const TabItemNameSchema = z.object({
  name: z.string(),
});

export const ChipBox = styled.div`
  display: inline-block;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.secondaryBorder};
  background-color: ${({ theme }) => theme.tertiaryButtonBG};
  padding: 4px 8px;
  margin: 3px 5px 5px 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.primaryTextColor};
`;

const ViewsDropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 4px;
  background: ${({ theme }) => theme.tertiaryButtonSelected};
  padding: 8px 10px;
  border: solid 1px ${({ theme }) => theme.selectedBorder};
  @media ${screenSize.small} {
    margin-right: 5px;
  }
`;

const ViewsDropDown = styled.div`
  position: absolute;
  top: 100%;
  left: -1px;
  min-width: 100%;
  padding: 6px;
  border: 1px solid #e7e8ea;
  background: #fff;
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
  z-index: 1;
  button {
    display: block;
    white-space: nowrap;
    width: 100%;
    background: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    &:hover {
      background: #f5f7f8;
    }
  }
`;

export type TabItemFormValue = z.infer<typeof TabItemNameSchema>;
export const TabItemNameSchemaFn = (t: TFunction, items: string[]) =>
  z.object({
    name: zodRequiredString(t).refine(
      (val) => Boolean(items.indexOf(val.toLocaleLowerCase()) < 0),
      {
        message: t("Duplicate tab name not allowed"),
      }
    ),
  });

export function SellerAdminTemplateViewTab() {
  const [addNewTab, setAddNewTab] = useState(false);
  const [showSaveButton, setshowSaveButton] = useState(false);
  const [currentView, setCurrentView] =
    useState<{ id: string; name: string }>();
  const [showConfigurationForm, setShowConfigurationForm] = useState(false);
  const [configurationsFormData, setConfigurationFormData] = useState<{
    viewName?: string;
    viewId?: string;
    templateId?: string;
    config?: TemplateViewConfigurstions;
  }>({});
  const [showViewsDropDown, setShowViewsDropDown] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{
    [key: string]: ExpandedState;
  }>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { template_id } = useParams<{ template_id: string }>();
  const location = useLocation();

  const ViewswrapperRef = useRef<HTMLDivElement>(null);

  const { hasPermission } = useAuthContext();
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { data: template, mutate: mutateTemplate } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/templates/${template_id}`, {
    revalidateOnFocus: showSaveButton ? false : true,
  });

  const { data: viewData, mutate: mutateViewData } = useSWR<
    TemplateViewSchema,
    AxiosError
  >(
    currentView
      ? `/v2/storefronts/${storefront_id}/pim/templates/${template_id}/views/${currentView.id}`
      : null,
    {
      revalidateOnFocus: showSaveButton ? false : true,
    }
  );

  const isSKUs = () =>
    template?.collections.find(
      (collection) => collection.name.toLowerCase() === "skus"
    );

  const isSEO = () =>
    template?.groups.find((group) => group.name.toLowerCase() === "seo");

  const [cards, setCards] = useState(viewData ? viewData.layout : []);
  const inputRef = useRef<HTMLInputElement>(null);
  const editNameUseForm = useForm({
    resolver: zodResolver(
      TabItemNameSchemaFn(
        t,
        viewData?.layout.map((item) => item.display_name.toLocaleLowerCase()) ||
          []
      )
    ),
  });

  const update_priority = <ItemType extends { priority: number }>(
    items: ItemType[]
  ) => {
    return items.map((item, idx) => ({ ...item, priority: idx }));
  };

  const updateRow = ({
    source,
    data,
  }: {
    source: string;
    data: TemplateTabItem[];
  }) => {
    setshowSaveButton(true);
    const newCards = [...cards];
    const cardIndex = cards.findIndex((card) => card.name === source);
    newCards[cardIndex].items = data;
    setCards(newCards);
  };

  const moveRow = ({
    item,
    source,
    data,
  }: {
    item: TemplateTabItem;
    source: string;
    data: TemplateTabItem[];
  }) => {
    const newCards = [...cards];
    const oldSource = newCards.find((card) => card?.items.includes(item));
    const oldSourceIndex = oldSource ? newCards.indexOf(oldSource) : -1;
    const newSource = newCards.find((card) => card?.name === source);
    const newSourceIndex = newSource ? newCards.indexOf(newSource) : -1;
    if (oldSourceIndex === newSourceIndex) {
      newCards[oldSourceIndex].items = data;
    } else {
      setshowSaveButton(true);
      if (oldSource && oldSourceIndex > -1) {
        const items = newCards[oldSourceIndex].items;
        items.splice(oldSource.items.indexOf(item), 1);
        newCards[oldSourceIndex].items = items;
      }
      if (newSource && newSourceIndex > -1) {
        newCards[newSourceIndex].items = data;
      }
    }
    setCards([]);
    setCards(update_priority(newCards));
  };

  const handleAddNewTab = async ({ name }: TabItemFormValue) => {
    try {
      await axios.post(
        `v2/tenants/${tenant_id}/templates/${template_id}/views/${currentView?.id}/tabs`,
        {
          name,
        }
      );
      await mutateViewData();
      setAddNewTab(false);
      notifySuccess(t(`Tab (${name}) has been created successfully`));
    } catch (error) {
      await mutateViewData();
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error editing tab name"),
        {
          error,
        }
      );
    }
  };

  const handleToggleAllRowsExpanded = (expanded: boolean) => {
    if (!expanded) {
      setExpandedRows({});
    }
  };

  const submitTemplateViewData = useCallback(async () => {
    try {
      await axios.patch(
        `v2/storefronts/${storefront_id}/pim/templates/${template_id}/views/${currentView?.id}`,
        { layout: cards }
      );
      setShowConfirmationModal(false);
      setshowSaveButton(false);
      notifySuccess(t("Template view has been updated successfully"));
      await mutateViewData();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error editing template view"),
        {
          error,
        }
      );
    }
  }, [
    cards,
    mutateViewData,
    notifyError,
    notifySuccess,
    t,
    template_id,
    currentView,
    storefront_id,
  ]);

  const saveReorderChanges = () => {
    if (template?.number_of_products && template?.number_of_products > 0) {
      setShowConfirmationModal(true);
    } else {
      submitTemplateViewData();
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const list = [...cards];
        list.splice(hoverIndex, 0, list.splice(dragIndex, 1)[0]);
        setCards(update_priority(list));
        setshowSaveButton(true);
      }
    },
    [cards]
  );
  const handleEditConfiguration = () => {
    setConfigurationFormData({
      viewId: viewData?.id,
      templateId: template_id,
      viewName: viewData?.name,
      config: viewData?.configurations,
    });
    setShowConfigurationForm(true);
  };

  const handleClickOutsideViews = (event: any) => {
    if (
      ViewswrapperRef.current &&
      !ViewswrapperRef.current.contains(event.target)
    ) {
      setShowViewsDropDown(false);
    }
  };

  const handleCardExpandedState = (
    cardId: string,
    expandedState: ExpandedState
  ) => {
    setExpandedRows((prev) => {
      let newExpanded = prev;
      newExpanded[cardId] = expandedState;
      return newExpanded;
    });
  };

  const handleSubmittedView = (view: TemplateViewSchema) => {
    mutateViewData();
    setShowConfigurationForm(false);
    mutateTemplate();
    setCurrentView({ id: view.id, name: view.name });
  };

  const handleDeleteView = async () => {
    try {
      await axios.delete(
        `/v2/storefronts/${storefront_id}/pim/templates/${template_id}/views/${currentView?.id}`
      );
      notifySuccess(t("View has been deleted successfully."));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the template view"),
        {
          error,
        }
      );
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideViews, false);
    return () => {
      document.removeEventListener("click", handleClickOutsideViews, false);
    };
  }, []);

  useEffect(() => {
    if (template && !currentView) {
      setCurrentView(
        template.views.find(
          (view) => view.name?.toLocaleLowerCase() === "default"
        )
      );
    }
  }, [template, currentView]);

  useEffect(() => {
    if (viewData) setCards(viewData.layout);
  }, [viewData]);

  return (
    <PageWrapper>
      <div style={{ position: "relative" }}>
        <Link
          to={
            location.search.includes("from=dashboard")
              ? `${adminPath}/dashboard`
              : `${adminPath}/pim/templates`
          }
        >
          <GoBackButtonSmall text={"Back"} />
        </Link>
        {showSaveButton && viewData && (
          <SaveButtonContainer>
            <DestructiveButtonLarge
              onClick={() => {
                setCards([...viewData.layout]);
                setshowSaveButton(false);
                mutateViewData();
              }}
              style={{ marginRight: "15px" }}
            >
              {t("Cancel")}
            </DestructiveButtonLarge>
            <PrimaryButtonMedium onClick={() => saveReorderChanges()}>
              {t("Save")}
            </PrimaryButtonMedium>
          </SaveButtonContainer>
        )}
      </div>
      <TemplatesNav
        pageTitle={template?.template_name || t("Template")}
        tabIndex={4}
        templateID={template_id}
      />
      {template && viewData && (
        <>
          <ContentWrapper>
            <div
              style={{
                marginBottom: `20px`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <H3 style={{ marginRight: "15px" }}>{t("View")}:</H3>
              <ViewsDropDownWrapper
                ref={ViewswrapperRef}
                onClick={() => setShowViewsDropDown(!showViewsDropDown)}
              >
                <ViewIcon width={20} height={20} />
                <span
                  style={{
                    padding: "0 10px 0 5px",
                    fontSize: `${theme.fontSizes.medium}`,
                  }}
                  onClick={() => setShowViewsDropDown(!showViewsDropDown)}
                >
                  {currentView?.name}
                </span>
                {showViewsDropDown ? (
                  <CaretUpIcon width={20} height={20} />
                ) : (
                  <CaretDownIcon width={20} height={20} />
                )}
                {showViewsDropDown && template.views.length > 0 && (
                  <ViewsDropDown>
                    {template.views.map((view) => (
                      <Button onClick={() => setCurrentView(view)}>
                        {view.name}
                      </Button>
                    ))}
                  </ViewsDropDown>
                )}
              </ViewsDropDownWrapper>
              {hasPermission("modify_templates") && (
                <PrimaryButtonWithPlusIcon
                  style={{
                    padding: "8px 12px 8px 10px",
                    border: `1px solid ${theme.secondaryBorder}`,
                    background: `${theme.primaryBG}`,
                  }}
                  onClick={() => {
                    setConfigurationFormData({ templateId: template_id });
                    setShowConfigurationForm(true);
                  }}
                >
                  {t("New")}
                </PrimaryButtonWithPlusIcon>
              )}
            </div>
            <BorderCard>
              <div
                style={{
                  paddingBottom: `10px`,
                  marginBottom: `20px`,
                  borderBottom: `1px solid ${theme.secondaryBorder}`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <H3 style={{ margin: 0 }}>{t("Configuration")}</H3>
                {!showConfigurationForm && hasPermission("modify_templates") && (
                  <InvisibleButton onClick={() => handleEditConfiguration()}>
                    <EditIcon
                      height={16}
                      width={16}
                      fill={theme.secondaryTextColor}
                    />
                    {t("Edit")}
                  </InvisibleButton>
                )}
              </div>
              <div
                style={{
                  marginTop: `10px`,
                }}
              >
                <InfoBlockExtraSmall
                  header={t("View Name")}
                  content={currentView?.name}
                />
              </div>
              <TwoColumGrid>
                <div
                  style={{
                    marginTop: `10px`,
                  }}
                >
                  <InfoBlockExtraSmall
                    header={t("Internal Assignment")}
                    content={
                      <>
                        {viewData &&
                        viewData.configurations.assigned_roles.length > 0 ? (
                          viewData.configurations.assigned_roles.map((role) => (
                            <ChipBox>{role.name}</ChipBox>
                          ))
                        ) : (
                          <span>--</span>
                        )}
                      </>
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: `10px`,
                  }}
                >
                  <InfoBlockExtraSmall
                    header={t("Customer Assignment")}
                    content={
                      viewData.configurations.is_assigned_to_customer
                        ? t("Yes")
                        : t("No")
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: `10px`,
                  }}
                >
                  <InfoBlockExtraSmall
                    header={t("Distributor Assignment")}
                    content={
                      viewData.configurations.is_assigned_to_distributor
                        ? t("Yes")
                        : t("No")
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: `10px`,
                  }}
                >
                  <InfoBlockExtraSmall
                    header={t("Public Assignment")}
                    content={
                      viewData.configurations.is_assigned_to_outside_login
                        ? t("Yes")
                        : t("No")
                    }
                  />
                </div>
              </TwoColumGrid>
            </BorderCard>
            <BorderCard>
              <div
                style={{
                  paddingBottom: `10px`,
                  marginBottom: `10px`,
                  borderBottom: `1px solid ${theme.secondaryBorder}`,
                }}
              >
                <H3 style={{ margin: 0 }}>{t("Customization")}</H3>
                <RegularTextSmall>
                  {t(
                    "Rearrange groups and collections and configure which attributes you want to be visible for this view"
                  )}
                </RegularTextSmall>
              </div>
              {currentView &&
                cards &&
                cards.map((card, i) => (
                  <TemplateViewCard
                    key={card.id}
                    expandedRows={expandedRows[card.id]}
                    handleExpandedState={handleCardExpandedState}
                    disabled={showSaveButton}
                    templateId={template_id}
                    viewId={currentView?.id}
                    index={i}
                    card={card}
                    moveCard={moveCard}
                    moveRow={moveRow}
                    updateRow={updateRow}
                    isSKUs={!!isSKUs()}
                    isSEO={!!isSEO()}
                    mutateView={mutateViewData}
                    handleToggleAllRowsExpanded={handleToggleAllRowsExpanded}
                    handleDrop={
                      showSaveButton ? undefined : () => setshowSaveButton(true)
                    }
                    tabNames={viewData?.layout.map((item) =>
                      item.display_name.toLocaleLowerCase()
                    )}
                  />
                ))}
              {addNewTab && (
                <Flex style={{ marginBottom: "40px" }}>
                  <form
                    id="add_new"
                    noValidate
                    onSubmit={editNameUseForm.handleSubmit(handleAddNewTab)}
                    style={{ position: "relative" }}
                  >
                    <EditModeTitle
                      name={"name"}
                      theref={inputRef}
                      register={editNameUseForm.register}
                      formState={editNameUseForm.formState}
                      errors={editNameUseForm.errors}
                      tabIndex={0}
                      defaultValue={""}
                      fontSize={"regular"}
                      fontWeight={"large"}
                    />
                  </form>
                  <ButtonGroup>
                    <InvisibleButton form={"add_new"} type="submit">
                      <CheckIcon fill={theme.activeToggleBG} />
                    </InvisibleButton>
                    <InvisibleButton
                      type="button"
                      onClick={() => setAddNewTab(false)}
                    >
                      <XIcon fill={theme.destructiveTextColor} />
                    </InvisibleButton>
                  </ButtonGroup>
                </Flex>
              )}
              {!addNewTab &&
                viewData?.layout &&
                viewData?.layout.length > 0 &&
                hasPermission("modify_templates") && (
                  <PrimaryButtonLarge
                    onClick={() => setAddNewTab(true)}
                    style={{ marginBottom: "30px" }}
                  >
                    {t("Add Tab")}
                  </PrimaryButtonLarge>
                )}
            </BorderCard>
            {hasPermission("delete_templates") && (
              <DeleteViewButtonContainer>
                <ButtonWithConfirmDialog
                  Button={DestructiveButton}
                  buttonText={t("Delete View")}
                  testid={"delete-template-view"}
                  handleConfirm={() => handleDeleteView()}
                  confirmMessage={"Are you sure you want to delete this view?"}
                  disabled={
                    currentView?.name.toLocaleLowerCase() === "default" ||
                    viewData.configurations.assigned_roles.length > 0 ||
                    !hasPermission("delete_templates")
                  }
                  datatip={
                    currentView?.name.toLocaleLowerCase() === "default" &&
                    template.views.length === 1
                      ? t("The 'Default' view cannot be deleted.")
                      : viewData.configurations.assigned_roles.length > 0
                      ? "View cannot be deleted, Remove roles from view first"
                      : ""
                  }
                  datafor="delete-template-view"
                />
              </DeleteViewButtonContainer>
            )}
          </ContentWrapper>
          <Modal
            overlay
            show={showConfirmationModal}
            closeModal={() => {
              setShowConfirmationModal(false);
              setCards([...viewData?.layout]);
            }}
            modalWidth={"600px"}
          >
            <ModalWrapper>
              <div style={{ marginBottom: "15px" }}>
                <WarningIcon width={50} height={50} />
              </div>
              <WarningTitle>
                {t(
                  `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                  {
                    numberOfProducts: template?.number_of_products,
                    product:
                      template?.number_of_products === 1
                        ? "product"
                        : "products",
                  }
                )}
              </WarningTitle>
              <div style={{ display: "flex", marginTop: "30px" }}>
                <SecondaryButtonFitContainer
                  onClick={() => {
                    setShowConfirmationModal(false);
                    setCards([...viewData?.layout]);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  {t("Cancel")}
                </SecondaryButtonFitContainer>
                <PrimaryButtonFitContainer
                  onClick={() => submitTemplateViewData()}
                  style={{ marginLeft: "10px" }}
                >
                  {t("Confirm")}
                </PrimaryButtonFitContainer>
              </div>
            </ModalWrapper>
          </Modal>
          <SlideOut
            closeFlyout={() => setShowConfigurationForm(false)}
            show={showConfigurationForm}
          >
            <TemplateViewConfigurationForm
              handleCancel={() => setShowConfigurationForm(false)}
              configurations={configurationsFormData}
              handleSubmittedView={handleSubmittedView}
              showConfirmationModal={showConfirmationModal}
              numberOfProducts={template?.number_of_products}
              numberOfViews={template.views.length}
            />
          </SlideOut>
        </>
      )}
    </PageWrapper>
  );
}
