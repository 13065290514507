import type { Moment } from "moment";
import moment from "moment";

export type DateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
};

export type DateRangeStr = {
  startDate?: string | null;
  endDate?: string | null;
};

export const get_preselected_date_range = (date: DateRangeStr | undefined) => {
  const { startDate, endDate } = date ?? { startDate: null, endDate: null };
  return date
    ? {
        startDate:
          startDate && startDate !== "undefined" && startDate !== "null" // This is from a query parameter and null or undefined value can be parsed as a string
            ? moment(date.startDate)
            : null,
        endDate:
          endDate && endDate !== "undefined" && endDate !== "null"
            ? moment(date.endDate)
            : null,
      }
    : undefined;
};

export const is_date_filters_equal = (a: DateRange, b: DateRange) => {
  const updated_a = {
    startDate: !!a.startDate ? a.startDate : moment(),
    endDate: !!a.endDate ? a.endDate : moment(),
  };
  const updated_b = {
    startDate: !!b.startDate ? b.startDate : moment(),
    endDate: !!b.endDate ? b.endDate : moment(),
  };
  return (
    updated_a.startDate?.isSame(updated_b.startDate) &&
    updated_a.endDate?.isSame(updated_b.endDate)
  );
};
