import { PrimaryButtonLarge } from "../../../components/Buttons/Buttons";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextArea } from "../../../components/TextArea/TextArea";
import { TextField } from "../../../components/TextFields/TextFields";
import { Title } from "../../../components/Typography/Typography";
import type {
  IContactRequest,
  IContactRequestWithAddress,
  LeadConfiguration,
  OptionType,
  SEODetail,
  StorefrontCustomizableFormSchema,
  StorefrontFormData,
  SupportedLanguage,
} from "../../../types/types";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  FormGrid2x2,
} from "../../../layout/FormLayout";
import type { AxiosError } from "axios";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useSWR from "swr";
import * as zod from "zod";
import { Auth, useAuthContext } from "../../../components/Auth";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import {
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../../util/location";
import { useCountriesList, useStatesList } from "../../../util/Locations";
import {
  getPhoneCodesOptions,
  getPhoneOptionByAlpha2,
} from "../../../util/phone";
import { useRoutePath } from "../../../util/Routing";
import { strings } from "../../../util/strings";
import {
  isAxiosError,
  isValidPhoneNumber,
  useFormWrapper,
  usePolicyDocuments,
  useStoreState,
} from "../../../util/util";
import { endpoints } from "../../../endpoints";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import { zodAddressDefault } from "../../../util/zod.util";
import {
  ColoredTextOnError,
  getBrowserLanguage,
} from "../../../util/util-components";
import { OutsideLoginCustomFields } from "../../admin/SellerAdmin/SellerAdminSettings/ThemeAndContentTab/OutsideLoginCustomFields/OutsideLoginCustomFields";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  WarningMessageBox,
} from "../../../components/Form/Form";
import { WarningIcon } from "../../../components/Icons/Icons";
import { ConfirmationSpacer } from "../SampleRequestCart/SampleRequestCart";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { create_configured_checkboxes_fields } from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import {
  captcha_zod_validation,
  captcha_zod_validation_not_required,
  ControlledRecaptcha,
} from "../../../components/Recaptcha/ControlledRecaptcha";

interface RowProps {
  flex?: number | undefined;
}

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;
`;

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    flex: ${({ flex }) => flex || 1};
    margin-right: 9px;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

const ContactUsSubtitle = styled.div`
  margin-bottom: 2em;
  line-height: 1.75rem;
  .ql-editor {
    height: auto !important;
  }
`;

export const defaultContactDescription = (t: (s: string) => string) =>
  t(
    "Looking for something specific? We'd be happy to help. Fill in the form below and a member of our team will get back to you."
  );
/**
 * Page component for the "Contact Us" page.
 */
export function ContactUsPage() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { notifyError } = useContext(Notifications);
  const { storePath } = useRoutePath();
  const { user } = useContext(Auth);
  const {
    storefront_id,
    slug,
    storefront_metadata: {
      supported_languages,
      is_contact_message_compulsory,
      show_privacy_policy_on_contact_forms,
      configured_checkboxes,
      route_configuration,
      require_captcha,
    },
    api_metadata: { captcha_site_key },
  } = useStoreState();
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const { t } = useTranslation();
  const countries = useCountriesList();
  const [phoneNumberWarning, setPhoneNumberWarning] =
    useState<{ message: string } | null>(null);
  const { privacyPolicy } = usePolicyDocuments();

  const contactUsRoute = route_configuration.find(
    (route) => route.route === "contact-us"
  );

  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${slug}`
  ] as SupportedLanguage;
  const browserLanguage: SupportedLanguage | undefined =
    getBrowserLanguage() as SupportedLanguage;

  const langs = Object.keys(supported_languages);

  const { data: leadConfiguration, error: leadConfigurationError } =
    useSWR<LeadConfiguration>(
      `/v1/storefronts/${storefront_id}/lead-configuration`
    );

  const { data: leadCustomForms, error: leadCustomFormsError } =
    useSWR<StorefrontCustomizableFormSchema>(
      `/v2/storefronts/${storefront_id}/custom-forms/contact-us`
    );

  const { data: storefrontFormData, error: formDataError } = useSWR<
    StorefrontFormData,
    AxiosError<StorefrontFormData>
  >(endpoints.v1_storefronts_id_form_data(storefront_id, "en"));

  const { data: contactPageSEO } = useSWR<SEODetail, AxiosError<SEODetail>>(
    endpoints.v1_storefronts_id_or_slug_seo_page(storefront_id, "contact_page")
  );

  const contactCheckboxes = configured_checkboxes.filter(
    (item) => item.contact
  );

  const defaultTranslatedContactDescription = defaultContactDescription(t);

  const contactUsDescription = storefrontFormData?.data?.Contact?.Description;

  // This will default to false for all storefronts except brenntag at time of
  // writing, if the endpoint errors out still show the form without the address portion
  const showAddressOnForm =
    !leadConfigurationError && !!leadConfiguration?.show_location_form;

  const showPrivacyPolicy =
    Boolean(privacyPolicy) && show_privacy_policy_on_contact_forms;

  const ContactUsSchema = zod.object({
    first_name: zod.string().min(1, strings(t).thisIsARequiredField),
    last_name: zod.string().min(1, strings(t).thisIsARequiredField),
    company: zod.string().min(1, strings(t).thisIsARequiredField),
    email: zod.string().email(),
    country_code: zod.object({
      value: zod.string().min(1, strings(t).thisIsARequiredField),
      label: zod.string().min(1, strings(t).thisIsARequiredField),
    }),
    phone_number: zod.string().min(1, strings(t).phoneNumberMustBeValid),
    message: is_contact_message_compulsory
      ? zod.string().min(1, strings(t).thisIsARequiredField)
      : zod.string().optional(),
    arbitrary_fields: zod
      .record(zod.object({ value: zod.string(), label: zod.string() }))
      .optional(),
    confirmation_pv: zod.boolean(),
    configured_checkboxes:
      contactCheckboxes.length > 0
        ? zod.array(zod.object({ value: zod.boolean() }))
        : zod.undefined(),
    ...(require_captcha
      ? captcha_zod_validation(t)
      : captcha_zod_validation_not_required()),
  });

  const ContactUsSchemaWithFullAddress = ContactUsSchema.extend(
    zodAddressDefault(t)
  );

  const ContactUsSchemaUnion = zod.union([
    ContactUsSchema,
    ContactUsSchemaWithFullAddress,
  ]);

  type FormInputs = zod.infer<typeof ContactUsSchemaUnion>;

  const methodsOfUseForm = useFormWrapper<FormInputs>({
    defaultValues: {
      message: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState,
    errors,
    control,
    setValue,
    watch,
    setError,
  } = methodsOfUseForm;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  useEffect(() => {
    if (contactCheckboxes.length > 0 && fields?.length < 1) {
      contactCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [contactCheckboxes, append, fields]);

  const maybeCountry: OptionType<string> | null = watch("country");
  const selectedCountry = maybeCountry ? maybeCountry.value : "";
  const states = useStatesList(selectedCountry);

  const title = roleIsSomeKindOfSeller
    ? t(`Cannot submit contact request to own company`)
    : t("Submit");

  const onSubmit = async (inputs: FormInputs) => {
    // Validate email via sendgrid
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`/v1/emails/validate`, {
        email: inputs.email,
        source: "contact us form",
      });

      if (response.data.valid) {
        postFormData(inputs);
      }
    } catch (error) {
      setError("email", { message: strings(t).emailAddressMustBeValid });
      notifyError(t("Email validation error, please try again"), { error });
      setLoading(false);
    }
  };

  const postFormData = async (inputs: FormInputs) => {
    // Submit form to Agilis API
    try {
      const {
        first_name,
        last_name,
        company,
        email,
        phone_number,
        country_code,
        message,
        captcha_token,
      } = inputs;

      if (
        !isValidPhoneNumber({ phone_number, country_code }) &&
        !phoneNumberWarning
      ) {
        // only display this once, if the user clicks submit a second time let
        // them go
        setPhoneNumberWarning({
          message: t(
            "phone number might be invalid, press submit again to continue"
          ),
        });

        return;
      }

      const maybeValuesWithAddress = showPrivacyPolicy
        ? ContactUsSchemaWithFullAddress.safeParse(inputs)
        : ContactUsSchemaWithFullAddress.omit({
            confirmation_pv: true,
          }).safeParse(inputs);

      const customChoices: Record<string, string> = {};

      for (const fieldName in inputs.arbitrary_fields) {
        customChoices[fieldName] = inputs?.arbitrary_fields?.[fieldName]?.label;
      }

      let dataToPost: IContactRequestWithAddress | IContactRequest = {
        seller_id_or_slug: storefront_id,
        message: message,
        buyer_first_name: first_name,
        buyer_last_name: last_name,
        buyer_email: email,
        buyer_phone_number: `${country_code.value}${phone_number}`,
        buyer_company_name: company,
        custom_choices: customChoices,
        captcha_token: captcha_token,
        recaptcha_key: captcha_site_key,
        language:
          preferredLanguage ??
          user?.preferred_language ??
          (() => {
            const supportedLanguage = langs.find(
              (lang) => lang === browserLanguage
            );
            if (supportedLanguage) {
              return supportedLanguage;
            } else {
              return null;
            }
          })() ??
          "en",
      };

      if (maybeValuesWithAddress.success) {
        const {
          country,
          city,
          state,
          county,
          postal_code,
          address1,
          address2,
        } = maybeValuesWithAddress.data;
        dataToPost = {
          ...dataToPost,
          address: {
            country: country.value,
            state: state.value,
            city,
            postal_code,
            address1,
            address2: address2 ?? null,
            county: county ?? "",
          },
        };
      }

      const response = await axios.post(
        `/v1/storefronts/${storefront_id}/leads/contact-requests`,
        dataToPost
      );
      setLoading(false);

      if (response.status === 208) {
        notifyError(
          t(
            "You are already registered on the storefront. Please log in to raise the request"
          )
        );
        history.push(`${storePath}/login`);
        return;
      } else {
        history.push(`${storePath}/contact-us/thank-you`);
      }
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Contact us request is already in progress`));
        setLoading(false);
        return;
      }
      const message =
        isAxiosError(error) && error.response?.data.message
          ? error.response?.data.message
          : t("There was an error, please try again");

      notifyError(message, { error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showAddressOnForm && countries.length > 0 && leadConfiguration) {
      setValue(
        "country",
        countries.find(
          (country) => country.value === leadConfiguration.default_country
        )
      );
    }
  }, [showAddressOnForm, countries, leadConfiguration, setValue]);

  useEffect(() => {
    if (selectedCountry) {
      setValue("city", undefined);
      setValue("state", null);
      setValue("phone_number", undefined);
      setValue("country_code", getPhoneOptionByAlpha2(selectedCountry));
    }
  }, [selectedCountry, setValue]);

  if (contactUsRoute?.enabled === false) {
    return <Redirect to={`${storePath}/login`} />;
  }

  return (
    <Container>
      {/* Handle setting the SEO information every time it changes */}
      {contactPageSEO && <SEOHelmet seo={contactPageSEO} />}

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Title>{t("Contact Us")}</Title>
        <ContactUsSubtitle>
          {contactUsDescription && !formDataError && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="ql-editor">${contactUsDescription}</div>`,
              }}
            ></div>
          )}
          {!contactUsDescription &&
            !storefrontFormData?.data?.Contact &&
            !formDataError &&
            storefrontFormData &&
            defaultTranslatedContactDescription}
          {formDataError && defaultTranslatedContactDescription}
        </ContactUsSubtitle>
        {phoneNumberWarning && (
          <WarningMessageBox>
            <div style={{ marginRight: "4px" }}>
              <WarningIcon width={18} />
            </div>
            {t(
              `The phone number provided cannot be validated, if you're confident it is correct please press "submit" again to continue`
            )}
          </WarningMessageBox>
        )}
        <Row>
          <TextField
            name="first_name"
            label={t("First Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="last_name"
            label={t("Last Name")}
            autoComplete="organization"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
        </Row>
        <Row>
          <TextField
            name="company"
            label={t("Company")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="email"
            label={t("Business Email Address")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="email"
          />
        </Row>
        {showAddressOnForm && countries.length > 0 && leadConfiguration && (
          <FormGrid2x2>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="country"
              autoComplete={"country-name"}
              placeholder={t("Country")}
              options={countries}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
            <Controller
              as={SelectBoxV2}
              control={control}
              name="state"
              autoComplete="address-level1"
              placeholder={getStatesInputLabel(selectedCountry, t)}
              options={states}
              // isSearchBar
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
          </FormGrid2x2>
        )}
        {showAddressOnForm && (
          <Row flex={0.5}>
            <TextField
              name="address1"
              autoComplete="address-line-1"
              label={t("Address Line 1")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="address2"
              autoComplete="address-line-2"
              label={t("Address Line 2")}
              theref={register({
                required: false,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Row>
        )}
        {showAddressOnForm && (
          <Row flex={0.5}>
            <TextField
              name="city"
              label={t("City")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="postal_code"
              autoComplete="postal-code"
              label={getZipCodeInputLabel(selectedCountry, t)}
              theref={register({
                required: true,
                maxLength: 20,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Row>
        )}
        {leadConfiguration && (
          <>
            <Row>
              <Flex>
                <Flex1>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country_code"
                    autoComplete="countryCode"
                    placeholder={t("Country Code")}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    defaultValue={getPhoneOptionByAlpha2(
                      leadConfiguration.default_country_code
                    )}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name="phone_number"
                    label={t("Phone Number")}
                    theref={register({
                      required: true,
                    })}
                    warningText={phoneNumberWarning}
                    formState={formState}
                    errors={errors}
                    type="tel"
                  />
                </Flex2>
              </Flex>
            </Row>
          </>
        )}
        {leadCustomForms &&
          !leadCustomFormsError &&
          leadCustomForms.is_active && (
            <OutsideLoginCustomFields
              fields={leadCustomForms}
              methodsOfUseForm={methodsOfUseForm}
            />
          )}
        <Controller
          name="message"
          control={control}
          rules={{ required: is_contact_message_compulsory }}
          render={({ onChange, value }) => (
            <TextArea
              name="message"
              label={
                is_contact_message_compulsory
                  ? t("How can we help you?")
                  : t("How can we help you? (Optional)")
              }
              onChange={(event) => onChange(event.currentTarget.value)}
              value={value}
              required={is_contact_message_compulsory}
              formState={formState}
              type="text"
              errors={errors}
            />
          )}
        />
        {showPrivacyPolicy && (
          <ConfirmationSpacer>
            <CheckBoxContainer>
              <div style={{ width: "22px", marginRight: "15px" }}>
                <CheckBoxNoLabel
                  name="confirmation_pv"
                  id="confirmation_pv"
                  ref={register({
                    validate: (val: boolean) =>
                      val || strings(t).thisIsARequiredField,
                  })}
                  error={!!errors.confirmation_pv?.message}
                />
              </div>
              <CheckBoxFinePrintLabel htmlFor="confirmation_pv">
                <ColoredTextOnError isError={!!errors.confirmation_pv?.message}>
                  <>
                    <span>{t("I agree to the ")}</span>
                    <PrivacyPolicyLink customPolicy />
                    <span>.</span>
                  </>
                </ColoredTextOnError>
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>
          </ConfirmationSpacer>
        )}
        {fields.length > 0 && (
          <>
            {create_configured_checkboxes_fields({
              fields,
              methodsOfUseForm,
              t,
            })}
          </>
        )}
        <ControlledRecaptcha methodsOfUseForm={methodsOfUseForm} />
        <PrimaryButtonLarge
          style={{ minWidth: "242px", marginTop: "15px" }}
          loading={loading}
          disabled={roleIsSomeKindOfSeller}
          title={title}
        >
          {t("Submit")}
        </PrimaryButtonLarge>
      </Form>
    </Container>
  );
}
