import ReactDOM from "react-dom";
import "./TooltipPortal.css";

export const TooltipPortal = ({ children }: { children: JSX.Element }) => {
  const tooltipPortal = document.getElementById("tooltip_portal");
  if (!tooltipPortal) {
    const domNode = document.createElement("div");
    domNode.setAttribute("id", "tooltip_portal");
    domNode.setAttribute("class", "tooltip-portal");
    document.body.appendChild(domNode);
  }
  if (tooltipPortal) {
    return ReactDOM.createPortal(children, tooltipPortal);
  }
  return ReactDOM.createPortal(children, document.createElement("div"));
};
