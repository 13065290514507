import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  PrimaryButtonSmall,
  TextButton,
} from "../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { EditablePriceList } from "../../../components/EditablePriceList/EditablePriceList";
import { SectionTitle } from "../../../components/Form/Form";
import { Row } from "../../../components/Layout/Layout";
import type { Currency, Fee, MethodsOfUseForm } from "../../../types/types";
import { useFees, useStoreState } from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import { normalizeAttributeName } from "../../admin/SellerAdmin/PIM/components/PIM.components.util";
import type { UpdatedFees } from "./UpdateFeesDialog";
import { UpdateFeesDialog } from "./UpdateFeesDialog";

const FeesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SectionTitleNoMargin = styled(SectionTitle)`
  margin: 0 0 14px;
`;

const BrandedTextButton = styled(TextButton)`
  color: ${({ theme }) => theme.brandColor};
`;

const calculateTotal = (subtotal: number, values: Fee[]) => {
  const totalFees: number = values.reduce(
    (a, b) => a + (Number(b["amount"]) || 0),
    0
  );
  return Number(subtotal) + Number(totalFees);
};

interface IQuoteFees {
  methodsOfUseForm: MethodsOfUseForm;
  currency: Currency;
  subtotal: number;
}

export const QuoteFees = forwardRef<
  { updateTaxFee: (isTaxExempt: boolean) => void },
  IQuoteFees
>(({ methodsOfUseForm, currency, subtotal }: IQuoteFees, ref) => {
  const [total, setTotal] = useState(0);
  const [show_add_fees, set_show_add_fees] = useState(false);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { data: fees_and_charges, loading: is_loading_fees } =
    useFees(storefront_id);
  const { watch, control } = methodsOfUseForm;
  const methodsOfUseFieldArray = useFieldArray({
    control,
    name: "feesList",
    keyName: "key",
  });
  const { fields, append, prepend, remove } = methodsOfUseFieldArray;

  const on_add_new_fees = (fees: Array<UpdatedFees>) => {
    const updated_fees = fees.map((fee) => ({
      ...fee,
      is_disabled: String(fee.is_disabled) === "true",
    }));
    append(updated_fees);
    set_show_add_fees(false);
  };

  useEffect(() => {
    setTotal(calculateTotal(subtotal, watch("feesList", fields)));
  }, [fields, subtotal, watch]);

  useImperativeHandle(
    ref,
    () => ({
      updateTaxFee(isTaxExempt: boolean) {
        const hasTaxFee = fields.some((fee) => fee.id === "tax_fee");
        if (!isTaxExempt && !hasTaxFee) {
          prepend({ name: "Tax", amount: 0, id: "tax_fee" });
        } else {
          remove(fields.findIndex((fee) => fee.id === "tax_fee"));
        }
      },
    }),
    [fields, prepend, remove]
  );

  const selected_fees = watch("feesList", fields) as UpdatedFees[];

  const updated_fees_and_charges = (() => {
    const selected_fees_map = selected_fees.reduce((acc, fee) => {
      acc[normalizeAttributeName(fee.name).toLowerCase()] = fee;
      return acc;
    }, {} as Record<string, UpdatedFees>);
    return fees_and_charges.filter((fee) => {
      const selected_fee =
        selected_fees_map[normalizeAttributeName(fee.name).toLowerCase()];
      return selected_fee
        ? Number(selected_fee.amount) !== Number(fee.amount)
        : true;
    });
  })();

  return (
    <FeesContainer>
      <SectionTitleNoMargin>{t("Fees")}</SectionTitleNoMargin>
      <EditablePriceList
        methodsOfUseForm={methodsOfUseForm}
        methodsOfUseFieldArray={methodsOfUseFieldArray}
        testIdPrefix={"quote-fee"}
        currencySymbol={currency.symbol}
        hasPossibleTaxFee={true}
      />
      <Row style={{ margin: "13px 0px" }}>
        {is_loading_fees ? (
          <DelayedSpinner />
        ) : (
          <>
            {fees_and_charges.length > 0 ? (
              <BrandedTextButton
                type="button"
                onClick={() => set_show_add_fees(true)}
              >
                {t("Add Fees & Charges")}
              </BrandedTextButton>
            ) : (
              <PrimaryButtonSmall
                type="button"
                onClick={() => append({ name: "", amount: "" })}
              >
                {t("Add Fee")}
              </PrimaryButtonSmall>
            )}
          </>
        )}
      </Row>
      <Row>
        <SectionTitleNoMargin>{t("Total")}</SectionTitleNoMargin>
        <SectionTitleNoMargin>
          {" "}
          {formatPrice(total, currency.code)}
        </SectionTitleNoMargin>
      </Row>
      {show_add_fees && (
        <UpdateFeesDialog
          show={show_add_fees}
          onComplete={(fees) => on_add_new_fees(fees)}
          onCancel={() => set_show_add_fees(false)}
          fees={updated_fees_and_charges}
        />
      )}
    </FeesContainer>
  );
});
