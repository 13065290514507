import type { MouseEventHandler } from "react";
import { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components/macro";
import { PrimaryButtonSmall, SecondaryButtonSmall } from "../Buttons/Buttons";
import { XIcon } from "../Icons/Icons";

interface IDialogProps {
  show: boolean;
  children?: any;
  closeDialog: any;
  confirmMessage: string;
  handleConfirm?: MouseEventHandler<HTMLButtonElement>;
  handleCancel?: MouseEventHandler<HTMLButtonElement>;
}

const DialogFade = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const DialogSlide = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

const CloseIconAnimation = keyframes`
 0% { opacity: 0; transform: rotate(270deg)}
 50% { opacity: 0.3; }
 100% { opacity: 1; transform: rotate(0deg)}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const DialogOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.overlayColor};
  animation-name: ${DialogFade};
  animation-duration: 0.3s;
  z-index: 900;
  overflow: hidden;
  .__react_component_tooltip.place-top::before {
    bottom: -1px;
    height: 8px !important;
  }
`;

const DialogContainer = styled.div`
  min-width: 250px;
  max-width: 550px;
  background: ${({ theme }) => theme.primaryBG};
  position: relative;
  flex: 0 0 auto;
  margin: 0px;
  padding: 30px;
  border-radius: 4px;
  font-family: Inter;
  overflow: auto;
  max-height: 100%;
  animation-name: ${DialogSlide};
  animation-duration: 0.3s;
  p {
    margin-bottom: 30px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  margin: 0 auto;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  animation-name: ${CloseIconAnimation};
  animation-duration: 0.4s;
`;

/**
 * Dialog Body that allows user to specify whatever content they want as the body of the dialog.
 * The only constant here is the presence of a close button.
 */
export const GenericDialogBody = ({
  show,
  closeDialog,
  children,
  showCloseButton = true,
}: {
  show: boolean;
  closeDialog: () => void;
  children: JSX.Element;
  showCloseButton?: boolean;
}) => {
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        closeDialog();
      }
    },
    [closeDialog]
  );

  useEffect(() => {
    document.addEventListener("keyup", escFunction);

    return () => {
      document.removeEventListener("keyup", escFunction);
    };
  }, [escFunction]);

  return show ? (
    ReactDOM.createPortal(
      <DialogOverlay>
        <DialogContainer>
          {children}
          {showCloseButton && (
            <CloseButton onClick={closeDialog}>
              <XIcon />
            </CloseButton>
          )}
        </DialogContainer>
      </DialogOverlay>,
      document.body
    )
  ) : (
    <></>
  );
};

const DialogBody = ({ ...props }: IDialogProps) => {
  const { t } = useTranslation();
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      props.closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", escFunction);

    return () => {
      document.removeEventListener("keyup", escFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <DialogOverlay>
      <DialogContainer>
        <p>{props.confirmMessage}</p>
        <ButtonsWrapper>
          <SecondaryButtonSmall
            onClick={
              props.handleCancel ? props.handleCancel : props.closeDialog
            }
          >
            {t("Cancel")}
          </SecondaryButtonSmall>
          {props.handleConfirm && (
            <PrimaryButtonSmall onClick={props.handleConfirm}>
              {t("Confirm")}
            </PrimaryButtonSmall>
          )}
        </ButtonsWrapper>
        <CloseButton onClick={props.closeDialog}>
          <XIcon />
        </CloseButton>
      </DialogContainer>
    </DialogOverlay>,
    document.body
  );
};

export const ConfirmDialog = ({ ...props }: IDialogProps) => {
  if (props.show) {
    return (
      <DialogBody
        show={props.show}
        closeDialog={props.closeDialog}
        confirmMessage={props.confirmMessage}
        handleConfirm={props.handleConfirm}
        handleCancel={props.handleCancel}
      >
        {props.children}
      </DialogBody>
    );
  }
  return null;
};
