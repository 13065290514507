import type { AxiosError } from "axios";
import Axios from "axios";
import type { FC } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import useSWR, { mutate } from "swr";
import { endpoints } from "../../endpoints";
import { ErrorHandler } from "../../ErrorHandler";
import type { LoginCredentials } from "../../pages/Misc/Login";
import type { Permission, RBACRole, TOTPSchema, User } from "../../types/types";
import { useStoreState } from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";

type AuthAction =
  | { type: "LOGIN"; payload: User }
  | { type: "UPDATE_USER"; payload: User }
  | { type: "LOGOUT"; payload: null };

type AuthState = User | null;

export type HasPermission = (
  p?: Permission | Permission[],
  permission_type?: "some" | "every"
) => boolean;

export type AuthProviderType = {
  user: AuthState;
  initiateLogin: (
    values: LoginCredentials,
    redirect_url?: string
  ) => Promise<{
    user: AuthState;
    loginError: Error | null;
    unauthenticated_user?: TOTPSchema;
  }>;
  logout: () => Promise<void>;
  updateUser: (user: User) => void;
  loginError: Error | undefined;
  logoutError: Error | undefined;
  role: RBACRole;
  permissions: Permission[];
  isLoadingSession: boolean;
  updatePermissions: (p: Permission[]) => void;
  hasPermission: HasPermission;
  roleIsSellerAdmin: boolean;
  roleIsBuyerAdmin: boolean;
  roleIsDistributor: boolean;
  roleIsDistributorAdmin: boolean;
  roleIsSomeKindOfSeller: boolean;
  roleIsSomeKindOfBuyer: boolean;
  roleIsBuyer: boolean;
  roleIsSellerStandard: boolean;
  roleIsGuest: boolean;
  roleIsAdmin: boolean;
};

const initial: AuthState = null;

function authReducer(state: AuthState, action: AuthAction) {
  const { type, payload } = action;
  switch (type) {
    case "LOGIN":
      return payload;
    case "UPDATE_USER":
      return payload;
    case "LOGOUT":
      return null;
    default:
      return state;
  }
}

export const Auth = createContext<AuthProviderType>({
  // These are dummy functions so that we don't have an any type here.
  // In the real world AuthProvider overwrites these.
  user: initial,
  logout: async () => {},
  initiateLogin: async (_values: LoginCredentials, _redirect_url?: string) => ({
    user: initial,
    loginError: null,
  }),
  updateUser: (_: User) => {},
  loginError: undefined,
  logoutError: undefined,
  role: "guest",
  isLoadingSession: true,
  updatePermissions: (_: Permission[]) => {},
  permissions: [],
  hasPermission: () => false,
  roleIsSellerAdmin: false,
  roleIsSomeKindOfSeller: false,
  roleIsSomeKindOfBuyer: false,
  roleIsBuyerAdmin: false,
  roleIsDistributor: false,
  roleIsDistributorAdmin: false,
  roleIsBuyer: false,
  roleIsSellerStandard: false,
  roleIsAdmin: false,
  roleIsGuest: false,
});

export const AuthProvider: FC = ({ children }) => {
  const [user, dispatch] = useReducer(authReducer, initial);

  const [loginError, setLoginError] = useState<Error | undefined>();
  const [logoutError, setLogoutError] = useState<Error | undefined>();
  const [role, setRole] = useState<RBACRole>("guest");
  const { storefront_id } = useStoreState();
  // This only exists in state to make it easier for local testing. Modifying
  // permissions in devtools is a lot faster than mocking with msw
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const updateUser = (user: User) => {
    dispatch({
      type: "UPDATE_USER",
      payload: user,
    });
  };

  const updatePermissions = useCallback(
    (permissions: Permission[]) => {
      // updateUser({ ...user!, permissions_list: permissions });
      setPermissions(permissions);
    },
    [setPermissions]
  );

  const { data, error: initialLoginError } = useSWR<User, AxiosError>(
    //  This will run once on public pages and return 401 if the user is not
    //  logged in.
    !user && storefront_id
      ? endpoints.v1_storefronts_id_users_login(storefront_id)
      : null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onSuccess: () => {
        if (!data?.totp_login_required) {
          ErrorHandler.setUser(data?.id as User["id"]);
        }
      },
    }
  );

  const isLoadingSession =
    data && data.totp_login_required
      ? false
      : user === null && !initialLoginError;

  if (data && !data.totp_login_required) {
    updateUser(data);
  }

  if (user) {
    if (permissions.length === 0 && user?.permissions_list?.length > 0) {
      setPermissions(user?.permissions_list ?? []);
    }
    if (!role || role === "guest") {
      setRole(user.rbac_role);
    }
  }

  useEffect(() => {
    if (initialLoginError) {
      dispatch({
        type: "LOGOUT",
        payload: null,
      });
      setRole("guest");
    }
  }, [initialLoginError]);

  const { notifyError } = useContext(Notifications);

  const initiateLogin = async (
    values: LoginCredentials,
    redirect_url?: string
  ) => {
    let error: AxiosError | null = null;
    let unauthenticated_user: TOTPSchema | undefined = undefined;

    // Clear chatbot thread from sessionStorage on login so that a new thread is created.
    // The data might be different inside login.
    sessionStorage.removeItem(`thread_${storefront_id}`);
    sessionStorage.removeItem("chatbotIsOpen");
    sessionStorage.removeItem("chatbotIsExpanded");

    try {
      const response = await Axios.post<User>(
        endpoints.v1_storefronts_id_users_login(storefront_id),
        values
      );

      if (response.status === 201) {
        const { data: user } = response;
        if (!user.totp_login_required) {
          ErrorHandler.setUser(response.data.id);
          if (redirect_url) {
            window.open(redirect_url, "_blank");
          }
          dispatch({
            type: "LOGIN",
            payload: user,
          });
          mutate(`/v2/storefronts/${storefront_id}/chatbots`);
        } else {
          const {
            has_totp_key,
            preferred_2fa_type,
            totp_last_authorized,
            totp_login_required,
            sms_2fa_phone_number,
          } = user;
          unauthenticated_user = {
            has_totp_key,
            preferred_2fa_type,
            totp_last_authorized,
            totp_login_required,
            sms_2fa_phone_number,
          };
        }
      }
    } catch (err) {
      error = err as AxiosError;
      setLoginError(err as Error);
      notifyError("There was an error logging in, please try again", {
        error: err,
      });
    }

    return { user, loginError: error, unauthenticated_user };
  };

  const logout = async () => {
    try {
      const response = await Axios.delete(
        endpoints.v1_storefronts_id_users_login(storefront_id)
      );
      if (response.status === 204 || response.status === 200) {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        // clear session storage
        sessionStorage.clear();
      }
    } catch (error) {
      notifyError("There was an error logging out, please try again", {
        error,
      });
      setLogoutError(error as Error);
    }
  };

  const hasPermission = (
    p?: Permission | Permission[],
    permission_type?: "some" | "every"
  ): boolean => {
    if (p === undefined) {
      return false;
    } else if (typeof p === "string") {
      return Boolean(permissions.find((perm) => perm === p));
    } else {
      return Boolean(
        permission_type
          ? p[permission_type]((value) => permissions.indexOf(value) >= 0)
          : p.some((value) => permissions.indexOf(value) >= 0)
      );
    }
  };

  return (
    <Auth.Provider
      value={{
        user,
        initiateLogin,
        logout,
        logoutError,
        loginError,
        updateUser,
        role,
        permissions,
        hasPermission,
        updatePermissions,
        //For cases where code runs before this data exists in the cache, check
        //to see if we have loaded the data rather than using default values.
        isLoadingSession,
        roleIsSellerAdmin: role === "seller_admin",
        roleIsSomeKindOfSeller:
          role === "seller_admin" || role === "seller_standard",
        roleIsSomeKindOfBuyer:
          role === "buyer_standard" ||
          role === "buyer_admin" ||
          role === "distributor" ||
          role === "distributor_admin",
        roleIsBuyerAdmin: role === "buyer_admin",
        roleIsDistributorAdmin: role === "distributor_admin",
        roleIsDistributor: role === "distributor",
        roleIsBuyer: role === "buyer_standard",
        roleIsSellerStandard: role === "seller_standard",
        roleIsAdmin:
          role === "seller_admin" ||
          role === "buyer_admin" ||
          role === "distributor_admin",
        roleIsGuest: role === "guest",
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export const AuthConsumer = Auth.Consumer;

/**
 * A helper to provide slightly streamlined usage, one import instead of two.
 */
export const useAuthContext = () => useContext(Auth);
