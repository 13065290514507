import type {
  IPagination,
  LocationState,
  SampleRequestStageTwo,
  SampleRequestStatusUnion,
  StatusAndId,
  User,
} from "../../../types/types";
import type { ChangeEvent, MouseEvent } from "react";
import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import {
  fetcher,
  useInAppNotifications,
  makeUrlWithParams,
  rowHover,
  useStoreState,
  convertStringArrayToObj,
  formatDate,
} from "../../../util/util";
import { formateInternationalPhoneNumber } from "../../../util/phone";
import { Table } from "../../../components/Table/Table";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import { SampleRequestStatus } from "../../../components/SampleRequestStatus/SampleRequestStatus";
import { Auth } from "../../../components/Auth";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { useDebounce } from "../../../util/hooks";
import { SampleRequestStatusFilters } from "../LeadsPage/leadsUtilities";
import { FiltersWrapper } from "../../../layout/portalPageLayout";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import type { ColumnDef } from "@tanstack/react-table";

type SampleRequestAPIResponse = {
  data: SampleRequestStageTwo[];
  pagination: IPagination;
};

type ApplyFiltersToURL = {
  params: URLSearchParams;
};

function applyFiltersToURL({ params }: ApplyFiltersToURL): URLSearchParams {
  return params;
}

/**
 * This is the page with a table of sample requests coming from guest users
 * (new leads).
 */
export const SampleRequestLeadList = ({
  perPage,
  tabIndex,
}: {
  perPage: number;
  tabIndex: number;
}) => {
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    status: ArrayParam,
    q: StringParam,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const { storefront_id } = useStoreState();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { user } = useContext(Auth);
  const { notifications } = useInAppNotifications(storefront_id, user as User);
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );

  const BASE_URL = `/v1/storefronts/${storefront_id}/sample-requests?`;

  function constructQuery() {
    const params = new URLSearchParams(
      `offset=${offset as number}&limit=${perPage}&q=${debouncedSearchQuery}`
    );

    if (selectedStatusList.length) {
      selectedStatusList.forEach((param) => params.append("status", param));
    }

    return (
      BASE_URL +
      applyFiltersToURL({
        params: params,
      })
    );
  }

  const { data: sampleRequestResponse, error: sampleRequestError } =
    useSWR<SampleRequestAPIResponse>(constructQuery(), fetcher);

  const isLoading = !sampleRequestResponse && !sampleRequestError;

  //table
  const [tableData, setTableData] = useState<any[]>([]);

  // Determine if the 'Region' column should be shown based on storefront settings
  const [showRegion, setShowRegion] = useState(false);
  useEffect(() => {
    if (sampleRequestResponse) {
      // Check if 'region' is present in any of the sample requests
      const hasRegion = sampleRequestResponse.data.some(
        (sample) => "region" in sample
      );
      setShowRegion(hasRegion);
    }
  }, [sampleRequestResponse]);

  const tableColumns = React.useMemo<
    ColumnDef<{
      id: string;
      companyName: string;
      fullName: string;
      assignee: string;
      email: string;
      phone: string;
      received: string;
      status: { status: string; id: string };
      unread: boolean;
      region: string;
    }>[]
  >(
    () => [
      {
        header: t("Customer Name"),
        accessorKey: "fullName",
      },
      {
        header: t("Company Name"),
        accessorKey: "companyName",
      },
      {
        header: t("Email"),
        accessorKey: "email",
      },
      ...(showRegion
        ? [
            {
              header: t("Region"),
              accessorKey: "region",
            },
          ]
        : []),
      {
        header: t("Date Received"),
        accessorKey: "received",
      },
      {
        header: t("Assignee"),
        accessorKey: "assignee",
      },
      {
        header: t("Status"),
        accessorKey: "status",
        align: "right",
        width: 50,
        minWidth: 50,
        cell: (cell) => (
          <SampleRequestStatus
            position="right"
            status={
              (cell.getValue() as StatusAndId<SampleRequestStatusUnion>).status
            }
            id={(cell.getValue() as StatusAndId<SampleRequestStatusUnion>).id}
            t={t}
          />
        ),
      },
    ],
    [t, showRegion]
  );

  useEffect(() => {
    setQuery({ offset, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      SampleRequestStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  useEffect(() => {
    const getUnread = (sample: SampleRequestStageTwo): boolean => {
      return notifications?.leads.sample_requests.ids
        ? notifications?.leads.sample_requests.ids.includes(sample?.id)
        : false;
    };

    const handleSampleData = ({
      data,
      pagination,
    }: SampleRequestAPIResponse) => {
      setTableData(
        data.map((sample: SampleRequestStageTwo) => ({
          id: sample.id,
          companyName: sample.buyer_company_name || "--",
          fullName:
            `${sample.buyer_first_name} ${sample.buyer_last_name}` || "--",
          assignee: sample.assignee
            ? `${sample.assignee?.firstname} ${sample.assignee?.lastname}`
            : "--",
          email: sample.buyer_email || "--",
          phone: sample.buyer_phone
            ? formateInternationalPhoneNumber(sample.buyer_phone)
            : "--",
          received: formatDate(sample.created_at),

          status: { status: sample.status || "--", id: sample.id },
          unread: getUnread(sample),
          region: sample?.region || "--",
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (sampleRequestResponse) {
      const { data: messages, pagination } = sampleRequestResponse;

      handleSampleData({ data: messages, pagination });
    }
  }, [sampleRequestResponse, setTableData, perPage, notifications, showRegion]);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const handleContactClick = (e: MouseEvent) => {
    history.push(
      makeUrlWithParams(`leads/sample-requests/${e.currentTarget.id}`, {
        offset,
        perPage,
      }),
      {
        listState: {
          offset,
          perPage,
          selectedStatusList,
          searchQuery,
          tabIndex,
        },
      }
    );
  };

  useEffect(() => {
    const listState = (location.state as LocationState)?.listState;

    if (listState) {
      setOffset(listState.offset ?? 0);
      setSelectedStatusList(listState.selectedStatusList ?? []);
      setSearchQuery(listState.searchQuery ?? "");

      // Clear the state after restoring it
      history.replace({ ...location, state: undefined });
    }
  }, [location, history]);

  return (
    <>
      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={SampleRequestStatusFilters(t)}
        />
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by Company Name, Customer or Assignee")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </FiltersWrapper>

      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={sampleRequestError}
        rowClick={handleContactClick}
        rowHover={rowHover}
      />
      <Pagination
        pagination={tablePagination}
        offset={offset}
        handlePageClick={changePage}
      />
    </>
  );
};
