import axios from "axios";
import { t } from "i18next";
import { useContext, useState } from "react";
import styled from "styled-components";
import {
  DownloadIcon,
  LoadingIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../endpoints";
import type { Assets } from "../../../../../types/types.PIM";

interface IStyledCalProps {
  disabled: boolean;
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DownloadButtonWrapper = styled.div<IStyledCalProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

interface BulkActionOnAssetsProps {
  rows: string[] | "all";
  resetSelectedRows: () => void;
  tenant_id: string;
  fetchAssetsForDownload: () => Promise<Assets[]>;
}

export const BulkActionOnAssets: React.FC<BulkActionOnAssetsProps> = ({
  rows,
  resetSelectedRows,
  tenant_id,
  fetchAssetsForDownload,
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [isLoading, setIsLoading] = useState(false);

  const resetDownloadState = () => {
    setIsLoading(false);
    resetSelectedRows();
  };

  const handleBulkAssetDownload = async (rows: string[] | "all") => {
    if (Array.isArray(rows) && rows.length > 0) {
      if (rows.length === 1) {
        if (fetchAssetsForDownload) {
          const assetsAllData = await fetchAssetsForDownload();
          const assetsId = assetsAllData.find((el) => el.id === rows[0]);
          if (assetsId) window.open(assetsId.signed_url, "_blank");
        }
      } else {
        setIsLoading(true);
        try {
          const response = await axios.post(
            endpoints.v2_storefronts_id_pim_assets_download(tenant_id),
            { data: rows }
          );
          const downloadId = response.data;
          if (downloadId.job_id) {
            await checkDownloadStatus(downloadId.job_id);
          }
        } catch (error) {
          resetDownloadState();
          notifyError(t("Error during the download process. Please try again"));
        }
      }
    }
  };

  const handleDownloadSuccess = async (downloadId: string) => {
    try {
      const response = await axios.get(
        endpoints.v2_storefronts_id_pim_assets_download_id(
          tenant_id,
          downloadId
        ),
        { responseType: "blob" }
      );

      const blob = response.data;
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "download.zip";

      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      resetDownloadState();
      notifySuccess(t("Download successful! Your file has been saved"));
    } catch (error) {
      resetDownloadState();
      notifyError(t("Failed to handle successful download. Please try again"));
    }
  };

  const checkDownloadStatus = async (downloadId: string) => {
    try {
      const response = await axios.get(
        endpoints.v2_storefronts_id_pim_assets_download_id_status(
          tenant_id,
          downloadId
        )
      );
      const data = response.data;

      if (data.status === "processing") {
        setTimeout(() => checkDownloadStatus(downloadId), 2000);
      } else if (data.status === "success") {
        await handleDownloadSuccess(downloadId);
      } else if (data.status === "failed") {
        resetDownloadState();
        notifyError(t("File compression failed. Please try again"));
      }
    } catch (error) {
      resetDownloadState();
      notifyError(t("Failed to check download status. Please try again"));
    }
  };

  return (
    <ButtonWrapper>
      {isLoading && <LoadingIcon width={22} height={22} />}
      <DownloadButtonWrapper
        onClick={() => handleBulkAssetDownload(rows)}
        disabled={isLoading}
      >
        <DownloadIcon width={16} height={16} />
        {t("Download")}
      </DownloadButtonWrapper>
    </ButtonWrapper>
  );
};
