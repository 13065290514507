import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { useAuthContext } from "../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { LoadingIcon } from "../../../../components/Icons/Icons";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { StatusLeft } from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import { Flex, Flex1, Flex2 } from "../../../../layout/FormLayout";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import type {
  DataMutate,
  TeamPermissionsEnum,
  TeamProductPermissionSchema,
  TeamProductSchema,
  TeamSummary,
  UUID,
  WithPagination,
} from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { useDebounce } from "../../../../util/hooks";
import {
  defaultHandleSort,
  TablePlaceholder,
  useStoreState,
} from "../../../../util/util";
import { AddTeamsToProducts } from "./SellerAdminAddTeamsToProducts";
import {
  CheckboxNoMargin,
  HeaderCheckboxContainer,
  SpinnerContainer,
} from "./seller_admin.utils";

type TableData = TeamSummary & { id: string } & TeamProductPermissionSchema;

export function SellerAdminTeamsListByProduct({
  product,
  mutateProduct,
}: {
  product: PIMProduct;
  mutateProduct: DataMutate<PIMProduct>;
}) {
  const { t } = useTranslation();

  const {
    tenant_id,
    storefront_metadata: { enable_team_product_permissions },
  } = useStoreState();

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const [is_all_view_permissions_enabled, set_is_all_view_permissions_enabled] =
    useState(false);
  const [is_all_edit_permissions_enabled, set_is_all_edit_permissions_enabled] =
    useState(false);
  const [
    is_all_publish_permissions_enabled,
    set_is_all_publish_permissions_enabled,
  ] = useState(false);

  const [team_in_edit, set_team_in_edit] = useState<TableData | null>(null);
  const [property_in_edit, set_property_in_edit] =
    useState<keyof TeamProductPermissionSchema | null>(null);
  const [is_all_team_edit_loading, set_is_all_team_edit_loading] =
    useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const { roleIsSellerAdmin } = useAuthContext();
  const { notifyError, notifySuccess } = useNotifications();
  const [showAddTeamToProductSlideOut, setShowAddTeamToProductSlideOut] =
    useState(false);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  function constructQuery({ baseUrl, query }: any) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}&is_active=true`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return baseUrl + "?" + paramsWithOffset;
  }

  const {
    data: teamsResponse,
    error: teamsError,
    mutate: mutateTeamList,
  } = useSWR<WithPagination<{ data: TeamProductSchema[] }>>(
    constructQuery({
      baseUrl: `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams`,
      query: debouncedSearchQuery,
    })
  );

  const non_team_details = useMemo((): TableData => {
    const non_team_viewable =
      product.non_team_permission === "can_view" ||
      product.non_team_permission === "can_edit" ||
      product.non_team_permission === "can_publish";
    const non_team_editable =
      product.non_team_permission === "can_edit" ||
      product.non_team_permission === "can_publish";
    const non_team_publishable = product.non_team_permission === "can_publish";
    return {
      team_id: "non_team_permission",
      team_name: t("Non Team Users"),
      number_of_products: 0,
      number_of_users: 0,
      status: "active",
      id: "non_team_permission",
      tenant_id,
      can_view: non_team_viewable,
      can_edit: non_team_editable,
      can_publish: non_team_publishable,
    };
  }, [product.non_team_permission, t, tenant_id]);

  const mutate = useCallback(async () => {
    await mutateTeamList();
    await mutateProduct();
  }, [mutateProduct, mutateTeamList]);

  const handleRemoveTeam = useCallback(
    (id: UUID) => {
      const removeTeam = async () => {
        try {
          await axios.patch(
            `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams`,
            { team_ids: [id] }
          );
          notifySuccess(t("Team removed successfully"));
          await mutate();
        } catch (error) {
          notifyError(t("There was an error removing the team"));
        }
      };
      removeTeam();
    },
    [mutate, notifyError, notifySuccess, product.id, t, tenant_id]
  );

  const onAddTeamSuccess = async () => {
    setShowAddTeamToProductSlideOut(false);
    await mutate();
  };

  const update_all_permissions = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      property_in_edit: keyof TeamProductPermissionSchema
    ) => {
      const on_update = async (
        e: React.ChangeEvent<HTMLInputElement>,
        property_in_edit: keyof TeamProductPermissionSchema
      ) => {
        try {
          set_is_all_team_edit_loading(true);
          set_property_in_edit(property_in_edit);
          const value = e.target.checked;
          let permission: TeamPermissionsEnum = "no_permission";
          if (property_in_edit === "can_view" && value) {
            permission = "can_view";
          }
          if (property_in_edit === "can_edit") {
            permission = value ? "can_edit" : "can_view";
          }
          if (property_in_edit === "can_publish") {
            permission = value ? "can_publish" : "can_edit";
          }
          await axios.patch(
            endpoints.v2_tenants_tenant_id_pim_products_product_id_all_team_permissions(
              tenant_id,
              product.product_number ?? product.id
            ),
            {
              permissions: permission,
            }
          );
          await axios.patch(
            endpoints.v2_tenants_tenant_id_pim_products_product_id_non_team_permissions(
              tenant_id,
              product.product_number ?? product.id
            ),
            {
              permissions: permission,
            }
          );
          await mutate();
          if (property_in_edit === "can_view") {
            set_is_all_view_permissions_enabled(value);
          } else if (property_in_edit === "can_edit") {
            set_is_all_edit_permissions_enabled(value);
          } else {
            set_is_all_publish_permissions_enabled(value);
          }
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not update team permission. Please try again."),
            {
              error,
            }
          );
        } finally {
          set_is_all_team_edit_loading(false);
          set_property_in_edit(null);
        }
      };
      on_update(e, property_in_edit);
    },
    [mutate, notifyError, product.id, product.product_number, t, tenant_id]
  );

  const update_team_product_permissions = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      team: TableData,
      property_in_edit: keyof TeamProductPermissionSchema
    ) => {
      const on_update = async (
        e: React.ChangeEvent<HTMLInputElement>,
        team: TableData,
        property_in_edit: keyof TeamProductPermissionSchema
      ) => {
        try {
          set_team_in_edit(team);
          set_property_in_edit(property_in_edit);
          let permission: TeamPermissionsEnum = "no_permission";
          if (property_in_edit === "can_view") {
            permission = e.target.checked ? "can_view" : "no_permission";
          }
          if (property_in_edit === "can_edit") {
            permission = e.target.checked ? "can_edit" : "can_view";
          }
          if (property_in_edit === "can_publish") {
            permission = e.target.checked ? "can_publish" : "can_edit";
          }
          if (team.id === "non_team_permission") {
            await axios.patch(
              endpoints.v2_tenants_tenant_id_pim_products_product_id_non_team_permissions(
                tenant_id,
                product.product_number ?? product.id
              ),
              {
                permissions: permission,
              }
            );
          } else {
            await axios.patch(
              endpoints.v2_tenants_tenant_id_pim_teams_team_id_products_product_id_permissions(
                tenant_id,
                team.team_id,
                product.product_number ?? product.id
              ),
              {
                permissions: permission,
              }
            );
          }
          await mutate();
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not update team permission. Please try again."),
            {
              error,
            }
          );
        } finally {
          set_team_in_edit(null);
          set_property_in_edit(null);
        }
      };
      on_update(e, team, property_in_edit);
    },
    [mutate, notifyError, product.id, product.product_number, t, tenant_id]
  );

  const is_team_property_loading = useCallback(
    (team_id: string, prop: keyof TeamProductPermissionSchema) => {
      return team_in_edit?.team_id === team_id && property_in_edit === prop;
    },
    [property_in_edit, team_in_edit?.team_id]
  );

  const is_team_property_update_in_progress = useCallback(
    (team_id: string) => team_in_edit?.team_id === team_id,
    [team_in_edit?.team_id]
  );

  const isLoading = !teamsResponse && !teamsError;

  const can_edit_teams = useMemo(
    () =>
      product.is_editable && product.status !== "archived" && roleIsSellerAdmin,
    [product.is_editable, product.status, roleIsSellerAdmin]
  );

  const team_permission_headers = useMemo(
    (): Array<ColumnDef<TableData>> => [
      {
        header: () => (
          <>
            {property_in_edit === "can_view" && is_all_team_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="view_all_checkbox"
                  id="view_all_checkbox_id"
                  checked={is_all_view_permissions_enabled}
                  disabled={is_all_team_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_view")}
                />
                {t("View")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_view",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_team_property_loading(cell.row.original.team_id, "can_view") ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="view_checkbox"
                id="view_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_team_property_update_in_progress(
                    cell.row.original.team_id
                  ) || is_all_team_edit_loading
                }
                onChange={(e) =>
                  update_team_product_permissions(
                    e,
                    cell.row.original,
                    "can_view"
                  )
                }
              />
            )}
          </>
        ),
      },
      {
        header: () => (
          <>
            {property_in_edit === "can_edit" && is_all_team_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="edit_all_checkbox"
                  id="edit_all_checkbox_id"
                  checked={is_all_edit_permissions_enabled}
                  disabled={is_all_team_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_edit")}
                />
                {t("Edit")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_edit",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_team_property_loading(cell.row.original.team_id, "can_edit") ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="edit_checkbox"
                id="edit_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_team_property_update_in_progress(
                    cell.row.original.team_id
                  ) || is_all_team_edit_loading
                }
                onChange={(e) =>
                  update_team_product_permissions(
                    e,
                    cell.row.original,
                    "can_edit"
                  )
                }
              />
            )}
          </>
        ),
      },
      {
        header: () => (
          <>
            {property_in_edit === "can_publish" && is_all_team_edit_loading ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <HeaderCheckboxContainer>
                <CheckboxNoMargin
                  name="publish_all_checkbox"
                  id="publish_all_checkbox_id"
                  checked={is_all_publish_permissions_enabled}
                  disabled={is_all_team_edit_loading}
                  onChange={(e) => update_all_permissions(e, "can_publish")}
                />
                {t("Publish/Unpublish")}
              </HeaderCheckboxContainer>
            )}
          </>
        ),
        accessorKey: "can_publish",
        enableSorting: false,
        cell: (cell) => (
          <>
            {is_team_property_loading(
              cell.row.original.team_id,
              "can_publish"
            ) ? (
              <SpinnerContainer>
                <LoadingIcon width={24} height={24} />
              </SpinnerContainer>
            ) : (
              <CheckBoxNoLabel
                name="publish_checkbox"
                id="publish_checkbox_id"
                checked={cell.getValue() as boolean}
                disabled={
                  is_team_property_update_in_progress(
                    cell.row.original.team_id
                  ) || is_all_team_edit_loading
                }
                onChange={(e) =>
                  update_team_product_permissions(
                    e,
                    cell.row.original,
                    "can_publish"
                  )
                }
              />
            )}
          </>
        ),
      },
    ],
    [
      is_all_edit_permissions_enabled,
      is_all_team_edit_loading,
      is_all_publish_permissions_enabled,
      is_all_view_permissions_enabled,
      is_team_property_loading,
      is_team_property_update_in_progress,
      property_in_edit,
      t,
      update_all_permissions,
      update_team_product_permissions,
    ]
  );

  const tableColumns = React.useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("Team Name"),
        accessorKey: "team_name",
      },
      {
        header: t("# of users"),
        accessorKey: "number_of_users",
        cell: (cell) => (
          <span>
            {cell.row.original.team_id === "non_team_permission"
              ? "--"
              : (cell.getValue() as number)}
          </span>
        ),
      },
      {
        header: t("# of Products"),
        accessorKey: "number_of_products",
        cell: (cell) => (
          <span>
            {cell.row.original.team_id === "non_team_permission"
              ? "--"
              : (cell.getValue() as number)}
          </span>
        ),
      },
      ...(enable_team_product_permissions && can_edit_teams
        ? team_permission_headers
        : []),
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: (cell) =>
          cell.row.original.team_id === "non_team_permission" ? (
            <span>{"--"}</span>
          ) : (
            <StatusLeft
              color={cell.getValue() === "active" ? "green" : "red"}
              text={cell.getValue() as string}
              textStyle={{ fontSize: "13px" }}
              productStatusType="product"
            />
          ),
      },
      ...(can_edit_teams
        ? [
            {
              header: "",
              accessorKey: " ",
              enableSorting: false,
              cell: ({ row: { original } }: { row: { original: TableData } }) =>
                original.team_id === "non_team_permission" ? (
                  <></>
                ) : (
                  <ButtonWithConfirmDialog
                    Button={DeleteButton}
                    testid={"remove-team-from-product"}
                    handleConfirm={() => handleRemoveTeam(original.id)}
                    confirmMessage={t(
                      "Are you sure you want to remove this team?"
                    )}
                  />
                ),
            },
          ]
        : []),
    ],
    [
      t,
      enable_team_product_permissions,
      team_permission_headers,
      can_edit_teams,
      handleRemoveTeam,
    ]
  );

  useEffect(() => {
    const handleTeamsData = ({
      data,
      pagination,
    }: WithPagination<{ data: TeamProductSchema[] }>) => {
      setTableData([
        ...(enable_team_product_permissions ? [non_team_details] : []),
        ...data.map<TableData>(({ team, can_edit, can_publish, can_view }) => ({
          ...team,
          id: team.team_id,
          can_edit,
          can_publish,
          can_view,
        })),
      ]);
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (teamsResponse) {
      const { data: products, pagination } = teamsResponse;
      handleTeamsData({ data: products, pagination });
    }
  }, [
    enable_team_product_permissions,
    non_team_details,
    perPage,
    teamsResponse,
  ]);

  useEffect(() => {
    if (teamsResponse) {
      const { data } = teamsResponse;
      const is_all_team_view_permissions_enabled = data.every(
        (team) => team.can_view
      );
      const is_all_team_edit_permissions_enabled = data.every(
        (team) => team.can_edit
      );
      const is_all_team_publish_permissions_enabled = data.every(
        (team) => team.can_publish
      );
      set_is_all_view_permissions_enabled(
        is_all_team_view_permissions_enabled && non_team_details.can_view
      );
      set_is_all_edit_permissions_enabled(
        is_all_team_edit_permissions_enabled && non_team_details.can_edit
      );
      set_is_all_publish_permissions_enabled(
        is_all_team_publish_permissions_enabled && non_team_details.can_publish
      );
    }
  }, [
    non_team_details.can_edit,
    non_team_details.can_publish,
    non_team_details.can_view,
    teamsResponse,
  ]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData(enable_team_product_permissions ? [non_team_details] : []);
  };

  return (
    <>
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Team Name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            {can_edit_teams && (
              <PrimaryButtonWithPlusIcon
                onClick={() => setShowAddTeamToProductSlideOut(true)}
                style={{ padding: "8px 12px" }}
              >
                {t("Add")}
              </PrimaryButtonWithPlusIcon>
            )}
            <SlideOut
              show={showAddTeamToProductSlideOut}
              closeFlyout={() => setShowAddTeamToProductSlideOut(false)}
            >
              <AddTeamsToProducts
                productID={product.id}
                onSuccess={onAddTeamSuccess}
                onCancel={() => setShowAddTeamToProductSlideOut(false)}
              />
            </SlideOut>
          </div>
        </Flex1>
      </Flex>

      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        ></DropDown>
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={teamsError}
          lastChildleftAlign={!can_edit_teams}
          // rowClick={handleRowClick}
          handleSort={handleSort}
          Placeholder={
            <TablePlaceholder
              message={
                !!teamsError
                  ? t(
                      "There was an error fetching customers. Please try again later."
                    )
                  : t(
                      "No items to show. Please click the add button to add teams."
                    )
              }
            />
          }
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
}
