import { zodResolver } from "@hookform/resolvers/zod";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import styled from "styled-components/macro";
import useSWR from "swr";
import { SecondaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { endpoints } from "../../../../../endpoints";
import type {
  ListItemPatchArgSchema,
  ListItemSchema,
  PaginatedListItemSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import {
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import type { ListItemFormValue } from "./ListHome";
import {
  ListHeader,
  ARCHIVED_UUID,
  IconContainer,
  MatchCount,
} from "./ListHome";
import { ListItemDetails } from "./ListItemDetails";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { ListItemBase, ListItemName, ListItemWrapper } from "./ListItem";
import { EditableTitle } from "../../../../../components/EditableTitle/EditableTitle";
import type { DataMutate } from "../../../../../types/types";
import { H5Normal } from "../../../../../components/Typography/Typography";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { ArchivedListItemDetails } from "./ArchivedListItemDetails";
import { CaretRight } from "../../../../../components/Icons/Icons";
import { useAuthContext } from "../../../../../components/Auth";
import { StringParam, useQueryParams } from "use-query-params";
import { Form } from "../../../../../layout/FormLayout";
import { zodRequiredString } from "../../../../../util/zod.util";
import { z } from "zod";

export const ListDetailsParent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  &:after {
    content: " ";
    width: 15px;
    height: 100%;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ListDetailsWrapper = styled.div<{ height: string }>`
  flex: 2 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: ${({ theme }) => `2px solid ${theme.secondaryBorder}`};
`;

const ListWrapper = styled.div`
  height: 100%;
  overflow: auto;
  min-height: 250px;
`;

const PerPageWrapper = styled.div`
  div[class^="DropDown__DropDownModalWrapper"] {
    bottom: 100%;
    top: auto;
  }
`;

const EmptyItemsContainer = styled.div`
  margin: 16px;
  padding: 24px;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ListDetailsHeader = styled(ListHeader)`
  border-right: none;
`;

export const ItemDetailsWrapper = styled(ListItemWrapper)`
  > h3 {
    max-width: 350px;
  }
  border-right: ${({ theme, active }) =>
    active ? `2px solid ${theme.brandColor}` : "none"};
`;

export const ListItemNameSchemaFn = (t: TFunction, maxLength?: number) =>
  z.object({
    name: zodRequiredString(t).max(
      maxLength ?? 25,
      t("Maximum {{chars}} characters allowed", { chars: maxLength ?? 25 })
    ),
  });

export const ListDetails = ({
  item,
  mutateList,
  pageHeight,
  selectSearchInputOption,
  shouldFetchQuery = false,
}: {
  item: ShortListSchema;
  mutateList: DataMutate<PaginatedListSchema>;
  pageHeight: string;
  selectSearchInputOption?: string;
  shouldFetchQuery?: boolean;
}) => {
  const perPageOptions = [10, 20, 50];
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [listItems, setListItems] = useState<ListItemSchema[]>();
  const [selectedItem, setSelectedItem] = useState<string>();
  const [showAddItem, setShowAddItem] = useState(false);
  const { t } = useTranslation();
  const [query] = useQueryParams({
    q: StringParam,
  });
  const addItemRef =
    useRef<{ setEditMode: (editMode: boolean) => void; focus: () => void }>(
      null
    );
  const addItemUseForm = useFormWrapper({
    resolver: zodResolver(ListItemNameSchemaFn(t, 50)),
  });
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { hasPermission } = useAuthContext();

  const {
    data: activeListData,
    error: activeListItemError,
    mutate: mutateActiveListItem,
  } = useSWR<PaginatedListItemSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists_id_items(tenant_id, item.id),
      useMemo(() => {
        const params = { offset, limit: perPage, q: "", status: "active" };

        if (!shouldFetchQuery && query.q) {
          params.q = query.q;
        }

        return { params };
      }, [offset, perPage, query.q, shouldFetchQuery]),
      selectSearchInputOption,
    ],
    {
      revalidateOnFocus: false,
      onSuccess: ({ data }) => {
        setListItems(data);
        setSelectedItem(selectSearchInputOption);
      },
    }
  );

  const {
    data: archivedListData,
    error: archivedListItemError,
    mutate: mutateArchivedListItem,
  } = useSWR<PaginatedListItemSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists_id_items(tenant_id, item.id),
      useMemo(
        () => ({
          params: { offset, limit: perPage, q: query.q, status: "archived" },
        }),
        [offset, perPage, query.q]
      ),
    ],
    {
      revalidateOnFocus: false,
    }
  );

  const handleClick = useCallback((name: string | undefined) => {
    setSelectedItem(name);
  }, []);

  const handleAddItem = () => {
    setShowAddItem(true);
    setTimeout(() => addItemRef.current?.setEditMode(true));
  };

  const showArchived = () => selectedItem === ARCHIVED_UUID;

  const handleAddItemConfirm = async ({ name }: ListItemFormValue) => {
    try {
      await Axios.post<{ name: string }>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id_items(tenant_id, item.id),
        {
          name: name.trim(),
        }
      );
      notifySuccess(t("List item added successfully"));
      setShowAddItem(false);
      await mutateActiveListItem();
      await mutateList();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not add list item. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const handleDeleteListItem = useCallback(
    (itemDetail: ListItemSchema) => {
      const deleteItem = async (listItem: ListItemSchema) => {
        try {
          await Axios.delete(
            endpoints.v2_tenants_id_or_slug_pim_lists_id_items_id(
              tenant_id,
              item.id,
              listItem.id
            )
          );
          notifySuccess(t("List item archived successfully"));
          await mutateActiveListItem();
          await mutateArchivedListItem();
          handleClick(undefined);
          await mutateList();
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not archive list item. Something went wrong."),
            {
              error,
            }
          );
        }
      };
      deleteItem(itemDetail);
    },
    [
      handleClick,
      item.id,
      mutateActiveListItem,
      mutateArchivedListItem,
      mutateList,
      notifyError,
      notifySuccess,
      t,
      tenant_id,
    ]
  );

  const handleEditListItem = useCallback(
    (
      formValues: { name?: string; icon?: File; delete_icon?: boolean },
      listItemDetail: ListItemSchema,
      setEditMode: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const editItem = async (
        formValues: { name?: string; icon?: File; delete_icon?: boolean },
        listItem: ListItemSchema
      ) => {
        try {
          const formData = new FormData();
          if (formValues.name) {
            formData.append("name", formValues.name?.trim());
          }
          if (formValues.delete_icon) {
            formData.append(
              "delete_icon",
              JSON.stringify(formValues.delete_icon)
            );
          } else if (formValues.icon) {
            formData.append("icon", formValues.icon);
          }
          await Axios.patch<ListItemPatchArgSchema, ListItemSchema>(
            endpoints.v2_tenants_id_or_slug_pim_lists_id_items_id(
              tenant_id,
              item.id,
              listItem.id
            ),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          notifySuccess(t("List item edited successfully"));
          mutateList();
          mutateActiveListItem();
          setEditMode(false);
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not edit list item. Something went wrong."),
            {
              error,
            }
          );
        }
      };
      editItem(formValues, listItemDetail);
    },
    [
      item.id,
      mutateActiveListItem,
      mutateList,
      notifyError,
      notifySuccess,
      t,
      tenant_id,
    ]
  );

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const restoreArchivedItem = async () => {
    if (
      archivedListData &&
      archivedListData.data.length === 1 && // if the last archived item is about to be restored. unselect archived items
      selectedItem === ARCHIVED_UUID
    ) {
      setSelectedItem(undefined);
    }
    await mutateActiveListItem();
    await mutateArchivedListItem();
    await mutateList();
  };

  useEffect(() => {
    setPagination({
      perPage: perPage,
      pageCount: Math.ceil((activeListData?.pagination.total ?? 10) / perPage),
      pageIndex: (activeListData?.pagination.offset ?? 10) / perPage + 1,
    });
  }, [
    activeListData?.pagination.offset,
    activeListData?.pagination.total,
    perPage,
  ]);

  useEffect(() => {
    setOffset(0);
    setListItems(undefined);
    setSelectedItem(undefined);
  }, [item.id]);

  const isLoading = !activeListData && !activeListItemError;

  if (isLoading || listItems === undefined) {
    return <DelayedSpinner />;
  }

  if (activeListItemError || archivedListItemError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error fetching the list details. Please try again later."
        )}
      />
    );
  }

  return (
    <ListDetailsParent>
      {activeListData && archivedListData && (
        <ListDetailsWrapper height={"100%"}>
          <ListDetailsHeader>
            <span>{toTitleCase(item.name)}</span>

            {activeListData.data.length > 0 && (
              <>
                {!showAddItem && hasPermission("modify_lists") && (
                  <SecondaryButtonWithPlusIcon onClick={handleAddItem}>
                    <span style={{ fontSize: "15px" }}>{t("New Value")}</span>
                  </SecondaryButtonWithPlusIcon>
                )}
              </>
            )}
          </ListDetailsHeader>
          <ListWrapper>
            {listItems &&
              listItems?.length > 0 &&
              listItems.map((itemDetail) => (
                <ListItemDetails
                  parentList={item}
                  key={itemDetail.id}
                  showIcon={item.icons_enabled}
                  item={itemDetail}
                  active={selectedItem === itemDetail.name}
                  selectListItem={() => handleClick(itemDetail.name)}
                  handleDeleteListItem={() => handleDeleteListItem(itemDetail)}
                  handleEditListItem={(
                    formValues: {
                      name?: string;
                      icon?: File;
                      delete_icon?: boolean;
                    },
                    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
                  ) => handleEditListItem(formValues, itemDetail, setEditMode)}
                />
              ))}
          </ListWrapper>
          {archivedListData?.pagination.total > 0 && (
            <ListItemWrapper
              onClick={() => setSelectedItem(ARCHIVED_UUID)}
              active={selectedItem === ARCHIVED_UUID}
            >
              <ListItemName>{`${t("Archived")} (${
                archivedListData?.pagination.total ?? 0
              })`}</ListItemName>
              <IconContainer>
                {query.q && Boolean(query.q.trim().length) && (
                  <MatchCount>
                    {archivedListData.pagination.total === 1
                      ? t("1 match")
                      : archivedListData.pagination.total > 1
                      ? t("{{count}} matches", {
                          count: archivedListData.pagination.total,
                        })
                      : t("No match")}
                  </MatchCount>
                )}
                <CaretRight />
              </IconContainer>
            </ListItemWrapper>
          )}
          <div>
            {showAddItem && (
              <ItemDetailsWrapper style={{ padding: "16px" }}>
                <Form
                  id="add-item-form"
                  noValidate
                  onSubmit={addItemUseForm.handleSubmit(handleAddItemConfirm)}
                >
                  <EditableTitle
                    title=""
                    formId="add-item-form"
                    name="name"
                    methodsOfUseForm={addItemUseForm}
                    ref={addItemRef}
                    onClose={() => setShowAddItem(false)}
                    fontSize="regular"
                    fontWeight="regular"
                  />
                </Form>
              </ItemDetailsWrapper>
            )}
            {activeListData.data.length === 0 && (
              <EmptyItemsContainer>
                {hasPermission("modify_lists") ? (
                  <>
                    <H5Normal style={{ marginBottom: "16px" }}>
                      {t("Click the button to add values.")}
                    </H5Normal>
                    <SecondaryButtonWithPlusIcon onClick={handleAddItem}>
                      <span style={{ fontSize: "15px" }}>{t("New Value")}</span>
                    </SecondaryButtonWithPlusIcon>
                  </>
                ) : (
                  <H5Normal>
                    {t("You don't have permission to add new values to Lists")}
                  </H5Normal>
                )}
              </EmptyItemsContainer>
            )}

            {pagination.pageCount > 1 && (
              <ListItemBase style={{ height: "fit-content" }}>
                <Pagination
                  pagination={pagination}
                  offset={offset}
                  handlePageClick={(offset) => setOffset(offset)}
                  miniPagination={true}
                />

                <PerPageWrapper style={{ zIndex: "2" }}>
                  <DropDown
                    items={perPageOptions}
                    activeItem={perPage}
                    textLeft={t("items") + ":"}
                    textRight={t("Per Page")}
                    direction={"right"}
                    className={"per_Page"}
                    clickHandler={changePerPage}
                  />
                </PerPageWrapper>
              </ListItemBase>
            )}
          </div>
        </ListDetailsWrapper>
      )}
      {showArchived() && (
        <ArchivedListItemDetails
          item={item}
          onRestore={restoreArchivedItem}
          pageHeight={"100%"}
        />
      )}
    </ListDetailsParent>
  );
};
