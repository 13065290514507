import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { z } from "zod";
import {
  ConfirmOrCancelButtonContainer,
  DeleteButton,
  SecondaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { SearchSelectInfiniteScroll } from "../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { TextField } from "../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../endpoints";
import { Form } from "../../../../layout/FormLayout";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import type {
  AssignedProductSchema,
  AssignedUserSchema,
  OptionType,
  UpdateTeamSchema,
  User,
  UUID,
} from "../../../../types/types";
import type { PIMProductBase } from "../../../../types/types.PIM";
import { findNonEmptyDuplicateIndexes } from "../../../../util/form.utils";
import { useFormWrapper, useStoreState } from "../../../../util/util";
import {
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../util/zod.util";
import { MarginBottomH6 } from "../PIM/SellerAdminPIMAttributes/CreateAttribute";
import { FullWidthSelectWithDeleteButton } from "./SellerAdminCreateNewTeam";

const CreateTeamSchemaBase = z.object({
  team_name: z.string(),
  first_user: zodSelectBoxType,
  first_product: zodSelectBoxType,
  users: z
    .object({
      user: zodSelectBoxType,
    })
    .array()
    .optional(),
  products: z.object({ product: zodSelectBoxType }).array().optional(),
});

const CreateTeamSchema = (t: TFunction) =>
  z
    .object({
      team_name: zodRequiredString(t),
      first_user: zodSelectBoxDefault(t),
      first_product: zodSelectBoxDefault(t),
      users: z
        .object({
          user: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
      products: z
        .object({
          product: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_user, first_product, products, users }, ctx) => {
      const duplicateUserIdxs = users
        ? findNonEmptyDuplicateIndexes([
            first_user.value,
            ...users.map(({ user: { value } }) => value),
          ])
        : [];

      if (duplicateUserIdxs.length > 0) {
        duplicateUserIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Cannot add the same user twice"),
            path: [`users[${idx - 1}].user`],
          });
        });
      }

      const duplicateProductIdxs = products
        ? findNonEmptyDuplicateIndexes([
            first_product.value,
            ...products.map(({ product: { value } }) => value),
          ])
        : [];

      if (duplicateProductIdxs.length > 0) {
        duplicateProductIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Cannot add the same product twice"),
            path: [`products[${idx - 1}].product`],
          });
        });
      }
    });

type FormOutput = z.infer<typeof CreateTeamSchemaBase>;

export const SellerAdminEditTeam = ({
  onCancel,
  products,
  users,
  teamName,
  onSuccess,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  products: (AssignedProductSchema & { product_id: string })[];
  users: AssignedUserSchema[];
  teamName: string;
}) => {
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const { team_id } = useParams<{ team_id: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = useMemo(() => {
    const userDefaultValues = users.reduce((acc, user, index) => {
      if (index !== 0) {
        acc.push({
          user: {
            label: `${user.firstname} ${user.lastname}`,
            value: user.user_id,
          },
        });
      }
      return acc;
    }, [] as { user: OptionType }[]);

    const productDefaultValues = products.reduce((acc, product, index) => {
      if (index !== 0) {
        acc.push({
          product: {
            label: product.product_name,
            value: product.product_id,
          },
        });
      }
      return acc;
    }, [] as { product: OptionType }[]);
    return {
      first_user:
        users?.length > 0
          ? {
              label: `${users[0].firstname} ${users[0].lastname}`,
              value: users[0].user_id,
            }
          : { label: "", value: "" },
      first_product:
        products?.length > 0
          ? {
              label: products[0].product_name,
              value: products[0].product_id,
            }
          : { label: "", value: "" },
      team_name: teamName,
      users: userDefaultValues,
      products: productDefaultValues,
    };
  }, [products, teamName, users]);

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateTeamSchema(t)),
    defaultValues,
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, formState, errors, register } =
    methodsOfUseForm;

  const {
    fields: userFields,
    remove: removeUserSelect,
    append: appendUserSelect,
  } = useFieldArray({
    control,
    name: "users",
  });

  const {
    fields: productFields,
    remove: removeProductSelect,
    append: appendProductSelect,
  } = useFieldArray({
    control,
    name: "products",
  });

  const createUserFields = useCallback(
    () =>
      userFields.map((field, index: number) => (
        <FullWidthSelectWithDeleteButton key={field.id}>
          {/* This is intentionally wrapped in Controller,
     not getting the values in the onSubmit otherwise.
     Seems to be some interaction between setValue and the fieldArray,
      some github issues are adjacent to this but never the exact problem */}
          <Controller
            as={SearchSelectInfiniteScroll}
            control={control}
            name={`users[${index}].user`}
            key={field.id}
            errors={{
              [`users[${index}].user`]:
                errors?.users?.[index]?.user?.value ??
                errors?.users?.[index]?.user ??
                undefined,
            }}
            defaultValue={field.user}
            formState={formState}
            placeholder={t("Select User")}
            baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
              storefront_id,
              tenant_id
            )}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              return params;
            })()}
            getOptions={(response: User[]) =>
              response.map((user) => ({
                value: user.id,
                label: `${user.firstname} ${user.lastname}`,
              }))
            }
          />
          <DeleteButton
            testid={`delete-button-${index}`}
            onClick={() => removeUserSelect(index)}
            type="button"
            height={20}
            width={20}
          />
        </FullWidthSelectWithDeleteButton>
      )),
    [
      control,
      errors?.users,
      formState,
      removeUserSelect,
      storefront_id,
      t,
      tenant_id,
      userFields,
    ]
  );

  const createProductFields = useCallback(
    () =>
      productFields.map((field, index: number) => (
        // Attempting to put the key on the container for only the product to
        // see if this is the issue
        <FullWidthSelectWithDeleteButton key={field.id}>
          <Controller
            control={control}
            as={SearchSelectInfiniteScroll}
            key={field.id}
            name={`products[${index}].product`}
            errors={{
              [`products[${index}].product`]:
                errors?.products?.[index]?.product?.value ??
                errors?.products?.[index]?.product ??
                undefined,
            }}
            formState={formState}
            defaultValue={field.product}
            placeholder={t("Select Product")}
            baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              params.append("show_inactive", "true");
              return params;
            })()}
            value={
              field.value
                ? { value: field.value, label: field.label }
                : undefined
            }
            getOptions={(response: PIMProductBase[]) =>
              response.map((product) => ({
                value: product.id,
                label: product.name,
              }))
            }
          />
          <DeleteButton
            testid={`delete-button-${index}`}
            onClick={() => removeProductSelect(index)}
            type="button"
            height={20}
            width={20}
          />
        </FullWidthSelectWithDeleteButton>
      )),
    [
      control,
      errors?.products,
      formState,
      productFields,
      removeProductSelect,
      t,
      tenant_id,
    ]
  );

  const onSubmit = async (values: FormOutput) => {
    setIsSubmitting(true);
    const additionalUsers: UUID[] =
      values.users && values.users.length > 0
        ? values.users.map(({ user }) => user.value)
        : [];
    const additionalProducts: UUID[] =
      values.products && values.products.length > 0
        ? values.products.map(({ product }) => product.value)
        : [];
    const productIDsFromForm: UUID[] = [
      values.first_product.value,
      ...additionalProducts,
    ];
    const userIDsFromForm: UUID[] = [
      values.first_user.value,
      ...additionalUsers,
    ];
    const originalUserIDs: UUID[] = users.map((user) => user.user_id);
    const originalProductIDs: UUID[] = products.map(
      (product) => product.product_id
    );

    const userIDsToAdd = userIDsFromForm.filter(
      (id) => originalUserIDs.indexOf(id) === -1
    );

    const productIDsToAdd = productIDsFromForm.filter(
      (id) => originalProductIDs.indexOf(id) === -1
    );

    const userIDsToRemove = originalUserIDs.filter(
      (id) => userIDsFromForm.indexOf(id) === -1
    );

    const productIDsToRemove = originalProductIDs.filter(
      (id) => productIDsFromForm.indexOf(id) === -1
    );

    const req: UpdateTeamSchema = {
      team_name: values.team_name,
      user_ids_to_add: userIDsToAdd,
      user_ids_to_remove: userIDsToRemove,
      product_ids_to_add: productIDsToAdd,
      product_ids_to_remove: productIDsToRemove,
    };

    try {
      await axios.patch(`/v2/tenants/${tenant_id}/pim/teams/${team_id}`, req);
      notifySuccess(t("Team edited successfully"));
      setIsSubmitting(false);
      onSuccess();
    } catch (error) {
      notifyError(t("There was an error editing the team"));
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>{t("Edit Team")}</PageTitle>

      <ContentWrapper>
        <PageWrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FullWidthHorizontalSeparator style={{ marginBottom: "16px" }} />
            <MarginBottomH6 style={{ paddingTop: "16px" }}>
              {t("Team Details")}
            </MarginBottomH6>
            <TextField
              name="team_name"
              label={t("Team Name")}
              theref={register()}
              formState={formState}
              errors={errors}
              type="text"
            />
            <MarginBottomH6>{t("Users")}</MarginBottomH6>
            <Controller
              as={SearchSelectInfiniteScroll}
              control={control}
              name={`first_user`}
              errors={{
                first_user:
                  errors?.first_user?.value ?? errors?.first_user ?? undefined,
              }}
              defaultValue={
                users?.length > 0
                  ? {
                      label: `${users[0].firstname} ${users[0].lastname}`,
                      value: users[0].user_id,
                    }
                  : { label: "", value: "" }
              }
              formState={formState}
              placeholder={t("Select User")}
              baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
                storefront_id,
                tenant_id
              )}
              params={(() => {
                const params = new URLSearchParams();
                params.append("order_by", "asc");
                return params;
              })()}
              getOptions={(response: User[]) =>
                response.map((user) => ({
                  value: user.id,
                  label: `${user.firstname} ${user.lastname}`,
                }))
              }
            />
            {createUserFields()}
            <SecondaryButtonWithPlusIcon
              type="button"
              style={{ fontSize: "15px" }}
              onClick={() =>
                appendUserSelect({ user: { label: "", value: "" } })
              }
            >
              {t("Add user")}
            </SecondaryButtonWithPlusIcon>
            <MarginBottomH6>{t("Products")}</MarginBottomH6>
            <Controller
              as={SearchSelectInfiniteScroll}
              name={`first_product`}
              control={control}
              errors={{
                first_product:
                  errors?.first_product?.value ??
                  errors?.first_product ??
                  undefined,
              }}
              formState={formState}
              placeholder={t("Search from your products")}
              defaultValue={
                products?.length > 0
                  ? {
                      label: products[0].product_name,
                      value: products[0].product_id,
                    }
                  : { label: "", value: "" }
              }
              baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
              params={(() => {
                const params = new URLSearchParams();
                params.append("order_by", "asc");
                params.append("show_inactive", "true");
                return params;
              })()}
              getOptions={(response: PIMProductBase[]) =>
                response.map((product) => ({
                  value: product.id,
                  label: product.name,
                }))
              }
              testid={"existing-product-search"}
            />
            {createProductFields()}
            <SecondaryButtonWithPlusIcon
              type="button"
              style={{ marginBottom: "40px", fontSize: "15px" }}
              onClick={() =>
                appendProductSelect({ product: { value: "", label: "" } })
              }
            >
              {t("Add Product")}
            </SecondaryButtonWithPlusIcon>
            <ConfirmOrCancelButtonContainer
              isConfirmLoading={isSubmitting}
              onCancel={onCancel}
              onConfirm={() => {}}
              confirmText={t("Submit")}
            />
          </Form>
        </PageWrapper>
      </ContentWrapper>
    </>
  );
};
