import styled from "styled-components/macro";
import { baseProductListStyles } from "../../layout/publicPageLayout";
import { H3 } from "../../components/Typography/Typography";
import type { PIMProduct } from "../../types/types.PIM";
import { getAttributeWithObjectType, useMediaQueries } from "../../util/util";
import { useEffect, useRef, useState } from "react";
import type { ConstructQuery } from "../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { applyFiltersToURL } from ".";

export const PAGE_SIZE = 10;

export const LoaderContainer = styled.div`
  ${baseProductListStyles}
  border: none;
  align-items: center;
`;

export const TotalProducts = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 10px 0 20px;
  height: 15px;
`;

export const CustomH3 = styled(H3)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px; // becauase overflow is hidden, it sometimes cut off
`;

export const CustomProductName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  margin-bottom: 5px;
  max-width: 100%;
  display: block;
`;

export const CasNumber = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const getProductName = (product: PIMProduct) =>
  getAttributeWithObjectType<string>({
    product,
    group_name: "general",
    object_type: "product_name",
  });

export const getBrandName = (product: PIMProduct) =>
  getAttributeWithObjectType<string>({
    product,
    group_name: "general",
    object_type: "brand_name",
  });

export const usePortfolioUpdateHeight = (product: PIMProduct | undefined) => {
  const [triggerListHeightUpdate, setTriggerListHeightUpdate] =
    useState<number>();
  const [productListHeight, setProductListHeight] =
    useState<number | string>("fit-content");
  const heightRef = useRef<HTMLDivElement>(null);
  const { isLargeScreen } = useMediaQueries();

  useEffect(() => {
    let timeoutId = 0;
    if (heightRef.current && !isLargeScreen) {
      timeoutId = setTimeout(() => {
        setProductListHeight(heightRef?.current?.clientHeight ?? "fit-content");
      });
    } else {
      timeoutId = setTimeout(() => {
        setProductListHeight("fit-content");
      });
    }
    return () => clearTimeout(timeoutId);
  }, [isLargeScreen, triggerListHeightUpdate, product]);
  return { productListHeight, setTriggerListHeightUpdate, heightRef };
};

export const construct_product_infinite_scroll_query = <
  ProductType extends {}
>({
  baseUrl,
  query,
  filtersFromUrl,
  previousPageData,
  index,
  pageSize,
}: ConstructQuery<ProductType>) => {
  if (previousPageData && previousPageData.data.length === 0) {
    return null;
  }
  // first page, previousPageData is null
  if (index === 0) {
    const params = new URLSearchParams(
      `?limit=${pageSize ?? PAGE_SIZE}&offset=0&order_by=asc`
    );

    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        filtersFromUrl,
        q: query,
        params,
      })
    );
  }

  // 2nd or later page, apply filters in addition to increasing offset

  // This will always be a number because we can't get to this stage without
  // previousPageData.pagination existing.
  const offset = previousPageData?.pagination.offset;
  const paramsWithOffset = new URLSearchParams(
    `offset=${(offset as number) + (pageSize ?? PAGE_SIZE)}&limit=${
      pageSize ?? PAGE_SIZE
    }&order_by=asc`
  );

  return (
    baseUrl +
    "?" +
    applyFiltersToURL({
      filtersFromUrl,
      q: query,
      params: paramsWithOffset,
    })
  );
};

export const PRODUCT_SEARCH_POPUP = "product_search_popup";
