import styled from "styled-components/macro";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";

export const HeaderCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckboxNoMargin = styled(CheckBoxNoLabel)`
  margin-right: 0;
`;

export const SpinnerContainer = styled.div`
  width: 24px;
  height: 24px;
`;
