import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useSWR, { useSWRInfinite } from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useAuthContext } from "../../../components/Auth";
import { DropDown } from "../../../components/DropDown/DropDown";
import { FiltersArea } from "../../../components/Filters/Filters";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { endpoints } from "../../../endpoints";
import { Flex, Flex1, Flex2 } from "../../../layout/FormLayout";
import {
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import type { TagClassificationConfig, User } from "../../../types/types";
import type {
  PIMProduct,
  PIMProductPaginatedOutput,
} from "../../../types/types.PIM";
import { useDebounce } from "../../../util/hooks";
import {
  createStorefrontMetadataLocalization,
  toTitleCase,
  updateCustomLabels,
  useStoreState,
  useSupportedLanguages,
} from "../../../util/util";
import { groupPortfolioParamsByKey } from "../../public";
import { PAGE_SIZE } from "../../public/pages.public.util";
import { PortfolioModernView } from "../../public/PortfolioLayouts/PortfolioModernView";
import type { ConstructQuery } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { applyFiltersToURL } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { RequestUnlistedProduct } from "../RequestUnlistedProduct/RequestUnlistedProduct";
import { ProductsPortfolioView } from "./ProductsPortfolioView";
import { ProductsTableViewList } from "./ProductsTableViewList";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;
export const BuyerProductsList = () => {
  const { t } = useTranslation();
  const { user, updateUser } = useAuthContext();
  const [resetFirstProduct, setResetFirstProduct] = useState(false);
  const { supportedLanguageOptions } = useSupportedLanguages();
  const {
    slug: storefrontSlug,
    storefront_metadata,
    storefront_id,
    tenant_id,
  } = useStoreState();
  const [cookies] = useCookies([`preferred-language-${storefrontSlug}`]);
  const preferred: string | undefined =
    cookies[`preferred-language-${storefrontSlug}`];
  const { placeholder } = createStorefrontMetadataLocalization({
    storefront_metadata,
    cookie: preferred,
  });

  const [viewMode, setViewMode] = useState<"list" | "classic" | "modern">(
    (sessionStorage.getItem("portfolioView") as
      | "list"
      | "classic"
      | "modern") ||
      (user?.products_view_mode === "portfolio"
        ? "classic"
        : user?.products_view_mode) ||
      "classic"
  );
  const viewModes = ["List", "Classic", "Modern"];

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);

  const queryObject = () => {
    const querylist: any = {
      product: StringParam,
      q: StringParam,
      offset: NumberParam,
      perPage: NumberParam,
    };

    return querylist;
  };

  const [query, setQuery] = useQueryParams(queryObject());

  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();
  const { search, pathname } = useLocation();

  const filteredSearch = (() => {
    const params = new URLSearchParams(search);
    // delete params that are not filters
    params.delete("quote");
    params.delete("tab");
    return params.toString();
  })();

  useEffect(() => {
    const params = new URLSearchParams(filteredSearch);
    const filters = groupPortfolioParamsByKey(params);
    setFiltersFromUrl(filters);
    if (viewMode !== "list") {
      setResetFirstProduct(true);
    }
  }, [filteredSearch, viewMode]);

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const history = useHistory();
  const location = useLocation();

  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({
    baseUrl,
    query,
    filtersFromUrl,
    previousPageData,
    index,
  }: ConstructQuery<PIMProduct>) {
    if (previousPageData && previousPageData.data.length === 0) {
      return null;
    }
    const updatedOffset =
      index === undefined
        ? offset
        : index === 0
        ? 0
        : (previousPageData?.pagination.offset ?? 0) + PAGE_SIZE;

    const updatedLimit = index === undefined ? perPage : PAGE_SIZE;

    const paramsWithOffset = new URLSearchParams(
      `offset=${updatedOffset}&limit=${updatedLimit}`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }

    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        filtersFromUrl,
        params: paramsWithOffset,
      })
    );
  }

  const {
    data: productsResponse,
    error: productsError,
    mutate: mutateProducts,
  } = useSWR<PIMProductPaginatedOutput>(
    viewMode === "list"
      ? constructQuery({
          baseUrl: endpoints.v2_tenants_id_pim_products(tenant_id),
          filtersFromUrl,
          query: debouncedSearchQuery,
        })
      : null
  );

  const {
    data: portfolioProductsResponse,
    error: portfolioProductsError,
    setSize,
    size,
    mutate: mutateProductClassic,
  } = useSWRInfinite<PIMProductPaginatedOutput>((index, previousPageData) =>
    constructQuery({
      baseUrl: endpoints.v2_tenants_id_pim_products(tenant_id),
      query: debouncedSearchQuery,
      filtersFromUrl,
      previousPageData,
      index,
    })
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    if (viewMode === "classic") {
      setSize(1);
    } else {
      setOffset(0);
    }
    setResetFirstProduct(true);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
    setResetFirstProduct(true);
  };

  const changeViewMode = async (curMode: "List" | "Classic" | "Modern") => {
    sessionStorage.setItem("portfolioView", curMode.toLowerCase());
    localStorage.setItem("portfolioView", curMode.toLowerCase());
    try {
      await axios.patch<User>(`/v1/users/${user!.id}`, {
        products_view_mode:
          curMode.toLowerCase() === "classic"
            ? "portfolio"
            : curMode.toLowerCase(),
      });
      const params = new URLSearchParams(location.search);
      params.delete("perPage");
      params.delete("offset");
      history.replace({
        search: params.toString(),
      });
      setTimeout(() => {
        mutateProductClassic();
        mutateProducts();
      });
      // setOffset(0);
      updateUser({
        ...(user as User),
        products_view_mode:
          curMode.toLowerCase() === "classic"
            ? "portfolio"
            : (curMode.toLowerCase() as "list" | "modern"),
      });
    } catch (error) {
      console.error(error);
    }
    setViewMode(curMode.toLowerCase() as "list" | "classic" | "modern");
  };

  const handleClearFilter = () => {
    const destination = pathname;
    history.replace(destination);
    setQuery({ q: query.q, offset: query.offset, perPage: query.per_Page });
    mutateProducts();
    setResetFirstProduct(true);
  };

  // useEffect(() => {
  //   setQuery({ offset });
  // }, [offset, setQuery]);

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
    setResetFirstProduct(true);
  }, [setQuery, debouncedSearchQuery]);

  return (
    <PageWrapper fullWidth={viewMode === "modern"}>
      <PageTitle>{t("Products")}</PageTitle>
      <FullWidthHorizontalSeparator />
      {(viewMode === "classic" || viewMode === "list") && (
        <>
          <Flex
            style={{
              marginTop: "30px",
              position: "relative",
              zIndex: 1,
              justifyContent: "space-between",
            }}
          >
            <Flex2>
              <SearchContainer>
                <SearchBar
                  query={searchQuery}
                  placeHolder={
                    viewMode === "list"
                      ? placeholder
                      : t("Search by product name or CAS#")
                  }
                  handleChange={handleSearch}
                  handleClearInput={handleClearSearch}
                  maxWidth="450px"
                />
                {searchQuery.length > 0 &&
                  storefront_metadata.unlisted_product_requests_enabled && (
                    <RequestUnlistedProduct />
                  )}
              </SearchContainer>
            </Flex2>
            <Flex1 style={{ display: "flex" }}>
              <DropDown
                items={viewModes}
                activeItem={toTitleCase(viewMode)}
                textLeft={t("View Mode:")}
                direction={"right"}
                clickHandler={changeViewMode}
              />
            </Flex1>
          </Flex>
          <>
            {!customLabelsError && (
              <FiltersArea
                resetOffset={setOffset}
                customLabels={customLabels ?? []}
                clearFilter={() => handleClearFilter()}
              />
            )}
          </>
        </>
      )}
      {viewMode === "list" ? (
        <ProductsTableViewList
          productsResponse={productsResponse}
          productsError={productsError}
          perPage={perPage}
          setPerPage={setPerPage}
          offset={offset}
          setOffset={setOffset}
          sortingRules={sortingRules}
          setSortingRules={setSortingRules}
        />
      ) : viewMode === "classic" ? (
        <ProductsPortfolioView
          productsResponse={portfolioProductsResponse}
          productsError={portfolioProductsError}
          size={size}
          setSize={setSize}
          resetFirstProduct={resetFirstProduct}
          setResetFirstProduct={setResetFirstProduct}
        />
      ) : (
        <PortfolioModernView
          //@ts-ignore
          setPortfolioLayout={setViewMode}
          isPublic={false}
        />
      )}
    </PageWrapper>
  );
};
