import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Returns a debounced value (passed as props) after a delay (passed as props).
 *
 * Also returns a function that allows immediately setting the debounced value
 * that is returned (and clearing the existing timeout if one is in progress.)
 */
export function useDebounce<T>(
  value: T,
  delay: number
): [T, (value: T) => void] {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [timeoutId, setTimeoutId] = useState<number>();

  useEffect(
    () => {
      // Update debounced value after delay
      const id = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      setTimeoutId(id as unknown as number);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(id);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  const immediatelySetDebouncedValue = (value: T) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setDebouncedValue(value);
  };

  return [debouncedValue, immediatelySetDebouncedValue];
}
/**
 * hook for tracking clicks outside of a container.
 * sourced from https://usehooks.com/useOnClickOutside/ which is public domain.
 * @param ref
 * @param handler
 */
export function useOnClickOutside(
  ref: React.RefObject<any>,
  handler: Function
) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    /*  
    Add ref and handler to effect dependencies
    It's worth noting that because passed in handler is a new 
    function on every render that will cause this effect
    callback/cleanup to run every render. It's not a big deal
    but to optimize you can wrap handler in useCallback before
    passing it into this hook. */
    [ref, handler]
  );
}

/**
 * Return an URLSearchParams object wrapping useLocation.search.
 * It is currently not possible to provide a generic interface to this, see
 * https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1202
 *
 * lifted from the react router docs here: https://v5.reactrouter.com/web/example/query-parameters
 */
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Hook for listening to global DOM events.
 * @param type The type of event to listen for.
 * @param eventHandler The event handler function.
 */
export function useGlobalDOMEvent<K extends keyof WindowEventMap>(
  type: K,
  eventHandler: (e: WindowEventMap[K]) => void
) {
  useEffect(() => {
    window.addEventListener<K>(type, eventHandler);

    return () => {
      window.removeEventListener(type, eventHandler);
    };
  }, [type, eventHandler]);
}
