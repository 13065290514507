import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import axios from "axios";
import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useTheme } from "styled-components/macro";
import useSWR from "swr";
import { useAuthContext } from "../../../../components/Auth";
import { GoBackButtonSmall } from "../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { DelayedSpinner } from "../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../components/Error";
import { InfoIcon } from "../../../../components/Icons/Icons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { Table } from "../../../../components/Table/Table";
import {
  H3,
  SmallSectionHeaderRegular,
} from "../../../../components/Typography/Typography";
import { Form } from "../../../../layout/FormLayout";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
} from "../../../../layout/portalPageLayout";
import { PageWrapper } from "../../../../layout/publicPageLayout";
import type { Permission } from "../../../../types/types";
import type { Role } from "../../../../types/types.PIM";
import { useRoutePath } from "../../../../util/Routing";
import { useFormWrapper, useStoreState } from "../../../../util/util";

type FormOutput = { [K in Permission]: boolean };

type ViewModifyDelete = "view" | "modify" | "delete";

type TableRow = { [K in ViewModifyDelete]: JSX.Element } & { name: string };

type BaseTableRow = { [K in ViewModifyDelete]: JSX.Element } & { name: string };
type ProductTableRow = BaseTableRow & {
  publish?: JSX.Element;
  unpublish?: JSX.Element;
};

let previous: FormOutput | null = null;

const getToolTipText = (
  role: Role["name"] | undefined,
  category: "templates_products_assets" | "attributes_lists"
) => {
  if (role === "Admin") {
    return "Admin permissions cannot be modified";
  }
  if (category === "templates_products_assets") {
    return `Control permissions for ${role + "s"} on Templates & Products`;
  }
  if (category === "attributes_lists") {
    return `Control permissions for ${role + "s"} on Attributes & Lists`;
  }
};

export function SellerAdminRoleDetail() {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { role_id } = useParams<{ role_id: string }>();
  const { updatePermissions } = useAuthContext();
  const { adminPath } = useRoutePath();
  const { notifyError } = useContext(Notifications);
  const theme = useTheme();

  const [show_confirm_modal, set_show_confirm_modal] = useState(false);

  const [attributesListsTableData, setAttributesListsTableData] = useState<
    TableRow[]
  >([]);
  const [
    templatesProductsAssetsTableData,
    setTemplatesProductsAssetsTableData,
  ] = useState<ProductTableRow[]>([]);

  const {
    data: role,
    error: roleError,
    revalidate,
  } = useSWR<Role, AxiosError>(
    `/v1/storefronts/${storefront_id}/roles/${role_id}/permissions`,
    {
      revalidateOnFocus: false,
    }
  );

  const isLoadingRole = !role && !roleError;

  const { register, watch, formState } = useFormWrapper<FormOutput>({});

  // The backend supports changing the permissions of the admin account from the
  // admin account. For local testing of permissions this is the line to change.
  const editDisabled = (role?.name as string) === "Admin";

  const onCancelPermissionUpdate = () => {
    set_show_confirm_modal(false);
    window.location.reload();
  };

  const onConfirmPermissionUpdate = async () => {
    if (data) {
      if (view_attributes_dialog_message) {
        data["view_attributes"] = true;
      }
      if (view_templates_dialog_message) {
        data["view_templates"] = true;
      }
      try {
        await axios.patch<Role>(
          `/v1/storefronts/${storefront_id}/roles/${role_id}/permissions`,
          {
            // bandaid for a very occasional bug where the form returns ["on"] or []
            // instead of true or false.
            permissions: Object.entries(data).map(([key, value]) => ({
              has_permission: Array.isArray(value)
                ? value.length > 0
                : typeof value === "string"
                ? value === "on"
                : Boolean(value),
              slug: key,
            })),
          }
        );
      } catch (error) {
        notifyError(t("There was an error saving the permissions"));
      } finally {
        set_show_confirm_modal(false);
        window.location.reload();
      }
    }
  };

  // columns and table data can be made fully dynamic once all permissions are supported.
  const tableColumns = React.useMemo<ColumnDef<TableRow>[]>(
    () => [
      {
        header: "",
        accessorKey: "name",
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("View")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "attributes_lists")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "view",
        cell: (cell) => cell.renderValue(),
        enableSorting: false,
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Create/Edit")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "attributes_lists")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "modify",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Delete/Archive")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "attributes_lists")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "delete",
        cell: (cell) => cell.renderValue(),
      },
    ],
    [role, t, theme.secondaryIconColor]
  );

  const templatesProductsAndAssetsTableColumns = React.useMemo<
    ColumnDef<TableRow>[]
  >(
    () => [
      {
        header: "",
        accessorKey: "name",
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("View")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "templates_products_assets")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "view",
        cell: (cell) => cell.renderValue(),
        enableSorting: false,
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Create/Edit")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "templates_products_assets")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "modify",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Delete/Archive")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "templates_products_assets")}
              style={{ paddingTop: "2px" }}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
            <ReactTooltip id="info-icon-tip" />
          </div>
        ),
        accessorKey: "delete",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Publish")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "templates_products_assets")}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
          </div>
        ),
        accessorKey: "publish" as const,
        cell: (cell) => cell.renderValue(),
      },
      {
        header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "3px" }}>{t("Unpublish")}</div>
            <div
              data-for="info-icon-tip"
              data-tip={getToolTipText(role?.name, "templates_products_assets")}
            >
              <InfoIcon
                fill={theme.secondaryIconColor}
                height={15}
                width={15}
              />
            </div>
          </div>
        ),
        accessorKey: "unpublish" as const,
        cell: (cell) => cell.renderValue(),
      },
    ],
    [role, t, theme.secondaryIconColor]
  );

  useEffect(() => {
    const handleRolesData = (data: Role) => {
      const hasPermissionLocal = (p: Permission) => {
        return Boolean(
          data?.permissions.find(
            ({ is_active, permission }) => permission.slug === p && is_active
          )
        );
      };

      setAttributesListsTableData([
        {
          name: "Attributes",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_attributes"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_attributes")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_attributes"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_attributes")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_attributes"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_attributes")}
            />
          ),
        },
        {
          name: "Attribute Groups",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_groups"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_groups")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_groups"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_groups")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_groups"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_groups")}
            />
          ),
        },
        {
          name: "Attribute Collections",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_collections"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_collections")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_collections"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_collections")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_collections"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_collections")}
            />
          ),
        },
        {
          name: "Lists",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_lists"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_lists")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_lists"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_lists")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_lists"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_lists")}
            />
          ),
        },
      ]);

      setTemplatesProductsAssetsTableData([
        {
          name: "Templates",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_templates"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_templates")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_templates"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_templates")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_templates"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_templates")}
            />
          ),
        },
        {
          name: "Products",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_products"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_products")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_products"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_products")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_products"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_products")}
            />
          ),
          publish: (
            <CheckBoxNoLabel
              ref={register}
              name="publish_products"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("publish_products")}
            />
          ),
          unpublish: (
            <CheckBoxNoLabel
              ref={register}
              name="unpublish_products"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("unpublish_products")}
            />
          ),
        },
        {
          name: "Assets",
          view: (
            <CheckBoxNoLabel
              ref={register}
              name="view_assets"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("view_assets")}
            />
          ),
          modify: (
            <CheckBoxNoLabel
              ref={register}
              name="modify_assets"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("modify_assets")}
            />
          ),
          delete: (
            <CheckBoxNoLabel
              ref={register}
              name="delete_assets"
              disabled={editDisabled}
              defaultChecked={hasPermissionLocal("delete_assets")}
            />
          ),
        },
      ]);
    };

    if (role) {
      handleRolesData(role);
    }
  }, [register, role, editDisabled]);

  const data: FormOutput | null = watch();

  const is_truthy = (value: string[] | boolean) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return value;
    }
  };

  const view_attributes_dialog_message = useMemo(() => {
    return !is_truthy(data["view_attributes"]) &&
      is_truthy(data["modify_groups"])
      ? t(
          "To be able to modify groups, you must have permission to view attributes."
        )
      : !is_truthy(data["view_attributes"]) &&
        is_truthy(data["modify_collections"])
      ? t(
          "To be able to modify collections, you must have permission to view attributes."
        )
      : !is_truthy(data["view_attributes"]) &&
        is_truthy(data["modify_templates"])
      ? t(
          "To be able to modify templates, you must have permission to view attributes."
        )
      : null;
  }, [data, t]);

  const view_templates_dialog_message = useMemo(() => {
    return !is_truthy(data["view_templates"]) &&
      is_truthy(data["modify_products"])
      ? t(
          "To be able to modify products, you must have permission to view templates."
        )
      : null;
  }, [data, t]);

  useEffect(() => {
    const autoSaveForm = async (data: FormOutput) => {
      if (view_attributes_dialog_message || view_templates_dialog_message) {
        set_show_confirm_modal(true);
      } else {
        try {
          await axios.patch<Role>(
            `/v1/storefronts/${storefront_id}/roles/${role_id}/permissions`,
            {
              // bandaid for a very occasional bug where the form returns ["on"] or []
              // instead of true or false.
              permissions: Object.entries(data).map(([key, value]) => ({
                has_permission: Array.isArray(value)
                  ? value.length > 0
                  : typeof value === "string"
                  ? value === "on"
                  : Boolean(value),
                slug: key,
              })),
            }
          );
        } catch (error) {
          notifyError(t("There was an error saving the permissions"));
        }
      }
    };
    if (
      !isLoadingRole &&
      formState.isDirty &&
      data !== null &&
      Object.keys(data).length > 0 &&
      !isEqual(data, previous)
    ) {
      previous = data;

      autoSaveForm(data);
    }
  }, [
    isLoadingRole,
    data,
    formState,
    storefront_id,
    role_id,
    updatePermissions,
    revalidate,
    notifyError,
    t,
    view_attributes_dialog_message,
    view_templates_dialog_message,
  ]);

  if (isLoadingRole) {
    return <DelayedSpinner />;
  }

  if (roleError) {
    return (
      <ErrorPlaceholder message={t("There was an error loading the role")} />
    );
  }

  if (role) {
    return (
      <PageWrapper>
        <Link to={`${adminPath}/organization/roles`}>
          <GoBackButtonSmall text={t("Back")} />
        </Link>
        <PageTitle>{role.name}</PageTitle>
        <FullWidthHorizontalSeparator />
        <ContentWrapper style={{ marginTop: "24px" }}>
          <Form>
            <H3>{t("Attributes & Lists")}</H3>
            <SmallSectionHeaderRegular>
              <span>
                {`Control permissions for ${role.name + "s"} on
                Attributes & Lists.`}
              </span>
            </SmallSectionHeaderRegular>
            <Table
              columns={tableColumns}
              data={attributesListsTableData}
              error={undefined}
              isLoading={false}
              lastChildleftAlign
            />
          </Form>
          <Form>
            <H3>{t("Templates, Products & Assets")}</H3>
            <SmallSectionHeaderRegular>
              <span>
                {`Control permissions for ${
                  role.name + "s"
                } on Templates, Products & Assets`}
              </span>
            </SmallSectionHeaderRegular>
            <Table
              columns={templatesProductsAndAssetsTableColumns}
              data={templatesProductsAssetsTableData}
              isLoading={false}
              error={undefined}
              lastChildleftAlign
            />
          </Form>
        </ContentWrapper>
        {show_confirm_modal && (
          <ConfirmDialog
            show={show_confirm_modal}
            closeDialog={onCancelPermissionUpdate}
            confirmMessage={
              view_attributes_dialog_message ||
              view_templates_dialog_message ||
              ""
            }
            handleCancel={onCancelPermissionUpdate}
            handleConfirm={onConfirmPermissionUpdate}
          />
        )}
      </PageWrapper>
    );
  } else return null;
}
