import type { Dispatch, SetStateAction } from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import useSWR from "swr";

import {
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import type { SEODetail, WithPagination } from "../../../types/types";

import { ErrorPlaceholder } from "../../../components/Error";
import type { ErrorWithStatus } from "../../../util/util";
import {
  PIM_PRODUCT_DETAILS_PORTFOLIO_TAB,
  useStoreState,
} from "../../../util/util";

import { Tabs } from "@reach/tabs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { helmetJsonLdProp } from "react-schemaorg";
import type { Product } from "schema-dts";
import { NumberParam, useQueryParams } from "use-query-params";
import { Auth } from "../../../components/Auth";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { Modal } from "../../../components/Modal/Modal";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import { endpoints } from "../../../endpoints";
import type {
  PIMProduct,
  ProductListPriceSchema,
} from "../../../types/types.PIM";
import { useRoutePath } from "../../../util/Routing";
import type { ProductMutate } from "../../SharedPages/ProductDetailPage/ProductDetailPage";
import { ProductTabs } from "../../SharedPages/ProductDetailPage/util";
import { ProductDetailsTransactionsSection } from "./ProductDetailsTransactionsButtons";

const ProductHeader = styled(PageHeader)`
  align-items: flex-start;
  justify-content: flex-start;
`;

const ProductHeaderImage = styled.img`
  min-width: 100px;
  max-width: 200px;
  max-height: 150px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  padding: 1px;
  align-self: center;
  cursor: pointer;
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
  h1 {
    margin: 0px 5px 2px 0;
  }
`;

const ModalWrapper = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProductDetails {
  product: PIMProduct | undefined;
  mutateProduct: ProductMutate;
  error: ErrorWithStatus | undefined;
  updateListHeight?: Dispatch<SetStateAction<number | undefined>>;
  setbackToProductList?: Dispatch<SetStateAction<boolean>>;
  can_set_tab_query?: boolean;
}
/**
 * Portfolio Product detail page
 */
export function PIMProductDetailsPortfolio({
  product,
  mutateProduct,
  error,
  updateListHeight,
  setbackToProductList,
  can_set_tab_query = true,
}: IProductDetails) {
  const { hasPermission, roleIsGuest, roleIsSomeKindOfBuyer } =
    useContext(Auth);

  const { t } = useTranslation();
  const { storefront_metadata, tenant_id } = useStoreState();
  const { pathname } = useLocation();
  const history = useHistory();
  const { storePath } = useRoutePath();
  const portfolioView = sessionStorage.getItem("portfolioView");
  const [showProductImageModal, setShowProductImageModal] = useState(false);
  const [fullSizeProductImage, setFullSizeProductImage] = useState(false);
  const [query, setQuery] = useQueryParams({ tab: NumberParam });
  const [currentTab, setCurrentTab] = useState(0);

  const { data: product_list_prices } = useSWR<
    WithPagination<{ data: ProductListPriceSchema[] }>
  >(
    product?.id
      ? endpoints.v2_tenants_tenant_id_products_product_id_list_prices(
          tenant_id,
          product.id
        )
      : null
  );

  useEffect(() => {
    // we don't know the length of the tabs that the buyer will see, so when the
    // product changes reset to the first tab, because there will always be 1.
    const tab =
      query?.tab ??
      (localStorage.getItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB)
        ? Number(localStorage.getItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB))
        : 0);
    setCurrentTab(tab);
  }, [query]);

  const changeTab = (index: number) => {
    if (updateListHeight) {
      updateListHeight(index);
    }
    setCurrentTab(index);
    if (can_set_tab_query) {
      setQuery({ tab: index });
    } else {
      localStorage.setItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB, `${index}`);
    }
  };

  useEffect(() => {
    if (roleIsSomeKindOfBuyer) {
      if (portfolioView === "modern" || portfolioView === "classic") {
        const basePath = `${storePath}/admin/pim/products`;
        const orderId = pathname.split("/").pop();
        history.replace(`${basePath}/${orderId}`);
      }
    }
  }, [history, roleIsSomeKindOfBuyer, portfolioView, storePath, pathname]);

  if (!product) {
    // Product data is still loading.
    return <DelayedSpinner />;
  }

  const productCoverImage = product.cover_image?.signed_url;

  const seoDetails = (): SEODetail => {
    const seoGroupSummary = product.product_schema.groups.find(
      (group) => group.name.toLowerCase() === "seo"
    );

    const seoDataType: Record<string, string> = {};

    seoGroupSummary?.attributes?.forEach(
      ({ object_type, values }) =>
        (seoDataType[object_type] = (values?.[0]?.value as string) ?? "")
    );

    return {
      meta_description: seoDataType?.seo_meta_description ?? null,
      meta_keywords: seoDataType?.seo_meta_keywords ?? null,
      meta_title: seoDataType?.seo_meta_title ?? null,
      page_title: seoDataType?.seo_page_title ?? null,
      og_title: seoDataType?.seo_og_title ?? null,
      og_type: seoDataType?.seo_og_type ?? null,
      og_image: seoDataType?.seo_og_image ?? null,
      og_description: seoDataType?.seo_og_description ?? null,
      og_locale: seoDataType?.seo_og_locale ?? null,
      og_url: seoDataType?.seo_og_url ?? null,
    };
  };

  const normalized_name = (name?: string) =>
    (name ?? "").toLowerCase().split(" ").join("_");

  const glacial_acrylic_acid_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Glacial Acrylic Acid",
    description:
      "Glacial Acrylic Acid the simplest unsaturated carboxylic acid is a colorless liquid. Glacial Acrylic Acid is used as an additive in paints, lacquers, coatings, adhesives, dispersants, thickeners, adhesives, inks, lubricants, floor polish, plastics, textile, leather, paper finishes, hair styling and finishing products. It is also a feedstock for many other acrylic polymers, acrylates, and co-polymers.",
    url: "https://portal.cjchemicals.net/store/cj-chemicals/product/glacial-acrylic-acid",
    image: "",
    brand: {
      "@type": "Brand",
      name: "CJ Chemicals",
    },
    sku: "Drums, Totes, Bulk",
    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Molecular Formula",
        value: "C3H4O2",
      },
      {
        "@type": "PropertyValue",
        name: "CAS Number",
        value: "79-10-7",
      },
      {
        "@type": "PropertyValue",
        name: "Concentration",
        value: "99.5% Purity",
      },
      {
        "@type": "PropertyValue",
        name: "Boiling Point",
        value: "141°C",
      },
      {
        "@type": "PropertyValue",
        name: "Density",
        value: "1.051 g/cm³",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Ideal for high-performance coatings and adhesives.",
      },
      {
        "@type": "PropertyValue",
        name: "Safety Data Sheet",
        value:
          "https://portal.cjchemicals.net/api/v2/tenants/d1d1e976-3f1e-4a93-ac2f-cd1e718ba339/pim/assets/fd81f925-6580-4e2f-b0ec-b6a2093185eb/download",
      },
      {
        "@type": "PropertyValue",
        name: "Technical Data Sheet",
        value: "",
      },
    ],
    isRelatedTo: {
      "@type": "Product",
      name: "Acrylic Acid",
      url: "https://portal.cjchemicals.net/store/cj-chemicals/product/acrylic-acid",
    },
  });

  const acrylic_acid_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Acrylic Acid",
    description:
      "Acrylic acid is a colorless liquid that is the simplest unsaturated carboxylic acid. It found wide uses in architectural and industrial coatings, textiles, paintings, pulp and paper, leather coating, wood coating and metal coating, adhesives, and polishes. Acrylic Acid is used in the synthesis of polyacrylates that are used in the manufacturing of thickeners and dispersants. Acrylic Acid is also used in the production of superabsorbent polymers and detergent polymers.",
    url: "https://portal.cjchemicals.net/store/cj-chemicals/product/acrylic-acid",
    image: "",
    brand: {
      "@type": "Brand",
      name: "CJ Chemicals",
    },
    sku: "Drums, Totes, Bulk",

    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Molecular Formula",
        value: "C3H4O2",
      },
      {
        "@type": "PropertyValue",
        name: "CAS Number",
        value: "79-10-7",
      },
      {
        "@type": "PropertyValue",
        name: "Boiling Point",
        value: "141°C",
      },
      {
        "@type": "PropertyValue",
        name: "Density",
        value: "1.051 g/cm³",
      },
      {
        "@type": "PropertyValue",
        name: "Concentration",
        value: "Typically 94-99%",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Used in coatings, adhesives, and polymer production.",
      },
      {
        "@type": "PropertyValue",
        name: "Safety Data Sheet",
        value:
          "https://portal.cjchemicals.net/api/v2/tenants/d1d1e976-3f1e-4a93-ac2f-cd1e718ba339/pim/assets/17f2a893-f77c-43a5-b345-458482d84249/download",
      },
      {
        "@type": "PropertyValue",
        name: "Technical Data Sheet",
        value: "",
      },
    ],
    isRelatedTo: {
      "@type": "Product",
      name: "Glacial Acrylic Acid",
      url: "https://portal.cjchemicals.net/store/cj-chemicals/product/glacial-acrylic-acid",
    },
  });

  const crilat_2823_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "CRILAT 2823",
    description:
      "CRILAT 2823 is a cutting-edge aqueous dispersion, representing a milestone in adhesive technology. This plasticizer and APEO-free acrylic copolymer is meticulously crafted for adhesive formulation in the graphic arts, paper, and converting industries. It excels in film print lamination, offering low blocking, high gloss, and enhanced mechanical properties.",
    url: "https://portal.nsm-na.com/store/nsm/product/crilat-2823",
    image: "",
    brand: {
      "@type": "Brand",
      name: "Vinavil",
    },
    manufacturer: {
      "@type": "Organization",
      name: "Vinavil",
      url: "https://www.vinavil.com",
    },
    sku: "CRILAT-2823",
    category: "Polymers",

    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Product Type",
        value: "Styrene Acrylic Copolymers",
      },
      {
        "@type": "PropertyValue",
        name: "Intrinsic Properties",
        value:
          "APEO-Free, Plasticizer-Free, Low Viscosity, Environmentally Friendly Formulation",
      },
      {
        "@type": "PropertyValue",
        name: "End Application Benefits",
        value:
          "Low Peel, High Gloss, Low Blocking, UV Stability, Suitable for Porous Substrates",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value:
          "Film Print Lamination, Adhesives in Graphic Arts, Paper, and Converting Industries",
      },
      {
        "@type": "PropertyValue",
        name: "Solid Content",
        value: "54 ± 1%",
      },
      {
        "@type": "PropertyValue",
        name: "Viscosity",
        value: "250 ± 150 mPa·s",
      },
      {
        "@type": "PropertyValue",
        name: "pH",
        value: "6.0 ± 1.0",
      },
      {
        "@type": "PropertyValue",
        name: "Glass Transition Temperature (Tg)",
        value: "-20°C",
      },
      {
        "@type": "PropertyValue",
        name: "Emulsifying System",
        value: "Surfactant-based",
      },
      {
        "@type": "PropertyValue",
        name: "Packaging",
        value: "Bulk, 1000 L IBCs, 125 kg drums",
      },
      {
        "@type": "PropertyValue",
        name: "Shelf Life",
        value: "6 months from dispatch date",
      },
      {
        "@type": "PropertyValue",
        name: "Storage Conditions",
        value: "Store between 5°C and 40°C. Avoid frost and direct sunlight.",
      },
      {
        "@type": "PropertyValue",
        name: "Safety Data Sheet",
        value: "https://portal.nsm-na.com/docs/crilat-2823-sds.pdf",
      },
      {
        "@type": "PropertyValue",
        name: "Technical Data Sheet",
        value: "https://portal.nsm-na.com/docs/crilat-2823-tds.pdf",
      },
    ],
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Nagase Specialty Materials",
        url: "https://portal.nsm-na.com",
      },
    },
  });

  const vinavil_4735_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Vinavil 4735",
    description:
      "Vinavil 4735 is a styrene/acrylic copolymer emulsion, free from alkylphenol ethoxylates (APEO-free). It is used in elastomeric indoor and outdoor coatings, including flat roof coatings, laminating applications, pressure-sensitive adhesives (PSA), cementitious adhesives with enhanced properties such as waterproofing, and products made for bridging over cracks.",
    url: "https://www.vinavil.com/us/en/products/product-detail/vinavil-4735",
    image: "",
    brand: {
      "@type": "Brand",
      name: "Vinavil",
    },
    manufacturer: {
      "@type": "Organization",
      name: "Vinavil",
      url: "https://www.vinavil.com",
    },
    sku: "VINAVIL-4735",
    category: "Polymer Emulsions",
    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Product Type",
        value: "Styrene Acrylic Copolymers",
      },
      {
        "@type": "PropertyValue",
        name: "Intrinsic Property",
        value: "APEO-Free",
      },
      {
        "@type": "PropertyValue",
        name: "Intrinsic Property",
        value: "Water Resistance",
      },
      {
        "@type": "PropertyValue",
        name: "Intrinsic Property",
        value: "Styrene Acrylic Copolymer Emulsion",
      },
      {
        "@type": "PropertyValue",
        name: "Extrinsic Benefit",
        value: "Enhanced waterproofing for cementitious adhesives",
      },
      {
        "@type": "PropertyValue",
        name: "Extrinsic Benefit",
        value: "Bridging over cracks",
      },
      {
        "@type": "PropertyValue",
        name: "Extrinsic Benefit",
        value: "Durability in elastomeric coatings",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Cementitious Adhesives",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Elastomeric Indoor & Outdoor Coatings",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Pressure-Sensitive Adhesives",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Flexible Coating & Waterproofing Membranes",
      },
      {
        "@type": "PropertyValue",
        name: "Solid Content",
        value: "52.5%",
      },
      {
        "@type": "PropertyValue",
        name: "Viscosity",
        value: "150 cps",
      },
      {
        "@type": "PropertyValue",
        name: "pH",
        value: "6.5",
      },
      {
        "@type": "PropertyValue",
        name: "Glass Transition Temperature (Tg)",
        value: "-35°C",
      },
      {
        "@type": "PropertyValue",
        name: "Minimum Film Formation Temperature (MFFT)",
        value: "0°C",
      },
      {
        "@type": "PropertyValue",
        name: "Specific Gravity",
        value: "1.02",
      },
      {
        "@type": "PropertyValue",
        name: "Emulsifying System",
        value: "Anionic/Non-ionic",
      },
      {
        "@type": "PropertyValue",
        name: "Shelf Life",
        value: "12 months",
      },
    ],
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Nagase Specialty Materials",
        url: "https://portal.nsm-na.com",
      },
    },
  });

  const gasil_ij37 = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Gasil® IJ37",
    description:
      "Gasil® IJ37 is an organically treated silica gel that serves as an effective matting agent in various coatings. It provides optimal matting efficiency with minimal impact on clarity and mechanical properties. Suitable for water-based wood flooring coatings, radiation-cured coatings, and general industrial coatings.",
    url: "https://portal.nsm-na.com/store/nsm/product/gasil-ij37",
    image: "",
    brand: {
      "@type": "Brand",
      name: "PQ Corporation",
    },
    manufacturer: {
      "@type": "Organization",
      name: "PQ Corporation",
      url: "https://www.pqcorp.com",
    },
    sku: "GASIL-IJ37",
    category: "Coating Additives",
    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Water-Based Wood Flooring Coatings",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "Radiation-Cured Coatings",
      },
      {
        "@type": "PropertyValue",
        name: "Applications",
        value: "General Industrial Coatings",
      },
      {
        "@type": "PropertyValue",
        name: "Intrinsic Property",
        value: "Organically Treated Silica Gel",
      },
      {
        "@type": "PropertyValue",
        name: "Extrinsic Benefit",
        value: "Provides matte finish with minimal impact on clarity",
      },
      {
        "@type": "PropertyValue",
        name: "Extrinsic Benefit",
        value: "Improves durability and smoothness of coatings",
      },
    ],
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Nagase Specialty Materials",
        url: "https://portal.nsm-na.com",
      },
    },
  });

  const acetic_anehydride_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Acetic Acid Anhydride",
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Astro Chemicals Inc",
      },
    },
  });

  const advantra_phc_8290_schem = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Advantra® PHC-8290",
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Astro Chemicals Inc",
      },
    },
  });

  const aluminium_sulfate_solution_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Aluminium Sulfate Solution",
    description:
      "Aluminium Sulfate Solution is a versatile chemical compound commonly used in water treatment processes, paper manufacturing, and as a coagulating agent. It is known for its efficiency in removing impurities and balancing pH levels in industrial and municipal applications.",
    url: "https://portal.astrochemicals.com/store/astro-chemicals-inc/product/aluminum-sulfate-solution",
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Astro Chemicals Inc",
        url: "https://portal.astrochemicals.com",
      },
    },
  });

  const ammonium_hydroxide_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Ammonium Hydroxide 26° Baume",
    description:
      "Ammonium Hydroxide 26° Baume is a high-purity ammonia solution widely used in industrial applications such as water treatment, chemical manufacturing, and as a pH adjuster in various processes. Its reliable performance makes it a key component for numerous industries.",
    url: "https://portal.astrochemicals.com/store/astro-chemicals-inc/product/ammonium-hydroxide-26-baume",
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Astro Chemicals Inc",
        url: "https://portal.astrochemicals.com",
      },
    },
  });

  const trisodium_phosphate_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Trisodium Phosphate Crystal Gran Tech",
  });

  const potassium_hydroxide_schema = helmetJsonLdProp<Product>({
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Potassium Hydroxide Flakes",
    description:
      "Potassium Hydroxide Flakes are a high-purity chemical widely used in industrial applications such as soap and detergent manufacturing, chemical synthesis, and water treatment. Known for their excellent solubility and effectiveness in pH regulation.",
    url: "https://portal.astrochemicals.com/store/astro-chemicals-inc/product/potassium-hydroxide-flakes",
    offers: {
      "@type": "Offer",
      seller: {
        "@type": "Organization",
        name: "Astro Chemicals Inc",
        url: "https://portal.astrochemicals.com",
      },
    },
  });

  if (error) {
    return (
      <ErrorPlaceholder
        message={error.message ?? t("There was an error loading the product.")}
      />
    );
  }

  return (
    <PageWrapper style={{ position: "relative" }}>
      {seoDetails() && (
        <SEOHelmet
          seo={seoDetails()}
          extraProps={{
            slug: product.name,
            image_url: product.image_url,
          }}
        />
      )}
      <ProductHeader>
        {productCoverImage && (
          <ProductHeaderImage
            src={productCoverImage}
            alt={product.name}
            onClick={() => setShowProductImageModal(true)}
          />
        )}
        <ProductHeaderContainer>
          <PageTitle>{product.name}</PageTitle>
          <Helmet
            script={
              normalized_name(storefront_metadata?.tenant_name) ===
              "cj_chemicals"
                ? [
                    ...(normalized_name(product.name) === "glacial_acrylic_acid"
                      ? [glacial_acrylic_acid_schema]
                      : []),
                    ...(normalized_name(product.name) === "acrylic_acid"
                      ? [acrylic_acid_schema]
                      : []),
                  ]
                : normalized_name(storefront_metadata?.tenant_name) ===
                  "nagase_america"
                ? [
                    ...(normalized_name(product.name) === "crilat_2823"
                      ? [crilat_2823_schema]
                      : []),
                    ...(normalized_name(product.name) === "vinavil_4735"
                      ? [vinavil_4735_schema]
                      : []),
                    ...(normalized_name(product.name).includes("gasil") &&
                    normalized_name(product.name).includes("ij37")
                      ? [gasil_ij37]
                      : []),
                  ]
                : normalized_name(storefront_metadata?.tenant_name) ===
                  "astro_chemicals_inc."
                ? [
                    ...(normalized_name(product.name) === "acetic_anhydride"
                      ? [acetic_anehydride_schema]
                      : []),
                    ...(normalized_name(product.name).includes("advantra") &&
                    normalized_name(product.name).includes("phc-8290")
                      ? [advantra_phc_8290_schem]
                      : []),
                    ...(normalized_name(product.name) ===
                    "aluminum_sulfate_solution"
                      ? [aluminium_sulfate_solution_schema]
                      : []),
                    ...(normalized_name(product.name).includes(
                      "ammonium_hydroxide"
                    ) &&
                    normalized_name(product.name).includes("26") &&
                    normalized_name(product.name).includes("baume")
                      ? [ammonium_hydroxide_schema]
                      : []),
                    ...(normalized_name(product.name) ===
                    "trisodium_phosphate_crystal_gran_tech"
                      ? [trisodium_phosphate_schema]
                      : []),
                    ...(normalized_name(product.name) ===
                    "potassium_hydroxide_flakes"
                      ? [potassium_hydroxide_schema]
                      : []),
                  ]
                : []
            }
          >
            <meta property="og:title" content={product.name} />
            {product.image_url && (
              <meta property="og:image" content={product.image_url} />
            )}
            <meta
              property="og:description"
              content={`Check out ${product.name} ${
                product.produced_by ? `by ${product.produced_by}` : ``
              } 
    on the ${storefront_metadata.tenant_name} portfolio`}
            />
          </Helmet>
          <ProductDetailsTransactionsSection
            product={product}
            setbackToProductList={setbackToProductList}
          />
        </ProductHeaderContainer>
      </ProductHeader>

      <FullWidthHorizontalSeparator margin={"56px 0 0"} />
      {product && (
        <Tabs
          onChange={changeTab}
          style={{ marginTop: "8px" }}
          index={currentTab}
          key={product.id}
          defaultIndex={0}
        >
          {ProductTabs({
            product,
            t,
            refreshKey: currentTab,
            mutate: mutateProduct,
            hasPermission,
            replaceProductId: () => Promise.resolve(),
            isPortfolio: true,
            roleIsGuest,
            roleIsBuyer: roleIsSomeKindOfBuyer,
            has_list_prices: (product_list_prices?.data.length ?? 0) > 0,
            isShowTableWithBulkAction: true,
            isShowGridAssetWithBulkAction: true,
          })}
        </Tabs>
      )}

      {showProductImageModal && productCoverImage && (
        <Modal
          show={showProductImageModal}
          overlay={true}
          closeModal={() => setShowProductImageModal(false)}
          modalWidth="100%"
        >
          <ModalWrapper>
            <img
              src={productCoverImage}
              alt={product.name}
              style={{
                maxWidth: fullSizeProductImage ? "auto" : "100%",
                maxHeight: fullSizeProductImage ? "auto" : "100%",
                cursor: fullSizeProductImage ? "zoom-out" : "zoom-in",
              }}
              onClick={() => setFullSizeProductImage((current) => !current)}
            />
          </ModalWrapper>
        </Modal>
      )}
    </PageWrapper>
  );
}
