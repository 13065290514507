import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "./Store";
import { ErrorHandler } from "./ErrorHandler";
import { DelayedSpinner } from "./components/DelayedSpinner/DelayedSpinner";

ErrorHandler.start({
  // mandatory
  key: process.env.REACT_APP_ERROR_KEY ?? "",
  projectId: process.env.REACT_APP_ERROR_PROJECT ?? "agilis-corporate",

  // The following optional arguments can also be provided:

  // Name of the service reporting the error, defaults to 'web'.
  // We are using "agilis" to more easily match backend errors to frontend errors.
  service: "agilis",

  // Version identifier of the service reporting the error.
  version: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,

  // reportUncaughtExceptions: false,
  // Set to false to prevent reporting unhandled exceptions, default: `true`.

  // reportUnhandledPromiseRejections: false
  // Set to false to prevent reporting unhandled promise rejections, default: `true`.

  // only send error reports on production builds.
  disabled: process.env.NODE_ENV === "production" ? false : true,
});

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./__mocks__/browser");
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Suspense fallback={<DelayedSpinner />}>
        <App />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
