import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import zip from "lodash/zip";
import moment from "moment";
import { useContext, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonSmall,
} from "../../../components/Buttons/Buttons";
import {
  CurrencyInput,
  makeYupCurrencyTestFn,
} from "../../../components/CurrencyInput/CurrencyInput";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { StatusBox } from "../../../components/Form/Form";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { H3, H4, Title } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { Form } from "../../../layout/FormLayout";
import type {
  IPriceTierCreate,
  IPriceTierPatch,
  OptionType,
  ProductSKU,
} from "../../../types/types";
import { strings } from "../../../util/strings";
import {
  convertProductSKUToOption,
  useCurrencySymbol,
  useFormWrapper,
} from "../../../util/util";
import type { EditPriceTiersData } from "./SellerQuoteDetailPageContent";

const LocalStatusBox = styled(StatusBox)`
  flex-direction: column;
  & > * {
    margin-bottom: 15px;
  }
`;

const TierRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const AddTiersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const UnitsContainer = styled.div`
  max-width: 83px;
`;

const PricePerUnitOfMeasureContainer = styled.div`
  max-width: 100px;
`;

const SkuContainer = styled.div`
  flex-grow: 2;
`;

/**
 * To prevent creation of duplicate tiers that have the same number of units.
 *
 * @returns `true` if there are duplicates, `false` if there are no duplicates.
 */
export const checkForDuplicateTiers = (tiers: PriceTiersFormRow[]) => {
  const unitsArray = tiers.map((tier) => tier.units);
  const unitsSet = new Set(unitsArray);
  return unitsSet.size !== unitsArray.length;
};

/**
 * To prevent creation of tiers that do not include a tier that is low enough
 * for the number of units the buyer specified in their quote request. If such
 * a tier does not exist then we cannot respond to the quote request.
 *
 * @returns `false` if the tiers include the units from the quote request,
 *          `true` if the tiers do not include it.
 */
export const checkForNeedsLowerTier = (
  quoteItemNumberOfUnits: string,
  tiers: PriceTiersFormRow[]
) => {
  return tiers.reduce((result: boolean, tier: PriceTiersFormRow) => {
    const numberOfUnits = parseFloat(quoteItemNumberOfUnits);
    const tierUnits = parseFloat(tier.units);

    return numberOfUnits >= tierUnits ? false : result;
  }, true);
};

/**
 * Checks whether the tiers contain any non-positive or non-integer minimum
 * unit values. Tiers must be defined with positive integer minimum unit values.
 *
 * @returns `true` if there is a bad number tier, `false` if there is not one.
 */
export const checkForBadNumberTiers = (tiers: PriceTiersFormRow[]) => {
  return tiers.reduce((result: boolean, tier: PriceTiersFormRow) => {
    const tierUnits = parseFloat(tier.units);
    const isNotInteger = !Number.isInteger(tierUnits);
    const isNotPositive = tierUnits <= 0;

    return isNotInteger || isNotPositive ? true : result;
  }, false);
};

export interface PriceTiersFormRow {
  units: string;
  sku: OptionType<ProductSKU>;
  pricePerUnitOfMeasure: string;
}

interface PriceTiersFormData {
  valid_to_date: string;
  tiersList: PriceTiersFormRow[];
}

/**
 * A form for creating and editing price tiers for a particular quote request
 * item.
 *
 * @param props.quoteItem - The quote request item related to the price tiers.
 * @param props.savePriceTiers - Saves the changes when the form is submitted.
 * @param props.previousFormData - Optional, used for editing previous values.
 */
export const EditPriceTiersForm = ({
  editPriceTiersData: { priceTiers, quoteItem, mutatePriceTiers, sku },
  destinationId,
  deliveryTermId,
  paymentTermId,
  paymentModeId,
  sellerId,
  buyerId,
  closeModal,
}: {
  editPriceTiersData: EditPriceTiersData;
  destinationId: string;
  deliveryTermId: string;
  paymentTermId: string;
  paymentModeId: string;
  sellerId: string;
  buyerId: string;
  closeModal: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [submitting, setSubmitting] = useState(false);

  const itemCurrencySymbol = useCurrencySymbol(quoteItem.currency);
  const { t } = useTranslation();
  // Checking for a packaging_type ensures (for TypeScript) that we are not
  // dealing with a "no preference" SKU. (We should never be dealing with a
  // "no preference" SKU here.)
  const skuOptions: OptionType<ProductSKU>[] = sku.packaging_type
    ? [convertProductSKUToOption(sku)]
    : [];

  // All of the price tiers "should" have the same valid_to_date, if not they
  // will become the same after the user submits the form. So just grab the
  // valid_to_date from the first one if it exists.
  const validToDate = priceTiers[0]?.valid_to_date ?? "";

  // TODO: "invalid input" messages are not appearing. For the price tiers it
  // appears to be a limitation of react-hook-form's field arrays since their
  // docs do not cover it. Or maybe one can do it manually, like with setError?
  const editPriceTiersFormSchema = Yup.object().shape({
    valid_to_date: Yup.string()
      .required(strings(t).thisIsARequiredField)
      .nullable(),
    tiersList: Yup.array(
      Yup.object().shape({
        units: Yup.number()
          .positive()
          .required(strings(t).thisIsARequiredField)
          .nullable(),
        sku: Yup.object(),
        pricePerUnitOfMeasure: Yup.string()
          .test(
            "pricePerUnitOfMeasure",
            strings(t).currencyMustBeValid,
            makeYupCurrencyTestFn(quoteItem.currency)
          )
          .required(strings(t).thisIsARequiredField)
          .nullable(),
      })
    ),
  });

  const methodsOfUseForm = useFormWrapper<PriceTiersFormData>({
    defaultValues: {
      valid_to_date: validToDate,
      // If no tiers exist yet, start with one empty row.
      tiersList:
        priceTiers.length === 0
          ? [
              {
                units: "1",
                sku: skuOptions[0],
                pricePerUnitOfMeasure: undefined,
              },
            ]
          : priceTiers.map((tier) => {
              return {
                units: tier.minimum_sku_quantity,
                sku: convertProductSKUToOption(tier.product_sku),
                pricePerUnitOfMeasure: tier.price_per_uom,
              };
            }),
    },
    resolver: yupResolver(editPriceTiersFormSchema),
  });

  const { register, control, handleSubmit, formState, errors, watch } =
    methodsOfUseForm;

  const { fields, append, prepend, remove } = useFieldArray<
    PriceTiersFormRow,
    "key"
  >({
    control,
    name: "tiersList",
    keyName: "key",
  });

  const watchedTiersList = watch("tiersList");

  const hasDuplicateTiers = checkForDuplicateTiers(watchedTiersList);

  const hasBadNumberTier = checkForBadNumberTiers(watchedTiersList);

  // Allow the user to save if they want to delete all the tiers (length is 0).
  const disableSaveButton =
    watchedTiersList.length === 0
      ? false
      : hasDuplicateTiers || hasBadNumberTier;

  const onFormSubmit = async ({
    tiersList,
    valid_to_date,
  }: PriceTiersFormData) => {
    setSubmitting(true);
    try {
      // We use `zip` to pair up the old existing price tiers and the new ones
      // from the form. When one set of tiers is smaller than the other then
      // `zip` puts `undefined` in the pairs when that set of tiers runs out.
      // Then we iterate through the pairs and do a:
      //   - PATCH if the pair contains both tiers, to modify the existing tier.
      //   - POST if the pair has no old tier, to create the new tier.
      //   - DELETE if the pair has no new tier, to remove the old tier.
      //
      // This is a bit "brute force" but avoids the complexity of determining
      // whether a tier has changed or not.
      const requestPromises = zip(priceTiers, tiersList).map(
        ([oldTier, formTier]) => {
          if (oldTier && formTier) {
            const patchRequestBody: IPriceTierPatch = {
              minimum_sku_quantity: String(formTier.units),
              price_per_uom: parseFloat(formTier.pricePerUnitOfMeasure),
              valid_to_date: moment
                .utc(valid_to_date)
                .endOf("day")
                .toISOString(),
              delivery_term_id: deliveryTermId,
              payment_term_id: paymentTermId,
              payment_mode_id: paymentModeId,
            };
            return Axios.patch(
              endpoints.v1_priceTiers_id(oldTier.id),
              patchRequestBody
            );
          } else if (!oldTier && formTier) {
            const postRequestBody: IPriceTierCreate = {
              price_tiers: [
                {
                  minimum_sku_quantity: String(formTier.units),
                  price_per_uom: String(formTier.pricePerUnitOfMeasure),
                  valid_to_date: moment
                    .utc(valid_to_date)
                    .endOf("day")
                    .toISOString(),
                  product_sku_id: sku.id,
                  destination_id: destinationId,
                  delivery_term_id: deliveryTermId,
                  payment_term_id: paymentTermId,
                  seller_id: sellerId,
                  buyer_id: buyerId,
                  currency: quoteItem.currency,
                },
              ],
            };
            return Axios.post(endpoints.v1_priceTiers(), postRequestBody);
          } else if (oldTier && !formTier) {
            return Axios.delete(endpoints.v1_priceTiers_id(oldTier.id));
          }
          // Should never happen; this is just for TypeScript's sake.
          return null;
        }
      );

      await Promise.all(requestPromises);
      notifySuccess("Price tiers saved");
      setSubmitting(false);
      closeModal();
      mutatePriceTiers();
    } catch (error) {
      notifyError("An error occurred when saving price tiers", { error });
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Title>
        {priceTiers.length > 0 ? "Edit Price Tiers" : "Offer Price Tiers"}
      </Title>
      <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <LocalStatusBox>
          <div>
            <H3>{quoteItem.product.name}</H3>
          </div>
          {/* TODO: Backend will need to provide external product ID. */}
          {/* <div>{`ID# ${quoteItem.product.id}`}</div> */}
          <div>
            <DatePicker
              label={"Valid to Date"}
              name={"valid_to_date"}
              required={true}
              methodsOfUseForm={methodsOfUseForm}
              defaultValue={validToDate}
            />
          </div>
          <H4>{t("Tiers")}</H4>
          {fields.map((tier, index: number) => {
            return (
              <div key={tier.key}>
                <TierRow>
                  <UnitsContainer>
                    <TextField
                      label={"Units"}
                      name={`tiersList[${index}].units`}
                      defaultValue={tier.units}
                      theref={register({ required: true })}
                      errors={errors}
                      error={
                        errors?.tiersList && errors.tiersList[index]?.units
                          ? {
                              message:
                                errors?.tiersList[index]?.units?.message ||
                                t("Something went wrong"),
                              type:
                                errors?.tiersList[index]?.units?.type ||
                                "error",
                            }
                          : null
                      }
                      formState={formState}
                      type="number"
                    />
                  </UnitsContainer>
                  <SkuContainer>
                    <Controller
                      placeholder={"SKU"}
                      name={`tiersList[${index}].sku`}
                      as={SelectBoxV2}
                      control={control}
                      options={skuOptions}
                      rules={{ required: true }}
                      errors={errors}
                      formState={formState}
                      defaultValue={skuOptions[0]}
                      isDisabled={true}
                    />
                  </SkuContainer>
                  <PricePerUnitOfMeasureContainer>
                    <CurrencyInput
                      label={`${itemCurrencySymbol}/UoM`}
                      name={`tiersList[${index}].pricePerUnitOfMeasure`}
                      defaultValue={tier.pricePerUnitOfMeasure}
                      type="number"
                      theref={register({ required: true })}
                      errors={errors}
                      error={
                        errors?.tiersList &&
                        errors.tiersList[index]?.pricePerUnitOfMeasure
                          ? {
                              message:
                                errors?.tiersList[index]?.pricePerUnitOfMeasure
                                  ?.message || t("Something went wrong"),
                              type:
                                errors?.tiersList[index]?.pricePerUnitOfMeasure
                                  ?.type || "error",
                            }
                          : null
                      }
                      formState={formState}
                    />
                  </PricePerUnitOfMeasureContainer>
                  <div>
                    <DeleteButton
                      type="button"
                      testid={`delete-item-${tier.units}`}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </div>
                </TierRow>
              </div>
            );
          })}
          <AddTiersRow>
            <SecondaryButtonSmall
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                prepend({
                  units: undefined,
                  sku: skuOptions[0],
                  pricePerUnitOfMeasure: undefined,
                });
              }}
            >
              {t("Add Tier Above")}
            </SecondaryButtonSmall>
            <SecondaryButtonSmall
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                append({
                  units: undefined,
                  sku: skuOptions[0],
                  pricePerUnitOfMeasure: undefined,
                });
              }}
            >
              {t("Add Tier Below")}
            </SecondaryButtonSmall>
          </AddTiersRow>
          <PrimaryButtonFitContainer
            loading={submitting}
            disabled={disableSaveButton}
          >
            {t("Save")}
          </PrimaryButtonFitContainer>
        </LocalStatusBox>
      </Form>
    </div>
  );
};
