import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ScoreLabel = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

const ScoreValue = styled.span<{ isMandatory?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryTextColor};
`;

interface CompletionScoreProps {
  score: number | null;
  type: "total" | "mandatory";
  tooltipId?: string;
  attributeCompletionValues: {
    filled_attribute_count: number;
    filled_mandatory_attribute_count: number;
    total_mandatory_attribute_count: number;
    total_attribute_count: number;
  };
}

export function CompletionScore({
  score,
  type,
  tooltipId = "completion-score",
  attributeCompletionValues,
}: CompletionScoreProps) {
  const { t } = useTranslation();

  if (score === null) return null;

  const label =
    type === "mandatory" ? t("Mandatory Completion") : t("Total Completion");

  const tooltipText =
    type === "mandatory"
      ? t("{{completed}}/{{total}} mandatory attributes completed", {
          completed: attributeCompletionValues.filled_mandatory_attribute_count,
          total: attributeCompletionValues.total_mandatory_attribute_count,
        })
      : t("{{completed}}/{{total}} attributes completed", {
          completed: attributeCompletionValues.filled_attribute_count,
          total: attributeCompletionValues.total_attribute_count,
        });

  return (
    <ScoreContainer>
      <ScoreLabel>{label}:</ScoreLabel>
      <ScoreValue
        isMandatory={type === "mandatory"}
        data-tip={tooltipText}
        data-for={tooltipId}
      >
        {score}%
      </ScoreValue>
      <ReactTooltip id={tooltipId} />
    </ScoreContainer>
  );
}
