import type { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import type { Fee, MethodsOfUseForm } from "../../types/types";
import { DeleteButton } from "../Buttons/Buttons";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";
import { TextField } from "../TextFields/TextFields";

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  div {
    margin-right: 10px;
  }
`;

/**
 * Renders a dynamic list of fee inputs.  Each row has a name input,
 * a cost input, and a delete button.
 *
 * Tips for using react-hook-form's `useFieldArray`:
 *
 * To get pre-populated fields to work we have to do two things:
 * 1. Set `defaultValues` when calling `useForm`. This is needed so that
 *    `fields` (from `useFieldArray`) has the right number of items in it,
 *    so that many items are shown on first render.
 * 2. Set the `defaultValue` prop on each individual input element. This is
 *    needed so that the fields are populated with the values on first render.
 *
 * Example usage:
 *
 * const methodsOfUseForm = useFormWrapper({
 *   defaultValues: {
 *     feesList: fees
 *   }
 * })
 *
 * const methodsOfUseFieldArray = useFieldArray({
 *   control: methodsOfUseForm.control,
 *   name: "feesList",
 *   keyName: "key",
 * })
 *
 * Then pass those as props to `EditablePriceList`.
 *
 */
export const EditablePriceList = ({
  methodsOfUseForm: { register, errors, formState },
  methodsOfUseFieldArray: { fields, remove },
  testIdPrefix,
  currencySymbol,
  hasPossibleTaxFee,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  methodsOfUseFieldArray: ReturnType<typeof useFieldArray>;
  testIdPrefix: string;
  currencySymbol: string;
  hasPossibleTaxFee: boolean;
}) => {
  useTranslation();
  const isTaxFee = <T extends { name: string; id: string }>(item: T) =>
    item.id === "tax_fee" || item.name.trim().toLowerCase() === "tax";

  return (
    <>
      {fields.map((item, index: number) => (
        <FormRow key={item.key || index + "FormRow"}>
          <TextField
            name={`feesList[${index}].name`}
            label="Name"
            type={"text"}
            theref={register()}
            // Default value is required here for pre-populated values to work.
            defaultValue={item.name}
            errors={errors}
            error={errors?.feesList ? errors?.feesList[index]?.name : undefined}
            formState={formState}
            readOnly={
              item.is_disabled || (hasPossibleTaxFee && isTaxFee(item as Fee))
            }
          />
          <CurrencyInput
            name={`feesList[${index}].amount`}
            label={`Cost (${currencySymbol})`}
            theref={register()}
            formState={formState}
            errors={errors}
            error={
              errors?.feesList ? errors?.feesList[index]?.amount : undefined
            }
            // Default value is required here for pre-populated values to work.
            type="number"
            defaultValue={item.amount}
            readOnly={item?.is_disabled}
            testid={`${testIdPrefix}-input-field-${index}`}
          />
          {hasPossibleTaxFee && isTaxFee(item as Fee) ? (
            <div style={{ width: "67px" }}></div>
          ) : (
            <DeleteButton
              type="button"
              onClick={() => remove(index)}
              // TODO: Both of these testid props shouldn't be needed.
              testid={`delete-item-${item.amount}`}
              data-testid={`${testIdPrefix}-delete-button-${index}`}
            />
          )}
        </FormRow>
      ))}
    </>
  );
};
