import { useEffect, useState } from "react";
import type {
  ProductStatusesSchema,
  ProductStatusType,
} from "../../../../../types/types.PIM";
import type { AxiosError } from "axios";
import axios from "axios";
import { useStoreState } from "../../../../../util/util";
import { endpoints } from "../../../../../endpoints";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import useSWR from "swr";
import { GenericDialogBody } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import {
  DestructiveText,
  H3,
  H5,
} from "../../../../../components/Typography/Typography";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { getProductStatusText } from "../../../../../components/Status/Status";
import { ConfirmOrCancelButtonContainer } from "../../../../../components/Buttons/Buttons";
import { DialogWrapper, RadioButtonContainer } from "./utils";
import { UploadPopups } from "../CreatePimProductPage/CreateFromUploads/UploadPopups";
import { useAuthContext } from "../../../../../components/Auth";

type BulkProductStatusResponse = {
  status: "success" | "in_progress" | "failed";
  success?: {
    product_name: string;
    status: ProductStatusType;
  }[];
  failed?: {
    product_name: string;
    status: ProductStatusType;
  }[];
};

export const ProductStatusUpdate = ({
  show,
  onCompleteUpdate,
  resetBulkAction,
  product_ids,
  onCancelUpdate,
}: {
  show: boolean;
  onCompleteUpdate: () => void;
  resetBulkAction: () => void;
  product_ids: string[];
  onCancelUpdate: () => void;
}) => {
  const [showPopup, setShowPopup] = useState(show);
  const [
    show_product_status_update_dialog,
    set_show_product_status_update_dialog,
  ] = useState(false);

  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { notifyError, notifyInfo, pollNotification } = useNotifications();

  const onSelectOption = async (selectedStatus: ProductStatusType) => {
    try {
      const {
        data: { notification_id },
      } = await axios.patch<{ notification_id: string }>(
        endpoints.v2_tenants_tenant_id_pim_products_status(tenant_id),
        {
          product_ids,
          status: selectedStatus,
        }
      );
      setShowPopup(false);
      resetBulkAction();
      set_show_product_status_update_dialog(true);
      const pollFn = async () => {
        const {
          data: { status },
        } = await axios.get<BulkProductStatusResponse>(
          endpoints.v2_tenants_tenant_id_pim_products_status_notifications_notification_id(
            tenant_id,
            notification_id
          )
        );
        const message = t(
          "Product status update completed. Please see email for details."
        );
        return {
          shouldPoll: status === "in_progress",
          status,
          message,
        };
      };

      const onComplete = ({ message }: { message: string }) => {
        onCompleteUpdate();
        notifyInfo(message);
        set_show_product_status_update_dialog(false);
      };

      const onError = (error?: unknown) => {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t(
                "There was an error while updating the product status. Please try again later."
              ),
          {
            error,
          }
        );
      };

      pollNotification({
        pollFn,
        onComplete,
        onError,
      });
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t(
              "There was an error while updating the product status. Please try again later."
            ),
        {
          error,
        }
      );
    }
  };

  const onCancel = () => {
    setShowPopup(false);
    onCancelUpdate();
  };

  useEffect(() => setShowPopup(show), [show]);

  return (
    <>
      <ProductStatusPopup
        show={showPopup}
        onSelectOption={onSelectOption}
        onCancel={onCancel}
      />
      <ProductStatusUpdateInProgress
        show={show_product_status_update_dialog}
        onClose={() => set_show_product_status_update_dialog(false)}
      />
    </>
  );
};

const ProductStatusPopup = ({
  onSelectOption,
  onCancel,
  show,
}: {
  onSelectOption: (option: ProductStatusType) => Promise<void>;
  onCancel: () => void;
  show: boolean;
}) => {
  const [showDialog, setShowDialog] = useState(show);
  const [status, setStatus] = useState<ProductStatusType>();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { hasPermission } = useAuthContext();

  const { data: statusOptions, error } = useSWR<ProductStatusesSchema>(
    endpoints.v2_tenants_tenant_id_pim_products_status(tenant_id)
  );
  const statuses = statusOptions?.statuses;

  const updateStatus = (e: React.FormEvent<HTMLSelectElement>) => {
    setStatus(e.currentTarget.value as ProductStatusType);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setStatus(undefined);
    onCancel();
  };

  const isLoading = !statuses && !error;

  const onConfirm = async () => {
    setIsSubmitLoading(true);
    await onSelectOption(status!);
    setIsSubmitLoading(false);
  };

  useEffect(() => setShowDialog(show), [show]);

  const isStatusAllowed = (status: ProductStatusType) => {
    if (status === "published" && !hasPermission("publish_products")) {
      return false;
    }
    if (status === "unpublished" && !hasPermission("unpublish_products")) {
      return false;
    }
    return true;
  };

  return (
    <GenericDialogBody show={showDialog} closeDialog={closeDialog}>
      <DialogWrapper>
        <H3 style={{ margin: 0 }}>{t("Update product status")}</H3>
        <H5>{t("Choose from a status below")}</H5>
        {isLoading ? (
          <DelayedSpinner />
        ) : (
          <RadioButtonContainer>
            {error ? (
              <DestructiveText>
                {t("Could not fetch product statuses. Please try again later.")}
              </DestructiveText>
            ) : (
              statuses!.reduce<JSX.Element[]>((acc, option) => {
                const excludedStatus: ProductStatusType[] = [
                  "draft",
                  "staged",
                  "unpublished_staged",
                  "bulk_edit_in_progress",
                ];
                if (
                  excludedStatus.every((item) => item !== option) &&
                  isStatusAllowed(option)
                ) {
                  acc.push(
                    <RadioButton
                      key={option}
                      name={"status"}
                      value={option}
                      checked={option === status}
                      optionTitle={getProductStatusText(option, t)}
                      handleChange={updateStatus}
                    />
                  );
                }
                return acc;
              }, [])
            )}
          </RadioButtonContainer>
        )}
        <ConfirmOrCancelButtonContainer
          onCancel={closeDialog}
          onConfirm={onConfirm}
          isConfirmLoading={isSubmitLoading}
          isConfirmDisabled={!status}
        />
      </DialogWrapper>
    </GenericDialogBody>
  );
};

const ProductStatusUpdateInProgress = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const [showDialog, setShowDialog] = useState(show);
  const { t } = useTranslation();

  useEffect(() => setShowDialog(show), [show]);

  return (
    <UploadPopups
      showDialog={showDialog}
      onComplete={onClose}
      setShowDialog={setShowDialog}
      title={t("Update in progress")}
      message={t(
        "Your status update is in progress and you will receive an email confirmation shortly."
      )}
      status="in_progress"
      uploadId=""
    />
  );
};
