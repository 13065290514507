import type { Permission } from "../../types/types";
import { useAuthContext } from "../Auth";

export function WithPermission({
  children,
  permission,
  permission_type,
}: {
  children: JSX.Element;
  permission: Permission | Permission[];
  permission_type?: "some" | "every";
}) {
  const { hasPermission } = useAuthContext();

  if (hasPermission(permission, permission_type)) {
    return children;
  } else {
    return null;
  }
}
