import React from "react";
import type { CurrencyCode } from "../../types/types";
import { TextField } from "../TextFields/TextFields";

/**
 * Return a function to be used in a yup `.test(theFunction)` call, to test for
 * valid input for a given currency. Among other things this ensures positive
 * currency values.
 *
 * The `allowedValues` param specifies acceptable values such as empty string,
 * `null`, or `undefined`.
 */
export const makeYupCurrencyTestFn = (
  currencyCode: CurrencyCode,
  allowedValues?: (null | undefined | "")[]
) => {
  if (currencyCode === "JPY") {
    // For JPY we only deal in integer values. If we did allow decimals then
    // there would be rounding involved when formatting the values (because the
    // default JPY formatting rounds to integer values) which would make things
    // more complex and potentially confusing for the user.
    return (value: string | null | undefined) => {
      if (allowedValues?.some((val) => val === value)) {
        return true;
      }
      const numValue = Number(value);
      return Number.isInteger(numValue) && numValue > 0;
    };
  }
  return (value: string | null | undefined) => {
    if (allowedValues?.some((val) => val === value)) {
      return true;
    }
    const numValue = Number(value);
    return numValue > 0;
  };
};

/**
 * Input to use for currency (money) values. Using this component makes it easy
 * to find everywhere we have currency inputs, and easier to handle them
 * consistently. (The original idea was to also do validation in this component
 * based on the type of currency, but that does not match how `yup` works.)
 */
export const CurrencyInput = ({
  name,
  label,
  defaultValue,
  identifier,
  theref,
  formState,
  errors,
  error,
  testid,
  disabled,
  ...rest
}: React.ComponentProps<typeof TextField>) => {
  return (
    <TextField
      name={name}
      label={label}
      defaultValue={defaultValue}
      identifier={identifier}
      theref={theref}
      formState={formState}
      errors={errors}
      error={error}
      testid={testid}
      disabled={disabled}
      {...rest}
      type="number"
    />
  );
};
