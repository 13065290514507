import type { ChangeEvent, MouseEvent } from "react";
import React, { useEffect, useState } from "react";
import { DropDown } from "../../../components/DropDown/DropDown";
import { Pagination } from "../../../components/Pagination/Pagination";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { IDMobile } from "../../../components/IDs/IDs";
import ReactTooltip from "react-tooltip";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import { Table } from "../../../components/Table/Table";
import { useDebounce } from "../../../util/hooks";
import type {
  IOrderItem,
  IOrdersAPIResponse,
  IPurchaseOrder,
  OrderStatus,
  StatusAndId,
} from "../../../types/types";
import {
  ContentWrapper,
  FiltersWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import {
  BuyerOrderStatus,
  getBuyerOrderStatusFilters,
  getOrderProductsAsText,
} from "../../../components/OrderStatus/OrderStatus";
import {
  convertStringArrayToObj,
  formatAddressCityState,
  formatDate,
  formatDateTime,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import type { AxiosError } from "axios";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { getOrderItemsList } from "../../../util/QuotesAndOrders";
import { useTranslation } from "react-i18next";
import { calculatePriceDetails } from "../../../components/PriceDetails/PriceDetails";
import {
  TooltipCell,
  applyFiltersToURL,
  constructQuery,
} from "./BuyerOrders.util";
import { TrimmedName } from "../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type { Getter } from "@tanstack/react-table";
import {
  IconWrapper,
  NumberBadge,
} from "../../SharedPages/SellerOrdersListPage/SellerOrdersListPage";
import { MailIcon } from "../../../components/Icons/Icons";

type OrdersTable = {
  id: string;
  products: { items: IOrderItem[]; id: string };
  seller_name: string;
  total_value: string;
  last_activity: string;
  status: StatusAndId<OrderStatus>;
  allOrderItems: IOrderItem[];
  unread_message_count?: number;
};

export const BuyerOrders = providePrivatePageProps(({ user }) => {
  const { t } = useTranslation();
  const localStrings = {
    pageTitle: t("Orders"),
    searchBarPlaceholder: t("Search by Product or ID"),
    status: t("Status"),
    perPage: t("Per Page"),
  };

  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50, 100];
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );
  const history = useHistory();
  const { accountPath } = useRoutePath();

  const { storefront_id } = useStoreState();
  const { ordersNotifications } = useInAppNotifications(storefront_id, user);

  const { data: ordersResponse, error: ordersError } = useSWR<
    IOrdersAPIResponse,
    AxiosError<IOrdersAPIResponse>
  >(
    constructQuery({
      baseUrl: `/v1/storefronts/${storefront_id}/orders?`,
      query: debouncedSearchQuery,
      status: selectedStatusList,
      offset,
      perPage,
    })
  );

  const isLoading = !ordersResponse && !ordersError;
  const [tableData, setTableData] = useState<OrdersTable[]>([]);

  const hasUnreadMessages = tableData.some(
    (item) => item.unread_message_count !== 0
  );

  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Order ID"),
        accessorKey: "number",
        cell: ({ getValue }: { getValue: Getter<string> }) => (
          <IDMobile>{`${getValue()}`}</IDMobile>
        ),
      },
      {
        header: t("Product(s)"),
        accessorKey: "products",
        cell: ({
          getValue,
        }: {
          getValue: Getter<{ items: IOrderItem[]; id: string }>;
        }) => {
          return (
            <TooltipCell data-for={`${getValue().id}-tooltip`} data-tip={""}>
              <TrimmedName text={getOrderProductsAsText(getValue().items, t)} />
              <ReactTooltip
                id={`${getValue().id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                backgroundColor="#60676F"
                multiline={true}
              >
                {getOrderItemsList(getValue().items)}
              </ReactTooltip>
            </TooltipCell>
          );
        },
      },
      {
        header: t("Seller"),
        accessorKey: "seller_name",
      },
      {
        header: t("Total Value"),
        accessorKey: "total_value",
      },
      {
        header: t("Last Activity"),
        accessorKey: "last_activity",
        align: "right",
      },
      ...(hasUnreadMessages
        ? [
            {
              header: "",
              accessorKey: "unread_message_count",
              width: 60,
              cell: ({ getValue }: { getValue: () => number }) => {
                const count = getValue();

                return count !== 0 ? (
                  <IconWrapper>
                    <MailIcon width={24} height={24} />
                    <NumberBadge>{count as number}</NumberBadge>
                  </IconWrapper>
                ) : null;
              },
            },
          ]
        : []),
      {
        header: t("Order Placed"),
        accessorKey: "created_at",
        // make this look not so close to the last activity column
        align: "right",
      },
      { header: t("Shipping Address"), accessorKey: "ship_to" },
      {
        header: t("Status"),
        accessorKey: "status",
        width: 50,
        minWidth: 50,
        align: "right",
        cell: ({
          getValue,
        }: {
          getValue: Getter<StatusAndId<OrderStatus>>;
        }) => (
          <BuyerOrderStatus
            t={t}
            status={getValue().status}
            id={getValue().id}
          />
        ),
      },
    ],
    [t, hasUnreadMessages]
  );

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  useEffect(() => {
    setQuery({ offset, perPage, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      getBuyerOrderStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    const getUnread = (order: IPurchaseOrder): boolean => {
      return ordersNotifications?.ids
        ? ordersNotifications?.ids.includes(order?.id)
        : false;
    };

    if (ordersResponse) {
      const handleOrdersData = ({ data, pagination }: IOrdersAPIResponse) => {
        setTableData(
          data.map((order: IPurchaseOrder) => ({
            id: order.id,
            number: order.number,
            products: { items: order.items, id: order.id },
            seller_name: order.seller_name || "--",
            total_value: (() => {
              const maybeInvoiceCharges =
                order.invoice && order.invoice.additional_charges
                  ? order.invoice.additional_charges
                  : [];

              const feesAndCharges = [...order.fees, ...maybeInvoiceCharges];

              const { totalString } = calculatePriceDetails({
                items: order.items,
                fees: feesAndCharges,
                currencyCode: order.currency,
              });

              return totalString;
            })(),
            last_activity: formatDateTime(order.modified_at) || "--",
            created_at: formatDate(order.created_at),
            ship_to: formatAddressCityState(order.shipping_address),
            status: { status: order.status || "--", id: order.id },
            allOrderItems: order.items,
            unread: getUnread(order),
            unread_message_count: order.unread_message_count,
          }))
        );
        setTablePagination({
          perPage: perPage,
          pageCount: Math.ceil(pagination.total / perPage),
          pageIndex: pagination.offset / perPage + 1,
        });
      };
      handleOrdersData(ordersResponse);
    }
  }, [ordersResponse, perPage, ordersNotifications]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (e: MouseEvent) => {
    const existingParams = new URLSearchParams();
    if (query.q) {
      existingParams.append("q", query.q);
    }
    existingParams.append("offset", String(offset));
    existingParams.append("perPage", String(perPage));
    history.push(
      `${accountPath}/orders/${e.currentTarget.id}?${applyFiltersToURL({
        status: selectedStatusList,
        params: existingParams,
      })}`
    );
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings.pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={localStrings.searchBarPlaceholder}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </PageHeader>

      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={getBuyerOrderStatusFilters(t)}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textRight={localStrings.perPage}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={ordersError}
          rowClick={handleRowClick}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
});
