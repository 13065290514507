import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import useSWR from "swr";
import * as z from "zod";
import { PrimaryButtonFitContainer } from "../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { EditDeleteModal } from "../../../../components/EditDeleteModal/EditDeleteModal";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  SectionTitle,
} from "../../../../components/Form/Form";
import { HeaderLeft } from "../../../../components/Layout/Layout";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../endpoints";
import { Form } from "../../../../layout/FormLayout";
import type {
  DataMutate,
  Language,
  OptionType,
  SupportedLanguage,
  WithPagination,
} from "../../../../types/types";
import type {
  Assets,
  ListDetailsSchema,
  ListItemObjectSchema,
  PIMProduct,
  SupportedAssetAccessibility,
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../types/types.PIM";
import { stringIsNotOnlyWhiteSpaceRegex } from "../../../../util/regexes";
import {
  formatDate,
  getDateTime,
  isAxiosError,
  toTitleCase,
  useFormWrapper,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../util/util";
import {
  zodOptionalString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../util/zod.util";
import type { AssetCategory } from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { FileUploader } from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/FileUploader";
import { getNameAndExtension } from "../Resources/Resources.utils";

import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { ErrorPlaceholder } from "../../../../components/Error";
import { useCountriesList, useRegionsList } from "../../../../util/Locations";
import { getCountryOption } from "../../../../util/location";
import type { IAssetPayload } from "./NewAssetForm";
import { H4, SmallText } from "../../../../components/Typography/Typography";
import { RadioButton } from "../../../../components/RadioButton/RadioButton";

const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

const AddAssetSchema = z.object({
  asset_name: z.string(),
  input_asset_type: zodSelectBoxType,
  input_category_type: zodSelectBoxType,
  input_lang_type: zodSelectBoxType.optional(),
  input_document_type: zodSelectBoxType.optional().nullable(),
  input_issue_date: zodOptionalString,
  input_expiration_date: zodOptionalString,
  input_renewal_date: zodOptionalString,
  input_country: zodSelectBoxType.optional().nullable(),
  input_region: zodSelectBoxType.optional().nullable(),
});

type FormValues = z.infer<typeof AddAssetSchema>;

const AddAssetSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      asset_name: z
        .string()
        .regex(stringIsNotOnlyWhiteSpaceRegex, t("This is a required field")),
      input_asset_type: zodSelectBoxDefault(t),
      input_category_type: zodSelectBoxDefault(t),
      input_lang_type: zodSelectBoxDefault(t).optional(),
      input_document_type: zodSelectBoxType.optional().nullable(),
      input_issue_date: zodOptionalString,
      input_expiration_date: zodOptionalString,
      input_renewal_date: zodOptionalString,
      input_country: zodSelectBoxDefault(t).optional().nullable(),
      input_region: zodSelectBoxDefault(t).optional().nullable(),
    })
    .superRefine(({ input_asset_type, input_category_type }, ctx) => {
      if (!input_asset_type?.value || !input_category_type?.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("This is a required field"),
          path: ["input_asset_type"],
        });
      }
    });

export const EditAssetForm = ({
  onComplete,
  product,
  fetchProductData,
  assetData,
  formType,
}: {
  onComplete: (productId?: string) => void;
  product: PIMProduct;
  fetchProductData: DataMutate<WithPagination<{ data: Assets[] }>>;
  assetData: Assets | null;
  formType: string;
}) => {
  const { t } = useTranslation();
  const countries = useCountriesList();
  const regions = useRegionsList();
  const [submitting, setSubmitting] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [documentTypesFilters, setDocumentTypesFilters] = useState<
    OptionType<string>[]
  >([]);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);
  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);
  const [formData, setFormData] = useState<{
    name: string;
    asset_type: string;
    category_id: string;
    language?: string;
    visibility?: SupportedAssetAccessibility;
    is_cover_image?: boolean;
  }>({
    name: "",
    asset_type: "",
    category_id: "",
    language: undefined,
    visibility: undefined,
  });
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id, storefront_id } = useStoreState();

  const isDocument = formType === "Documents";

  const updateProductStatus = useUpdateProductStatus({ product });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {
    category,
    language,
    id,
    name,
    file_name,
    asset_type,
    number_of_products,
    document_type,
    issue_date,
    expiration_date,
    renewal_date,
    country,
    region,
    visibility,
  } = assetData as Assets;

  const assetTypeDefault: {
    label: Capitalize<SupportedAssetType>;
    value: SupportedAssetType;
  } = isDocument
    ? { label: t("Document"), value: "document" }
    : {
        label: toTitleCase(asset_type) as Capitalize<SupportedAssetType>,
        value: asset_type,
      };

  const [accessibility, setAccessibility] =
    useState<SupportedAssetAccessibility>(visibility);

  const [isCoverImage, setIsCoverImage] = useState(
    (product.cover_image?.id ?? undefined) === (assetData?.id ?? "")
  );

  const existingFileName: string = selectedFile
    ? selectedFile.name
    : file_name ?? name;

  const handleSetCoverImage = () => {
    if (!isCoverImage) {
      setAccessibility("public");
    }
    setIsCoverImage(!isCoverImage);
  };

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddAssetSchemaFn(t)),
    defaultValues: {
      input_asset_type: assetTypeDefault ?? undefined,
      input_category_type: {
        label: category?.name,
        value: category?.id,
      },
      input_document_type: document_type
        ? {
            label: document_type,
            value: document_type,
          }
        : undefined,
      input_issue_date: issue_date ? formatDate(issue_date, true) : undefined,
      input_expiration_date: expiration_date
        ? formatDate(expiration_date, true)
        : undefined,
      input_renewal_date: renewal_date
        ? formatDate(renewal_date, true)
        : undefined,
      input_country: country ? getCountryOption(countries, country) : undefined,
      input_region: region
        ? {
            label: region,
            value: region,
          }
        : undefined,
      asset_name: getNameAndExtension(name)?.name,
    },
  });

  const {
    handleSubmit,
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
  } = methodsOfUseForm;

  const selectedAssetType = watch("input_asset_type")?.value;

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    selectedAssetType?.length
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?asset_type=${asset_type ?? selectedAssetType}`
      : endpoints.v2_tenants_id_pim_assets_categories(tenant_id),
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        ),
    }
  );

  const { data: documentTypesResponse, error: documentTypeError } = useSWR<{
    data: ListDetailsSchema;
  }>(
    endpoints.v2_tenants_id_pim_get_list_by_name(tenant_id, "Document Types"),
    {
      onError: (error) => {
        console.error("There was an error fetching the Document types list.", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setDocumentTypesFilters(
          data.data.items.map((type: ListItemObjectSchema) => {
            return { label: type.name, value: type.name };
          })
        );
      },
    }
  );

  const { data: languagesResponse } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
      const selectLanguageOption = languages.languages.find(
        (item) => item.alpha_2 === language?.toLocaleLowerCase()
      );
      setValue(
        "input_lang_type",
        selectLanguageOption?.alpha_2
          ? {
              label: `${
                selectLanguageOption?.name
              } (${selectLanguageOption?.alpha_2.toLocaleUpperCase()})`,
              value: selectLanguageOption?.alpha_2,
            }
          : undefined
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const onSubmit = async ({
    asset_name,
    input_asset_type,
    input_category_type,
    input_document_type,
    input_lang_type,
    input_issue_date,
    input_expiration_date,
    input_renewal_date,
    input_region,
    input_country,
  }: FormValues): Promise<void> => {
    const paramsObj: IAssetPayload & {
      is_downloadable?: boolean;
      is_cover_image: boolean;
      visibility: SupportedAssetAccessibility;
    } = {
      name: asset_name,
      asset_type: input_asset_type?.value.toLowerCase(),
      category_id: input_category_type?.value,
      language: input_lang_type?.value,
      visibility: accessibility, //isCoverImage || isVisible,
      is_cover_image: isCoverImage,
      issue_date: input_issue_date
        ? getDateTime(input_issue_date, true).dateFullYear
        : null,
      expiration_date: input_expiration_date
        ? getDateTime(input_expiration_date, true).dateFullYear
        : null,
      renewal_date: input_renewal_date
        ? getDateTime(input_renewal_date, true).dateFullYear
        : null,
      document_type: input_document_type?.value || null,
      country: input_country?.value || null,
      region: input_region?.value || null,
    };
    setFormData(paramsObj);
    setShowEditPopup(true);
  };

  const changeAssetAccessibility = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      setAccessibility(e.currentTarget.value as SupportedAssetAccessibility);
    }
  };

  const handleConfirm = async (): Promise<void> => {
    setSubmitting(true);

    try {
      const { data: updatedProduct } = await updateProductStatus();

      const baseURL = endpoints.v2_storefronts_id_pim_assets_id(tenant_id, id);

      if (formData?.is_cover_image !== undefined) {
        const coverImageUrl =
          endpoints.v2_storefronts_id_pim_product_cover_image(
            tenant_id,
            updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
          );
        const cover_image_id = !formData.is_cover_image ? null : id;
        await Axios.patch(coverImageUrl, { cover_image_id: cover_image_id });
      }

      const { is_cover_image, ...formDataWithoutIsCoverImage } = formData;
      await Axios.patch(baseURL, formDataWithoutIsCoverImage);

      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile as File);
        await Axios.put(baseURL, formData);
      }
      await fetchProductData();
      notifySuccess(t("Asset successfully Edited"));
      onComplete(
        updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
      );
      setShowEditPopup(false);
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (languagesResponse) {
      setAssetLanguages(
        languagesResponse.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
      const selectLanguageOption = languagesResponse.languages.find(
        (item) => item.alpha_2 === language?.toLocaleLowerCase()
      );
      setValue(
        "input_lang_type",
        selectLanguageOption?.alpha_2
          ? {
              label: `${
                selectLanguageOption?.name
              } (${selectLanguageOption?.alpha_2.toLocaleUpperCase()})`,
              value: selectLanguageOption?.alpha_2,
            }
          : undefined
      );
    }
  }, [languagesResponse, language, setValue]);

  useEffect(() => {
    if (documentTypesResponse?.data) {
      setDocumentTypesFilters(
        documentTypesResponse.data?.items.map((type: ListItemObjectSchema) => {
          return { label: type.name, value: type.name };
        })
      );
    }
  }, [documentTypesResponse]);

  useEffect(() => {
    if (countries.length > 0 && country) {
      setValue("input_country", getCountryOption(countries, country));
    }
  }, [countries, country, setValue]);

  if (categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset categories. Please try again later"
        )}
      />
    );
  }

  const coverImageTooltip = isCoverImage
    ? t("Cannot hide this image because it is used as product cover image")
    : assetData?.is_cover_image
    ? t(
        "Cannot hide this image because it is used as a cover image for another product"
      )
    : "";

  return (
    <>
      <MarginBottomHeaderLeft>
        {formType === "Documents" ? (
          <SectionTitle>{t("Edit Document")}</SectionTitle>
        ) : (
          <SectionTitle>{t("Edit Digital Media")}</SectionTitle>
        )}
      </MarginBottomHeaderLeft>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_asset_type"
          autoComplete={"input_asset_type"}
          placeholder={t("Asset Type")}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
          isDisabled={true}
          defaultValue={assetTypeDefault ?? false}
          options={
            !isDocument && [
              { label: t("Image"), value: "image" },
              { label: t("Video"), value: "video" },
            ]
          }
        />
        {!categoryTypeError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_category_type"
            autoComplete={"input_category_type"}
            placeholder={t("Category")}
            options={assetCategories}
            errors={errors}
            formState={formState}
          />
        )}
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setValue={setValue}
          selectedAssetType={selectedAssetType}
          control={control}
          errors={errors}
          formState={formState}
          existingFileName={existingFileName}
        />
        <TextField
          name="asset_name"
          label={t("Asset Name")}
          autoComplete={"asset_name"}
          formState={formState}
          theref={register({
            required: false,
          })}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_lang_type"
          autoComplete={"input_lang_type"}
          placeholder={t("Language")}
          options={assetLanguages}
          defaultValue={
            language
              ? assetLanguages.find((item) => item.value === language)
              : undefined
          }
          errors={errors}
          formState={formState}
        />
        {!documentTypeError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_document_type"
            isClearable={true}
            autoComplete={"input_document_type"}
            placeholder={t("Document Type")}
            options={documentTypesFilters}
            defaultValue={
              document_type
                ? { value: document_type, label: document_type }
                : undefined
            }
            errors={errors}
            formState={formState}
          />
        )}
        <DatePicker
          label={t("Issue Date")}
          name={"input_issue_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MMM DD, YYYY")}
          defaultValue={issue_date ?? undefined}
          isOutsideRange={() => false}
        />
        <DatePicker
          label={t("Expiration Date")}
          name={"input_expiration_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MMM DD, YYYY")}
          defaultValue={expiration_date ?? undefined}
          isOutsideRange={() => false}
        />
        <DatePicker
          label={t("Renewal Date")}
          name={"input_renewal_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MMM DD, YYYY")}
          defaultValue={renewal_date ?? undefined}
          isOutsideRange={() => false}
        />
        {countries && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_country"
            autoComplete={"country"}
            placeholder={t("Country")}
            defaultValue={
              countries && country
                ? getCountryOption(countries, country)
                : undefined
            }
            isClearable={true}
            options={countries}
            rules={{
              required: false,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_region"
          autoComplete={"region"}
          placeholder={t("Region")}
          isClearable={true}
          options={regions}
          rules={{
            required: false,
          }}
          errors={errors}
          formState={formState}
        />
        {formType === "Documents" ? (
          <>
            <H4>{t("Accessibility")}</H4>
            <SmallText>{t("Document can be downloaded by:")}</SmallText>
            <RadioButton
              name={"public"}
              value="public"
              checked={accessibility === "public"}
              optionTitle={t("Public")}
              handleChange={changeAssetAccessibility}
            />
            <RadioButton
              name={"logged_in_user"}
              value="logged_in_user"
              checked={accessibility === "logged_in_user"}
              optionTitle={t("Customers & Internal Users")}
              handleChange={changeAssetAccessibility}
            />
            <RadioButton
              name={"internal"}
              value="internal"
              checked={accessibility === "internal"}
              optionTitle={t("Internal Users Only")}
              handleChange={changeAssetAccessibility}
            />
          </>
        ) : selectedAssetType === "image" ? (
          <>
            <CheckBoxContainer>
              <CheckBoxNoLabel
                name="is_cover_image"
                id="is_cover_image"
                checked={isCoverImage}
                ref={register({ required: false })}
                onChange={handleSetCoverImage}
              />
              <CheckBoxFinePrintLabel htmlFor="is_cover_image">
                {t("Make this the cover image for the product")}
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>

            <>
              <H4>{t("Accessibility")}</H4>
              <SmallText>{t("Media is accessible by:")}</SmallText>
              <div>
                <span
                  style={{ display: "inline-block" }}
                  data-tip={coverImageTooltip}
                  data-for={`tooltip-is-visible`}
                >
                  <RadioButton
                    name={"public"}
                    value="public"
                    checked={accessibility === "public"}
                    disabled={isCoverImage || assetData?.is_cover_image}
                    optionTitle={t("Public")}
                    handleChange={changeAssetAccessibility}
                    style={{ marginBottom: "15px" }}
                  />
                </span>
              </div>
              <div>
                <span
                  style={{ display: "inline-block" }}
                  data-tip={coverImageTooltip}
                  data-for={`tooltip-is-visible`}
                >
                  <RadioButton
                    name={"logged_in_user"}
                    value="logged_in_user"
                    checked={accessibility === "logged_in_user"}
                    disabled={isCoverImage || assetData?.is_cover_image}
                    optionTitle={t("Customers & Internal Users")}
                    handleChange={changeAssetAccessibility}
                    style={{ marginBottom: "15px" }}
                  />
                </span>
              </div>
              <div>
                <span
                  style={{ display: "inline-block" }}
                  data-tip={coverImageTooltip}
                  data-for={`tooltip-is-visible`}
                >
                  <RadioButton
                    name={"internal"}
                    value="internal"
                    checked={accessibility === "internal"}
                    disabled={isCoverImage || assetData?.is_cover_image}
                    optionTitle={t("Internal Users Only")}
                    handleChange={changeAssetAccessibility}
                    style={{ marginBottom: "15px" }}
                  />
                </span>
              </div>
            </>
          </>
        ) : (
          <>
            <H4>{t("Accessibility")}</H4>
            <SmallText>{t("Media is accessible by:")}</SmallText>
            <RadioButton
              name={"public"}
              value="public"
              checked={accessibility === "public"}
              optionTitle={t("Public")}
              handleChange={changeAssetAccessibility}
            />
            <RadioButton
              name={"logged_in_user"}
              value="logged_in_user"
              checked={accessibility === "logged_in_user"}
              optionTitle={t("Customers & Internal Users")}
              handleChange={changeAssetAccessibility}
            />
            <RadioButton
              name={"internal"}
              value="internal"
              checked={accessibility === "internal"}
              optionTitle={t("Internal Users Only")}
              handleChange={changeAssetAccessibility}
            />
          </>
        )}
        <ReactTooltip
          id={`tooltip-is-visible`}
          place="top"
          data-html={true}
          data-class={"thumbnail-tooltip"}
          effect="solid"
          backgroundColor="#60676f"
          multiline={true}
        />
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
      {showEditPopup && (
        <EditDeleteModal
          title={t("Edit Asset")}
          show={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          isLoading={submitting}
          onConfirm={handleConfirm}
          body={t(
            " This change will affect {{number_of_products}} other products. Would you like to continue ? ",
            { number_of_products }
          )}
          confirmBtnLabel={t("Confirm")}
          hideIcon={true}
        />
      )}
    </>
  );
};
