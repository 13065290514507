import type { AxiosError } from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import {
  GoBackButtonSmall,
  InvisibleButton,
  PrimaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { endpoints } from "../../../endpoints";
import type { PIMProduct } from "../../../types/types.PIM";
import {
  ErrorWithStatus,
  useStoreState,
  makeUrlWithParams,
  PIM_PRODUCT_DETAILS_PORTFOLIO_TAB,
} from "../../../util/util";
import { PIMProductDetailsPortfolio } from "../../public/ProductDetailsPortfolio/PIMProductDetailsPortfolio";
import { PageWrapper } from "../../../layout/portalPageLayout";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useRoutePath } from "../../../util/Routing";
import { ErrorPlaceholder } from "../../../components/Error";
import { CenterContainer } from "../../../layout/publicPageLayout";
import { H4, Title } from "../../../components/Typography/Typography";
import { Auth } from "../../../components/Auth";

export const BuyerProductDetailPortfolio = () => {
  const { roleIsSomeKindOfBuyer } = useContext(Auth);
  const { pathname } = useLocation();
  const { productSlug: productId } = useParams<{ productSlug: string }>();
  const [backToProductList, setbackToProductList] = useState(false);
  const { adminPath, storePath } = useRoutePath();
  const history = useHistory();
  const { t } = useTranslation();

  const { tenantSlug } = useParams<{ tenantSlug: string }>();
  const { tenant_id, storefront_metadata } = useStoreState();

  // We cant annotate the type inline while destucturing but "mutate"
  // here is type ProductMutate

  useEffect(() => {
    const portfolioView = localStorage.getItem("portfolioView");
    if (roleIsSomeKindOfBuyer) {
      if (
        (portfolioView && portfolioView === "modern") ||
        portfolioView === "classic"
      ) {
        const basePath = `${storePath}/admin/pim/products`;
        const orderId = pathname.split("/").pop();
        history.replace(`${basePath}/${orderId}`);
      }
    }
  }, [history, roleIsSomeKindOfBuyer, storePath, pathname]);

  const {
    data: product,
    error: productError,
    mutate: mutateProduct,
  } = useSWR<PIMProduct, AxiosError>(
    productId
      ? makeUrlWithParams(
          endpoints.v2_tenants_id_pim_products_id(tenant_id, productId),
          { use_database: "true" }
        )
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  const errorWithStatus = productError
    ? new ErrorWithStatus(
        Number(productError?.code ?? 0),
        productError?.message ?? ""
      )
    : undefined;

  const onBackClick = () => {
    localStorage.removeItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB);
    const historyLength = window.history?.length;
    if (backToProductList || historyLength === 1) {
      history.push(`${adminPath}/pim/products`);
    } else {
      history.goBack();
    }
  };

  if (!product && !productError) {
    return <DelayedSpinner />;
  }

  if (productError) {
    return (
      <>
        {productError.response?.data.message ===
        "Product is no longer available" ? (
          <>
            <InvisibleButton onClick={() => onBackClick()}>
              <GoBackButtonSmall text={t("Back")} />
            </InvisibleButton>

            <CenterContainer>
              <Title>{t("This product is not available")}</Title>
              <H4>
                {t(
                  "{{tenantName}} has not made this product publicly accessible. please contact {{tenantName}} to access this product",
                  {
                    tenantName: storefront_metadata.tenant_name,
                  }
                )}
              </H4>
              <PrimaryButtonMedium
                style={{ marginTop: "20px" }}
                onClick={() => history.push(`/store/${tenantSlug}`)}
              >
                {t("Go to Homepage")}
              </PrimaryButtonMedium>
            </CenterContainer>
          </>
        ) : productError.response?.data.message === "product not found!" ? (
          <>
            <InvisibleButton onClick={() => onBackClick()}>
              <GoBackButtonSmall text={t("Back")} />
            </InvisibleButton>

            <CenterContainer>
              <Title>{t("404")}</Title>
              <H4>{t("Product not found!")}</H4>
              <PrimaryButtonMedium
                style={{ marginTop: "20px" }}
                onClick={() => onBackClick()}
              >
                {t("Back")}
              </PrimaryButtonMedium>
            </CenterContainer>
          </>
        ) : (
          <>
            <InvisibleButton onClick={() => onBackClick()}>
              <GoBackButtonSmall text={t("Back")} />
            </InvisibleButton>
            <ErrorPlaceholder
              message={t("There was an error loading the product.")}
            />
          </>
        )}
      </>
    );
  }

  return (
    <PageWrapper>
      <div style={{ marginBottom: "13px" }}>
        <InvisibleButton onClick={onBackClick}>
          <GoBackButtonSmall text={t("Back")} />
        </InvisibleButton>
      </div>
      <PIMProductDetailsPortfolio
        product={product}
        error={errorWithStatus}
        mutateProduct={mutateProduct}
        setbackToProductList={setbackToProductList}
        can_set_tab_query={false}
      />
    </PageWrapper>
  );
};
