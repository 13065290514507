import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { normalizeAttributeName } from "../../pages/admin/SellerAdmin/PIM/components/PIM.components.util";
import type { UpdatedFees } from "../../pages/Seller/SellerCreateQuote/UpdateFeesDialog";
import { UpdateFeesDialog } from "../../pages/Seller/SellerCreateQuote/UpdateFeesDialog";
import type { Currency, Fee, MethodsOfUseForm } from "../../types/types";
import { useFees, useStoreState } from "../../util/util";
import { formatPrice } from "../../util/util-components";
import { PrimaryButtonSmall, TextButton } from "../Buttons/Buttons";
import { DelayedSpinner } from "../DelayedSpinner/DelayedSpinner";
import { EditablePriceList } from "../EditablePriceList/EditablePriceList";
import { Row } from "../Layout/Layout";
import { TaxExemptFee } from "../TaxExempt/TaxExemptFee";

const Container = styled.div`
  max-width: 340px;
  background-color: ${({ theme }) => theme.secondaryBG};
  padding: 0 6x;
`;

const BrandedTextButton = styled(TextButton)`
  color: ${({ theme }) => theme.brandColor};
`;

const RowWithMargin = styled(Row)`
  div {
    margin-right: 10px;
  }
`;

const ItemText = styled.p`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const NumberText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TotalText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const HorizontalSeparator = styled.ol`
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding-left: 0;
  list-style: none;
  display: flex;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const calculateTotal = (subtotal: number, values: Fee[]) => {
  const totalFees: number = values.reduce(
    (a, b) => a + (Number(b["amount"]) || 0),
    0
  );
  return Number(subtotal) + Number(totalFees);
};

export const EditSubtotal = ({
  subtotal,
  total,
  setTotal,
  isTaxExempt,
  methodsOfUseForm,
  currency,
}: {
  subtotal: number;
  total: number;
  setTotal: (total: number) => void;
  isTaxExempt: boolean;
  methodsOfUseForm: MethodsOfUseForm;
  currency: Currency;
}): JSX.Element => {
  const [show_add_fees, set_show_add_fees] = useState(false);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { watch, control } = methodsOfUseForm;

  const { data: fees_and_charges, loading: is_loading_fees } =
    useFees(storefront_id);

  const methodsOfUseFieldArray = useFieldArray({
    control,
    name: "feesList",
    keyName: "key",
  });
  const { fields, append } = methodsOfUseFieldArray;

  const on_add_new_fees = (fees: Array<UpdatedFees>) => {
    const updated_fees = fees.map((fee) => ({
      ...fee,
      is_disabled: String(fee.is_disabled) === "true",
    }));
    append(updated_fees);
    set_show_add_fees(false);
  };

  useEffect(() => {
    setTotal(calculateTotal(subtotal, watch("feesList", fields)));
  }, [fields, setTotal, subtotal, watch]);

  const selected_fees = watch("feesList", fields) as UpdatedFees[];

  const updated_fees_and_charges = (() => {
    const selected_fees_map = selected_fees.reduce((acc, fee) => {
      acc[normalizeAttributeName(fee.name).toLowerCase()] = fee;
      return acc;
    }, {} as Record<string, UpdatedFees>);
    return fees_and_charges.filter((fee) => {
      const selected_fee =
        selected_fees_map[normalizeAttributeName(fee.name).toLowerCase()];
      return selected_fee
        ? Number(selected_fee.amount) !== Number(fee.amount)
        : true;
    });
  })();

  return (
    <Container>
      <RowWithMargin>
        <ItemText>{t("Subtotal")}</ItemText>
        <NumberText data-testid={"subtotal"}>
          {formatPrice(subtotal, currency.code)}
        </NumberText>
      </RowWithMargin>
      <FormContainer>
        <EditablePriceList
          methodsOfUseForm={methodsOfUseForm}
          methodsOfUseFieldArray={methodsOfUseFieldArray}
          testIdPrefix={"quote-fee"}
          currencySymbol={currency.symbol}
          hasPossibleTaxFee={true}
        />
      </FormContainer>
      <RowWithMargin style={{ margin: "13px 0px" }}>
        {is_loading_fees ? (
          <DelayedSpinner />
        ) : (
          <>
            {fees_and_charges.length > 0 ? (
              <BrandedTextButton
                type="button"
                onClick={() => set_show_add_fees(true)}
              >
                {t("Add Fees & Charges")}
              </BrandedTextButton>
            ) : (
              <PrimaryButtonSmall
                type="button"
                onClick={() => append({ name: "", amount: "" })}
              >
                {t("Add Fee")}
              </PrimaryButtonSmall>
            )}
          </>
        )}
      </RowWithMargin>

      <TaxExemptFee
        isTaxExempt={isTaxExempt}
        methodsOfUseFieldArray={methodsOfUseFieldArray}
        methodsOfUseForm={methodsOfUseForm}
      />
      <HorizontalSeparator />
      <RowWithMargin>
        <TotalText>{t("Total")}</TotalText>
        <TotalText data-testid={"quote-total-value"}>
          {formatPrice(total, currency.code)}
        </TotalText>
      </RowWithMargin>
      {show_add_fees && (
        <UpdateFeesDialog
          show={show_add_fees}
          onComplete={(fees) => on_add_new_fees(fees)}
          onCancel={() => set_show_add_fees(false)}
          fees={updated_fees_and_charges}
        />
      )}
    </Container>
  );
};
