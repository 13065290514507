import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  RestoreButton,
} from "../../../../../components/Buttons/Buttons";
import type {
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import {
  highlightTextByQuery,
  toTitleCase,
  useStoreState,
} from "../../../../../util/util";
import { ArchivedListSublist } from "./ArchivedListSublist";
import {
  ItemDetailsWrapper,
  ListDetailsHeader,
  ListDetailsParent,
  ListDetailsWrapper,
} from "./ListDetails";
import { ListItemBase, ListItemName } from "./ListItem";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { StringParam, useQueryParams } from "use-query-params";
import { IconContainer } from "./ListHome";

export const ArchivedListDetails = ({
  searchQuery,
  onRestore,
  pageHeight,
}: {
  searchQuery: string;
  onRestore: (item: ShortListSchema) => Promise<void>;
  pageHeight: string;
}) => {
  const perPageOptions = [10, 20, 50];
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [lists, setLists] = useState<ShortListSchema[]>([]);
  const [selectedItemID, setSelectedItemID] = useState<string>();
  const [query, setQuery] = useQueryParams({
    selectedArchivedListID: StringParam,
  });
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { hasPermission } = useAuthContext();

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const {
    data,
    error: listError,
    mutate,
  } = useSWR<PaginatedListSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id),
      useMemo(
        () => ({
          params: {
            q: searchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "archived",
          },
        }),
        [searchQuery, perPage, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: ({ data }) => setLists(data),
    }
  );

  const isLoading = !data && !listError;

  useEffect(() => {
    setPagination({
      perPage: perPage,
      pageCount: Math.ceil((data?.pagination.total ?? 10) / perPage),
      pageIndex: (data?.pagination.offset ?? 10) / perPage + 1,
    });
  }, [data?.pagination.offset, data?.pagination.total, perPage]);

  useEffect(() => {
    const hasList = (id: string) => lists.some((list) => list.id === id);
    if (query.selectedArchivedListID) {
      if (hasList(query.selectedArchivedListID)) {
        setSelectedItemID(query.selectedArchivedListID);
      }
      setQuery({ selectedArchivedListID: undefined });
    }
  }, [lists, query.selectedArchivedListID, setQuery]);

  if (isLoading) {
    return <DelayedSpinner />;
  }

  if (listError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error fetching lists. Please try again later."
        )}
      />
    );
  }

  return (
    <ListDetailsParent>
      <ListDetailsWrapper height={pageHeight}>
        <ListDetailsHeader>
          <span>{t("Lists > Archived")}</span>
          <div style={{ zIndex: "2" }}>
            <DropDown
              items={perPageOptions}
              activeItem={perPage}
              textLeft={t("items") + ":"}
              textRight={t("Per Page")}
              direction={"right"}
              className={"per_Page"}
              clickHandler={changePerPage}
            />
          </div>
        </ListDetailsHeader>
        {(data?.pagination?.total ?? 0) > 0 &&
          lists.map((item) => (
            <ItemDetailsWrapper
              key={item.id}
              active={selectedItemID === item.id}
              onClick={() => setSelectedItemID(item.id)}
            >
              <ListItemName
                dangerouslySetInnerHTML={{
                  __html: `${highlightTextByQuery(
                    toTitleCase(item.name),
                    searchQuery
                  )} (${item.count})`,
                }}
              />
              <IconContainer>
                {/* TODO is restoration a modify permission or a delete permission */}
                {hasPermission("modify_lists") && (
                  <ButtonWithConfirmDialog
                    Button={(props) => (
                      <RestoreButton {...props} width={24} height={24} />
                    )}
                    testid={`list-item-${item.id}`}
                    handleConfirm={async () => {
                      await onRestore(item);
                      await mutate();
                      setSelectedItemID(undefined);
                    }}
                    confirmMessage={t(
                      "Are you sure you want to restore this list item, {{itemName}}?",
                      { itemName: item.name }
                    )}
                  />
                )}
              </IconContainer>
            </ItemDetailsWrapper>
          ))}
        {pagination.pageCount > 1 && (
          <ListItemBase style={{ height: "fit-content" }}>
            <Pagination
              pagination={pagination}
              offset={offset}
              handlePageClick={(offset) => setOffset(offset)}
            />
          </ListItemBase>
        )}
      </ListDetailsWrapper>
      <ArchivedListSublist
        item={lists.find((item) => item.id === selectedItemID)}
        pageHeight={pageHeight}
      />
    </ListDetailsParent>
  );
};
