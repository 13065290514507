import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useAuthContext } from "../../../../components/Auth";
import { PrimaryButtonWithPlusIcon } from "../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { StatusLeft } from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { Flex, Flex1, Flex2 } from "../../../../layout/FormLayout";

import type { ColumnDef } from "@tanstack/react-table";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import type {
  TeamDetailsSchema,
  TeamSummary,
  WithPagination,
} from "../../../../types/types";
import { useRoutePath } from "../../../../util/Routing";
import { useDebounce } from "../../../../util/hooks";
import { defaultHandleSort, useStoreState } from "../../../../util/util";
import { SellerAdminCreateNewTeam } from "./SellerAdminCreateNewTeam";

type TableData = {
  id: string;
  team_id: string;
  name: string;
  number_of_products: number;
  number_of_users: number;
  status: TeamSummary["status"];
};

export function SellerAdminTeamsListingPage() {
  const { t } = useTranslation();

  const { tenant_id } = useStoreState();

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const { adminPath } = useRoutePath();
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(query.perPage ?? 10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const { roleIsSellerAdmin } = useAuthContext();
  const [showCreateTeamSlideOut, setShowCreateTeamSlideOut] = useState(false);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const history = useHistory();

  function constructQuery({ baseUrl, query }: any) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return baseUrl + "?" + paramsWithOffset;
  }

  const { data: teamsResponse, error: teamsError } = useSWR<
    WithPagination<{ data: TeamDetailsSchema[] }>
  >(
    constructQuery({
      baseUrl: `/v2/tenants/${tenant_id}/pim/teams`,
      query: debouncedSearchQuery,
    })
  );

  const isLoading = !teamsResponse && !teamsError;

  const [tableData, setTableData] = useState<TableData[]>([]);
  const tableColumns = React.useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("Team Name"),
        accessorKey: "name",
      },
      {
        header: t("# of users"),
        accessorKey: "number_of_users",
      },
      {
        header: t("# of Products"),
        accessorKey: "number_of_products",
      },
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: (cell) => (
          <StatusLeft
            color={cell.getValue() === "active" ? "green" : "red"}
            text={cell.getValue() as string}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    const handleTeamsData = ({
      data,
      pagination,
    }: WithPagination<{ data: TeamDetailsSchema[] }>) => {
      setTableData(
        data.map(
          ({
            team_id,
            team_name,
            number_of_products,
            number_of_users,
            status,
          }) => ({
            id: team_id,
            team_id,
            name: team_name,
            number_of_users,
            number_of_products: status === "archived" ? 0 : number_of_products,
            status,
          })
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (teamsResponse) {
      handleTeamsData(teamsResponse);
    }
  }, [teamsResponse, perPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    setQuery({ offset, perPage });
  }, [offset, perPage, setQuery]);

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    history.push(`${adminPath}/organization/teams/${e.currentTarget.id}`);
  };

  return (
    <>
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Team Name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            {roleIsSellerAdmin && (
              <PrimaryButtonWithPlusIcon
                onClick={() => setShowCreateTeamSlideOut(true)}
                style={{ padding: "8px 12px" }}
              >
                {t("Create")}
              </PrimaryButtonWithPlusIcon>
            )}
            <SlideOut
              show={showCreateTeamSlideOut}
              closeFlyout={() => setShowCreateTeamSlideOut(false)}
            >
              <SellerAdminCreateNewTeam
                onCancel={() => setShowCreateTeamSlideOut(false)}
              />
            </SlideOut>
          </div>
        </Flex1>
      </Flex>

      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        ></DropDown>
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          lastChildleftAlign={true}
          error={teamsError}
          rowClick={handleRowClick}
          handleSort={handleSort}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
}
