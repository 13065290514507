import { DropDown } from "../../../components/DropDown/DropDown";
import { Pagination } from "../../../components/Pagination/Pagination";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { IDMobile } from "../../../components/IDs/IDs";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import { Table } from "../../../components/Table/Table";
import { useDebounce } from "../../../util/hooks";
import type {
  IQuotesAPIResponse,
  IQuoteRequest,
  StatusAndId,
  QuoteStatus,
  User,
  QuoteRequestItem,
} from "../../../types/types";
import {
  ContentWrapper,
  FiltersWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import type { MouseEvent, ChangeEvent } from "react";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import {
  BuyerQuoteStatus,
  getBuyerQuoteStatusFilters,
  getQuoteItemsList,
  getQuoteProductsAsText,
} from "../../../components/QuoteStatus";
import {
  convertStringArrayToObj,
  formatDateTime,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { useRoutePath } from "../../../util/Routing";
import { useTranslation } from "react-i18next";
import { Auth } from "../../../components/Auth";
import ReactTooltip from "react-tooltip";
import { calculatePriceDetails } from "../../../components/PriceDetails/PriceDetails";
import { TooltipCell, constructQuery } from "../BuyerOrders/BuyerOrders.util";
import { TrimmedName } from "../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type { Getter } from "@tanstack/react-table";
import {
  IconWrapper,
  NumberBadge,
} from "../../SharedPages/SellerOrdersListPage/SellerOrdersListPage";
import { MailIcon } from "../../../components/Icons/Icons";

type IBuyerQuotesListProps = {};

type QuotesTable = {
  id: string;
  products: { items: QuoteRequestItem[]; id: string };
  total_value: string;
  last_activity: string;
  status: StatusAndId<QuoteStatus>;
  unread_message_count?: number;
};

export const BuyerQuotesList = (props: IBuyerQuotesListProps) => {
  const { t } = useTranslation();

  const localStrings = {
    pageTitle: t("Quotes"),
    searchBarPlaceholder: t("Search by Product or Quote ID"),
    status: t("Status"),
    perPage: t(" Per Page"),
  };
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });

  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50, 100];
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    ((query?.status ?? []).filter(
      (status) => typeof status === "string"
    ) as string[]) ?? []
  );

  const history = useHistory();

  const { accountPath } = useRoutePath();
  const { user } = useContext(Auth);
  const { storefront_id } = useStoreState();
  const { quotesNotifications } = useInAppNotifications(
    storefront_id,
    user as User
  );

  const { data: quotesResponse, error: quotesError } = useSWR(
    constructQuery({
      baseUrl: `/v1/storefronts/${storefront_id}/quotes?`,
      query: debouncedSearchQuery,
      status: selectedStatusList,
      offset,
      perPage,
    })
  );

  const isLoading = !quotesResponse && !quotesError;

  const [tableData, setTableData] = useState<QuotesTable[]>([]);

  const hasUnreadMessages = tableData.some(
    (item) => item.unread_message_count !== 0
  );

  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Quote ID"),
        accessorKey: "number",
        cell: ({ getValue }: { getValue: Getter<string> }) => (
          <IDMobile>{`${getValue()}`}</IDMobile>
        ),
      },
      {
        header: t("Product(s)"),
        accessorKey: "products",
        cell: ({
          getValue,
        }: {
          getValue: Getter<{ items: QuoteRequestItem[]; id: string }>;
        }) => {
          return (
            <TooltipCell data-for={`${getValue().id}-tooltip`} data-tip={""}>
              <TrimmedName text={getQuoteProductsAsText(getValue().items, t)} />
              <ReactTooltip
                id={`${getValue().id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                backgroundColor="#60676F"
                multiline={true}
              >
                {getQuoteItemsList(getValue().items)}
              </ReactTooltip>
            </TooltipCell>
          );
        },
      },
      {
        header: t("Total Value"),
        accessorKey: "total_value",
      },
      {
        header: t("Last Activity"),
        accessorKey: "last_activity",
      },
      ...(hasUnreadMessages
        ? [
            {
              header: "",
              accessorKey: "unread_message_count",
              width: 50,
              cell: ({ getValue }: { getValue: () => number }) => {
                const count = getValue();

                return count !== 0 ? (
                  <IconWrapper>
                    <MailIcon width={24} height={24} />
                    <NumberBadge>{count as number}</NumberBadge>
                  </IconWrapper>
                ) : null;
              },
            },
          ]
        : []),
      {
        header: t("Status"),
        accessorKey: "status",
        width: 50,
        minWidth: 50,
        align: "right",
        cell: ({
          getValue,
        }: {
          getValue: Getter<StatusAndId<QuoteStatus>>;
        }) => (
          <BuyerQuoteStatus
            status={getValue().status}
            id={getValue().id}
            t={t}
          />
        ),
      },
    ],
    [t, hasUnreadMessages]
  );

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  useEffect(() => {
    setQuery({ offset, perPage, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      getBuyerQuoteStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    const getUnread = (quote: IQuoteRequest): boolean => {
      return quotesNotifications?.ids
        ? quotesNotifications?.ids.includes(quote?.id)
        : false;
    };
    const handleQuotesData = ({ data, pagination }: IQuotesAPIResponse) => {
      setTableData(
        data.map((quote: IQuoteRequest) => ({
          id: quote.id,
          number: quote.number,
          products: { items: quote.items, id: quote.id },
          total_value: quote.items.length
            ? (() => {
                const { totalString } = calculatePriceDetails({
                  items: quote.items,
                  fees: quote.fees,
                  // It should be impossible for quote items to have different
                  // currencies. This is just a weird API design thing.
                  currencyCode: quote.items[0].currency,
                });

                return totalString;
              })()
            : "-/-",
          last_activity: formatDateTime(quote.modified_at) || "--",
          status: { status: quote.status || "--", id: quote.id },
          unread: getUnread(quote),
          unread_message_count: quote.unread_message_count,
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (quotesResponse) {
      const { data: quotes, pagination } = quotesResponse;
      handleQuotesData({ data: quotes, pagination });
    }
  }, [quotesResponse, setTableData, perPage, quotesNotifications]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (e: MouseEvent) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));
    (
      (query?.status ?? []).filter(
        (status) => typeof status === "string"
      ) as string[]
    ).forEach((status) => params.append("status", status));
    history.push(`${accountPath}/quotes/${e.currentTarget.id}?${params}`);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings.pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={localStrings.searchBarPlaceholder}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </PageHeader>

      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={getBuyerQuoteStatusFilters(t)}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textRight={localStrings.perPage}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={quotesError}
          rowClick={handleRowClick}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        ></Pagination>
      </ContentWrapper>
    </PageWrapper>
  );
};
