import type { AxiosError } from "axios";
import axios from "axios";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ConfirmOrCancelButtonContainer } from "../../../../components/Buttons/Buttons";
import { ErrorPlaceholder } from "../../../../components/Error";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import { Form } from "../../../../layout/FormLayout";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import type {
  OptionType,
  TeamDetailsSchema,
  UUID,
  WithPagination,
} from "../../../../types/types";
import { useFormWrapper, useStoreState } from "../../../../util/util";

export function AddTeamsToProducts({
  productID,
  onCancel,
  onSuccess,
}: {
  productID: UUID;
  onCancel: () => void;
  onSuccess: () => Promise<void>;
}) {
  const { formState, errors, control, handleSubmit } = useFormWrapper();

  const { t } = useTranslation();

  const { tenant_id } = useStoreState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: teams, error: teamsError } = useSWR<
    WithPagination<{ data: TeamDetailsSchema[] }>,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/teams`);

  const { notifyError, notifySuccess } = useNotifications();
  const teamOptions = teams
    ? teams.data.reduce<OptionType<string>[]>(
        (acc, { team_name, team_id, status }) =>
          status === "archived"
            ? acc
            : [
                ...acc,
                {
                  label: team_name,
                  value: team_id,
                },
              ],
        []
      )
    : [];

  const onSubmit = async (value: any) => {
    setIsSubmitting(true);
    try {
      await axios.post(
        `/v2/tenants/${tenant_id}/pim/products/${productID}/teams`,
        {
          visibility: "team_specific",
          team_ids: [value.team.value],
        }
      );
      notifySuccess("Team added successfully");
      setIsSubmitting(false);
      await onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error adding the team"),
        {
          error,
        }
      );
      setIsSubmitting(false);
    }
  };

  if (teamsError) {
    return (
      <ErrorPlaceholder message={t("There was an error loading the teams")} />
    );
  }

  return (
    <>
      <PageTitle>{t("Add Team")}</PageTitle>
      <ContentWrapper>
        <PageWrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
              <FullWidthHorizontalSeparator />
            </div>
            <Controller
              as={SelectBoxV2}
              control={control}
              name={"team"}
              placeholder={t("Select Team")}
              options={teamOptions}
              isClearable
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
            <ConfirmOrCancelButtonContainer
              isConfirmLoading={isSubmitting}
              onConfirm={handleSubmit(onSubmit)}
              onCancel={onCancel}
            />
          </Form>
        </PageWrapper>
      </ContentWrapper>
    </>
  );
}
